import React, { useEffect, useRef, useState } from "react";
import ReactToPrint from 'react-to-print';
import Logo from './frame-10000027081.png'
import Signature from './Picture 1.png'
import { BASE_URL, FRONTEND_URL } from "../../config/axios";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import numberToWords from 'number-to-words';

const DataSalarySlip = React.forwardRef((props, ref) => {
  let { month, year, id } = useParams();
  const location = useLocation();
  const { i, monthh, yearr } = location.state;

  console.log("i")
  console.log(i)

  const monthNames = [
    "JANUARY", "FEBRUARY", "MARCH", "APRIL", "MAY", "JUNE", "JULY",
    "AUGUST", "SEPTEMBER", "OCTOBER", "NOVEMBER", "DECEMBER"
  ];

  const monthName = monthNames[monthh - 1];

  const [word, setWord] = useState("");

  useEffect(() => {
    setWord(numberToWords.toWords(i.salarypay || 0))
  }, []);

  return (
    // <>
    <div className='' ref={ref}>
      <div className='salaryslip'>
        <div className='salaryslip-header'>
          <div className='salaryslip-header-left'>
            <img src={Logo} alt='logo' className='salaryslip-logo' />
            <div className='salaryslip-header-left-text  font-size-heading'>
              SALARY SLIP FOR THE MONTH OF {monthName} {yearr}
            </div>
          </div>
          <div className='salaryslip-header-right'>
            <div className='salaryslip-header-right-top  font-weight600   font-size-heading'>
              Civilmantra infracon private limited
            </div>
            <div className='salaryslip-header-right-bottom  font-weight400  font-size-subheading'>
              3rd Floor, Tower 3A,<br /> DLF Corporate Greens, Sector 74A,<br /> Gurugram, Haryana 122004 <br /> services@civilmantra.com
            </div>
          </div>
        </div>
        <div className='salaryslip-hr'></div>
        <div className='salaryslip-header-second'>
          <div className='salaryslip-header-second-section1'>
            <div className='salaryslip-heading-black   font-weight600 font-size-subheading'>NAME</div>
            <div className='salaryslip-heading-black   font-weight600 font-size-subheading'>FATHER / HUSBAND</div>
            <div className='salaryslip-heading-black   font-weight600 font-size-subheading'>DATE OF JOINING</div>
            <div className='salaryslip-heading-black   font-weight600 font-size-subheading'>DEPARTMENT</div>


          </div>
          <div className='salaryslip-header-second-section2'>
            <div className='salaryslip-heading-black1  font-weight400  font-size-subheading'>{i.name}</div>
            <div className='salaryslip-heading-black1  font-weight400  font-size-subheading'>{i.user_details?.father_name}</div>
            <div className='salaryslip-heading-black1  font-weight400  font-size-subheading'>{i.user_details?.joining_date}</div>
            <div className='salaryslip-heading-black1  font-weight400  font-size-subheading'>{i.user_details?.department_name}</div>
          </div>
          <div className='salaryslip-header-second-section3'>
          </div>
          <div className='salaryslip-header-secondsection4'>
            <div className='salaryslip-heading-black   font-weight600 font-size-subheading'>CODE</div>
            <div className='salaryslip-heading-black   font-weight600 font-size-subheading'>BANK</div>
            <div className='salaryslip-heading-black   font-weight600 font-size-subheading'>ACCOUNT NO.</div>
            <div className='salaryslip-heading-black   font-weight600 font-size-subheading'>IFSC NO.</div>
            {/* <div className='salaryslip-heading-black   font-weight600 font-size-subheading'>UAN NO.</div> */}
          </div>
          <div className='salaryslip-header-second-section5'>
            <div className='salaryslip-heading-black1  font-weight400  font-size-subheading'>{i.emp_code}</div>
            <div className='salaryslip-heading-black1  font-weight400  font-size-subheading'>{i?.bank_details?.bank_name}</div>
            <div className='salaryslip-heading-black1  font-weight400  font-size-subheading'>{i?.bank_details?.account_number}</div>
            <div className='salaryslip-heading-black1  font-weight400  font-size-subheading'>{i?.bank_details?.ifsc_code}</div>
            {/* <div className='salaryslip-heading-black1  font-weight400  font-size-subheading'>{i.bank_details.branch_address}</div> */}
          </div>
        </div>
        <div className='salaryslip-hr'></div>
        <div className='salaryslip-header-third'>
          <div className='salaryslip-header-third-section1'>
            <div className='salaryslip-heading-blue font-weight500 font-size-subheading'>TOTAL DAYS</div>
            <div className='salaryslip-heading-blue font-weight500 font-size-subheading'>TOTAL LEAVES</div>
            <div className='salaryslip-heading-blue font-weight500 font-size-subheading'>TOTAL ABSENT</div>
            <div className='salaryslip-heading-blue font-weight500 font-size-subheading'>WORKING DAYS</div>
          </div>
          <div className='salaryslip-header-third-section2'>
            <div className='salaryslip-heading-blue font-weight500'>{i.total_days}</div>
            <div className='salaryslip-heading-blue font-weight500'>{i.attendance_summary?.leave}</div>
            <div className='salaryslip-heading-blue font-weight500'>{i.attendance_summary?.absent}</div>
            <div className='salaryslip-heading-blue font-weight500'>{i.total_paid_days}</div>

          </div>
          <div className='salaryslip-header-third-section3'>
            <div className='salaryslip-heading-blue font-weight500 font-size-subheading'>WEEKLY OFF</div>
            <div className='salaryslip-heading-blue font-weight500 font-size-subheading'>LEAVE WITHOUT PAY</div>
            <div className='salaryslip-heading-blue font-weight500 font-size-subheading'>HOLIDAY</div>
            <div className='salaryslip-heading-blue font-weight500 font-size-subheading'>NET PAID DAYS</div>

          </div>
          <div className='salaryslip-header-third-section4'>
            <div className='salaryslip-heading-blue font-weight500'>{i.attendance_summary?.weeklyoff}</div>
            <div className='salaryslip-heading-blue font-weight500'>{i.attendance_summary?.unpaidleave}</div>
            <div className='salaryslip-heading-blue font-weight500'>{i.attendance_summary?.holiday}</div>
            <div className='salaryslip-heading-blue font-weight500'>{i.total_paid_days}</div>
          </div>
        </div>
        <div className='salaryslip-body'>
          <table className='nested-table-lightblue'>
            <thead>
              <tr className='slaryslip-head'>
                {/* <th >S. No.</th> */}
                <th style={{ width: "260px", padding: "0px 5px" }}>Salary Component</th>
                <th >AMOUNT</th>
                <th >STANDARD</th>
              </tr>
            </thead>
            <tbody>
              {
                i.deduction_details?.sort((a, b) => a.sorting_var - b.sorting_var)
                  .map((j, index) => {
                    return (
                      j.salary_type === "total" || j.salary_type === "difference" ?
                        <tr className="slaryslip-head">
                          {/* <th className='salary-table-td'>{j.sorting_var}</th> */}
                          <th className='salary-table-td'>{j.name}</th>
                          <th className='salary-table-td'>{j.actual_amount}</th>
                          <th className='salary-table-td'>{j.standard_amount}</th>
                        </tr>
                        :
                        <tr className="slaryslip-head">
                          {/* <td className='salary-table-td'>{j.sorting_var}</td> */}
                          <td className='salary-table-td'>{j.name}</td>
                          <td className='salary-table-td'>{j.actual_amount}</td>
                          <td className='salary-table-td'>{j.standard_amount}</td>
                        </tr>

                    )
                  })
              }
              <tr>


                <td>BASIC SALARY</td>
                <td className='salary-table-td'>{i.stdbasicsalary}</td>
                <td className='salary-table-td'>{i.basicsalary}</td>
                <td>TDS</td>
                <td className='salary-table-td'>{i.stdtds}</td>
                <td className='salary-table-td'>{i.tds}</td>
              </tr>
              <tr>
                <td>HRA</td>
                <td className='salary-table-td'>{i.stdhra}</td>
                <td className='salary-table-td'>{i.hra}</td>
                <td>ESI</td>
                <td className='salary-table-td'>{i.stdesideduction}</td>
                <td className='salary-table-td'>{i.esideduction}</td>
              </tr>
              <tr>
                <td>TRANSPORT</td>
                <td className='salary-table-td'>{i.stdtransport}</td>
                <td className='salary-table-td'>{i.transport}</td>
                <td>PF</td>
                <td className='salary-table-td'>{i.stdpfdeduction}</td>
                <td className='salary-table-td'>{i.pfdeduction}</td>
              </tr>
              <tr>
                <td>SPECIAL ALLOWANCE</td>
                <td className='salary-table-td'>{i.stdspecialallowance}</td>
                <td className='salary-table-td'>{i.specialallowance}</td>
                <td></td>
                <td></td>
                <td></td>


              </tr>
              <tr>
                <td>ARREAR</td>
                <td className='salary-table-td'>-</td>
                <td className='salary-table-td'>{i.arial}</td>
                <td>Advance</td>
                <td className='salary-table-td'>-</td>
                <td className='salary-table-td'>{i.advance}</td>

              </tr>
              <tr>
                <td colSpan={6} className='salaryslip-hrr'></td>
              </tr>
              <tr>
                <th >TOTAL EARNINGS</th>
                <td className='salary-table-td'>{i.stdtotalearnings}</td>
                <td className='salary-table-td'>{i.totalearnings}</td>
                <th>TOTAL DEDUCTIONS</th>
                <td className='salary-table-td'> {i.stdtotaldeductions}</td>
                <td className='salary-table-td'> {i.totaldeductions}</td>

              </tr>
              <tr>
                <td className='salaryslip-heading-blue font-weight500 font-size-subheading'>SALARY PAY</td>
                <td></td>
                <td className='salary-table-td'>{i.salarypay}</td>

              </tr>
              <tr>
                <th>IN WORDS</th>
                <td colSpan={5} style={{ paddingLeft: "30px" }}>{word}</td>
              </tr>


            </tbody>

            <thead style={{ margin: "20px 0px" }}>
              <tr className='slaryslip-head' >
                <th style={{ width: "260px", padding: "0px 5px" }}>EMPLOYER CONTRIBUTION</th>
                <th style={{ width: "90px", padding: "0px 5px" }}>STANDARD</th>
                <th style={{ width: "80px", padding: "0px 5px" }}>AMOUNT</th>
                <th style={{ width: "170px", padding: "0px 5px" }}></th>
                <th style={{ width: "100px", padding: "0px 5px" }}>STANDARD</th>
                <th style={{ width: "80px", padding: "0px 5px" }}>AMOUNT</th>


              </tr>
            </thead>

            <tbody>
              <tr>
                <td>ESI</td>
                <td className='salary-table-td'>{i.stdesi}</td>
                <td className='salary-table-td'>{i.esi}</td>
                <td>CTC MONTHLY</td>
                <td className='salary-table-td'>{i.stdctcofmonth}</td>
                <td className='salary-table-td'>{i.ctcofmonth}</td>
              </tr>
              <tr>
                <td>PF</td>
                <td className='salary-table-td'>{i.stdepf}</td>
                <td className='salary-table-td'>{i.epf}</td>
                <td>CTC YEARLY</td>
                <td className='salary-table-td'>{i.stdctcofyear}</td>
                <td className='salary-table-td'>{i.ctcofyear}</td>
              </tr>
              <tr>
                <td>GRATUITY</td>
                <td className='salary-table-td'>{i.stdgratuity}</td>
                <td className='salary-table-td'>{i.gratuity}</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>STATUTORY BONUS</td>
                <td className='salary-table-td'>{i.stdbonus}</td>
                <td className='salary-table-td'>{i.bonus}</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td colSpan={6} className='salaryslip-hrr'></td>
              </tr>
              <tr>
                <th >TOTAL EMPLOYER CONTRI.</th>
                <td className='salary-table-td'>{i.stdemployercontributions}</td>
                <td className='salary-table-td'>{i.employercontributions}</td>
                <th></th>
                <td > </td>
                <td className='salary-table-td'></td>

              </tr>



            </tbody>

            <tr>

            </tr>


          </table>

          {/* <table className='salary-table'>
            <thead>
              <tr className='slaryslip-head'>
                <th style={{ width: "260px", padding: "0px 5px" }}>EARNINGS</th>
                <th style={{ width: "90px", padding: "0px 5px" }}>STANDARD</th>
                <th style={{ width: "80px", padding: "0px 5px" }}>AMOUNT</th>
                <th style={{ width: "170px", padding: "0px 5px" }}>DEDUCTIONS</th>
                <th style={{ width: "100px", padding: "0px 5px" }}>STANDARD</th>
                <th style={{ width: "80px", padding: "0px 5px" }}>AMOUNT</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>BASIC SALARY</td>
                <td className='salary-table-td'>{i.stdbasicsalary}</td>
                <td className='salary-table-td'>{i.basicsalary}</td>
                <td>TDS</td>
                            <td className='salary-table-td'>{i.stdtds}</td>
                <td className='salary-table-td'>{i.tds}</td>
              </tr>
              <tr>
                <td>HRA</td>
                <td className='salary-table-td'>{i.stdhra}</td>
                <td className='salary-table-td'>{i.hra}</td>
                <td>ESI</td>
                <td className='salary-table-td'>{i.stdesideduction}</td>
                <td className='salary-table-td'>{i.esideduction}</td>
              </tr>
              <tr>
                <td>TRANSPORT</td>
                <td className='salary-table-td'>{i.stdtransport}</td>
                <td className='salary-table-td'>{i.transport}</td>
                <td>PF</td>
                <td className='salary-table-td'>{i.stdpfdeduction}</td>
                <td className='salary-table-td'>{i.pfdeduction}</td>
              </tr>
              <tr>
                <td>SPECIAL ALLOWANCE</td>
                <td className='salary-table-td'>{i.stdspecialallowance}</td>
                <td className='salary-table-td'>{i.specialallowance}</td>
                <td></td>
                <td></td>
                <td></td>


              </tr>
              <tr>
                <td>ARREAR</td>
                <td className='salary-table-td'>-</td>
                <td className='salary-table-td'>{i.arial}</td>
                <td>Advance</td>
                <td className='salary-table-td'>-</td>
                <td className='salary-table-td'>{i.advance}</td>

              </tr>
              <tr>
                <td colSpan={6} className='salaryslip-hrr'></td>
              </tr>
              <tr>
                <th >TOTAL EARNINGS</th>
                <td className='salary-table-td'>{i.stdtotalearnings}</td>
                <td className='salary-table-td'>{i.totalearnings}</td>
                <th>TOTAL DEDUCTIONS</th>
                <td className='salary-table-td'> {i.stdtotaldeductions}</td>
                <td className='salary-table-td'> {i.totaldeductions}</td>

              </tr>
              <tr>
                <td className='salaryslip-heading-blue font-weight500 font-size-subheading'>SALARY PAY</td>
                <td></td>
                <td className='salary-table-td'>{i.salarypay}</td>

              </tr>
              <tr>
                <th>IN WORDS</th>
                <td colSpan={5} style={{ paddingLeft: "30px" }}>{word}</td>
              </tr>


            </tbody>

            <thead style={{ margin: "20px 0px" }}>
              <tr className='slaryslip-head' >
                <th style={{ width: "260px", padding: "0px 5px" }}>EMPLOYER CONTRIBUTION</th>
                <th style={{ width: "90px", padding: "0px 5px" }}>STANDARD</th>
                <th style={{ width: "80px", padding: "0px 5px" }}>AMOUNT</th>
                <th style={{ width: "170px", padding: "0px 5px" }}></th>
                <th style={{ width: "100px", padding: "0px 5px" }}>STANDARD</th>
                <th style={{ width: "80px", padding: "0px 5px" }}>AMOUNT</th>


              </tr>
            </thead>

            <tbody>
              <tr>
                <td>ESI</td>
                <td className='salary-table-td'>{i.stdesi}</td>
                <td className='salary-table-td'>{i.esi}</td>
                <td>CTC MONTHLY</td>
                <td className='salary-table-td'>{i.stdctcofmonth}</td>
                <td className='salary-table-td'>{i.ctcofmonth}</td>
              </tr>
              <tr>
                <td>PF</td>
                <td className='salary-table-td'>{i.stdepf}</td>
                <td className='salary-table-td'>{i.epf}</td>
                <td>CTC YEARLY</td>
                <td className='salary-table-td'>{i.stdctcofyear}</td>
                <td className='salary-table-td'>{i.ctcofyear}</td>
              </tr>
              <tr>
                <td>GRATUITY</td>
                <td className='salary-table-td'>{i.stdgratuity}</td>
                <td className='salary-table-td'>{i.gratuity}</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>STATUTORY BONUS</td>
                <td className='salary-table-td'>{i.stdbonus}</td>
                <td className='salary-table-td'>{i.bonus}</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td colSpan={6} className='salaryslip-hrr'></td>
              </tr>
              <tr>
                <th >TOTAL EMPLOYER CONTRI.</th>
                <td className='salary-table-td'>{i.stdemployercontributions}</td>
                <td className='salary-table-td'>{i.employercontributions}</td>
                <th></th>
                <td > </td>
                <td className='salary-table-td'></td>

              </tr>



            </tbody>

            <tr>

            </tr>


          </table> */}
          <br />
          <h6 style={{ fontSize: '10px' }}><b>*This is Software generated Salary Slip no signature required*</b></h6>

        </div>
        {/* <div className='salaryslip-hr'></div> */}
        {/* <div className='salaryslip-bottom'>
          <img src={Signature} alt='signature' className='slaryslip-bg-signature' />
          <th>AUTHORISED SIGNATURE</th>
        </div> */}
      </div>
      <div className='slaryslip-bg'></div>
    </div>
    // </>
  )
});
const PrintDynamicSalarySlipData = () => {
  const ref = useRef();
  const navigate = useNavigate()
  const currentUrl = window.location.href;
  const urlParts = currentUrl.split('/');
  const backUrl = "/" + urlParts[3] + `/payrollmanagement`;

  const backroute = () => {
    navigate(backUrl)

  }
  return (

    <div>
      <button className="model-button   font-weight500   " onClick={backroute}>Back</button>
      <ReactToPrint
        bodyClass="print-agreement"
        content={() => ref.current}
        trigger={() => (
          <button>
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 20 18" fill="none">
              <path d="M17 5H3C1.34 5 0 6.34 0 8V12C0 13.1 0.9 14 2 14H4V16C4 17.1 4.9 18 6 18H14C15.1 18 16 17.1 16 16V14H18C19.1 14 20 13.1 20 12V8C20 6.34 18.66 5 17 5ZM13 16H7C6.45 16 6 15.55 6 15V11H14V15C14 15.55 13.55 16 13 16ZM17 9C16.45 9 16 8.55 16 8C16 7.45 16.45 7 17 7C17.55 7 18 7.45 18 8C18 8.55 17.55 9 17 9ZM15 0H5C4.45 0 4 0.45 4 1V3C4 3.55 4.45 4 5 4H15C15.55 4 16 3.55 16 3V1C16 0.45 15.55 0 15 0Z" fill="black" />
            </svg> Print
          </button>
        )}
      />

      <DataSalarySlip ref={ref} />
    </div>
  );
};
export default PrintDynamicSalarySlipData
