import { FRONTEND_URL, IMAGE_URL } from "../../config/axios"
import usePermission from "../../config/permissions";
import OurCompanyDetails from "../career/update_details/OurCompanydetailsupdate"
import { UpdateEmployeeData } from "./components/AddNewEmployeeModel";
import EmployeeCard from "./EmployeeCard";

const EmployeeDetailCard = ({ getAllEmployee, filteredEmployee, Viewdetails, departmentname, designationname, rhname, SubCompany, attendpolicy, weeklypolicy, worksite }) => {
    const { PERMISSION_ROLE, PERMISSION_AUTHORITY } = usePermission(); // Use the custom hook

    return (
        <div className='employee-cards'>

            {filteredEmployee.sort((a, b) => a.emp_code - b.emp_code).map((e, index) => {
                return (
                    <div className='employee-card'>
                        <div className='employee-card-a'>



                            <div className='employee-card-image'>
                                <div className='employee-card-image-background'>
                                    <img src={e.profilepic != "" ? `${IMAGE_URL}${e.profilepic}` : 'https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'} alt="card-pic" className="employee-card-imageee" />
                                </div>
                                <div className='employee-card-icons'>
                                    <div className='employee-card-name font-weight500   font-size-subheading'>{e.name}</div>
                                    <div className='employee-card-designation font-weight400   font-size-subheading'>{e.designation_name}</div>
                                    <div className="employee-card-name font-weight500   font-size-subheading">Emp Code: {e.emp_code}</div>


                                </div>

                            </div>

                            <div className="flex-row">
                                {PERMISSION_ROLE ?

                                    <>
                                        {/* <OurCompanyDetails e={e} departmentname={departmentname}
                                            designationname={designationname}
                                            rhname={rhname}
                                            SubCompany={SubCompany}
                                            attendpolicy={attendpolicy}
                                            weeklypolicy={weeklypolicy}
                                            worksite={worksite} /> */}
                                        <UpdateEmployeeData
                                            i={e}
                                            SubCompany={SubCompany}
                                            getAllEmployee={getAllEmployee}
                                        />
                                        <a href={`${FRONTEND_URL}/personaldetailss/${e.user_detail}/`} className="model-hue-button" style={{ marginRight: '10px' }} target="_blank" title="Edit Profile Details" rel="noopener noreferrer">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="18"
                                                height="18"
                                                data-name="Layer 1"
                                                viewBox="0 0 24 24"
                                                className='employee-detail-edit-svg'
                                                id="edit">
                                                <path d="M3.5,24h15A3.51,3.51,0,0,0,22,20.487V12.95a1,1,0,0,0-2,0v7.537A1.508,1.508,0,0,1,18.5,22H3.5A1.508,1.508,0,0,1,2,20.487V5.513A1.508,1.508,0,0,1,3.5,4H11a1,1,0,0,0,0-2H3.5A3.51,3.51,0,0,0,0,5.513V20.487A3.51,3.51,0,0,0,3.5,24Z"></path><path d="M9.455,10.544l-.789,3.614a1,1,0,0,0,.271.921,1.038,1.038,0,0,0,.92.269l3.606-.791a1,1,0,0,0,.494-.271l9.114-9.114a3,3,0,0,0,0-4.243,3.07,3.07,0,0,0-4.242,0l-9.1,9.123A1,1,0,0,0,9.455,10.544Zm10.788-8.2a1.022,1.022,0,0,1,1.414,0,1.009,1.009,0,0,1,0,1.413l-.707.707L19.536,3.05Zm-8.9,8.914,6.774-6.791,1.4,1.407-6.777,6.793-1.795.394Z">
                                                </path>
                                            </svg>
                                        </a>
                                    </>
                                    : null}

                                <button className='employee-edit' onClick={() => Viewdetails(e.emp_code, e.user_detail)} title="View Profile">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" className='employee-edit-svg'>
                                        <path d="M9 9.75C9.41421 9.75 9.75 9.41421 9.75 9C9.75 8.58579 9.41421 8.25 9 8.25C8.58579 8.25 8.25 8.58579 8.25 9C8.25 9.41421 8.58579 9.75 9 9.75Z" stroke="#2576BC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M9 4.5C9.41421 4.5 9.75 4.16421 9.75 3.75C9.75 3.33579 9.41421 3 9 3C8.58579 3 8.25 3.33579 8.25 3.75C8.25 4.16421 8.58579 4.5 9 4.5Z" stroke="#2576BC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M9 15C9.41421 15 9.75 14.6642 9.75 14.25C9.75 13.8358 9.41421 13.5 9 13.5C8.58579 13.5 8.25 13.8358 8.25 14.25C8.25 14.6642 8.58579 15 9 15Z" stroke="#2576BC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg></button>
                            </div>
                        </div>
                        <div className='employee-card-b'>
                            <div className='employee-card-b-flex'>
                                <div className="cart-subdiv-flex" ><span className='employee-card-b-heading  font-weight400  font-size-label'>Department  :  </span><span className='employee-card-b-text  font-weight400 ing font-size-label'>{e.department_name}</span></div>
                                <div className="cart-subdiv-flex" ><span className='employee-card-b-heading  font-weight400  font-size-label'>Email  :  </span><span className='employee-card-b-text  font-weight400 ing font-size-label'>{e.user_detail}</span></div>

                            </div>
                            <div className='employee-card-b-flex'>
                                <div className="cart-subdiv-flex" ><span className='employee-card-b-heading  font-weight400  font-size-label'>Location  :  </span><span className='employee-card-b-text  font-weight400 ing font-size-label'>{e.location_name}</span></div>
                                <div className="cart-subdiv-flex" ><span className='employee-card-b-heading  font-weight400  font-size-label'>Mobile No :  </span><span className='employee-card-b-text  font-weight400 ing font-size-label'>{e.mobile_number}</span></div>
                            </div>

                        </div>


                    </div >
                )
                {/* <EmployeeCard
                        e={e}
                        key={index}
                        name={e.name}
                        about={e.about || `Emp Code: ${e.emp_code}`}
                        profileImage={e.profilepic != "" ? `${IMAGE_URL}${e.profilepic}` : 'https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'} // Assuming profileImage is a relative path
                        filteredEmployee={filteredEmployee} Viewdetails={Viewdetails} 
                        /> */}
            })}




        </div >)
}
export default EmployeeDetailCard