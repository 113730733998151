import React, { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { Modal, Form } from "react-bootstrap";
import { BASE_URL } from "../../../config/axios";
import { AddwithBlueCircle, AddwithWhiteCircle, DeleteDustbin, DropdownArrowOption } from "../../AllSvg";
import { customSortByKey, formatCurrencyIndian, getFileNameFromLink, handleAllError, handleErrorToast, ViewFile, ViewOtherFile } from "../../CustomFunctions";
import { UpdatePencil } from "../../AllSvg";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import * as XLSX from 'xlsx';




const UploadSalaryDataBulk = ({ id, getPayrunlist }) => {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);



    const [fileName, setFileName] = useState("");
    const [excelJson, setExcelJson] = useState(null); // State to store the converted JSON data
    const [docErrors, setDocErrors] = useState({});
    const [docInputState, setDocInputState] = useState({});

    const [jsonData, setJsonData] = useState(null);


    const [salaryStructureHeadings, setSalaryStructureHeadings] = useState([]);


    useEffect(() => {
        getAllSalaryRelatedData();
    }, []);

    const getAllSalaryRelatedData = async (emp_code) => {
        try {


            const headings = await axios.get(`${BASE_URL}/wfm/salaryHeadingbysubcompany/${sessionStorage.getItem("company_id")}/`);
            setSalaryStructureHeadings(headings.data);

        } catch (err) {
            console.error("Error fetching salary data", err);
        }
    };

    const bulktableRef = useRef(null);
    const handleDownload = () => {
        const table = bulktableRef.current;
        const wb = XLSX.utils.table_to_book(table, { sheet: "Attendance Sheet" });
        const wbout = XLSX.write(wb, { bookType: "xls", type: "binary" });

        const blob = new Blob([s2ab(wbout)], {
            type: "application/vnd.ms-excel",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Salary Sheet Format.xls";
        a.click();
        window.URL.revokeObjectURL(url);
    };

    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
    };


    const handleClose = () => {
        setFileName("")
        setExcelJson(null)
        setDocErrors({})
        setShow(false)
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        console.log(file)
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = new Uint8Array(e.target.result);

                const workbook = XLSX.read(data, { type: 'array' });

                console.log("workbook")
                console.log(workbook)

                // Assuming the sheet with attendance data is named "Attendance"
                const worksheet = workbook.Sheets['Attendance Sheet'];

                const rawData = XLSX.utils.sheet_to_json(worksheet);
                console.log("rawData")
                console.log(rawData)
                // Transform rawData into the required format for uploading

                setJsonData(rawData);
            };
            reader.readAsArrayBuffer(file);
            setFileName(file.name);
        }
    };

    const [loading, setLoading] = useState(false); //loading logic
    const handleSubmit = async () => {
        setLoading(true); //loading logic
        const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
        if (!jsonData) {
            alert("Please upload an Excel file first!");
            return;
        }
        try {
            const response = await axios.post(`${BASE_URL}/wfm/bulkcustomdeductionupload/`, jsonData);
            // alert('Attendance uploaded successfully!');
            toast.success('Attendance uploaded successfully!');
            await getPayrunlist()
            handleClose()
        } catch (error) {
            console.error('Error uploading attendance:', error);
            toast.err('Error uploading Slary Structures.');
            // alert('Error uploading Slary Structures.');
        } finally {
            setLoading(false); //loading logic
            toast.dismiss(loadingToastId);
        }
    };

    const [allEmployeeList, setAllEmployeeList] = useState([]);
    const [buffer, setBuffering] = useState(true);

    useEffect(() => {
        getAllEmployeeData();
    }, []);

    let getAllEmployeeData = async () => {
        setBuffering(true); //buffering logic
        try {
            const res = await axios.get(
                `${BASE_URL}/wfm/ourcompanyempdetailsactive/${sessionStorage.getItem("company_id")}/`
            );

            const sortedList = customSortByKey(res.data, "emp_code");

            setAllEmployeeList(sortedList);
            // setAllEmployeeList(res.data);
        } catch (err) {
            //toast Logic
            handleErrorToast(err);

        } finally {
            setBuffering(false); //buffering logic
        }
    };


    const [selectedIndex, setSelectedIndex] = useState(-1);

    const handleClick = (index) => {
        setSelectedIndex(index);
    };

    const tableRef = useRef(null);





    return (
        <>
            <button
                title="Bulk Upload Salary Data"
                className="model-button font-weight500 model-button-print"
                onClick={handleShow}
                style={{ margin: "2px" }}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="upload">
                    <g fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                        <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M14 5l-4-4-4 4M10 1v14"></path>
                    </g>
                </svg>
            </button>

            <Modal show={show} dialogClassName="ourcompany-model">
                <Modal.Header className="task-Tab-heading font-weight500    font-size-heading ">
                    <Modal.Title>Upload Bulk Salary Data in Format:</Modal.Title>

                    <button onClick={handleDownload} className="model-button-download model-button font-weight500" style={{ marginLeft: "35vw" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="download" fill="#fff">
                            <g fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                                <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M6 11l4 4 4-4M10 1v14"></path>
                            </g>
                        </svg>
                        {"  "} Format
                    </button>
                </Modal.Header>
                <Modal.Body>


                    <div className="table-css-white-background" style={{ display: "none" }}>
                        <table
                            ref={bulktableRef}
                            id="my-table"
                            style={{
                                borderColor: "#323232",
                                borderRadius: "5px",
                                padding: "0px 5px",
                                borderWidth: "1px",
                                // marginLeft: "10px",
                                // zoom: "70%",
                            }}
                        >
                            <thead                            >
                                <tr>
                                    <th className="summary-excel-tab br-5">emp_code</th>
                                    <th className="summary-excel-tab br-5">name</th>
                                    {salaryStructureHeadings.sort((a, b) => a.sorting_var - b.sorting_var).map((heading) => (
                                        <React.Fragment key={heading.id}>
                                            <th>{heading.title} fixed_amount</th>
                                            <th>{heading.title} percentage</th>
                                            <th>{heading.title} percentage_of</th>
                                        </React.Fragment>
                                    ))}
                                </tr>
                            </thead>
                            {
                                // buffer ? <div className="spinner"></div> :
                                <tbody>
                                    {allEmployeeList
                                        .sort((a, b) => a.emp_code - b.emp_code)
                                        .map((data, index) => {
                                            const inactivename = data.employee_status === "inactive" ? "#ff1212" : "black";
                                            return (
                                                <>
                                                    <tr
                                                        style={{
                                                            backgroundColor:
                                                                index === selectedIndex ? "yellow" : "white",
                                                            color: inactivename,
                                                        }}
                                                        onClick={() => handleClick(index)}
                                                    >
                                                        <td className="summary-excel-tab br-5">{data.emp_code}</td>
                                                        <td className="summary-excel-tab br-5">{data.name}</td>
                                                    </tr>
                                                </>
                                            );
                                        })}
                                </tbody>
                            }
                        </table>

                    </div>



                    <div className="flex-column field-cont-div">
                        <label htmlFor="document_file" className="form-labels font-weight500 font-size-heading">
                            Upload File
                        </label>
                        <label
                            htmlFor="document_file"
                            className={`align-center form-inputss custom-file-upload ${docErrors.document_file ? "error" : docInputState.document_file ? "success" : ""
                                }`}
                        >
                            <svg
                                className="pdf-input"
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 20 20"
                                fill="none"
                            >
                                <path
                                    d="M17.8666 9.2081L10.2082 16.8664C9.27005 17.8046 7.99757 18.3317 6.67075 18.3317C5.34393 18.3317 4.07145 17.8046 3.13325 16.8664C2.19505 15.9282 1.66797 14.6558 1.66797 13.3289C1.66797 12.0021 2.19505 10.7296 3.13325 9.79144L10.7916 2.1331C11.4171 1.50763 12.2654 1.15625 13.1499 1.15625C14.0345 1.15625 14.8828 1.50763 15.5082 2.1331C16.1337 2.75857 16.4851 3.60689 16.4851 4.49144C16.4851 5.37598 16.1337 6.2243 15.5082 6.84977L7.84158 14.5081C7.52885 14.8208 7.10469 14.9965 6.66242 14.9965C6.22014 14.9965 5.79598 14.8208 5.48325 14.5081C5.17051 14.1954 4.99482 13.7712 4.99482 13.3289C4.99482 12.8867 5.17051 12.4625 5.48325 12.1498L12.5582 5.0831"
                                    stroke="#707070"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </label>
                        <input
                            type="file"
                            accept=".xlsx, .xls"
                            onChange={handleFileUpload}
                            id="document_file"
                            name="document_file"
                            className="file-inputs align-center"
                            style={{ display: "none", position: "relative" }} />
                        {fileName && <p className="file-name align-center">{fileName}</p>}
                        <br />
                        {docErrors.document_file && <span className="file-name error-message font-size-text ">{docErrors.document_file}</span>}
                    </div>

                    <div className="button-models">
                        <button className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                            Cancel
                        </button>
                        <button className="model-button-download model-button font-weight500" onClick={handleSubmit} disabled={loading}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" id="upload">
                                <g fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                                    <path d="M1 16v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3M14 5l-4-4-4 4M10 1v14"></path>
                                </g>
                            </svg>
                            {"  "} {loading ? "Uploading..." : "Upload"}
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
            <ToastContainer position="top-center" autoClose={10000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </>
    );
};



const EmployeeSalaryStructure = ({
    code,
    getSalaryStructure,
}) => {

    const [show, setShow] = useState(false);
    const [customData, setCustomData] = useState([]);
    const handleClose = () => setShow(false);
    const handleShow = async () => {
        getAllSalaryRelatedData(code)
        setShow(true);
    };


    const [salaryData, setSalaryData] = useState({});
    const [deductionData, setDeductionData] = useState([]);
    const [salaryDeductionPolicies, setSalaryDeductionPolicy] = useState([]);
    const [salaryStructureHeadings, setSalaryStructureHeadings] = useState([]);



    const getAllSalaryRelatedData = async (emp_code) => {
        try {
            const salary = await axios.get(
                `${BASE_URL}/wfm/getsalarystructurebyemp/${emp_code}/`
            );
            setSalaryData(salary.data);
            const deduction = await axios.get(`${BASE_URL}/wfm/customdeductionbysalary_structure/${salary.data.id}/`);
            setDeductionData(deduction.data);

            const policies = await axios.get(`${BASE_URL}/wfm/salarypolicybysubcompany/${sessionStorage.getItem("company_id")}/`);
            setSalaryDeductionPolicy(policies.data);

            const headings = await axios.get(`${BASE_URL}/wfm/salaryHeadingbysubcompany/${sessionStorage.getItem("company_id")}/`);
            setSalaryStructureHeadings(headings.data);
            if (Array.isArray(headings.data)) {
                setSalaryStructureHeadings(headings.data);
                const customData = headings.data.map(item => ({
                    name: item.id,
                    salary_structure: salary.data.id,
                    salary_type: item.salary_type,
                    sorting_var: item.sorting_var,
                    percentage: '',
                    percentage_of: [],
                    fixed_amount: '',
                }));
                setCustomData(customData);
            } else {
                console.error("Error: Expected a list of Salary Headings");
            }
        } catch (err) {
            handleAllError(err)
        } finally {
        }
    };


    return (
        <>
            <button
                className="model-view-button model-add"
                title="Add Salary Details"
                onClick={handleShow}
            >
                {/* <svg
                    width="24"
                    height="25"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 64 64"
                    id="savings-account"
                >
                    <path d="M10.5 19h5.5c.55 0 1-.45 1-1s-.45-1-1-1h-3v-2c0-.55-.45-1-1-1s-1 .45-1 1v2h-.5c-1.93 0-3.5 1.57-3.5 3.5s1.57 3.5 3.5 3.5h3c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5h-5.5c-.55 0-1 .45-1 1s.45 1 1 1h3v2c0 .55.45 1 1 1s1-.45 1-1v-2h.5c1.93 0 3.5-1.57 3.5-3.5s-1.57-3.5-3.5-3.5h-3c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5zM31 16h-10c-.55 0-1 .45-1 1s.45 1 1 1h10c.55 0 1-.45 1-1s-.45-1-1-1zM31 22h-10c-.55 0-1 .45-1 1s.45 1 1 1h10c.55 0 1-.45 1-1s-.45-1-1-1zM31 28h-10c-.55 0-1 .45-1 1s.45 1 1 1h10c.55 0 1-.45 1-1s-.45-1-1-1z"></path>
                    <path d="m38,37c2.21,0,4-1.79,4-4v-4.37c1.82.87,3.85,1.37,6,1.37,7.72,0,14-6.28,14-14s-6.28-14-14-14c-5.17,0-9.68,2.82-12.11,7H6c-2.21,0-4,1.79-4,4v20c0,2.21,1.79,4,4,4h13v13h-3c-.55,0-1,.45-1,1v7c0,2.21,1.79,4,4,4h34c2.21,0,4-1.79,4-4v-27c0-.55-.45-1-1-1s-1,.45-1,1v27c0,1.1-.9,2-2,2h-2c-1.1,0-2-.9-2-2v-7c0-.55-.45-1-1-1h-27v-13h17Zm3.29-20.71c.39-.39,1.02-.39,1.41,0l3.25,3.25,7.3-8.21c.37-.41,1-.45,1.41-.08.41.37.45,1,.08,1.41l-8,9c-.18.21-.44.33-.72.33,0,0-.02,0-.03,0-.26,0-.52-.1-.71-.29l-4-4c-.39-.39-.39-1.02,0-1.41ZM6,35c-1.1,0-2-.9-2-2V13c0-1.1.9-2,2-2h28.94c-.6,1.56-.94,3.24-.94,5,0,4.75,2.38,8.94,6,11.47v5.53c0,1.1-.9,2-2,2H6Z"></path>
                    <path d="M48 40h-20c-.55 0-1 .45-1 1s.45 1 1 1h20c.55 0 1-.45 1-1s-.45-1-1-1zM48 45h-20c-.55 0-1 .45-1 1s.45 1 1 1h20c.55 0 1-.45 1-1s-.45-1-1-1z"></path>
                </svg> */}
                <svg
                    width="24"
                    height="25"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 32 32"
                    id="saving">
                    <path d="M28,15V12a3,3,0,0,0-3-3H24c0-.07,0-.13,0-.2A5.8,5.8,0,0,0,18.2,3,5.71,5.71,0,0,0,15,4a5.69,5.69,0,0,0-3.2-1A5.8,5.8,0,0,0,6,8.8c0,.07,0,.13,0,.2H5a3,3,0,0,0-3,3V26a3,3,0,0,0,3,3H25a3,3,0,0,0,3-3V23a2,2,0,0,0,2-2V17A2,2,0,0,0,28,15ZM22,8.8c0,.07,0,.13,0,.2h-4.4c0-.07,0-.13,0-.2a5.8,5.8,0,0,0-1.09-3.38A3.76,3.76,0,0,1,22,8.8ZM8,8.8a3.8,3.8,0,0,1,7.6,0c0,.07,0,.13,0,.2H8C8,8.93,8,8.87,8,8.8ZM26,26a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V12a1,1,0,0,1,1-1H25a1,1,0,0,1,1,1v3H21a3,3,0,0,0-3,3v2a3,3,0,0,0,3,3h5Zm-5-5a1,1,0,0,1-1-1V18a1,1,0,0,1,1-1h7v4Z"></path>
                </svg>
            </button >
            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="four-fifth-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Salary Structure of : "{code}"</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div ">
                        <div className="bdy-div">
                            {/* <UserSalaryStructure
                                code={code}
                                salaryData={salaryData}
                                salaryStructureHeadings={salaryStructureHeadings}
                                salaryDeductionPolicies={salaryDeductionPolicies}
                                getSalaryStructure={getSalaryStructure}
                                getAllSalaryRelatedData={getAllSalaryRelatedData}
                            /> */}
                            <UserCustomDeductions
                                code={code}
                                deductionData={deductionData}
                                customData={customData}
                                salaryData={salaryData}
                                salaryStructureHeadings={salaryStructureHeadings}
                                salaryDeductionPolicies={salaryDeductionPolicies}
                                getSalaryStructure={getSalaryStructure}
                                getAllSalaryRelatedData={getAllSalaryRelatedData}
                            />
                        </div>
                    </div>
                </Modal.Body>
            </Modal >
        </>
    );
};

const EmployeeInvestmentDeclaration = ({
    code,
    getSalaryStructure,
}) => {

    const [show, setShow] = useState(false);
    const [customData, setCustomData] = useState([]);
    const handleClose = () => {
        getSalaryStructure()
        setShow(false)
    };
    const handleShow = async () => {
        getSalaryInvestmentRelatedData(code)
        setShow(true);
    };

    const [loading, setLoading] = useState(false);
    const [toggleCompany, setToggleCompany] = useState(sessionStorage.getItem("company_id"));
    const [salaryData, setSalaryData] = useState({});
    const [investmentDeclarationData, setInvestmentDeclarationData] = useState([]);
    // const [empInvestmentDeclarationData, setEmpInvestmentDeclarationData] = useState([]);
    const [investmentHeading, setInvestmentHeading] = useState([]);

    const [buffer, setBuffering] = useState(true); //buffering logic
    const getSalaryInvestmentRelatedData = async (emp_code) => {
        setBuffering(true); // Start Buffering
        try {
            const salary = await axios.get(
                `${BASE_URL}/wfm/getsalarystructurebyemp/${emp_code}/`
            );
            setSalaryData(salary.data);

            // const declarations = await axios.get(`${BASE_URL}/wfm/investmentdecleration/`);
            // setInvestmentDeclarationData(declarations.data);

            const empdeclarations = await axios.get(`${BASE_URL}/wfm/investmentdeclerationbyemployee/${emp_code}/`);
            setInvestmentDeclarationData(empdeclarations.data);

            const headings = await axios.get(`${BASE_URL}/wfm/investmentheadingbysub_company/${toggleCompany}/`);
            setInvestmentHeading(headings.data);
            if (Array.isArray(headings.data)) {
                setInvestmentHeading(headings.data);
                const customData = headings.data.map(item => ({
                    name: item.id,
                    salary_structure: salary.data.id,
                    employee: emp_code,
                    investment_proof: '',
                    fixed_amount: '',
                }));
                setCustomData(customData);

            } else {
                console.error("Error: Expected a list of Salary Headings");
            }

        } catch (err) {
            handleAllError(err)
        } finally {
            setBuffering(false);// End Buffering
        }
    };
    const [errors, setErrors] = useState({});
    const fileInputRefs = useRef([]);

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = [
            // "basicsalary",
            // "salary_policy",
        ];
        requiredFields.forEach((field) => {
            if (!customData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();


        setLoading(true); //loading logic
        const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
        // const formDataToSend = new FormData();
        // customData.forEach((doc, index) => {
        //     if (doc.fixed_amount && doc.investment_proof) {
        //         formDataToSend.append(`documents[${index}].name`, doc.name);
        //         formDataToSend.append(`documents[${index}].salary_structure`, doc.salary_structure);
        //         formDataToSend.append(`documents[${index}].fixed_amount`, doc.fixed_amount);
        //         formDataToSend.append(`documents[${index}].employee`, doc.employee);
        //         if (doc.investment_proof) {
        //             formDataToSend.append(`documents[${index}].investment_proof `, doc.investment_proof);
        //         }
        //     }
        // });

        const validData = customData.filter(doc => doc.fixed_amount && doc.investment_proof);

        const formDataToSend = new FormData();
        validData.forEach((doc, index) => {
            formDataToSend.append(`documents[${index}].name`, doc.name);
            formDataToSend.append(`documents[${index}].salary_structure`, doc.salary_structure);
            formDataToSend.append(`documents[${index}].fixed_amount`, doc.fixed_amount);
            formDataToSend.append(`documents[${index}].employee`, doc.employee);
            formDataToSend.append(`documents[${index}].investment_proof`, doc.investment_proof);
        });
        try {
            // Submit the customData array as bulk data
            const res = await axios.post(
                `${BASE_URL}/wfm/bulkinvestment/`,
                formDataToSend,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            if (res.status === 200) {
                await getSalaryInvestmentRelatedData(code);
            }
            else if (res.status === 201) {
                await getSalaryInvestmentRelatedData(code);
            } else {
                alert(res);
            }
        } catch (err) {
            handleAllError(err, loadingToastId);
        } finally {
            setLoading(false); //loading logic
            toast.dismiss(loadingToastId);
        }

    };

    //! **************************** post API End ***********************!//


    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        setCustomData((prevFormData) => {
            const newDocuments = [...prevFormData];
            newDocuments[index] = {
                ...newDocuments[index],
                [name]: value,
            };
            return newDocuments;
        });

        // Clear the error when the user inputs a value
        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            if (value.trim() !== "") {
                delete newErrors[`${index}-${name}`];
            }
            return newErrors;
        });
    };

    const handleFileChange = (e, index) => {
        const file = e.target.files[0];
        setCustomData((prevFormData) => {
            const newDocuments = [...prevFormData];
            newDocuments[index] = {
                ...newDocuments[index],
                investment_proof: file,
            };
            return newDocuments;
        });

        // Clear the file error when a file is selected
        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            if (file) {
                delete newErrors[`${index}-investment_proof `];
            }
            return newErrors;
        });
    };



    return (
        <>
            <button
                className="model-view-button model-add"
                title="Add Salary Details"
                onClick={handleShow}
            >
                {/* <svg
                    width="26"
                    height="27"
                    xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" clip-rule="evenodd" viewBox="0 0 64 64" id="tax">
                    <g transform="translate(-448 -576)">
                        <path d="M504,579C503.522,579 464,579 464,579C461.239,579 459,581.239 459,584C459,587.792 459,593.576 459,600C459,600.552 459.448,601 460,601C460.552,601 461,600.552 461,600L461,584C461,582.343 462.343,581 464,581L500,581C499.355,581.86 499,582.912 499,584C499,584 499,602.021 499,602.021C499,602.573 499.448,603.021 500,603.021C500.552,603.021 501,602.573 501,602.021L501,589L506,589C507.657,589 509,587.657 509,586L509,584C509,582.674 508.473,581.402 507.536,580.464C506.598,579.527 505.326,579 504,579ZM504,581C503.204,581 502.441,581.316 501.879,581.879C501.316,582.441 501,583.204 501,584L501,587C501,587 506,587 506,587C506.552,587 507,586.552 507,586L507,584C507,583.204 506.684,582.441 506.121,581.879C505.559,581.316 504.796,581 504,581Z"></path>
                        <path d="M93,608C93,606.674 92.473,605.402 91.536,604.464C90.598,603.527 89.326,603 88,603C83.561,603 76.439,603 72,603C70.674,603 69.402,603.527 68.464,604.464C67.527,605.402 67,606.674 67,608C67,614.148 67,625.852 67,632C67,633.326 67.527,634.598 68.464,635.536C69.402,636.473 70.674,637 72,637C76.439,637 83.561,637 88,637C89.326,637 90.598,636.473 91.536,635.536C92.473,634.598 93,633.326 93,632C93,625.852 93,614.148 93,608ZM91,608L91,632C91,632.796 90.684,633.559 90.121,634.121C89.559,634.684 88.796,635 88,635L72,635C71.204,635 70.441,634.684 69.879,634.121C69.316,633.559 69,632.796 69,632C69,625.852 69,614.148 69,608C69,607.204 69.316,606.441 69.879,605.879C70.441,605.316 71.204,605 72,605L88,605C88.796,605 89.559,605.316 90.121,605.879C90.684,606.441 91,607.204 91,608Z" transform="translate(384)"></path>
                        <path d="M89,610C89,608.343 87.657,607 86,607L74,607C72.343,607 71,608.343 71,610L71,614C71,615.657 72.343,617 74,617L86,617C87.657,617 89,615.657 89,614L89,610ZM87,610L87,614C87,614.552 86.552,615 86,615C86,615 74,615 74,615C73.448,615 73,614.552 73,614C73,614 73,610 73,610C73,609.448 73.448,609 74,609C74,609 86,609 86,609C86.552,609 87,609.448 87,610Z" transform="translate(384)"></path>
                        <path d="M473 630L473 622C473 620.343 471.657 619 470 619L458 619C456.343 619 455 620.343 455 622L455 630C455 631.657 456.343 633 458 633L470 633C471.657 633 473 631.657 473 630zM459 631L458 631C457.448 631 457 630.552 457 630L457 629 459 629 459 631zM461 629L461 631 463 631 463 629 461 629zM465 629L465 631 467 631 467 629 465 629zM471 629L471 630C471 630.552 470.552 631 470 631L469 631 469 629 471 629zM457 625L457 627 459 627 459 625 457 625zM461 625L461 627 463 627 463 625 461 625zM465 625L465 627 467 627 467 625 465 625zM469 625L469 627 471 627 471 625 469 625zM459 621L459 623 457 623 457 622C457 621.448 457.448 621 458 621L459 621zM461 621L461 623 463 623 463 621 461 621zM465 621L465 623 467 623 467 621 465 621zM469 621L470 621C470.552 621 471 621.448 471 622L471 623 469 623 469 621zM485.073 627L480.048 627C479.497 627 479.048 627.448 479.048 628 479.048 628.552 479.497 629 480.048 629L490 629C490.552 629 491 629.448 491 630 491 630 491 632 491 632 491 633.088 491.355 634.14 492 635L480 635C479.448 635 479 635.448 479 636 479 636.552 479.448 637 480 637 480 637 495.635 637 496 637 497.326 637 498.598 636.473 499.536 635.536 500.473 634.598 501 633.326 501 632 501 632 501 629.957 501 629.957 501 629.405 500.552 628.957 500 628.957 499.448 628.957 499 629.405 499 629.957L499 632C499 632.796 498.684 633.559 498.121 634.121 497.559 634.684 496.796 635 496 635 495.204 635 494.441 634.684 493.879 634.121 493.316 633.559 493 632.796 493 632L493 630C493 629.64 492.936 629.294 492.82 628.974 499.613 628.55 505 622.899 505 616 505 608.825 499.175 603 492 603 484.825 603 479 608.825 479 616 479 620.629 481.425 624.696 485.073 627zM492 605C498.071 605 503 609.929 503 616 503 622.071 498.071 627 492 627 485.929 627 481 622.071 481 616 481 609.929 485.929 605 492 605z"></path>
                        <path d="M491,609C488.79,609 487,610.791 487,613L487,613C487,615.209 488.79,617 491,617L491,617L491,621C489.895,621 489,620.105 489,619C489,618.448 488.552,618 488,618C487.448,618 487,618.448 487,619C487,621.209 488.79,623 491,623L491,623L491,624C491,624.552 491.448,625 492,625C492.552,625 493,624.552 493,624L493,623L493,623C495.209,623 497,621.209 497,619L497,619C497,616.791 495.209,615 493,615L493,615L493,611C494.104,611 495,611.895 495,613C495,613.552 495.448,614 496,614C496.552,614 497,613.552 497,613C497,610.791 495.209,609 493,609L493,609L493,608C493,607.448 492.552,607 492,607C491.448,607 491,607.448 491,608L491,609ZM493,617L493,621L493,621C494.104,621 495,620.105 495,619C495,619 495,619 495,619C495,617.895 494.104,617 493,617ZM491,611L491,611C489.895,611 489,611.895 489,613C489,613 489,613 489,613C489,614.105 489.895,615 491,615L491,611Z"></path>
                        <path d="M474,587C472.344,587 471,588.344 471,590C471,591.656 472.344,593 474,593C475.656,593 477,591.656 477,590C477,588.344 475.656,587 474,587ZM474,589C474.552,589 475,589.448 475,590C475,590.552 474.552,591 474,591C473.448,591 473,590.552 473,590C473,589.448 473.448,589 474,589Z" transform="translate(-4)"></path>
                        <path d="M474,587C472.344,587 471,588.344 471,590C471,591.656 472.344,593 474,593C475.656,593 477,591.656 477,590C477,588.344 475.656,587 474,587ZM474,589C474.552,589 475,589.448 475,590C475,590.552 474.552,591 474,591C473.448,591 473,590.552 473,590C473,589.448 473.448,589 474,589Z" transform="translate(4 8)"></path>
                        <path d="M472.707,600.707L484.707,588.707C485.097,588.317 485.097,587.683 484.707,587.293C484.317,586.903 483.683,586.903 483.293,587.293L471.293,599.293C470.903,599.683 470.903,600.317 471.293,600.707C471.683,601.097 472.317,601.097 472.707,600.707Z" transform="translate(-4)"></path>
                        <path d="M484,593L492,593C492.552,593 493,592.552 493,592C493,591.448 492.552,591 492,591L484,591C483.448,591 483,591.448 483,592C483,592.552 483.448,593 484,593Z" transform="translate(0 -4)"></path>
                        <path d="M484,593L492,593C492.552,593 493,592.552 493,592C493,591.448 492.552,591 492,591L484,591C483.448,591 483,591.448 483,592C483,592.552 483.448,593 484,593Z"></path>
                        <path d="M484,593L492,593C492.552,593 493,592.552 493,592C493,591.448 492.552,591 492,591L484,591C483.448,591 483,591.448 483,592C483,592.552 483.448,593 484,593Z" transform="translate(0 4)"></path>
                        <path d="M484,593L492,593C492.552,593 493,592.552 493,592C493,591.448 492.552,591 492,591L484,591C483.448,591 483,591.448 483,592C483,592.552 483.448,593 484,593Z" transform="translate(0 8)"></path>
                    </g>
                </svg> */}
                <svg
                    width="27"
                    height="27"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    d="money-saving">
                    <path fill="#00f" d="M4.209 19.247a.661.661 0 0 1-.32.41.629.629 0 0 1-.33.09.985.985 0 0 1-.17-.02.689.689 0 0 1-.41-.32.671.671 0 0 1-.07-.5.661.661 0 0 1 .32-.41.645.645 0 0 1 .51-.07.691.691 0 0 1 .4.31.657.657 0 0 1 .07.51Z"></path>
                    <path fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" d="M7.684 18.124h2.394a1.3 1.3 0 0 0 1.3-1.3h0a1.3 1.3 0 0 0-1.3-1.3h-1.09a1.3 1.3 0 0 1-1.3-1.3h0a1.3 1.3 0 0 1 1.3-1.3h2.159M9.533 12.91v-.986M9.533 19.109v-.985M2.924 17.124a5.176 5.176 0 0 1 .7-3.253L6.792 8.5m2.741 13.285H8.146a5.185 5.185 0 0 1-3.239-1.11M14.866 12.89l-2.59-4.39M9.536 21.79h1.38a5.131 5.131 0 0 0 2.82-.82"></path>
                    <rect width="8.541" height="1.783" x="5.263" y="6.713" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" rx=".891"></rect>
                    <path fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" d="M6.475 6.348 5.136 2.215l2.525 1.222 1.807-1.222M12.525 6.348l1.34-4.133-2.526 1.222-1.806-1.222"></path>
                    <rect width="7.412" height="2.224" x="13.699" y="19.562" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" rx="1.112"></rect>
                    <rect width="7.412" height="2.224" x="13.699" y="17.338" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" rx="1.112"></rect>
                    <rect width="7.412" height="2.224" x="13.699" y="15.115" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" rx="1.112"></rect>
                    <rect width="7.412" height="2.224" x="13.699" y="12.891" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" rx="1.112"></rect>
                </svg>
            </button>

            <Modal
                show={show}
                onHide={loading ? null : handleClose}
                // dialogClassName="three-fourth-modal"
                dialogClassName="ourcompany-model"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Investment Declarations of "{code}"</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {/* <AddInvestmentDeclaration code={code} salaryData={salaryData} investmentDeclarationData={investmentDeclarationData} investmentHeading={investmentHeading} getSalaryInvestmentRelatedData={getSalaryInvestmentRelatedData} /> */}
                        <table className="table-css width-10vw">
                            <thead>
                                <tr className="custom-table-head-tr width-10vw">
                                    <th className="align-left width-5vw">Declaration</th>
                                    <th className="align-center width-5vw">Fixed Amount</th>
                                    <th className="align-center width-5vw">Investment Proof</th>
                                    {investmentDeclarationData.length > 0 ?
                                        <th className="align-center width-5vw">Action</th> : null
                                    }
                                </tr>
                            </thead>
                            {investmentDeclarationData.length > 0 ?
                                <tbody>
                                    {investmentDeclarationData.map((entry, index) => (
                                        <tr className="custom-table-head-td" key={index}>
                                            <td className="align-left">{investmentHeading.find(t => t.id === entry.name)?.title || "Loading..."}</td>
                                            <td className="align-center">{formatCurrencyIndian(entry.fixed_amount) || '-'}</td>
                                            <td className="align-center">
                                                {entry.investment_proof ?
                                                    < ViewOtherFile
                                                        filename={`Delaration for ${investmentHeading.find(t => t.id === entry.name)?.title}` || `Investment Delaration ${index + 1}`}
                                                        filesrc={entry.investment_proof}
                                                    /> :
                                                    '- No Proof -'
                                                }
                                            </td>
                                            <td className="align-center">
                                                <UpdateInvestmentDeclaration investmentHeading={investmentHeading} i={entry} getSalaryInvestmentRelatedData={getSalaryInvestmentRelatedData} code={code} />
                                            </td>
                                            {/* <td className="align-right"></td> */}
                                        </tr>
                                    ))}
                                </tbody>
                                :
                                <tbody>
                                    {customData.map((entry, index) => (
                                        <tr className="custom-table-head-td" key={index}>
                                            <td>
                                                <div title="Salary Heading" className="flex-column ">
                                                    <label
                                                        htmlFor={`name_${index}`}
                                                        className="font-weight500 font-size-heading form-text-trasformation-uppercase"
                                                    >
                                                        {investmentHeading.find(t => t.id === entry.name)?.title || "Loading..."}
                                                        {/* <span className="required">*</span> */}
                                                    </label>
                                                </div>
                                            </td>
                                            <td>
                                                <div title="Fixed Amount" className="flex-column">
                                                    <input
                                                        id={`fixed_amount_${index}`}
                                                        name="fixed_amount"
                                                        placeholder="Fixed Amount"
                                                        type="number"
                                                        min={0}
                                                        step="0.01"
                                                        onWheel={(e) => e.target.blur()}
                                                        value={entry.fixed_amount}
                                                        // onChange={(e) => handleInputChange(e, index)}
                                                        onChange={(e) => {
                                                            const value = e.target.value;
                                                            if (/^\d*\.?\d{0,2}$/.test(value)) {
                                                                handleInputChange(e, index);
                                                            }
                                                        }}
                                                        className={`form-input-date  ${errors[`${index}_fixed_amount`] ? "error" : ""}`}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="upload-button-field">
                                                    <div title="Upload Document" className="flex-column">
                                                        <div className="align-center">
                                                            <div >
                                                                <label htmlFor={`investment_proof_${index}`} className="svg-icon">
                                                                    <svg className="pdf-input" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 20 20" fill="none">
                                                                        <path
                                                                            d="M17.8666 9.2081L10.2082 16.8664C9.27005 17.8046 7.99757 18.3317 6.67075 18.3317C5.34393 18.3317 4.07145 17.8046 3.13325 16.8664C2.19505 15.9282 1.66797 14.6558 1.66797 13.3289C1.66797 12.0021 2.19505 10.7296 3.13325 9.79144L10.7916 2.1331C11.4171 1.50763 12.2654 1.15625 13.1499 1.15625C14.0345 1.15625 14.8828 1.50763 15.5082 2.1331C16.1337 2.75857 16.4851 3.60689 16.4851 4.49144C16.4851 5.37598 16.1337 6.2243 15.5082 6.84977L7.84158 14.5081C7.52885 14.8208 7.10469 14.9965 6.66242 14.9965C6.22014 14.9965 5.79598 14.8208 5.48325 14.5081C5.17051 14.1954 4.99482 13.7712 4.99482 13.3289C4.99482 12.8867 5.17051 12.4625 5.48325 12.1498L12.5582 5.0831"
                                                                            stroke="#707070"
                                                                            strokeWidth="2"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        />
                                                                    </svg>
                                                                </label>
                                                                <input
                                                                    type="file"
                                                                    id={`investment_proof_${index}`}
                                                                    name="investment_proof"
                                                                    accept=".pdf, .doc, .docx"
                                                                    style={{ display: "none" }}
                                                                    onChange={(e) => handleFileChange(e, index)}
                                                                    ref={(el) => (fileInputRefs.current[index] = el)}
                                                                />
                                                            </div>
                                                            <div >
                                                                {entry.investment_proof && (
                                                                    <span className="file-name">
                                                                        {entry.investment_proof.name}
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                        {errors[`${index}-investment_proof`] && (
                                                            <span className="error-message font-size-text">
                                                                {errors[`${index}-investment_proof`]}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            </td>

                                        </tr>
                                    ))}
                                </tbody>}
                        </table>
                        <div className="button-models">
                            {investmentDeclarationData.length > 0 ? (
                                <AddInvestmentDeclaration code={code} salaryData={salaryData} investmentDeclarationData={investmentDeclarationData} investmentHeading={investmentHeading} getSalaryInvestmentRelatedData={getSalaryInvestmentRelatedData} />
                            ) : (
                                <button
                                    title="Add All Investment Declarations"
                                    type="submit"
                                    className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                                    // onClick={}
                                    disabled={loading}
                                    onClick={handleSubmit}
                                >
                                    Add Declarations
                                </button>
                            )}
                        </div>
                    </div>
                </Modal.Body>
            </Modal >
        </>
    );
};

const UserSalaryStructuree = ({
    code,
    salaryData,
    handleClose,
    salaryStructureHeadings,
    salaryDeductionPolicies,
    getSalaryStructure,
    getAllSalaryRelatedData,
}) => {

    const [formData, setFormData] = useState(salaryData);
    useEffect(() => {
        if (salaryData.emp_code) {
            setFormData(salaryData); // Populate form for update
        } else {
            setFormData({
                emp_code: code, // Reset for add mode
                basicsalary: "",
                salary_policy: "",
            });
        }
    }, [salaryData?.emp_code]); // Only triggers when emp_code changes

    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = [
            "basicsalary",
            "salary_policy",
        ];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // ?  ************** Validation End **************  ! //

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });
        setFormData({
            ...formData,
            [name]: value,
        });
    };


    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true); //loading logic
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            try {
                // let res = await axios.post(`${BASE_URL}/wfm/salarydataadd/`, formData);
                let res = await axios.post(`${BASE_URL}/wfm/salarystructure/`, formData);

                if (res.status === 200) {
                    await getAllSalaryRelatedData(code);
                    await getSalaryStructure();
                    handleClose();
                } else {
                    alert(res);
                }
            } catch (err) {
                handleAllError(err)
            } finally {
                setLoading(false); // Ensure loading state is stopped even in case of error //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };

    const handleUpdate = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true); //loading logic
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
            try {
                let res = await axios.put(
                    `${BASE_URL}/wfm/salarystructureupdate/${salaryData.id}/`,
                    formData
                );

                if (res.status === 200) {
                    await getAllSalaryRelatedData(code);
                    await getSalaryStructure();
                    handleClose();
                } else {
                    alert(res);
                }
            } catch (err) {
                handleAllError(err)
            } finally {
                setLoading(false); // Ensure loading state is stopped even in case of error //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };

    return (
        <>
            <div className="bdy-div">
                {/* <h6 className="task-Tab-heading   font-weight500    font-size-heading">Salary Structure</h6> */}

                <div className="flex-row justify-between">
                    <div className="flex-column" >


                        <div className="flex-column form-group-selectt">
                            <label htmlFor="salary_policy" className="form-labels  font-weight500    font-size-subheading">
                                Statuary Deduction Policy (EPF/ESI)<span className="required">*</span>
                            </label>
                            <br />
                            <select
                                id="salary_policy"
                                name="salary_policy"
                                value={formData.salary_policy}
                                onChange={handleInputChange}
                                className={`form-input form-group-selection ${errors.salary_policy ? "error" : inputState.salary_policy ? "success" : ""
                                    }`}
                            >
                                <option value="">Select Statuary Deduction Policy</option>
                                {/* <option value="">Select a Attendance Policy</option> */}
                                {salaryDeductionPolicies?.sort((a, b) => a.name.localeCompare(b.name, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                                    <option value={i.id}>{i.name}</option>
                                </>))}
                            </select>
                            <div className="form-group-selection-arrow">
                                <DropdownArrowOption />
                            </div>
                            <div>
                                {errors.salary_policy && (
                                    <span className="error-message font-size-text ">{errors.salary_policy}</span>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="button-models">
                        {salaryData.emp_code ? (
                            <button
                                type="submit"
                                className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                                onClick={handleUpdate}
                                disabled={loading}
                            >
                                {salaryData.basicsalary === null ?
                                    "Add" :
                                    "Update"
                                }
                            </button>
                        ) : (
                            <button
                                type="submit"
                                className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                                onClick={handleSubmit}
                                disabled={loading}
                            >
                                Add
                            </button>
                        )}
                    </div>
                </div>

            </div>
        </>
    );
};

const UserCustomDeductionss = ({
    code,
    salaryData,
    customData,
    deductionData,
    handleClose,
    salaryStructureHeadings,
    getSalaryStructure,
    getAllSalaryRelatedData,
    salaryDeductionPolicies,

}) => {

    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState(customData);
    // const handleInputChange = (e, index) => {
    //     const { name, value } = e.target;
    //     const updatedFormData = formData.map((entry, i) =>
    //         i === index ? { ...entry, [name]: value } : entry
    //     );
    //     setFormData(updatedFormData);
    // };

    // !  ************** Validation start **************  ! //
    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = [
            // "basicsalary",
            // "salary_policy",
        ];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // ?  ************** Validation End **************  ! //


    const handleSubmitCustomDeduction = async (e) => {
        e.preventDefault();
        setLoading(true); //loading logic
        const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
        if (validateForm()) {
            try {
                // Submit the formData array as bulk data
                const res = await axios.post(
                    `${BASE_URL}/wfm/bulkcustomdeduction/`,
                    formData
                );
                if (res.status === 200) {
                    await getAllSalaryRelatedData(code);
                }
                else if (res.status === 201) {
                    await getAllSalaryRelatedData(code);
                } else {
                    alert(res);
                }
            } catch (err) {
                handleErrorToast(err, loadingToastId);
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }

    };

    //! **************************** post API End ***********************!//

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const updatedFormData = formData.map((entry, i) =>
            i === index ? { ...entry, [name]: value } : entry
        );
        setFormData(updatedFormData);
    };

    return (
        <>
            <div>
                <table className="table-css width-10vw">
                    <thead>
                        <tr className="custom-table-head-tr">
                            <th className="align-left width-5vw">Heading</th>
                            <th className="align-center width-5vw">Type</th>
                            <th className="align-center width-5vw">Amount</th>
                            {deductionData.length > 0 ? null :
                                <th className="align-center width-5vw">OR</th>
                            }
                            <th className="align-center width-5vw">Percentage</th>
                            <th className="align-center width-5vw">Related To</th>
                            {deductionData.length > 0 ?
                                <th className="align-center width-5vw">Action</th> : null
                            }
                        </tr>
                    </thead>
                    {deductionData.length > 0 ?
                        <tbody>
                            {deductionData.map((entry, index) => (
                                <tr className="custom-table-head-td" key={index}>
                                    <td className="align-left">{salaryStructureHeadings.find(t => t.id === entry.name)?.title || "Loading..."}</td>
                                    <td className="align-center form-text-trasformation-uppercase">{salaryStructureHeadings.find(t => t.id === entry.name)?.salary_type || "Loading..."}</td>
                                    <td className="align-center">{entry.fixed_amount || '-'}</td>
                                    <td className="align-center">{entry.percentage || '-'}</td>
                                    <td className="align-center">{entry.percentage_of || '-'}</td>
                                    <td className="align-center">
                                        <UpdateCustomDeductions salaryStructureHeadings={salaryStructureHeadings} i={entry} getAllSalaryRelatedData={getAllSalaryRelatedData} code={code} />
                                    </td>
                                    {/* <td className="align-right"></td> */}
                                </tr>
                            ))}
                        </tbody>
                        :
                        <tbody>
                            {formData.map((entry, index) => (
                                <tr className="custom-table-head-td" key={index}>
                                    <td>
                                        <div title="Salary Heading" className="flex-column ">
                                            <label
                                                htmlFor={`name_${index}`}
                                                className="font-weight500 font-size-heading form-text-trasformation-uppercase"
                                            >
                                                {salaryStructureHeadings.find(t => t.id === entry.name)?.title || "Loading..."}
                                                {/* <span className="required">*</span> */}
                                            </label>
                                            <input
                                                id={`name_${index}`}
                                                name="name"
                                                style={{ display: 'none' }}
                                                value={salaryStructureHeadings.find(t => t.id === entry.name)?.title || "Loading..."}
                                                className={`width-5vw form-input-date ${errors[`${index}_name`] ? "error" : ""}`}
                                                disabled
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <div title="Salary Type" className="flex-column ">
                                            <label
                                                htmlFor="salary_type"
                                                className="font-weight500 font-size-heading form-text-trasformation-uppercase"
                                            >
                                                {salaryStructureHeadings.find(t => t.salary_type === entry.salary_type)?.salary_type || "Loading..."}
                                                {/* <span className="required">*</span>  */}
                                            </label>
                                            <input
                                                id={`salary_type_${index}`}
                                                name="salary_type"
                                                style={{ display: 'none' }}
                                                value={salaryStructureHeadings.find(t => t.salary_type === entry.salary_type)?.salary_type || "Loading..."}
                                                className={`width-5vw form-input-date ${errors[`${index}_name`] ? "error" : ""}`}
                                                disabled
                                            />
                                        </div>
                                    </td>

                                    <td>
                                        {entry.percentage || entry.percentage_of.length > 0 ? <div className="align-center">-</div> :
                                            <div title="Fixed Amount" className="flex-column">
                                                <input
                                                    id={`fixed_amount_${index}`}
                                                    name="fixed_amount"
                                                    placeholder="Fixed Amount"
                                                    type="number"
                                                    min={0}
                                                    step="0.01"
                                                    onWheel={(e) => e.target.blur()}
                                                    value={entry.fixed_amount}
                                                    // onChange={(e) => handleInputChange(e, index)}
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        if (/^\d*\.?\d{0,2}$/.test(value)) {
                                                            handleInputChange(e, index);
                                                        }
                                                    }}
                                                    className={`form-input-date width-5vw ${errors[`${index}_fixed_amount`] ? "error" : ""}`}
                                                />
                                            </div>
                                        }
                                    </td>
                                    <td>
                                        {/* OR */}
                                        <div style={{ width: '0.5vw' }} className="align-center width-5vw ">OR</div>
                                        {/* <hr className="field-cont-hr" /> */}
                                    </td>
                                    <td>
                                        {entry.fixed_amount ? <div className="align-center">-</div> :
                                            <div title="Percentage of Amount" className="flex-column form-group-selectt">
                                                <input
                                                    id={`percentage_${index}`}
                                                    name="percentage"
                                                    type="number"
                                                    min={0}
                                                    onWheel={(e) => e.target.blur()}
                                                    value={entry.percentage}
                                                    placeholder="Percentage of Amount"
                                                    onChange={(e) => handleInputChange(e, index)}
                                                    className={`form-input-date width-5vw ${errors[`${index}_percentage`] ? "error" : ""}`}
                                                />
                                            </div>
                                        }
                                    </td>
                                    <td>
                                        {entry.fixed_amount ? <div className="align-center">-</div> :
                                            <div title="Related To" className="flex-column form-group-selectt">
                                                <select
                                                    id={`percentage_of_${index}`}
                                                    name="percentage_of"
                                                    value={entry.percentage_of}
                                                    onChange={(e) => handleInputChange(e, index)}
                                                    className={`form-input-date width-5vw ${errors[`${index}_percentage_of`] ? "error" : ""}`}

                                                >
                                                    <option value="">Related To</option>
                                                    <option value="Basic">Basic</option>
                                                    <option value="Gross">Gross</option>
                                                    {salaryStructureHeadings?.sort((a, b) => a.title.localeCompare(b.title, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                                                        <option value={i.title}>{i.title}</option>
                                                    </>))}
                                                </select>
                                            </div>
                                        }
                                    </td>
                                </tr>
                            ))}
                        </tbody>}
                </table>

                <div className="button-models">
                    {deductionData.length > 0 ? (
                        <AddCustomDeductions
                            code={code}
                            deductionData={deductionData}
                            salaryData={salaryData}
                            salaryStructureHeadings={salaryStructureHeadings}
                            salaryDeductionPolicies={salaryDeductionPolicies}
                            getAllSalaryRelatedData={getAllSalaryRelatedData}
                        />
                    ) : (
                        <button
                            type="submit"
                            className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                            onClick={handleSubmitCustomDeduction}
                            disabled={loading}
                        >
                            Add
                        </button>
                    )}
                </div>
            </div>
        </>
    );
};


const UserSalaryStructure = ({
    code,
    salaryData,
    handleClose,
    salaryStructureHeadings,
    salaryDeductionPolicies,
    getSalaryStructure,
    getAllSalaryRelatedData,
}) => {
    const [formData, setFormData] = useState({
        emp_code: code || "", // Initialize with emp_code or empty if adding a new structure
        salary_policy: "",
        ...salaryData,
    });

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["salary_policy"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required!`;
            }
        });
        setErrors(newErrors);
        // console.log("newErrors")
        // console.log(newErrors)
        return Object.keys(newErrors).length === 0;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInputState((prev) => ({ ...prev, [name]: value.trim() ? "green" : "" }));
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e, isUpdate = false) => {
        e.preventDefault();
        if (!validateForm()) return;

        setLoading(true);
        const loadingToastId = toast.loading("Loading: Please wait...");
        const url = isUpdate
            ? `${BASE_URL}/wfm/salarystructureupdate/${salaryData.id}/`
            : `${BASE_URL}/wfm/salarystructure/`;

        try {
            const res = await axios({
                method: isUpdate ? "put" : "post",
                url,
                data: formData,
            });
            if (res.status === 200 || res.status === 201) {
                await getAllSalaryRelatedData(code);
                await getSalaryStructure();
                handleClose();
            }
        } catch (err) {
            handleAllError(err);
        } finally {
            setLoading(false);
            toast.dismiss(loadingToastId);
        }
    };

    return (
        <div className="bdy-div">
            <div className="flex-row justify-between">
                <div className="flex-column">
                    <div className="flex-column form-group-selectt">
                        <label htmlFor="salary_policy" className="form-labels">
                            Statuary Deduction Policy (EPF/ESI)<span className="required">*</span>
                        </label>
                        <br />
                        <select
                            id="salary_policy"
                            name="salary_policy"
                            value={formData.salary_policy}
                            onChange={handleInputChange}
                            className={`form-input form-group-selection ${errors.salary_policy ? "error" : inputState.salary_policy ? "success" : ""}`}
                        >
                            <option value="">Select Statuary Deduction Policy</option>
                            {salaryDeductionPolicies?.map((i) => (
                                <option key={i.id} value={i.id}>{i.name}</option>
                            ))}
                        </select>
                        <div className="form-group-selection-arrow">
                            <DropdownArrowOption />
                        </div>
                        {errors.salary_policy && <span className="error-message">{errors.salary_policy}</span>}
                    </div>
                </div>
                <div className="button-models">
                    {salaryData.basicsalary ? (
                        <button
                            type="submit"
                            className="model-button font-weight500 model-button-leave font-size-heading"
                            onClick={(e) => handleSubmit(e, true)}
                            disabled={loading}
                        >
                            Update
                        </button>
                    ) : (
                        <button
                            type="submit"
                            className="model-button font-weight500 model-button-leave font-size-heading"
                            onClick={(e) => handleSubmit(e)}
                            disabled={loading}
                        >
                            Add
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};





const UserCustomDeductions = ({
    handleClose,
    code,
    salaryData,
    customData,
    deductionData,
    salaryDeductionPolicies,
    salaryStructureHeadings,
    getAllSalaryRelatedData,
}) => {
    const [formData, setFormData] = useState(customData || []);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    // console.log("deductionData")
    // console.log(deductionData)
    useEffect(() => {
        setFormData(customData);
    }, [customData]);

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;

        // Update formData and clear dependent fields as needed
        const updatedFormData = formData.map((entry, i) => {
            if (i === index) {
                return {
                    ...entry,
                    [name]: value,
                    ...(name === "fixed_amount" ? { percentage: "", percentage_of: [] } : {}),
                    ...(name === "percentage" ? { fixed_amount: "" } : {}),
                };
            }
            return entry;
        });

        setFormData(updatedFormData);
    };

    const handleSelectChange = (selectedOptions, index) => {
        // Update percentage_of with selected values
        const updatedFormData = formData.map((entry, i) => {
            if (i === index) {
                return {
                    ...entry,
                    percentage_of: selectedOptions ? selectedOptions.map(option => option.value) : [],
                };
            }
            return entry;
        });

        setFormData(updatedFormData);
    };

    const validateForm = () => {
        const newErrors = {};
        formData.forEach((entry, index) => {
            const hasFixedAmount = !!entry.fixed_amount;
            const hasPercentage = !!entry.percentage;
            const hasRelatedTo = entry.percentage_of && entry.percentage_of.length > 0;

            if (hasFixedAmount && (hasPercentage || hasRelatedTo)) {
                newErrors[index] = "Specify either Fixed Amount or Percentage with Related To, not both.";
            } else if (hasPercentage && !hasRelatedTo) {
                newErrors[index] = "Related To is required when Percentage is specified.";
            }
            else if (!hasPercentage && hasRelatedTo) {
                newErrors[index] = "Percentage is required when Related To is specified.";
            }
            // } else if (!hasFixedAmount && (!hasPercentage || !hasRelatedTo)) {
            //     newErrors[index] = "Either Fixed Amount or both Percentage and Related To are required.";
            // }
        });

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };



    const handleSubmitCustomDeduction = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        setLoading(true);
        const loadingToastId = toast.loading("Loading: Please wait...");
        const validData = formData.filter(doc =>
            (doc.fixed_amount || (doc.percentage && doc.percentage_of.length > 0))
        );
        try {
            const res = await axios.post(`${BASE_URL}/wfm/bulkcustomdeduction/`, validData);
            if (res.status === 200 || res.status === 201) {
                await getAllSalaryRelatedData(code);
                toast.success("Deductions saved successfully.");
            }
        } catch (err) {
            await getAllSalaryRelatedData(code);

            // toast.error("An error occurred while saving deductions.");
        } finally {
            setLoading(false);
            toast.dismiss(loadingToastId);
        }
    };

    const deductionIds = deductionData.map(item => item.name);

    // Format options for react-select
    const relatedToOptions = salaryStructureHeadings
        .filter(i => !deductionIds.includes(i.id))
        .sort((a, b) => a.sorting_var - b.sorting_var)
        .map(i => ({ value: i.id, label: i.title }));

    const relatedToHeadingOptions = [
        { value: 'Gross', label: 'Gross' },
        ...salaryStructureHeadings
            .filter(i => !deductionIds.includes(i.id))
            .sort((a, b) => a.sorting_var - b.sorting_var)
            .map(i => ({ value: i.id, label: i.title })),
    ];

    return (
        <div>
            <ToastContainer position="top-center" autoClose={3000} hideProgressBar />
            <table className="table-css width-10vw">
                <thead>
                    <tr className="custom-table-head-tr">
                        <th className="align-left width-5vw">Heading</th>
                        <th className="align-center width-5vw">Type</th>
                        <th className="align-center width-5vw">Amount</th>
                        <th className="align-center" style={{ width: '0.5vw' }}>OR</th>
                        <th className="align-center width-5vw">Percentage</th>
                        <th className="align-center width-5vw">Related To</th>
                        {deductionData.length > 0 && <th className="align-center width-5vw">Action</th>}
                    </tr>
                </thead>
                <tbody>
                    <>
                        {
                            // deductionData.length > 0 ?
                            <>
                                {deductionData
                                    .sort((a, b) => a.name_sorting_var - b.name_sorting_var)
                                    .map((entry, index) => (
                                        <tr className="custom-table-head-td" key={index}>
                                            <td className="align-left">{entry.name_title}</td>
                                            <td className="align-left form-text-trasformation-uppercase">{entry.name_type}</td>

                                            <td className="align-center">{entry.fixed_amount || '-'}</td>
                                            <td className="align-center">
                                                <div style={{ width: '1vw' }} className="align-center width-5vw ">OR</div>
                                                {/* <hr className="field-cont-hr" /> */}
                                            </td>
                                            <td className="align-center">
                                                {entry.percentage ?
                                                    `${parseFloat(entry.percentage) % 1 === 0 ?
                                                        parseFloat(entry.percentage).toFixed(0)
                                                        : parseFloat(entry.percentage).toFixed(2)}%`
                                                    : '-'}
                                            </td>
                                            <td className="align-leftt width-10vw">
                                                {entry.percentage_of.length === 0 ?
                                                    <div className="align-center">-</div> :
                                                    // entry.percentage_of?.join(", ") || '-'
                                                    <ul style={{ paddingLeft: '0' }}>
                                                        {entry.percentage_of.map((data, index) => (
                                                            <li key={index}>
                                                                <span className="form-text-trasformation-uppercase">
                                                                    {data.name}
                                                                </span>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                }
                                            </td>
                                            <td className="align-center">
                                                {/* <UpdateCustomDeductions
                                                    i={entry}
                                                    code={code}
                                                    deductionData={deductionData}
                                                    salaryStructureHeadings={salaryStructureHeadings}
                                                    getAllSalaryRelatedData={getAllSalaryRelatedData}
                                                /> */}
                                                <DeleteCustomDeductions
                                                    i={entry}
                                                    code={code}
                                                    deductionData={deductionData}
                                                    salaryStructureHeadings={salaryStructureHeadings}
                                                    getAllSalaryRelatedData={getAllSalaryRelatedData}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                            </>
                            // :
                            // <>
                            //     {formData
                            //         .sort((a, b) => a.sorting_var - b.sorting_var)
                            //         .map((entry, index) => (
                            //             <>
                            //                 <tr className="custom-table-head-td" key={index}>
                            //                     <td className="align-left">{salaryStructureHeadings.find(t => t.id === entry.name)?.title || "Loading..."}</td>
                            //                     <td className="align-center form-text-trasformation-uppercase">{salaryStructureHeadings.find(t => t.id === entry.name)?.salary_type || "Loading..."}</td>
                            //                     <td className="align-center">
                            //                         <input
                            //                             type="number"
                            //                             name="fixed_amount"
                            //                             min={0}
                            //                             placeholder="Fixed Amount"
                            //                             value={entry.fixed_amount || ''}
                            //                             onWheel={(e) => e.target.blur()}
                            //                             onChange={(e) => handleInputChange(e, index)}
                            //                             className={`form-input-date ${errors[index] ? 'is-invalid' : ''}`}
                            //                             disabled={!!entry.percentage} // Disable if percentage is provided
                            //                         />
                            //                     </td>
                            //                     <td className="align-center">
                            //                         <div style={{ width: '1vw' }} className="align-center width-5vw ">OR</div>
                            //                     </td>
                            //                     <td className="align-center">
                            //                         <input
                            //                             type="number"
                            //                             name="percentage"
                            //                             min={0}
                            //                             placeholder="Percentage"
                            //                             value={entry.percentage || ''}
                            //                             onWheel={(e) => e.target.blur()}
                            //                             onChange={(e) => handleInputChange(e, index)}
                            //                             className={`form-input-date ${errors[index] ? 'is-invalid' : ''}`}
                            //                             disabled={!!entry.fixed_amount} // Disable if fixed amount is provided
                            //                         />
                            //                     </td>
                            //                     <td className="align-leftt width-10vw">
                            //                         <Select
                            //                             isMulti
                            //                             name="percentage_of"
                            //                             value={relatedToOptions.filter(option => entry.percentage_of?.includes(option.value))}
                            //                             onChange={(selectedOptions) => handleSelectChange(selectedOptions, index)}
                            //                             options={relatedToOptions}
                            //                             className={` width-15vw ${errors[index] ? 'is-invalid' : ''}`}
                            //                             classNamePrefix="select"
                            //                             placeholder="Select Related To"
                            //                             isDisabled={!!entry.fixed_amount} // Disable if fixed amount is provided
                            //                             styles={{
                            //                                 control: (provided) => ({
                            //                                     ...provided,
                            //                                     // width: '33vw',
                            //                                     // height: '50px',
                            //                                     borderRadius: '10px',
                            //                                     border: '1px solid #e3e3e3',
                            //                                     margin: '10px 0px 20px 0px',
                            //                                     color: '#4a4a4a',
                            //                                     backgroundColor: '#f7f8fa',
                            //                                     padding: '0px 10px',
                            //                                 }),
                            //                                 placeholder: (provided) => ({
                            //                                     ...provided,
                            //                                     color: '#4a4a4a',
                            //                                 }),
                            //                                 menu: (provided) => ({
                            //                                     ...provided,
                            //                                     borderRadius: '10px',
                            //                                     marginTop: '0',
                            //                                 }),
                            //                                 input: (provided) => ({
                            //                                     ...provided,
                            //                                     color: '#4a4a4a',
                            //                                 }),
                            //                                 singleValue: (provided) => ({
                            //                                     ...provided,
                            //                                     color: '#4a4a4a',
                            //                                 }),
                            //                             }}
                            //                         />
                            //                     </td>
                            //                 </tr>
                            //                 <tr>
                            //                     {errors[index] && <td colSpan='6' className="align-center error-message font-size-text">{errors[index]}</td>}
                            //                 </tr>
                            //             </>
                            //         ))}
                            // </>
                        }
                    </>
                </tbody>
            </table>

            <div className="button-models">
                {
                    // deductionData.length > 0 ?
                    (salaryStructureHeadings?.filter(i => !deductionIds.includes(i.id)).length > 0 ?
                        <AddCustomDeductions
                            code={code}
                            deductionData={deductionData}
                            salaryData={salaryData}
                            salaryStructureHeadings={salaryStructureHeadings}
                            salaryDeductionPolicies={salaryDeductionPolicies}
                            getAllSalaryRelatedData={getAllSalaryRelatedData}
                        /> :
                        null
                    )
                    // :
                    // <button
                    //     onClick={handleSubmitCustomDeduction}
                    //     className="model-button font-weight500 model-button-leave font-weight500 font-size-heading"
                    //     disabled={loading}
                    // >
                    //     {loading ? 'Saving...' : 'Save Deductions'}
                    // </button>
                }
            </div>
        </div>
    );
};

const AddCustomDeductions = ({
    code,
    salaryData,
    deductionData,
    salaryStructureHeadings,
    getAllSalaryRelatedData,
}) => {

    const [formData, setFormData] = useState({
        emp_code: code,
        name: "",
        salary_structure: salaryData.id,
        salary_type: "",
        percentage: '',
        percentage_of: [], // Initialize as an array for multiple selections
        fixed_amount: '',
    });
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);


    const validateForm = () => {
        const newErrors = {};
        const isSalaryHeadingSelected = !!formData.name;
        const hasFixedAmount = formData.fixed_amount !== '';
        const hasPercentage = formData.percentage !== '' && formData.percentage_of.length > 0;

        // Validate Salary Heading
        if (!isSalaryHeadingSelected) {
            newErrors.name = 'Salary Heading is required!';
        }

        // Validation for Fixed Amount or Percentage
        if (!hasFixedAmount && !hasPercentage) {
            newErrors.mixed = 'Either Fixed Amount or Percentage with Related To is required!';
        }

        // Additional validation for Fixed Amount if it's entered
        if (hasFixedAmount && !formData.fixed_amount) {
            newErrors.fixed_amount = 'Fixed Amount is required!';
        }

        // Additional validation for Percentage if it's entered
        if (hasPercentage) {
            if (!formData.percentage) {
                newErrors.percentage = 'Percentage of Amount is required!';
            }
            if (formData.percentage_of.length === 0) {
                newErrors.percentage_of = 'Related To is required!';
            }
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        // Handle multiple selections for 'percentage_of'
        if (name === "percentage_of") {
            const options = Array.from(e.target.selectedOptions, option => option.value);
            setFormData({ ...formData, percentage_of: options });
        } else {
            setFormData({ ...formData, [name]: value });
        }

        setInputState({ ...inputState, [name]: value.trim() ? "green" : "" });
    };

    const handleHeadingChange = (e) => {
        const { value } = e.target;
        const selectedHeading = salaryStructureHeadings.find(heading => heading.id === value);

        setFormData({
            ...formData,
            name: selectedHeading ? selectedHeading.id : "",
            salary_type: selectedHeading ? selectedHeading.salary_type : "",
        });

        setInputState({
            ...inputState,
            name: value.trim() ? "green" : "",
        });
    };
    const handleSelectChange = (selectedOptions) => {
        setFormData({
            ...formData,
            percentage_of: selectedOptions ? selectedOptions.map(option => option.value) : [],
        });
    };
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;
        const formattedString = formData.percentage_of.join(", ");
        const formattedFormData = {
            ...formData,
            percentage_of: formattedString,
        };

        setLoading(true);
        // const loadingToastId = toast.loading("Loading: Please wait...");

        try {
            const res = await axios.post(`${BASE_URL}/wfm/customdeduction/`, formData);

            if (res.status === 200 || res.status === 201) {
                // toast.success("Adding Deduction");
                await getAllSalaryRelatedData(code);
                handleClose();
            } else {
                // toast.error("Error adding deduction");
                console.log("Error adding deduction");
            }
        } catch (err) {
            // toast.error("An error occurred while adding the deduction");
            console.log("An error occurred while adding the deduction");
            // handleAllError(err);
        } finally {
            setLoading(false);
            // toast.dismiss(loadingToastId);
            // await getAllSalaryRelatedData(code);
            // handleClose();
        }
    };
    const deductionIds = deductionData.map(item => item.name);
    const relatedToOptions = salaryStructureHeadings
        .filter(i => deductionIds.includes(i.id))
        .sort((a, b) => a.sorting_var - b.sorting_var)
        .map(i => ({ value: i.title, label: i.title }));

    const headingOptions = salaryStructureHeadings
        // .filter(i => deductionIds.includes(i.id))
        .sort((a, b) => a.sorting_var - b.sorting_var)
        .map(i => ({ value: i.id, label: i.title }));

    const relatedToHeadingOptions = [
        { value: 'Gross', label: 'Gross' },
        ...salaryStructureHeadings
            // .filter(i => !deductionIds.includes(i.id))
            .sort((a, b) => a.sorting_var - b.sorting_var)
            .map(i => ({ value: i.id, label: i.title })),
    ];

    return (
        <div>
            <button title='Add Deduction' className="model-button model-button-leave" onClick={handleShow}>
                Add
            </button>
            <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
                <Modal.Header closeButton>
                    <Modal.Title>Add Custom Deduction</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ToastContainer position="top-center" autoClose={3000} hideProgressBar />
                    <form className="register-form" onSubmit={handleFormSubmit}>
                        <div className="form-flex-wrap">
                            <div title="Salary Structure Heading" className="flex-column form-group-selectt">
                                <label htmlFor="name" className="form-labels font-weight500 font-size-subheading">
                                    Salary Structure Heading<span className="required">*</span>
                                </label>
                                <br />
                                <select
                                    id="name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleHeadingChange}
                                    className={`form-input form-group-selection ${errors.name ? "error" : inputState.name ? "success" : ""}`}
                                >
                                    <option value="">Select Salary Heading</option>
                                    {salaryStructureHeadings
                                        ?.filter(i => !deductionIds.includes(i.id))
                                        .map((i) => (
                                            <option key={i.id} value={i.id}>
                                                {i.title}
                                            </option>
                                        ))}
                                </select>
                                {/* <br /> */}
                                <div>
                                    {errors.name && <span className="error-message font-size-text">{errors.name}</span>}
                                </div>
                            </div>

                            {formData.percentage || formData.percentage_of.length > 0 ? null : (
                                <div className="flex-column">
                                    <label className="form-labels font-weight500 font-size-subheading">
                                        Fixed Amount<span className="required">*</span>
                                    </label>
                                    <input
                                        type="number"
                                        name="fixed_amount"
                                        placeholder="Fixed Amount"
                                        min={0}
                                        value={formData.fixed_amount}
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.fixed_amount ? 'is-invalid' : ''}`}
                                    />
                                    {errors.fixed_amount && <div className="error-message font-size-text">{errors.fixed_amount}</div>}
                                </div>
                            )}
                            {formData.fixed_amount ? null : (
                                <>
                                    <div className="flex-column">
                                        <label className="form-labels font-weight500 font-size-subheading">
                                            Percentage of Amount<span className="required">*</span>
                                        </label>
                                        <input
                                            type="number"
                                            name="percentage"
                                            placeholder="Percentage of Amount"
                                            min={0}
                                            step={0.01}
                                            value={formData.percentage}
                                            onChange={handleInputChange}
                                            className={`form-input ${errors.percentage ? 'is-invalid' : ''}`}
                                        />
                                        {errors.percentage && <div className="error-message font-size-text">{errors.percentage}</div>}
                                    </div>

                                    <div title="Related To" className="flex-column">
                                        <label className="form-labels font-weight500 font-size-subheading">
                                            Related To<span className="required">*</span>
                                        </label>
                                        <Select
                                            isMulti
                                            name="percentage_of"
                                            value={relatedToOptions.filter(option => formData.percentage_of?.includes(option.value))}
                                            onChange={(selectedOptions) => handleSelectChange(selectedOptions)}
                                            options={relatedToOptions}
                                            className={`width-15vw ${errors.percentage_of ? 'is-invalid' : ''}`}
                                            classNamePrefix="select"
                                            placeholder="Select Related To"
                                            isDisabled={!!formData.fixed_amount} // Disable if fixed amount is provided
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    width: '33vw',
                                                    minHeight: '50px',
                                                    borderRadius: '10px',
                                                    border: '1px solid #e3e3e3',
                                                    margin: '10px 0px 20px 0px',
                                                    color: '#4a4a4a',
                                                    backgroundColor: '#f7f8fa',
                                                    padding: '0px 10px',
                                                }),
                                                placeholder: (provided) => ({
                                                    ...provided,
                                                    color: '#4a4a4a',
                                                }),
                                                menu: (provided) => ({
                                                    ...provided,
                                                    width: '33vw',
                                                    borderRadius: '10px',
                                                    marginTop: '0',
                                                }),
                                                singleValue: (provided) => ({
                                                    ...provided,
                                                    color: '#4a4a4a',
                                                }),
                                                input: (provided) => ({
                                                    ...provided,
                                                    color: '#4a4a4a',
                                                }),
                                            }}
                                        />
                                        {errors.percentage_of && <div className="error-message font-size-text">{errors.percentage_of}</div>}
                                    </div>
                                </>
                            )}
                        </div>
                        {errors.mixed && <div className="error-message font-size-text">{errors.mixed}</div>}
                        <div className="button-models">
                            <button className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                                Cancel
                            </button>
                            <button className="model-button font-weight500 model-button-leave font-size-heading" disabled={loading}>
                                {loading ? 'Adding...' : 'Add'}
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    );
};

const UpdateCustomDeductions = ({ i, code, deductionData, salaryStructureHeadings, getAllSalaryRelatedData }) => {

    const [formData, setFormData] = useState({
        name: i.id,
        salary_structure: "",
        salary_type: i.salary_type,
        percentage: '',
        percentage_of: [],
        fixed_amount: '',
    })
    const [show, setShow] = useState(false);
    const [selectedPercentageOf, setSelectedPercentageOf] = useState([]);

    const handleShow = () => {
        const transformedData = {
            ...i,
            percentage_of: Array.isArray(i.percentage_of) ? i.percentage_of.map(item => ({
                value: item.id || item.value,
                label: item.name || item.label,
            })) : [],
        };

        setFormData(transformedData);
        setSelectedPercentageOf(transformedData.percentage_of); // Set selected items for multi-select
        // setFormData(is);
        setShow(true);
    };

    const handleClose = () => {
        setShow(false);
    }

    const [inputState, setInputState] = useState({});
    const [errors, setErrors] = useState({});


    const validateForm = () => {
        const newErrors = {};
        const isSalaryHeadingSelected = !!formData.name;
        const hasFixedAmount = formData.fixed_amount !== '';
        const hasPercentage = formData.percentage !== '' && formData.percentage_of.length > 0;

        // Validate Salary Heading
        if (!isSalaryHeadingSelected) {
            newErrors.name = 'Salary Heading is required!';
        }

        // Validation for Fixed Amount or Percentage
        if (!hasFixedAmount && !hasPercentage) {
            newErrors.mixed = 'Either Fixed Amount or Percentage with Related To is required!';
        }

        // Additional validation for Fixed Amount if it's entered
        if (hasFixedAmount && !formData.fixed_amount) {
            newErrors.fixed_amount = 'Fixed Amount is required!';
        }

        // Additional validation for Percentage if it's entered
        if (hasPercentage) {
            if (!formData.percentage) {
                newErrors.percentage = 'Percentage of Amount is required!';
            }
            if (formData.percentage_of.length === 0) {
                newErrors.percentage_of = 'Related To is required!';
            }
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSelectChange = (selectedOptions) => {
        setSelectedPercentageOf(selectedOptions);
        setFormData({
            ...formData,
            percentage_of: selectedOptions ? selectedOptions.map(option => ({ value: option.value, label: option.label })) : [],
        });
    };
    // ?  ************** Validation End **************  ! //

    const [loading, setLoading] = useState(false); //loading logic
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        // if (!validateForm()) return;
        setLoading(true); //loading logic

        const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
        let sucessToastId;
        const listFormData = [
            formData
        ]
        const formattedString = formData.percentage_of.join(", ");
        const formattedFormData = {
            ...formData,
            percentage_of: formattedString,
        };
        const listformattedFormData = [
            formattedFormData
        ];

        const updatedFormData = {
            ...formData,
            percentage_of: formData.percentage_of.map(item => item.lable), // Only include labels in the array
        };

        try {
            let ress = await axios.put(`${BASE_URL}/wfm/customdeduction/${i.id}/`, updatedFormData);

            if (ress.status === 200 || ress.status === 201) {
                toast.success("Updated Deduction");
                await getAllSalaryRelatedData(code);
                handleClose();

            }
            //  else {
            //     alert(ress);
            // }
        } catch (err) {
            //toast Logic
            handleAllError(err);
            // toast.error("An error occurred while Updating the deduction");
        } finally {
            setLoading(false); // Ensure loading state is stopped even in case of error //loading logic
            toast.dismiss(loadingToastId);
        }

    };
    const deductionIds = deductionData.map(item => item.name);
    const relatedToOptions = salaryStructureHeadings
        .filter(i => deductionIds.includes(i.id))
        .sort((a, b) => a.sorting_var - b.sorting_var)
        .map(i => ({ value: i.title, label: i.title }));

    const headingOptions = salaryStructureHeadings
        // .filter(i => deductionIds.includes(i.id))
        .sort((a, b) => a.sorting_var - b.sorting_var)
        .map(i => ({ value: i.id, label: i.title }));

    const relatedToHeadingOptions = [
        { value: 'Gross', label: 'Gross' },
        ...salaryStructureHeadings
            // .filter(i => !deductionIds.includes(i.id))
            .sort((a, b) => a.sorting_var - b.sorting_var)
            .map(i => ({ value: i.id, label: i.title })),
    ];

    return (
        <div>
            <>
                <button title='Update Deduction' className='model-edit-button' onClick={handleShow}>
                    <UpdatePencil />
                </button>
                <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
                    <Modal.Header closeButton>
                        <Modal.Title>Update Deduction of : "{salaryStructureHeadings?.find(t => t.id === formData.name)?.title} ({salaryStructureHeadings?.find(t => t.id === formData.name)?.salary_type})"</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ToastContainer position="top-center" autoClose={3000} hideProgressBar />
                        <form className="register-form">
                            <div className="">
                                {formData.percentage || formData.percentage_of.length > 0 ? "" :
                                    <div className="flex-column">
                                        <label className="form-labels  font-weight500 font-size-subheading">Fixed Amount<span className="required">*</span></label>
                                        <input
                                            type="number"
                                            name="fixed_amount"
                                            placeholder="Fixed Amount"
                                            min={0}
                                            value={formData.fixed_amount}
                                            onChange={handleInputChange}
                                            className={`form-input ${errors.fixed_amount ? 'is-invalid' : ''}`}
                                        />
                                        {errors.fixed_amount && <div className="error-message font-size-text">{errors.fixed_amount}</div>}
                                    </div>
                                }
                                {formData.fixed_amount ? "" :
                                    <>
                                        <div className="flex-column">
                                            <label className="form-labels  font-weight500 font-size-subheading">Percentage of Amount<span className="required">*</span></label>
                                            <input
                                                type="number"
                                                name="percentage"
                                                placeholder="Percentage of Amount"
                                                min={0}
                                                value={formData.percentage}
                                                onChange={handleInputChange}
                                                className={`form-input ${errors.percentage ? 'is-invalid' : ''}`}
                                            />
                                            {errors.percentage && <div className="error-message font-size-text">{errors.percentage}</div>}
                                        </div>
                                        <div title="Related To" className="flex-column ">
                                            <label className="form-labels  font-weight500 font-size-subheading">Related To<span className="required">*</span></label>
                                            {/* <Select
                                                isMulti
                                                name="percentage_of"
                                                value={relatedToOptions.filter(option => formData.percentage_of?.includes(option.value))}
                                                onChange={handleSelectChange}
                                                options={relatedToOptions}
                                                className={`width-15vw ${errors.percentage_of ? 'is-invalid' : ''}`}
                                                classNamePrefix="select"
                                                placeholder="Select Related To"
                                                isDisabled={!!formData.fixed_amount}
                                                styles={{
                                                    control: (provided) => ({
                                                        ...provided,
                                                        width: '33vw',
                                                        minHeight: '50px',
                                                        borderRadius: '10px',
                                                        border: '1px solid #e3e3e3',
                                                        margin: '10px 0px 20px 0px',
                                                        color: '#4a4a4a',
                                                        backgroundColor: '#f7f8fa',
                                                        padding: '0px 10px',
                                                    }),
                                                    placeholder: (provided) => ({
                                                        ...provided,
                                                        color: '#4a4a4a',
                                                    }),
                                                    menu: (provided) => ({
                                                        ...provided,
                                                        width: '33vw',
                                                        borderRadius: '10px',
                                                        marginTop: '0',
                                                    }),
                                                    singleValue: (provided) => ({
                                                        ...provided,
                                                        color: '#4a4a4a',
                                                    }),
                                                    input: (provided) => ({
                                                        ...provided,
                                                        color: '#4a4a4a',
                                                    }),
                                                }}
                                            /> */}
                                            <Select
                                                isMulti
                                                name="percentage_of"
                                                value={selectedPercentageOf}
                                                onChange={handleSelectChange}
                                                options={relatedToOptions}
                                                className={`width-15vw ${errors.percentage_of ? 'is-invalid' : ''}`}
                                                classNamePrefix="select"
                                                placeholder="Select Related To"
                                                isDisabled={!!formData.fixed_amount}
                                                styles={{
                                                    control: (provided) => ({
                                                        ...provided,
                                                        width: '33vw',
                                                        minHeight: '50px',
                                                        borderRadius: '10px',
                                                        border: '1px solid #e3e3e3',
                                                        margin: '10px 0px 20px 0px',
                                                        color: '#4a4a4a',
                                                        backgroundColor: '#f7f8fa',
                                                        padding: '0px 10px',
                                                    }),
                                                    placeholder: (provided) => ({
                                                        ...provided,
                                                        color: '#4a4a4a',
                                                    }),
                                                    menu: (provided) => ({
                                                        ...provided,
                                                        width: '33vw',
                                                        borderRadius: '10px',
                                                        marginTop: '0',
                                                    }),
                                                    singleValue: (provided) => ({
                                                        ...provided,
                                                        color: '#4a4a4a',
                                                    }),
                                                    input: (provided) => ({
                                                        ...provided,
                                                        color: '#4a4a4a',
                                                    }),
                                                }}
                                            />
                                            {errors.percentage_of && <div className="error-message font-size-text">{errors.percentage_of}</div>}
                                        </div>
                                    </>
                                }
                            </div>
                        </form>
                        {errors.mixed && <div className="error-message font-size-text">{errors.mixed}</div>}
                        <div className="button-models">
                            <button className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                                Cancel
                            </button>
                            <button className="model-button  font-weight500  model-button-leave font-size-heading" onClick={handleFormSubmit} disabled={loading}>
                                {loading ? 'Updating...' : 'Update'}
                            </button>
                        </div>

                    </Modal.Body>
                </Modal>
                <ToastContainer
                    position="top-center"
                    autoClose={1000}
                    hideProgressBar={false}
                    newestOnTop={true}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </>

        </div>
    )

}

const DeleteCustomDeductions = ({ i, code, deductionData, salaryStructureHeadings, getAllSalaryRelatedData }) => {

    const [formData, setFormData] = useState({
        name: i.id,
        salary_structure: "",
        salary_type: i.salary_type,
        percentage: '',
        percentage_of: [],
        fixed_amount: '',
    })
    const [show, setShow] = useState(false);
    const [selectedPercentageOf, setSelectedPercentageOf] = useState([]);

    const handleShow = () => {
        const transformedData = {
            ...i,
            percentage_of: Array.isArray(i.percentage_of) ? i.percentage_of.map(item => ({
                value: item.id || item.value,
                label: item.name || item.label,
            })) : [],
        };

        setFormData(transformedData);
        setSelectedPercentageOf(transformedData.percentage_of); // Set selected items for multi-select
        // setFormData(is);
        setShow(true);


    };

    const handleClose = () => {
        setShow(false);
    }

    const [inputState, setInputState] = useState({});
    const [errors, setErrors] = useState({});



    // ?  ************** Validation End **************  ! //

    const [loading, setLoading] = useState(false); //loading logic
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        // if (!validateForm()) return;
        setLoading(true); //loading logic

        const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
        let sucessToastId;
        const listFormData = [
            formData
        ]
        const formattedString = formData.percentage_of.join(", ");
        const formattedFormData = {
            ...formData,
            percentage_of: formattedString,
        };
        const listformattedFormData = [
            formattedFormData
        ];

        const updatedFormData = {
            ...formData,
            percentage_of: formData.percentage_of.map(item => item.lable), // Only include labels in the array
        };

        try {
            let ress = await axios.delete(`${BASE_URL}/wfm/customdeduction/${i.id}/`);

            if (ress.status === 200 || ress.status === 201) {
                toast.success("Deleted Deduction Successfully");
                await getAllSalaryRelatedData(code);
                handleClose();
            }
            //  else {
            //     alert(ress);
            // }
        } catch (err) {
            //toast Logic
            handleAllError(err);
            // toast.error("An error occurred while Updating the deduction");
        } finally {
            setLoading(false); // Ensure loading state is stopped even in case of error //loading logic
            toast.dismiss(loadingToastId);
        }

    };
    const deductionIds = deductionData.map(item => item.name);
    const relatedToOptions = salaryStructureHeadings
        .filter(i => deductionIds.includes(i.id))
        .sort((a, b) => a.sorting_var - b.sorting_var)
        .map(i => ({ value: i.title, label: i.title }));


    return (
        <div>
            <>
                <button title='Delete Deduction' className='model-delete-button' onClick={handleShow}>
                    <DeleteDustbin />
                </button>
                <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Deduction of : "{salaryStructureHeadings?.find(t => t.id === formData.name)?.title} ({salaryStructureHeadings?.find(t => t.id === formData.name)?.salary_type})"</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ToastContainer position="top-center" autoClose={3000} hideProgressBar />
                        <form className="register-form">
                            <div className="">
                                {formData.percentage || formData.percentage_of.length > 0 ? "" :

                                    <div className="flex-column">
                                        <label className="form-labels  font-weight500 font-size-subheading">Amount :</label>

                                        <div className='form-labels  justify-center'>
                                            {formData?.fixed_amount}
                                        </div>
                                    </div>
                                    // <div className="flex-column">
                                    //         <label className="form-labels  font-weight500 font-size-subheading">Fixed Amount<span className="required">*</span></label>
                                    //         <input
                                    //             type="number"
                                    //             name="fixed_amount"
                                    //             placeholder="Fixed Amount"
                                    //             min={0}
                                    //             value={formData.fixed_amount}
                                    //             className={`form-input ${errors.fixed_amount ? 'is-invalid' : ''}`}
                                    //         />
                                    //         {errors.fixed_amount && <div className="error-message font-size-text">{errors.fixed_amount}</div>}
                                    //     </div>
                                }
                                {formData.fixed_amount ? "" :
                                    <>
                                        <div className="flex-column">
                                            <label className="form-labels  font-weight500 font-size-subheading">Percentage:</label>

                                            <div className='form-labels  justify-center'>
                                                {formData?.percentage}   %
                                            </div>
                                        </div>
                                        <div title="Related To" className="flex-column ">
                                            <label className="form-labels  font-weight500 font-size-subheading">Related To :</label>

                                            <ul>
                                                {formData?.percentage_of ?
                                                    formData?.percentage_of.map((item, index) => {

                                                        return (
                                                            <li key={index}>
                                                                {item?.label}
                                                            </li>)
                                                    }) :
                                                    null
                                                }
                                            </ul>
                                            {errors.percentage_of && <div className="error-message font-size-text">{errors.percentage_of}</div>}
                                        </div>
                                    </>
                                }
                            </div>
                        </form>
                        {errors.mixed && <div className="error-message font-size-text">{errors.mixed}</div>}
                        <div className="button-models">
                            <button className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                                Cancel
                            </button>
                            <button className="model-button  font-weight500  model-button-delete font-size-heading" onClick={handleFormSubmit} disabled={loading}>
                                {loading ? 'Deleting...' : 'Delete'}
                            </button>
                        </div>

                    </Modal.Body>
                </Modal>
                <ToastContainer
                    position="top-center"
                    autoClose={1000}
                    hideProgressBar={false}
                    newestOnTop={true}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </>
        </div>
    )
}

const AddInvestmentDeclaration = ({ code, salaryData, investmentHeading, investmentDeclarationData, getSalaryInvestmentRelatedData }) => {
    const [formData, setFormData] = useState({
        name: '',
        salary_structure: salaryData.id,
        employee: code,
        fixed_amount: '',
        investment_proof: '',
    });
    const [show, setShow] = useState(false);
    const [inputState, setInputState] = useState({});
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    // Update formData when salaryData changes
    useEffect(() => {
        setFormData((prevData) => ({
            ...prevData,
            salary_structure: salaryData.id,
        }));
    }, [salaryData]);
    const handleShow = () => {
        setShow(true);
    }

    const handleClose = () => {
        setShow(false);
        setFormData({
            name: '',
            salary_structure: salaryData.id,
            employee: code,
            fixed_amount: '',
            investment_proof: '',
        });
    };

    // const validateForm = () => {
    //     const newErrors = {};

    //     // Determine which fields are currently visible/active
    //     const isFixedAmountActive = !!formData.fixed_amount;
    //     const isPercentageActive = !!formData.percentage || !!formData.percentage_of;

    //     // Required fields logic
    //     if (isFixedAmountActive) {
    //         if (!formData.fixed_amount) {
    //             newErrors.fixed_amount = ' Fixed Amount is required!';
    //         }
    //     } else if (isPercentageActive) {
    //         if (!formData.percentage) {
    //             newErrors.percentage = ' Percentage of Amount is required!';
    //         }
    //         if (!formData.percentage_of) {
    //             newErrors.percentage_of = ' Related To is required!';
    //         }
    //     } else {
    //         // If neither field is active, show all required fields
    //         newErrors.fixed_amount = ' Fixed Amount is required!';
    //         newErrors.percentage = ' Percentage of Amount is required!';
    //         newErrors.percentage_of = ' Related To is required!';
    //     }

    //     setErrors(newErrors);
    //     return Object.keys(newErrors).length === 0;
    // };

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["fixed_amount", "investment_proof", "name"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)} is required !`;
            }
        });
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleFileChange = (e) => {
        const { name } = e.target;
        const file = e.target.files[0];
        if (file) {
            const validFileTypes = ["application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
            if (validFileTypes.includes(file.type)) {
                setFormData({ ...formData, [name]: file });
                setErrors({ ...errors, [name]: "" });
            } else {
                setErrors({ ...errors, [name]: "Invalid file type. Please select a PDF or DOC file." });
            }
        } else {
            setFormData({ ...formData, [name]: null });
        }
    };

    // const handleFileChange = (e, index) => {
    //     const file = e.target.files[0];
    //     setFormData({
    //         ...formData,
    //         investment_proof: file,
    //     });

    //     setErrors((prevErrors) => {
    //         const newErrors = { ...prevErrors };
    //         if (file) {
    //             delete newErrors[`investment_proof`];
    //         }
    //         return newErrors;
    //     });
    // };



    const handleFileClear = (name) => {
        setFormData({
            ...formData,
            [name]: null,
        });
    };

    // ?  ************** Validation End **************  ! //

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            setLoading(true); //loading logic
            const formDataToSend = new FormData();
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
            let sucessToastId;

            formDataToSend.append(`name`, formData.name);
            formDataToSend.append(`salary_structure`, formData.salary_structure);
            formDataToSend.append(`employee`, formData.employee);
            formDataToSend.append(`fixed_amount`, formData.fixed_amount);

            const symbols = '!@#$%^&*()_-+=';
            const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz';
            const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
            const numbers = '0123456789';
            const now = new Date();
            const date = now.toLocaleDateString('en-GB').split('/').reverse().join('-'); // Format: YYYY-MM-DD
            const time = now.toLocaleTimeString('en-GB', { hour12: false }).replace(/:/g, '-'); // Format: HH-MM-SS
            const allChars = symbols + lowercaseLetters + uppercaseLetters + numbers;
            let randomCode = '';
            for (let i = 0; i < 8; i++) {
                const randomIndex = Math.floor(Math.random() * allChars.length);
                randomCode += allChars[randomIndex];
            }
            const originalFile = formData.investment_proof;
            // const customFileName = `InvestmentProof_${Date.now()}_${originalFile.name}`;
            const customFileName = `Aimantra ${date} ${time}_${randomCode} ${originalFile?.name}`;
            const customFile = new File([originalFile], customFileName, { type: originalFile.type });
            formDataToSend.append("investment_proof", customFile);

            try {
                let response = await axios.post(`${BASE_URL}/wfm/investmentdecleration/`, formDataToSend,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );

                if (response.status === 200) {
                    await getSalaryInvestmentRelatedData(code);
                    handleClose();
                } else {
                    alert(response);
                }
            } catch (err) {
                //toast Logic
                handleAllError(err);
            } finally {
                setLoading(false); // Ensure loading state is stopped even in case of error //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };
    const declarationIds = investmentDeclarationData.map(item => item.name); // Extract IDs from deductionData
    return (
        <div>
            {/* <button title='Add Declaration' className='model-button model-button-submit' onClick={handleShow}>
                    Add
                </button> */}
            <button title='Add Declaration' className='upload-svg float-right' onClick={handleShow}>
                <AddwithWhiteCircle />{"  Investment Declaration"}
            </button>
            <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
                <Modal.Header closeButton>
                    <Modal.Title>Add Declaration</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ToastContainer position="top-center" autoClose={3000} hideProgressBar />
                    <div className="register-form">
                        <div className="form-flex-wrap flex-column">

                            <div title="Salary Structure Heading" className="flex-column form-group-selectt">
                                <label htmlFor="name" className="form-labels font-weight500 font-size-subheading">
                                    Salary Structure Heading<span className="required">*</span>
                                </label>
                                <br />
                                <select
                                    id="name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    className={`form-input form-group-selection ${errors.name ? "error" : inputState.name ? "success" : ""}`}
                                >
                                    <option value="">Select Investment Heading</option>
                                    {investmentHeading
                                        ?.filter(i => !declarationIds.includes(i.id))
                                        .map((i, index) => (
                                            <option key={index} value={i.id}>
                                                {i.title}
                                            </option>
                                        ))
                                    }
                                </select>

                                {/* <div className="form-group-selection-arrow">
                                        <DropdownArrowOption />
                                    </div> */}
                                <div>
                                    {errors.name && (
                                        <span className="error-message font-size-text">{errors.name}</span>
                                    )}
                                </div>
                            </div>

                            <div className="flex-column">
                                <label className="form-labels font-weight500 font-size-subheading">Fixed Amount<span className="required">*</span></label>
                                <input
                                    type="number"
                                    name="fixed_amount"
                                    placeholder="Fixed Amount"
                                    min={0}
                                    value={formData.fixed_amount}
                                    onChange={handleInputChange}
                                    className={`form-input ${errors.fixed_amount ? 'is-invalid' : ''}`}
                                />
                                {errors.fixed_amount && <div className="error-message font-size-text">{errors.fixed_amount}</div>}
                            </div>

                            <div className="flex-column">
                                <label htmlFor="investment_proof_file" className="form-labels font-weight500    announce-date font-weight400  font-size-heading">
                                    Investment Proof<span className="required">*</span>
                                </label>
                                <div className="flex-row">
                                    <label htmlFor={`investment_proof`} className="svg-icon">
                                        <svg className="pdf-input" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 20 20" fill="none">
                                            <path
                                                d="M17.8666 9.2081L10.2082 16.8664C9.27005 17.8046 7.99757 18.3317 6.67075 18.3317C5.34393 18.3317 4.07145 17.8046 3.13325 16.8664C2.19505 15.9282 1.66797 14.6558 1.66797 13.3289C1.66797 12.0021 2.19505 10.7296 3.13325 9.79144L10.7916 2.1331C11.4171 1.50763 12.2654 1.15625 13.1499 1.15625C14.0345 1.15625 14.8828 1.50763 15.5082 2.1331C16.1337 2.75857 16.4851 3.60689 16.4851 4.49144C16.4851 5.37598 16.1337 6.2243 15.5082 6.84977L7.84158 14.5081C7.52885 14.8208 7.10469 14.9965 6.66242 14.9965C6.22014 14.9965 5.79598 14.8208 5.48325 14.5081C5.17051 14.1954 4.99482 13.7712 4.99482 13.3289C4.99482 12.8867 5.17051 12.4625 5.48325 12.1498L12.5582 5.0831"
                                                stroke="#707070"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </label>
                                    <input
                                        id="investment_proof"
                                        type="file"
                                        name="investment_proof"
                                        accept=".pdf, .doc, .docx"
                                        onChange={handleFileChange}
                                        className="file-inputs align-center"
                                        // style={{ display: "block" }}
                                        style={{ display: "none", position: "relative" }}
                                    />
                                    <div >
                                        {formData.investment_proof && <span className="file-name">{formData.investment_proof.name}</span>}
                                    </div>
                                </div>
                                {formData.investment_proof && (
                                    <span className="file-clear" onClick={() => handleFileClear("investment_proof")}>Clear</span>
                                )}
                                {errors.investment_proof && <div className="error-message font-size-text">{errors.investment_proof}</div>}
                            </div>
                        </div>
                    </div>

                    <div className="button-models">
                        <button className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                            Cancel
                        </button>
                        <button className="model-button model-button-leave font-weight500 font-size-heading" onClick={handleFormSubmit} disabled={loading}>
                            {loading ? 'Adding...' : 'Add'}
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
            <ToastContainer
                position="top-center"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    )
}

const UpdateInvestmentDeclaration = ({ i, code, investmentHeading, getSalaryInvestmentRelatedData }) => {

    const [formData, setFormData] = useState({
        name: i.id,
        salary_structure: "",
        employee: '',
        fixed_amount: '',
        investment_proof: '',
    })
    const [show, setShow] = useState(false);
    const handleShow = () => {
        setShow(true);
        setFormData(i);
    }

    const handleClose = () => {
        setShow(false);
    }

    const [inputState, setInputState] = useState({});
    const [errors, setErrors] = useState({});

    const validateForm = () => {
        const newErrors = {};

        // Determine which fields are currently visible/active
        const isFixedAmountActive = !!formData.fixed_amount;
        const isPercentageActive = !!formData.percentage || !!formData.percentage_of;

        // Required fields logic
        if (isFixedAmountActive) {
            if (!formData.fixed_amount) {
                newErrors.fixed_amount = ' Fixed Amount is required!';
            }
        } else if (isPercentageActive) {
            if (!formData.percentage) {
                newErrors.percentage = ' Percentage of Amount is required!';
            }
            if (!formData.percentage_of) {
                newErrors.percentage_of = ' Related To is required!';
            }
        } else {
            // If neither field is active, show all required fields
            newErrors.fixed_amount = ' Fixed Amount is required!';
            newErrors.percentage = ' Percentage of Amount is required!';
            newErrors.percentage_of = ' Related To is required!';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleFileChange = (e, index) => {
        const file = e.target.files[0];
        setFormData({
            ...formData,
            investment_proof: file,
        });

        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            if (file) {
                delete newErrors[`investment_proof`];
            }
            return newErrors;
        });
    };

    const handleFileClear = (name) => {
        setFormData({
            ...formData,
            [name]: null,
        });
    };

    const isLink = (value) => {
        if (value instanceof File) return false;
        const urlPattern = /^(https?:\/\/)?([a-z0-9]+[.-])*[a-z0-9]+(\.[a-z]{2,})+(\/[^\s]*)?(\?[^\s]*)?$/i;
        return urlPattern.test(value);
    };


    // ?  ************** Validation End **************  ! //

    const [loading, setLoading] = useState(false); //loading logic
    const handleFormSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true); //loading logic
            const formDataToSend = new FormData();

            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
            let sucessToastId;

            formDataToSend.append(`name`, formData.name);
            formDataToSend.append(`salary_structure`, formData.salary_structure);
            formDataToSend.append(`fixed_amount`, formData.fixed_amount);
            formDataToSend.append(`employee`, formData.employee);

            const symbols = '!@#$%^&*()_-+=';
            const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz';
            const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
            const numbers = '0123456789';
            const now = new Date();
            const date = now.toLocaleDateString('en-GB').split('/').reverse().join('-'); // Format: YYYY-MM-DD
            const time = now.toLocaleTimeString('en-GB', { hour12: false }).replace(/:/g, '-'); // Format: HH-MM-SS
            const allChars = symbols + lowercaseLetters + uppercaseLetters + numbers;
            let randomCode = '';
            for (let i = 0; i < 8; i++) {
                const randomIndex = Math.floor(Math.random() * allChars.length);
                randomCode += allChars[randomIndex];
            }
            const originalFile = formData.investment_proof;
            const customFileName = `Aimantra ${date} ${time}_${randomCode} ${originalFile.name}`;
            const customFile = new File([originalFile], customFileName, { type: originalFile.type });
            // formDataToSend.append("investment_proof", customFile);

            if (!isLink(formData.investment_proof)) {
                formDataToSend.append('investment_proof', customFile);
            }

            try {
                let ress = await axios.put(
                    `${BASE_URL}/wfm/investmentdecleration/${i.id}/`,
                    formDataToSend
                );

                if (ress.status === 200) {
                    handleClose();
                    await getSalaryInvestmentRelatedData(code);

                } else {
                    alert(ress);
                }
            } catch (err) {
                //toast Logic
                handleAllError(err);
            } finally {
                setLoading(false); // Ensure loading state is stopped even in case of error //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };

    return (
        <div>
            <>
                <button title='Update Declaration' className='model-edit-button' onClick={handleShow}>
                    <UpdatePencil />
                </button>

                <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
                    <Modal.Header closeButton>
                        <Modal.Title>Update Declaration of  "{investmentHeading.find(t => t.id === formData.name)?.title}"</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ToastContainer position="top-center" autoClose={3000} hideProgressBar />
                        <form className="register-form">
                            <div className="form-flex-wrap flex-column">

                                <div className="flex-column">
                                    <label className="form-labels  font-weight500 font-size-subheading">Fixed Amount<span className="required">*</span></label>
                                    <input
                                        type="number"
                                        name="fixed_amount"
                                        placeholder="Fixed Amount"
                                        min={0}
                                        value={formData.fixed_amount}
                                        onChange={handleInputChange}
                                        className={`form-input ${errors.fixed_amount ? 'is-invalid' : ''}`}
                                    />
                                    {errors.fixed_amount && <div className="error-message font-size-text">{errors.fixed_amount}</div>}
                                </div>

                                <div className="flex-column">

                                    <label htmlFor="investment_proof_file" className="form-labels font-weight500    announce-date font-weight400  font-size-heading">
                                        Investment Proof<span className="required">*</span>
                                    </label>
                                    <div className="flex-row">

                                        <label htmlFor={`investment_proof`} className="svg-icon">
                                            <svg className="pdf-input" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 20 20" fill="none">
                                                <path
                                                    d="M17.8666 9.2081L10.2082 16.8664C9.27005 17.8046 7.99757 18.3317 6.67075 18.3317C5.34393 18.3317 4.07145 17.8046 3.13325 16.8664C2.19505 15.9282 1.66797 14.6558 1.66797 13.3289C1.66797 12.0021 2.19505 10.7296 3.13325 9.79144L10.7916 2.1331C11.4171 1.50763 12.2654 1.15625 13.1499 1.15625C14.0345 1.15625 14.8828 1.50763 15.5082 2.1331C16.1337 2.75857 16.4851 3.60689 16.4851 4.49144C16.4851 5.37598 16.1337 6.2243 15.5082 6.84977L7.84158 14.5081C7.52885 14.8208 7.10469 14.9965 6.66242 14.9965C6.22014 14.9965 5.79598 14.8208 5.48325 14.5081C5.17051 14.1954 4.99482 13.7712 4.99482 13.3289C4.99482 12.8867 5.17051 12.4625 5.48325 12.1498L12.5582 5.0831"
                                                    stroke="#707070"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </label>
                                        <input
                                            id="investment_proof"
                                            type="file"
                                            name="investment_proof"
                                            accept=".pdf"
                                            onChange={handleFileChange}
                                            className="file-inputs align-center"
                                            style={{ display: "none", position: "relative" }}

                                        />
                                        <div >
                                            {formData.investment_proof && (
                                                <span className="file-name">
                                                    {formData.investment_proof.name ? formData.investment_proof.name :
                                                        getFileNameFromLink(formData.investment_proof, "")
                                                    }
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    {formData.investment_proof ? (
                                        <span
                                            className="file-clear"
                                            onClick={() => handleFileClear("investment_proof")}
                                        >
                                            Clear
                                        </span>
                                    ) : null}
                                </div>

                            </div>
                        </form>


                        <div className="button-models">
                            <button className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                                Cancel
                            </button>
                            <button className="model-button  font-weight500  model-button-leave font-size-heading" onClick={handleFormSubmit} disabled={loading}>
                                {loading ? 'Updating...' : 'Update'}
                            </button>
                        </div>

                    </Modal.Body>
                </Modal>
                <ToastContainer
                    position="top-center"
                    autoClose={1000}
                    hideProgressBar={false}
                    newestOnTop={true}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </>

        </div>
    )



}

export {
    EmployeeSalaryStructure,
    UserSalaryStructure,
    UserCustomDeductions,
    EmployeeInvestmentDeclaration,
    UploadSalaryDataBulk,

};
