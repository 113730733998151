import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../config/axios";
import { useNavigate, useParams } from "react-router-dom";
import { formattedDate } from "../Date";
import { ToastContainer, toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import Docs from "../../assets/svg/docs.svg";
import Profile from "../../assets/svg/profile.svg";
import CreatableSelect from 'react-select/creatable';


const PersonaldetailsAdd = ({ email, id, forwardroutepath }) => {
  const [jdid, setJdid] = useState();

  const [formData, setFormData] = useState({
    name: "",
    email: email,
    alternate_email: null,
    father_name: "",
    mother_name: "",
    mobile: "",
    alt_mobile: null,
    gender: "",
    dob: "",
    blood_group: "",
    birth_mark: "",
    place_of_birth: "",
    married_status: "",
    marriage_date: null,
    aadhaar: "",
    pan: "",
    uan: "",
    esi: "",
    permanent_address: "",
    current_address: "",
    current_addresss: false,
  });

  const jobApplicant = useState({
    name: "",
    jobpostdetails: "",
    applied_date: "",
    user_detail: "",
  });

  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "name",
      "email",
      "father_name",
      "mother_name",
      "mobile",
      "gender",
      "dob",
      "blood_group",
      "birth_mark",
      "married_status",
      "aadhaar",
      "pan",
      "permanent_address",
      "current_address",
    ];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));

    // If checked, set permanent_address to current_address
    if (checked) {
      setFormData((prevData) => ({
        ...prevData,
        permanent_address: prevData.current_address,
      }));
    }

    if (!checked) {
      setFormData((prevData) => ({
        ...prevData,
        permanent_address: "",
      }));
    }
  };

  //same date

  // ?  ************** Validation End **************  ! //
  const navigate = useNavigate();

  const symbols = "!@#$%^&*()_-+=";
  const lowercaseLetters = "abcdefghijklmnopqrstuvwxyz";
  const uppercaseLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const numbers = "0123456789";

  const allChars = symbols + lowercaseLetters + uppercaseLetters + numbers;

  let newPassword = "";
  for (let i = 0; i < 8; i++) {
    const randomIndex = Math.floor(Math.random() * allChars.length);
    newPassword += allChars[randomIndex];
  }

  const [loading, setLoading] = useState(false); //loading logic

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    // navigate("/educationDetails")
    if (validateForm()) {
      setLoading(true); //loading logic
      // Navigate("/educationDetails")
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

      const updatedJobApplicant = {
        ...formData,
        employee: formData.email,
        email: formData.email,

        jobpostdetails: id,
        applied_date: formattedDate,
        user_detail: formData.email,
        user: formData.email,
        remarks: "Pending for Check",
        password: newPassword,
        role: "Jobapplicant",
      };

      try {
        let ress = await axios.post(
          `${BASE_URL}/jd/jobapplicant/`,
          updatedJobApplicant
        );

        if (ress.status === 200) {
          forwardroutepath === "educationDetailss" ?
            navigate(`/${forwardroutepath}/${formData.email}/`) : navigate(`/${forwardroutepath}/${id}/${formData.email}/`);
        } else {
          alert(ress);
        }
      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        setLoading(false); // Ensure loading state is stopped even in case of error //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };

  const handleFormPut = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

      const { profilepic, ...formDataWithoutProfilePic } = formData; // Destructure to remove profilepic

      const updatedFormData = {
        ...formDataWithoutProfilePic,
        employee: formData.email,
      };

      const updatedJobApplicant = {
        ...jobApplicant,
        name: formData.name,
        jobpostdetails: id,
        applied_date: formattedDate,
        user_detail: formData.email,
      };

      try {
        let res = await axios.put(
          `${BASE_URL}/userdata/userdata/${formData.email}/`,
          updatedFormData
        );

        if (jdid !== id) {
          let ress = await axios.post(
            `${BASE_URL}/jd/jobapplicant/`,
            updatedJobApplicant
          );
        }

        if (res.status === 200) {
          forwardroutepath === "educationDetailss" ?
            navigate(`/${forwardroutepath}/${formData.email}/`) : navigate(`/${forwardroutepath}/${id}/${formData.email}/`);
        } else {
          alert(res);
        }
      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };

  const [data, setData] = useState(false);
  const [jobposition, setJobPosition] = useState({});
  const [buffer, setBuffering] = useState(false); //buffering logic

  const getUSerDetails = async () => {
    setBuffering(true); //buffering logic
    try {
      const res = await axios.get(
        `${BASE_URL}/userdata/userdataget/${formData.email}/`
      );
      setFormData(res.data);
      setData(true);
    } catch (err) {
      setData(false);
      //toast Logic
      // if (err.response) {
      //   const errorData = err.response.data;

      //   if (typeof errorData.error === 'string') {
      //     // Single error message
      //     toast.error(`Error: ${errorData.error}`);
      //   } else if (typeof errorData === 'object' && errorData !== null) {
      //     // Multiple error messages
      //     Object.entries(errorData).forEach(([field, messages]) => {
      //       messages.forEach(message => toast.error(`"${field}": ${message}`));
      //     });
      //   } else {
      //     toast.error('Error:- Failed to Process!');
      //   }
      // } else {
      //   toast.error('Error processing your request.');
      // }
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  const getUSerDetailss = async () => {
    setBuffering(true); //buffering logic // Start Buffering

    try {
      const ress = await axios.get(
        `${BASE_URL}/jd/jobapplicant/${id}/${formData.email}/`
      );
      setJdid(ress.data.jobpostdetails);
    } catch (err) {
      ///toast Logic
      // if (err.response) {
      //   const errorData = err.response.data;

      //   if (typeof errorData.error === 'string') {
      //     // Single error message
      //     toast.error(`Error: ${errorData.error}`);
      //   } else if (typeof errorData === 'object' && errorData !== null) {
      //     // Multiple error messages
      //     Object.entries(errorData).forEach(([field, messages]) => {
      //       messages.forEach(message => toast.error(`"${field}": ${message}`));
      //     });
      //   } else {
      //     toast.error('Error:- Failed to Process!');
      //   }
      // } else {
      //   toast.error('Error processing your request.');
      // }
    } finally {
      setBuffering(false);// End Buffering
    }
  };
  const getJobpositionlist = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(`${BASE_URL}/jd/jobpost/${id}/`);
      setJobPosition(res.data);
    } catch (err) {
      //toast Logic
      // if (err.response) {
      //   const errorData = err.response.data;

      //   if (typeof errorData.error === 'string') {
      //     // Single error message
      //     toast.error(`Error: ${errorData.error}`);
      //   } else if (typeof errorData === 'object' && errorData !== null) {
      //     // Multiple error messages
      //     Object.entries(errorData).forEach(([field, messages]) => {
      //       messages.forEach(message => toast.error(`"${field}": ${message}`));
      //     });
      //   } else {
      //     toast.error('Error:- Failed to Process!');
      //   }
      // } else {
      //   toast.error('Error processing your request.');
      // }
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  useEffect(() => {
    getUSerDetailss();
    getUSerDetails();
    getJobpositionlist();
  }, [formData.email, email, id, jdid]);

  return (
    <>
      {buffer ? <div className="spinner-z"></div> : null}{/* //buffering logic */}
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div className="jd-heading-outer">
        <h2 className="jd-heading-main font-weight600  font-size-heading">Personal Information*</h2>
        <div>
          <div className="jd-heading-bottom-bold"></div>
          <div className="jd-heading-bottom-light"></div>
        </div>
      </div>

      <form className="register-form">
        <div className="form-flex-wrap">
          <div className="flex-column">
            <label htmlFor="name" className="form-labels font-weight500    font-size-subheading">
              Name<span className="required">*</span>
            </label>

            <input
              type="text"
              id="name"
              name="name"
              placeholder="Candidate name"
              onChange={handleInputChange}
              value={formData.name}
              className={`form-input form-text-trasformation-uppercase ${errors.name ? "error" : inputState.name ? "success" : ""
                }`}
            />
            {errors.name && (
              <span className="error-message font-size-text ">{errors.name}</span>
            )}
          </div>

          <div className="flex-column">
            <label htmlFor="dob" className="form-labels font-weight500    font-size-subheading">
              Date of Birth<span className="required">*</span>
            </label>
            <input
              type="date"
              id="dob"
              name="dob"
              onChange={handleInputChange}
              value={formData.dob}
              className={`form-input ${errors.dob ? "error" : inputState.dob ? "success" : ""
                }`}
            />
            {errors.dob && <span className="error-message font-size-text ">{errors.dob}</span>}
          </div>

          <div className="flex-column">
            <label htmlFor="email" className="form-labels font-weight500    font-size-subheading">
              Email<span className="required">*</span>
            </label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Demo@gmail.com"
              autoComplete="off"
              onChange={handleInputChange}
              onInput={(e) => {
                e.target.value = e.target.value.toLowerCase().trim(); // Convert input to lowercase
              }}
              value={formData.email}
              className={`form-input ${errors.email ? "error" : inputState.email ? "success" : ""
                }`}
            />
            {errors.email && (
              <span className="error-message font-size-text ">{errors.email}</span>
            )}
          </div>

          <div className="flex-column">
            <label htmlFor="alternate_email" className="form-labels font-weight500    font-size-subheading">
              Alternate Email
            </label>
            <input
              type="alternate_email"
              id="alternate_email"
              name="alternate_email"
              placeholder="Demo@gmail.com"
              autoComplete="off"
              onChange={handleInputChange}
              onInput={(e) => {
                e.target.value = e.target.value.toLowerCase().trim(); // Convert input to lowercase
              }}
              value={formData.alternate_email}
              className={`form-input ${errors.alternate_email
                ? "error"
                : inputState.alternate_email
                  ? "success"
                  : ""
                }`}
            />
          </div>

          <div className="flex-column">
            <label htmlFor="father_name" className="form-labels font-weight500    font-size-subheading">
              Father Name<span className="required">*</span>
            </label>
            <input
              maxLength={50}
              type="text"
              id="father_name"
              name="father_name"
              placeholder="Father name"
              onChange={handleInputChange}
              value={formData.father_name}
              className={`form-text-trasformation-uppercase form-input ${errors.father_name
                ? "error"
                : inputState.father_name
                  ? "success"
                  : ""
                }`}
            />
            {errors.father_name && (
              <span className="error-message font-size-text ">{errors.father_name}</span>
            )}
          </div>

          <div className="flex-column">
            <label htmlFor="mother_name" className="form-labels font-weight500    font-size-subheading">
              Mother Name<span className="required">*</span>
            </label>
            <input
              type="text"
              id="mother_name"
              maxLength={50}
              name="mother_name"
              placeholder="Mother name"
              onChange={handleInputChange}
              value={formData.mother_name}
              className={`form-text-trasformation-uppercase form-input ${errors.mother_name
                ? "error"
                : inputState.mother_name
                  ? "success"
                  : ""
                }`}
            />
            {errors.mother_name && (
              <span className="error-message font-size-text ">{errors.mother_name}</span>
            )}
          </div>

          <div className="flex-column">
            <label htmlFor="mobile" className="form-labels font-weight500    font-size-subheading">
              Mobile<span className="required">*</span>
            </label>
            <input
              type="number"
              id="mobile"
              name="mobile"
              maxLength={10}
              placeholder="99999 88888"
              onChange={handleInputChange}
              value={formData.mobile}
              min="0"
              className={`form-input ${errors.mobile ? "error" : inputState.mobile ? "success" : ""
                }`}
            />
            {errors.mobile && (
              <span className="error-message font-size-text ">{errors.mobile}</span>
            )}
          </div>

          <div className="flex-column">
            <label htmlFor="alt_mobile" className="form-labels font-weight500    font-size-subheading">
              Alternate Mobile
            </label>
            <input
              type="number"
              id="alt_mobile"
              name="alt_mobile"
              placeholder="99999 88888"
              onChange={handleInputChange}
              value={formData.alt_mobile}
              min="0"
              className={`form-input ${errors.alt_mobile
                ? "error"
                : inputState.alt_mobile
                  ? "success"
                  : ""
                }`}
            />
            {errors.alt_mobile && (
              <span className="error-message font-size-text ">{errors.alt_mobile}</span>
            )}
          </div>

          <div className="flex-column form-group-select">
            <label htmlFor="gender" className="form-labels font-weight500    font-size-subheading">
              Gender<span className="required">*</span>
            </label>
            <br />
            <select
              id="gender"
              name="gender"
              value={formData.gender}
              onChange={handleInputChange}
              className={`form-input form-group-selection ${errors.gender ? "error" : inputState.gender ? "success" : ""
                }`}
            >
              <option value="">Select Gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>
            <div className="form-group-selection-arrow">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="25"
                fill="currentColor"
                class="bi bi-caret-down-fill"
                viewBox="0 0 16 16"
              >
                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
              </svg>
            </div>
            <div>
              {errors.gender && (
                <span className="error-message font-size-text ">{errors.gender}</span>
              )}
            </div>
          </div>

          <div className="flex-column form-group-selectt">
            <label htmlFor="blood_group" className="form-labels font-weight500    font-size-subheading">
              Blood Group<span className="required">*</span>
            </label>
            <br />
            <select
              id="blood_group"
              name="blood_group"
              value={formData.blood_group}
              onChange={handleInputChange}
              className={`form-input form-group-selection ${errors.blood_group
                ? "error"
                : inputState.blood_group
                  ? "success"
                  : ""
                }`}
            >
              <option value="">Select a Blood Group</option>
              <option value="A+">A+</option>
              <option value="A-">A-</option>
              <option value="B+">B+</option>
              <option value="B-">B-</option>
              <option value="AB+">AB+</option>
              <option value="AB-">AB-</option>
              <option value="O+">O+</option>
              <option value="O-">O-</option>
              <option value="na">Not Know</option>
            </select>
            <div className="form-group-selection-arrow">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="25"
                fill="currentColor"
                class="bi bi-caret-down-fill"
                viewBox="0 0 16 16"
              >
                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
              </svg>
            </div>
            <div>
              {errors.blood_group && (
                <span className="error-message font-size-text ">{errors.blood_group}</span>
              )}
            </div>
          </div>

          <div className="flex-column">
            <label htmlFor="birth_mark" className="form-labels font-weight500    font-size-subheading">
              Birth Mark<span className="required">*</span>
            </label>
            <input
              type="text"
              id="birth_mark"
              name="birth_mark"
              placeholder="Birth mark"
              maxLength={50}
              onChange={handleInputChange}
              value={formData.birth_mark}
              className={`form-input ${errors.birth_mark
                ? "error"
                : inputState.birth_mark
                  ? "success"
                  : ""
                }`}
            />
            {errors.birth_mark && (
              <span className="error-message font-size-text ">{errors.birth_mark}</span>
            )}
          </div>

          <div className="flex-column">
            <label htmlFor="place_of_birth" className="form-labels font-weight500    font-size-subheading">
              Birth Place
            </label>
            <input
              type="text"
              id="place_of_birth"
              name="place_of_birth"
              placeholder="Place of birth"
              maxLength={50}
              onChange={handleInputChange}
              value={formData.place_of_birth}
              className={`form-input ${errors.place_of_birth
                ? "error"
                : inputState.place_of_birth
                  ? "success"
                  : ""
                }`}
            />
            {errors.place_of_birth && (
              <span className="error-message font-size-text ">{errors.place_of_birth}</span>
            )}
          </div>

          <div className="flex-column form-group-select">
            <label htmlFor="married_status" className="form-labels font-weight500    font-size-subheading">
              Marital Status<span className="required">*</span>
            </label>
            <br />
            <select
              id="married_status"
              name="married_status"
              value={formData.married_status}
              onChange={handleInputChange}
              className={`form-input form-group-selection ${errors.married_status
                ? "error"
                : inputState.married_status
                  ? "success"
                  : ""
                }`}
            >
              <option value="">Select a Married Status</option>
              <option value="Single">Single</option>
              <option value="Married">Married</option>
              <option value="Divorced">Divorced</option>
              <option value="Widowed">Widowed</option>
              <option value="Separated">Separated</option>
            </select>
            <div className="form-group-selection-arrow">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="25"
                fill="currentColor"
                class="bi bi-caret-down-fill"
                viewBox="0 0 16 16"
              >
                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
              </svg>
            </div>
            <div>
              {errors.married_status && (
                <span className="error-message font-size-text ">{errors.married_status}</span>
              )}
            </div>
          </div>

          <div className="flex-column">
            <label htmlFor="marriage_date" className="form-labels font-weight500    font-size-subheading">
              Marriage Date
            </label>
            <input
              type="date"
              id="marriage_date"
              name="marriage_date"
              placeholder="Demo Demo"
              onChange={handleInputChange}
              value={formData.marriage_date}
              className={`form-input ${errors.marriage_date
                ? "error"
                : inputState.marriage_date
                  ? "success"
                  : ""
                }`}
            />
          </div>

          <div className="flex-column">
            <label htmlFor="aadhaar" className="form-labels font-weight500    font-size-subheading">
              Aadhaar<span className="required">*</span>
            </label>
            <input
              type="number"
              id="aadhaar"
              name="aadhaar"
              placeholder="1234 1234 1234"
              min="0"
              onChange={handleInputChange}
              value={formData.aadhaar}
              className={`form-input ${errors.aadhaar ? "error" : inputState.aadhaar ? "success" : ""
                }`}
            />
            {errors.aadhaar && (
              <span className="error-message font-size-text ">{errors.aadhaar}</span>
            )}
          </div>

          <div className="flex-column">
            <label htmlFor="pan" className="form-labels font-weight500    font-size-subheading">
              Pan<span className="required">*</span>
            </label>
            <input
              type="text"
              id="pan"
              name="pan"
              placeholder="AAAAA1234A"
              min="0"
              maxLength={10}
              onChange={handleInputChange}
              onInput={(e) => {
                e.target.value = e.target.value.toUpperCase(); // Convert input to uppercase
              }}
              value={formData.pan}
              className={`form-input ${errors.pan ? "error" : inputState.pan ? "success" : ""
                }`}
            />
            {errors.pan && <span className="error-message font-size-text ">{errors.pan}</span>}
          </div>

          <div className="flex-column">
            <label htmlFor="uan" className="form-labels font-weight500    font-size-subheading">
              Uan
            </label>
            <input
              type="text"
              maxLength={50}
              id="uan"
              name="uan"
              // placeholder="Demo Demo"
              onChange={handleInputChange}
              value={formData.uan}
              className={`form-input ${errors.uan ? "error" : inputState.uan ? "success" : ""
                }`}
            />
          </div>

          <div className="flex-column">
            <label htmlFor="esi" className="form-labels font-weight500    font-size-subheading">
              Esi
            </label>
            <input
              type="text"
              id="esi"
              maxLength={50}
              name="esi"
              // placeholder="Demo Demo"
              onChange={handleInputChange}
              value={formData.esi}
              className={`form-input ${errors.esi ? "error" : inputState.esi ? "success" : ""
                }`}
            />
          </div>

          <div className="flex-column">
            <label htmlFor="current_address" className="form-labels font-weight500    font-size-subheading">
              Current Address<span className="required">*</span>
            </label>
            <textarea
              type="text"
              id="current_address"
              name="current_address"
              maxLength={300}
              placeholder="Current Address"
              onChange={handleInputChange}
              value={formData.current_address}
              className={`form-input-textarea   font-weight400  font-size-subheading ${errors.current_address
                ? "error"
                : inputState.current_address
                  ? "success"
                  : ""
                }`}
            />
            {errors.current_address && (
              <span className="error-message font-size-text ">{errors.current_address}</span>
            )}
          </div>

          <div className="flex-column">
            <label htmlFor="current_addresss" className="form-labels font-weight500    font-size-subheading">
              Permanent Address <span className="required">*</span>
              <span className="label-sub-heading   font-weight400  font-size-label">( Use Default Address)</span>
              <input
                type="checkbox"
                id="current_addresss"
                maxLength={300}
                name="current_addresss"
                checked={formData.current_addresss}
                onChange={handleCheckboxChange}
              />
            </label>

            <textarea
              type="text"
              id="permanent_address"
              name="permanent_address"
              placeholder="Permanent Address"
              maxLength={300}
              onChange={handleInputChange}
              value={formData.permanent_address}
              className={`form-input-textarea   font-weight400  font-size-subheading ${errors.permanent_address
                ? "error"
                : inputState.permanent_address
                  ? "success"
                  : ""
                }`}
            />
          </div>
        </div>

        <div className="button-models">
          {data ? (
            <button
              onClick={handleFormPut}
              className="model-button  font-weight500    model-button-submit"
              disabled={loading}
            >
              {/* //loading logic */}
              Next
            </button>
          ) : jobposition.status === "active" ? (
            <button
              onClick={handleFormSubmit}
              className="model-button  font-weight500    model-button-submit"
              disabled={loading}
            >
              {/* //loading logic */}
              Next
            </button>
          ) : (
            <button
              onClick={() => {
                const notification = document.createElement("div");
                notification.classList.add("notification");
                notification.textContent =
                  "This Form is Freezed Please Contact Our HR Team";
                document.body.appendChild(notification);
                setTimeout(() => {
                  document.body.removeChild(notification);
                }, 20000); // Remove notification after 2 seconds
              }}
              className="model-button  font-weight500    model-button-cancel font-size-heading"
              disabled={loading}
            >
              {/* //loading logic */}
              Freezed
            </button>
          )}
        </div>
      </form>
    </>
  );
};

const EducationdetailsAdd = ({ email, id, forwardroutepath, backroutepath }) => {
  const [inputFields, setInputFields] = useState([
    {
      college: "",
      institution_type: "",
      department: "",
      stream: "",
      startDate: "",
      endDate: "",
      grade: "",
      otherDepartment: "", // Add this line
      user: email,

    },
  ]);

  const [errors, setErrors] = useState({});

  // const handleChange = (index, e) => {
  //   const { name, value } = e.target;
  //   const updatedFields = [...inputFields];
  //   updatedFields[index][name] = value;
  //   setInputFields(updatedFields);
  // };


  const handleChange = (index, event) => {
    const { name, value } = event.target || event; // Handle event directly or from target
    const newInputFields = [...inputFields];

    if (name === "department") {
      newInputFields[index][name] = value;
      if (value !== "Other") {
        newInputFields[index].otherDepartment = ""; // Clear otherDepartment if department is not "Other"
      }
    } else if (name === "otherDepartment") {
      newInputFields[index][name] = value;
    } else if (name === "stream") {
      newInputFields[index][name] = value;
    } else {
      newInputFields[index][name] = value;
      setInputFields(newInputFields);
    }

    console.log("newInputFields");
    console.log(newInputFields);

    setInputFields(newInputFields);
  };

  // const handleInputChangeClient = (selectedOption) => {
  //   if (selectedOption && typeof selectedOption === 'object' && selectedOption.value) {
  //     setFormData({
  //       ...formData,
  //       client_name: selectedOption.value,
  //     });
  //     setInputState({
  //       ...inputState,
  //       client_name: selectedOption.value.trim() ? "green" : "",
  //     });
  //   } else if (typeof selectedOption === 'string') {
  //     setFormData({
  //       ...formData,
  //       client_name: selectedOption,
  //     });
  //     setInputState({
  //       ...inputState,
  //       client_name: selectedOption.trim() ? "green" : "",
  //     });
  //   }
  // };

  const handleAddInputField = () => {
    setInputFields([
      ...inputFields,
      {
        college: "",
        institution_type: "",
        department: "",
        stream: "",
        startDate: "",
        endDate: "",
        grade: "",
        user: email,
      },
    ]);
  };

  const handleRemoveInputField = (index) => {
    const updatedFields = [...inputFields];
    updatedFields.splice(index, 1);
    setInputFields(updatedFields);
  };

  const validateForm = () => {
    const newErrors = {};
    const bachelorDegreesWithMandatoryHighSchool = [
      "B.A. (Bachelor of Arts)",
      "B.Com. (Bachelor of Commerce)",
      "B.Tech. (Bachelor of Technology)",
      "B.E. (Bachelor of Engineering )",
      "B.Arch. (Bachelor of Architecture)",
      "Other",
    ];

    const bachelorDegreesWithMandatorySchool = [
      "Diploma",
      "ITI",
    ];

    const masterDegreesWithMandatorySchool = [
      "M.Com. (Masters of Commerce)",
      "MBA (Master of Business Administration)",
      "M.Tech. (Master of Technology)",
      "M.E. (Master of Engineering )",
      "PhD",
    ];

    const seniorSecondaryWithMandatorySchool = ["12th"];

    const requiredFields = [
      "college",
      "department",
      "stream",
      "institution_type",
      "startDate",
      "endDate",
      "grade",
    ];
    inputFields.forEach((field, index) => {

      // Basic validation for all fields
      requiredFields.forEach((reqField) => {
        if (!field[reqField]) {
          newErrors[`${reqField}_${index}`] = `${reqField.charAt(0).toUpperCase() + reqField.slice(1)
            } is required!`;
        }
      });

      // Additional validation for "Other" option
      if (field.department === "Other" && !field.department) {
        newErrors[`department_${index}`] = "Please specify the other degree!";
      }

      // Update department with otherDepartment if valid
      if (field.department === "Other" && field.otherDepartment) {
        field.department = field.otherDepartment;
      }

      // Additional validation for specific 12th
      if (seniorSecondaryWithMandatorySchool.includes(field.department)) {
        const hasTenth = inputFields.some((f) => f.department === "10th");
        if (!hasTenth) {
          newErrors[`10th_missing_${index}`] =
            "Completion of 10th is required for 12th.";
        }
      }

      // Additional validation for specific bachelor degrees
      if (bachelorDegreesWithMandatoryHighSchool.includes(field.department)) {
        // Check if 10th and 12th records exist in the input fields
        const hasTenth = inputFields.some((f) => f.department === "10th");
        const hasTwelfth = inputFields.some((f) => f.department === "12th");
        const hasDiploma = inputFields.some((f) => f.department === "Diploma");
        const hasITI = inputFields.some((f) => f.department === "ITI");

        if (!hasTenth) {
          newErrors[`10th_missing_${index}`] =
            "Completion of 10th is required for selected bachelor degree.";
        }
        if (!hasDiploma || !hasITI) {
          if (!hasTwelfth) {
            newErrors[`12th_missing_${index}`] =
              "Completion of 12th is required for selected bachelor degree.";
          }
        }
      }

      // Additional validation for specific bachelor degrees
      if (bachelorDegreesWithMandatorySchool.includes(field.department)) {
        // Check if 10th and 12th records exist in the input fields
        const hasTenth = inputFields.some((f) => f.department === "10th");
        if (!hasTenth) {
          newErrors[`10th_missing_${index}`] =
            "Completion of 10th is required for selected bachelor degree.";
        }
      }

      if (masterDegreesWithMandatorySchool.includes(field.department)) {
        const hasTenth = inputFields.some((f) => f.department === "10th");
        const hasTwelfth = inputFields.some((f) => f.department === "12th");
        const hasDiploma = inputFields.some((f) => f.department === "Diploma");
        const hasITI = inputFields.some((f) => f.department === "ITI");

        if (!hasTenth) {
          newErrors[`10th_missing_${index}`] =
            "Completion of 10th is required for selected master degree.";
        }
        if (!hasDiploma || !hasITI) {
          if (!hasTwelfth) {
            newErrors[`12th_missing_${index}`] =
              "Completion of 12th is required for selected bachelor degree.";
          }
        }
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  console.log(inputFields);

  const navigate = useNavigate();
  const navigatee = useNavigate();

  const [loading, setLoading] = useState(false); //loading logic

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

      // Cookies.set("userData", JSON.stringify(formData));
      try {
        let res = await axios.post(
          `${BASE_URL}/userdata/EmployeeEducationDetailsall/`,
          inputFields
        );
        if (res.status === 200) {
          forwardroutepath === "workexperiencee" ?
            navigate(`/${forwardroutepath}/${email}/`) : navigate(`/${forwardroutepath}/${id}/${email}/`);

        } else {
          alert(res);
        }
      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }

      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);

      }
    }
  };

  const handleFormPut = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

      try {
        // Assuming your backend supports updating existing education details via PUT
        await Promise.all(
          inputFields.map(async (educationDetail) => {
            if (educationDetail.id) {
              // Existing education detail, update it
              await axios.put(
                `${BASE_URL}/userdata/EmployeeEducationDetails/${educationDetail.id}/`,
                educationDetail
              );
            } else {
              // New education detail, create it
              await axios.post(
                `${BASE_URL}/userdata/EmployeeEducationDetails/`,
                educationDetail
              );
            }
          })
        );

        forwardroutepath === "workexperiencee" ?
          navigate(`/${forwardroutepath}/${email}/`) : navigate(`/${forwardroutepath}/${id}/${email}/`);
      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);

      }
    }
  };

  const backroute = () => {
    backroutepath === "personalDetailss" ? navigatee(`/${backroutepath}/${email}/`) : navigatee(`/${backroutepath}/${id}/${email}/`);
  };


  console.log(`/${backroutepath}/${email}/`)


  const [data, setData] = useState(false);
  const [buffer, setBuffering] = useState(true); //buffering logic
  const [streamlist, setStreamList] = useState([]);

  useEffect(() => {
    // const storedUserData = Cookies.get("userData");

    const getUSerDetails = async () => {
      setBuffering(true); //buffering logic // Start Buffering
      try {
        const res = await axios.get(
          `${BASE_URL}/userdata/EmployeeEducationDetailsallget/${email}/`
        );
        if (res.data.length > 0) {
          setInputFields(res.data);
          setData(true);
        }

        const edu = await axios.get(
          `${BASE_URL}/userdata/ActiveEmployeesalleducationdata/`
        );

        // Get unique set of stream types
        const uniqueStreamTypes = new Set();

        edu.data.forEach(entry => {
          entry.education_details.forEach(education => {
            uniqueStreamTypes.add(education.stream);
          });
        });

        // setStreamList(Array.from(uniqueStreamTypes));
        setStreamList(Array.from(uniqueStreamTypes).map(stream => ({ value: stream, label: stream })));


      } catch (err) {
        //toast Logic
        if (err.response) {
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        setBuffering(false);// End Buffering
      }
    };

    getUSerDetails();
  }, [email]);

  // console.log("streamlist");
  // console.log(streamlist);

  const availableDegrees = [
    "10th",
    "12th",
    "B.A. (Bachelor of Arts)",
    "B.Com. (Bachelor of Commerce)",
    "M.Com. (Masters of Commerce)",
    "MBA (Master of Business Administration)",
    "M.Tech. (Master of Technology)",
    "M.E. (Master of Engineering )",
    "B.Tech. (Bachelor of Technology)",
    "B.E. (Bachelor of Engineering )",
    "B.Arch. (Bachelor of Architecture)",
    "Diploma",
    "PhD",
    "ITI",
    "Other",
  ];
  return (
    <>
      {buffer ? <div className="spinner-z"></div> : null}{/* //buffering logic */}
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div className="jd-heading-outer">
        {/* Education Details */}
        <div className="jd-heading-outer-flex">
          <h2 className="jd-heading-main font-weight600  font-size-heading">Education Details*</h2>

          <h2 className="jd-heading-main font-weight600 -right font-size-text ">
            Please click here to add more{" "}
            <button
              type="button"
              onClick={handleAddInputField}
              className="jd-heading-main font-weight600 -right-button"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <g clipPath="url(#clip0_450_2151)">
                  <path
                    d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
                    fill="#2576BC"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_450_2151">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              {/* <AddwithBlueCircle /> */}
            </button>
          </h2>
        </div>
        <div>
          <div className="jd-heading-bottom-bold"></div>
          <div className="jd-heading-bottom-light"></div>
        </div>
      </div>

      <form className="register-form">
        {inputFields.map((field, index) => (
          <>
            <div key={index} className="form-flex-wrap">
              <div className="flex-column">
                <label htmlFor={`college_${index}`} className="form-labels font-weight500    font-size-subheading">
                  College/Institution<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id={`college_${index}`}
                  maxLength={100}
                  name="college"
                  value={field.college}
                  onChange={(e) => {
                    handleChange(index, e);
                    // handleFileChange(e)
                  }}
                  className={`form-text-trasformation-uppercase form-input ${errors[`college_${index}`]
                    ? "error"
                    : field.college
                      ? "success"
                      : ""
                    }`}
                />
                {errors[`college_${index}`] && (
                  <span className="error-message font-size-text ">
                    {errors[`college_${index}`]}
                  </span>
                )}
              </div>

              {/* <div className="flex-column form-group-selectt">
                <label htmlFor="department" className="form-labels font-weight500    font-size-subheading">
                  Degree Type<span className="required">*</span>
                </label>
                <br />
                <select
                  id="department"
                  name="department"
                  value={field.department}
                  onChange={(e) => handleChange(index, e)}
                  className={`form-input form-group-selection ${errors[`department_${index}`]
                    ? "error"
                    : field.department
                      ? "success"
                      : ""
                    }`}
                >
                  <option value="">Select a Degree Type</option>
                  {field.department &&
                    !inputFields.some(
                      (f) => f.department === field.department && f !== field
                    ) && (
                      <option value={field.department}>
                        {field.department}
                      </option>
                    )}
                  {availableDegrees.map((degree) =>
                    inputFields.find((f) => f.department === degree) ||
                      degree === field.department ? null : (
                      <option key={degree} value={degree}>
                        {degree}
                      </option>
                    )
                  )}
                </select>
                <div className="form-group-selection-arrow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="25"
                    fill="currentColor"
                    class="bi bi-caret-down-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                  </svg>
                </div>
                <div id={`department-error-${index}`}>
                  {errors[`department_${index}`] && (
                    <span className="error-message font-size-text ">
                      {errors[`department_${index}`]}
                    </span>
                  )}
                </div>
              </div> */}

              <div className="flex-column form-group-selectt">
                <label htmlFor="department" className="form-labels font-weight500    font-size-subheading">
                  Degree Type<span className="required">*</span>
                </label>
                <br />
                <select
                  id="department"
                  name="department"
                  value={field.department}
                  onChange={(e) => handleChange(index, e)}
                  className={`form-input form-group-selection ${errors[`department_${index}`]
                    ? "error"
                    : field.department
                      ? "success"
                      : ""
                    }`}
                >
                  <option value="">Select a Degree Type</option>
                  {/* If field.department is not in availableDegrees, add it as an extra option */}
                  {!availableDegrees.includes(field.department) && field.department && (
                    <option value={field.department}>
                      {field.department}
                    </option>
                  )}
                  {availableDegrees.map((degree) =>
                    inputFields.some((f) => f.department === degree && f !== field) ? null : (
                      <option key={degree} value={degree}>
                        {degree}
                      </option>
                    )
                  )}
                </select>
                <div className="form-group-selection-arrow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="25"
                    fill="currentColor"
                    className="bi bi-caret-down-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                  </svg>
                </div>
                <div id={`department-error-${index}`}>
                  {errors[`department_${index}`] && (
                    <span className="error-message font-size-text ">
                      {errors[`department_${index}`]}
                    </span>
                  )}
                </div>
              </div>

              {field.department === "Other" && (
                <div className="flex-column">
                  <label htmlFor={`otherDepartment_${index}`} className="form-labels font-weight500    font-size-subheading">
                    Specify Other Degree<span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    id={`otherDepartment_${index}`}
                    maxLength={100}
                    name="otherDepartment" // Correctly name the input
                    value={field.otherDepartment} // Bind to otherDepartment
                    onChange={(e) => handleChange(index, e)}
                    className={`form-input ${errors[`department_${index}`]
                      ? "error"
                      : field.otherDepartment
                        ? "success"
                        : ""
                      }`}
                  />
                  {errors[`department_${index}`] && (
                    <span className="error-message font-size-text ">
                      {errors[`department_${index}`]}
                    </span>
                  )}
                </div>
              )}


              <div className="flex-column">
                <label htmlFor={`stream_${index}`} className="form-labels font-weight500    font-size-subheading">
                  Stream<span className="required">*</span>
                </label>
                {/* <input
                  type="text"
                  id={`stream_${index}`}
                  maxLength={30}
                  name="stream"
                  value={field.stream}
                  onChange={(e) => handleChange(index, e)}
                  className={`form-input ${errors[`stream_${index}`]
                    ? "error"
                    : field.stream
                      ? "success"
                      : ""
                    }`}
                /> */}
                {/* <CreatableSelect
                  id={`stream_${index}`}
                  options={streamlist}
                  value={field.stream ? { value: field.stream, label: field.stream } : null}
                  onChange={(option) => handleChange(index, { target: { name: 'stream', value: option ? option.value : '' } })}
                  onInputChange={(inputValue) => handleChange(index, { target: { name: 'stream', value: inputValue } })}
                  inputValue={field.stream}
                  placeholder="Select or type a new stream"
                  className={`form-input ${errors[`stream_${index}`] ? 'error' : field.stream ? 'success' : ''}`}
                  isClearable
                  formatCreateLabel={(inputValue) => `Create new stream: ${inputValue}`}
                  isValidNewOption={(inputValue, selectValue, selectOptions) =>
                    inputValue.trim() !== '' &&
                    !selectOptions.find(option => typeof option.label === 'string' && option.label.toLowerCase() === inputValue.toLowerCase())
                  }
                /> */}

                <CreatableSelect
                  id={`stream_${index}`}
                  options={streamlist}
                  value={field.stream ? { value: field.stream, label: field.stream } : null}  // Ensure state reflects this
                  onChange={(option) => handleChange(index, { target: { name: 'stream', value: option ? option.value : '' } })}
                  placeholder="Select or type a new stream"
                  className={`${errors[`stream_${index}`] ? 'error' : field.stream ? 'success' : ''}`}
                  // isClearable
                  formatCreateLabel={(inputValue) => `Create new stream: ${inputValue}`}
                  isValidNewOption={(inputValue, selectValue, selectOptions) =>
                    inputValue.trim() !== '' &&
                    !selectOptions.find(option => option.label.toLowerCase() === inputValue.toLowerCase())
                  }
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      width: '33vw',
                      height: '50px',
                      borderRadius: '10px',
                      border: '1px solid #e3e3e3',
                      margin: '10px 0px 20px 0px',
                      color: '#4a4a4a',
                      backgroundColor: '#f7f8fa',
                      padding: '0px 10px',
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      color: '#4a4a4a',
                    }),
                    menu: (provided) => ({
                      ...provided,
                      borderRadius: '10px',
                      marginTop: '0',
                    }),
                    input: (provided) => ({
                      ...provided,
                      color: '#4a4a4a',
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: '#4a4a4a',
                    }),
                  }}
                />

                {/* 
                <CreatableSelect
                  id="client_name"
                  name="client_name"
                  options={clientList.map(client => ({ value: client.client_name, label: client.client_name }))}
                  value={
                    formData.client_name
                      ? { value: formData.client_name, label: formData.client_name }
                      : null
                  }
                  onChange={handleInputChangeClient}
                  onInputChange={(inputValue) =>
                    handleInputChangeClient({
                      value: inputValue,
                      label: inputValue
                    })
                  }
                  inputValue={formData.client_name}
                  placeholder="Select or type a new Client Name"
                  className={`form-input ${errors.client_name ? "error" : inputState.client_name ? "success" : ""}`}
                  isClearable
                  formatCreateLabel={(inputValue) =>
                    `Create new Client Name: ${inputValue}`
                  }
                  isValidNewOption={(inputValue, selectValue, selectOptions) =>
                    inputValue.trim() !== "" &&
                    !selectOptions.some(
                      (option) =>
                        option.value.toLowerCase() === inputValue.toLowerCase()
                    )
                  }
                /> */}
                {errors[`stream_${index}`] && (
                  <span className="error-message font-size-text ">
                    {errors[`stream_${index}`]}
                  </span>
                )}
              </div>

              <div className="flex-column form-group-selectt">
                <label htmlFor="institution_type" className="form-labels font-weight500    font-size-subheading">
                  Institution Type<span className="required">*</span>
                </label>
                <br />
                <select
                  id="institution_type"
                  name="institution_type"
                  value={field.institution_type}
                  onChange={(e) => handleChange(index, e)}
                  className={`form-input form-group-selection ${errors[`institution_type_${index}`]
                    ? "error"
                    : field.institution_type
                      ? "success"
                      : ""
                    }`}
                >
                  <option value="">Select a Institution Type</option>
                  <option value="Government">Government</option>
                  <option value="Private">Private</option>
                </select>
                <div className="form-group-selection-arrow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="25"
                    fill="currentColor"
                    class="bi bi-caret-down-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                  </svg>
                </div>
                <div>
                  {errors[`institution_type_${index}`] && (
                    <span className="error-message font-size-text ">
                      {errors[`institution_type_${index}`]}
                    </span>
                  )}
                </div>
              </div>

              <div className="flex-column">
                <label htmlFor={`startDate_${index}`} className="form-labels font-weight500    font-size-subheading">
                  Start Date<span className="required">*</span>
                </label>
                <input
                  type="date"
                  id={`startDate_${index}`}
                  name="startDate"
                  value={field.startDate}
                  onChange={(e) => handleChange(index, e)}
                  className={`form-input ${errors[`startDate_${index}`]
                    ? "error"
                    : field.startDate
                      ? "success"
                      : ""
                    }`}
                />
                {errors[`startDate_${index}`] && (
                  <span className="error-message font-size-text ">
                    {errors[`startDate_${index}`]}
                  </span>
                )}
              </div>

              <div className="flex-column">
                <label htmlFor={`endDate_${index}`} className="form-labels font-weight500    font-size-subheading">
                  End Date<span className="required">*</span>
                </label>
                <input
                  type="date"
                  id={`endDate_${index}`}
                  name="endDate"
                  value={field.endDate}
                  onChange={(e) => handleChange(index, e)}
                  className={`form-input ${errors[`endDate_${index}`]
                    ? "error"
                    : field.endDate
                      ? "success"
                      : ""
                    }`}
                />
                {errors[`endDate_${index}`] && (
                  <span className="error-message font-size-text ">
                    {errors[`endDate_${index}`]}
                  </span>
                )}
              </div>

              {/* Similar blocks for Department, Start Date, End Date, and Grade */}
              <div className="flex-column">
                <label htmlFor={`grade_${index}`} className="form-labels font-weight500    font-size-subheading">
                  Grade<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id={`grade_${index}`}
                  maxLength={30}
                  name="grade"
                  value={field.grade}
                  onChange={(e) => handleChange(index, e)}
                  className={`form-input ${errors[`grade_${index}`]
                    ? "error"
                    : field.grade
                      ? "success"
                      : ""
                    }`}
                />
                {errors[`grade_${index}`] && (
                  <span className="error-message font-size-text ">
                    {errors[`grade_${index}`]}
                  </span>
                )}
              </div>

              {/* {(field.college != "") ? <div className="flex-column">
                  <label htmlFor="bestAchieve" className="form-labels font-weight500    font-size-subheading">
                    Upload Certificate/Proof<span className="required">*</span>
                  </label>

                  <div className="" key={"index"}>
                    <div className="document-icons">
                      <div className="document-name-div">
                        <img
                          src={Docs}
                          alt="Document Icon"
                          className="document-icon"
                        />
                        <div>
                          <div className="document-name">{field.college}</div>
                          <input
                            id={`file_${index}`}
                            // id="file"
                            type="file"
                            name="document_file"
                            fileaddress="document_name"
                            filename={field.college}
                            accept=".pdf"
                            onChange={handleFileChange}
                            className="file-input"
                          />
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="document-download-button  width-5vw  width-5vw"
                        onClick={(e, index) => handleSubmitdoc(e, index)}
                        disabled={loadingDoc}
                      >
                        Upload
                      </button>
                    </div>
                  </div>
                </div> : ""} */}
            </div>
            <div id={`department-error-${index}`}>
              {errors[`10th_missing_${index}`] && (
                <span className="error-message font-size-text ">
                  {errors[`10th_missing_${index}`]}
                </span>
              )}{" "}
              <br />
              {errors[`12th_missing_${index}`] && (
                <span className="error-message font-size-text ">
                  {errors[`12th_missing_${index}`]}
                </span>
              )}
            </div>
            <div>
              {index > 0 ? (
                <button
                  type="button"
                  onClick={() => handleRemoveInputField(index)}
                  className="remove-button  font-weight500    font-size-heading"
                >
                  Remove
                </button>
              ) : (
                ""
              )}
            </div>
          </>
        ))}

        <div className="button-models">
          <button className="model-button  font-weight500" onClick={backroute}>
            Back
          </button>
          {data ? (
            <button
              onClick={handleFormPut}
              className="model-button  font-weight500    model-button-submit"
              disabled={loading}
            >
              Next
            </button>
          ) : (
            <button
              onClick={handleFormSubmit}
              className="model-button  font-weight500    model-button-submit"
              disabled={loading}
            >
              Next
            </button>
          )}
        </div>
      </form>
    </>
  );
};

const WorkexperienceAdd = ({ email, id, forwardroutepath, backroutepath }) => {
  const [inputFields, setInputFields] = useState([
    {
      company: "",
      startDate: "",
      endDate: "",
      jobposition: "",
      jobtype: "",
      monthlyCtc: "",
      monthlyInhand: "",
      user: email,
    },
  ]);
  const [errors, setErrors] = useState({});

  const handleChange = (index, e) => {
    const { name, value } = e.target;
    const updatedFields = [...inputFields];
    updatedFields[index][name] = value;
    setInputFields(updatedFields);
  };

  const handleAddInputField = () => {
    setInputFields([
      ...inputFields,
      {
        company: "",
        startDate: "",
        endDate: "",
        jobposition: "",
        jobtype: "",
        monthlyCtc: "",
        monthlyInhand: "",
        user: email,
      },
    ]);
  };

  const handleRemoveInputField = (index) => {
    const updatedFields = [...inputFields];
    updatedFields.splice(index, 1);
    setInputFields(updatedFields);
  };

  const validateForm = () => {
    const newErrors = {};
    inputFields.length >= 2 &&
      inputFields.forEach((field, index) => {
        const requiredFields = [
          "company",
          "jobposition",
          "startDate",
          "endDate",
          "jobtype",
          "monthlyCtc",
          "monthlyInhand",
        ];
        requiredFields.forEach((reqField) => {
          if (!field[reqField]) {
            newErrors[`${reqField}_${index}`] = `${reqField.charAt(0).toUpperCase() + reqField.slice(1)
              } is required!`;
          }
        });
      });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  console.log(inputFields);
  console.log(inputFields.length);

  const navigate = useNavigate();
  const navigatee = useNavigate();
  const [loading, setLoading] = useState(false); //loading logic

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

      try {
        let res = await axios.post(
          `${BASE_URL}/userdata/userworkexpbulkdata/`,
          inputFields
        );
        if (res.status === 200) {
          forwardroutepath === "emergencycontacts" ?
            navigate(`/${forwardroutepath}/${email}/`) : navigate(`/${forwardroutepath}/${id}/${email}/`);
        } else {
          alert(res);
        }
      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);

      }
    }
  };

  const handleFormPut = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      try {
        // Assuming your backend supports updating existing education details via PUT
        await Promise.all(
          inputFields.map(async (workexperience) => {
            if (workexperience.id) {
              // Existing education detail, update it
              await axios.put(
                `${BASE_URL}/userdata/userworkexp/${workexperience.id}/`,
                workexperience
              );
            } else if (workexperience.company != "") {
              // New education detail, create it
              await axios.post(
                `${BASE_URL}/userdata/userworkexp/`,
                workexperience
              );
            }
          })
        );

        forwardroutepath === "emergencycontacts" ?
          navigate(`/${forwardroutepath}/${email}/`) : navigate(`/${forwardroutepath}/${id}/${email}/`);
      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);

      }
    }
  };

  // const backroute = ({ backroutepath }) => {
  //   navigatee(`/${backroutepath}/${id}/${email}/`);
  // };


  const backroute = () => {
    backroutepath === "educationDetailss" ? navigatee(`/${backroutepath}/${email}/`) : navigatee(`/${backroutepath}/${id}/${email}/`);
  };


  const [data, setData] = useState(false);
  const [buffer, setBuffering] = useState(true); //buffering logic

  useEffect(() => {
    // const storedUserData = Cookies.get("userData");

    const getUSerDetails = async () => {
      setBuffering(true); //buffering logic // Start Buffering
      try {
        const res = await axios.get(
          `${BASE_URL}/userdata/userworkexpallget/${email}/`
        );
        if (res.data.length !== 0) {
          setInputFields(res.data);
        }
        setData(true);
      } catch (err) {
        //toast Logic
        if (err.response) {
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        setBuffering(false);// End Buffering
      }
    };

    getUSerDetails();
  }, [email]);

  return (
    <>
      {buffer ? <div className="spinner-z"></div> : null}{/* //buffering logic */}
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div className="jd-heading-outer">
        <div className="jd-heading-outer-flex">
          <h2 className="jd-heading-main font-weight600  font-size-heading">Work Experience *</h2>

          <h2 className="jd-heading-main font-weight600 -right font-size-text ">
            Please click here to add more{" "}
            <button
              type="button"
              onClick={handleAddInputField}
              className="jd-heading-main font-weight600 -right-button"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <g clipPath="url(#clip0_450_2151)">
                  <path
                    d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
                    fill="#2576BC"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_450_2151">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </button>
          </h2>
        </div>
        <div>
          <div className="jd-heading-bottom-bold"></div>
          <div className="jd-heading-bottom-light"></div>
        </div>
      </div>

      <form className="register-form" >
        {inputFields.map((field, index) => (
          <div key={index} className="form-flex-wrap">
            <div className="flex-column">
              <label htmlFor={`company_${index}`} className="form-labels font-weight500    font-size-subheading">
                Company {index + 1}{inputFields[index].company != "" || inputFields.length > 1 ? <span className="required">*</span> : ""}
              </label>
              <input
                type="text"
                id={`company_${index}`}
                maxLength={100}
                name="company"
                value={field.company}
                onChange={(e) => handleChange(index, e)}
                className={`form-text-trasformation-uppercase form-input ${errors[`company_${index}`]
                  ? "error"
                  : field.company
                    ? "success"
                    : ""
                  }`}
              />
              {errors[`company_${index}`] && (
                <span className="error-message font-size-text ">
                  {errors[`company_${index}`]}
                </span>
              )}
            </div>

            <div className="flex-column">
              <label htmlFor={`jobposition_${index}`} className="form-labels font-weight500    font-size-subheading">
                Job Position{inputFields[index].company != "" || inputFields.length > 1 ? <span className="required">*</span> : ""}
              </label>
              <input
                type="text"
                id={`jobposition_${index}`}
                maxLength={100}
                name="jobposition"
                value={field.jobposition}
                onChange={(e) => handleChange(index, e)}
                className={`form-text-trasformation-uppercase form-input ${errors[`jobposition_${index}`]
                  ? "error"
                  : field.jobposition
                    ? "success"
                    : ""
                  }`}
              />
              {errors[`jobposition_${index}`] && (
                <span className="error-message font-size-text ">
                  {errors[`jobposition_${index}`]}
                </span>
              )}
            </div>

            <div className="flex-column">
              <label htmlFor={`startDate_${index}`} className="form-labels font-weight500    font-size-subheading">
                Start Date{inputFields[index].company != "" || inputFields.length > 1 ? <span className="required">*</span> : ""}
              </label>
              <input
                type="date"
                id={`startDate_${index}`}
                name="startDate"
                value={field.startDate}
                onChange={(e) => handleChange(index, e)}
                className={`form-input ${errors[`startDate_${index}`]
                  ? "error"
                  : field.startDate
                    ? "success"
                    : ""
                  }`}
              />
              {errors[`startDate_${index}`] && (
                <span className="error-message font-size-text ">
                  {errors[`startDate_${index}`]}
                </span>
              )}
            </div>

            <div className="flex-column">
              <label htmlFor={`endDate_${index}`} className="form-labels font-weight500    font-size-subheading">
                End Date{inputFields[index].company != "" || inputFields.length > 1 ? <span className="required">*</span> : ""}
              </label>
              <input
                type="date"
                id={`endDate_${index}`}
                name="endDate"
                value={field.endDate}
                onChange={(e) => handleChange(index, e)}
                className={`form-input ${errors[`endDate_${index}`]
                  ? "error"
                  : field.endDate
                    ? "success"
                    : ""
                  }`}
              />
              {errors[`endDate_${index}`] && (
                <span className="error-message font-size-text ">
                  {errors[`endDate_${index}`]}
                </span>
              )}
            </div>

            <div className="flex-column form-group-select">
              <label className="form-labels font-weight500    font-size-subheading" htmlFor={`jobtype_${index}`}>
                Job Type{inputFields[index].company != "" || inputFields.length > 1 ? <span className="required">*</span> : ""}
              </label>
              <br />
              <select
                id={`jobtype_${index}`}
                name="jobtype"
                value={field.jobtype}
                onChange={(e) => handleChange(index, e)}
                className={`form-input form-group-selection ${errors[`jobtype_${index}`]
                  ? "error"
                  : field.jobtype
                    ? "success"
                    : ""
                  }`}
              >
                <option value="">Select Job Type</option>
                <option value="full-time">Full Time</option>
                <option value="part-time">Part Time</option>
                <option value="contract">Contract</option>
                <option value="freelance">Freelance</option>
                <option value="internship">Internship</option>
              </select>
              <div className="form-group-selection-arrow">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="25"
                  fill="currentColor"
                  class="bi bi-caret-down-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                </svg>
              </div>
              <div>
                {errors[`jobtype_${index}`] && (
                  <span className="error-message font-size-text ">
                    {errors[`jobtype_${index}`]}
                  </span>
                )}
              </div>
            </div>
            {/* Similar blocks for Department, Start Date, End Date, and Grade */}
            <div className="flex-column">
              <label htmlFor={`monthlyCtc_${index}`} className="form-labels font-weight500    font-size-subheading">
                Monthly CTC{inputFields[index].company != "" || inputFields.length > 1 ? <span className="required">*</span> : ""}
              </label>
              <input
                type="text"
                id={`monthlyCtc_${index}`}
                maxLength={15}
                name="monthlyCtc"
                value={field.monthlyCtc}
                onChange={(e) => handleChange(index, e)}
                className={`form-input ${errors[`monthlyCtc_${index}`]
                  ? "error"
                  : field.monthlyCtc
                    ? "success"
                    : ""
                  }`}
              />
              {errors[`monthlyCtc_${index}`] && (
                <span className="error-message font-size-text ">
                  {errors[`monthlyCtc_${index}`]}
                </span>
              )}
            </div>
            <div className="flex-column">
              <label htmlFor={`monthlyInhand_${index}`} className="form-labels font-weight500    font-size-subheading">
                Monthly In Hand{inputFields[index].company != "" || inputFields.length > 1 ? <span className="required">*</span> : ""}
              </label>
              <input
                type="text"
                maxLength={15}
                id={`monthlyInhand_${index}`}
                name="monthlyInhand"
                value={field.monthlyInhand}
                onChange={(e) => handleChange(index, e)}
                className={`form-input ${errors[`monthlyInhand_${index}`]
                  ? "error"
                  : field.monthlyInhand
                    ? "success"
                    : ""
                  }`}
              />
              {errors[`monthlyInhand_${index}`] && (
                <span className="error-message font-size-text ">
                  {errors[`monthlyInhand_${index}`]}
                </span>
              )}
            </div>

            <div>
              {index > 0 ? (
                <button
                  type="button"
                  onClick={() => handleRemoveInputField(index)}
                  className="remove-button  font-weight500    font-size-heading"
                >
                  Remove
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        ))}

        <div className="button-models">
          <button className="model-button  font-weight500   " onClick={backroute}>Back</button>
          {data ? (<button onClick={handleFormPut} className="model-button  font-weight500    model-button-submit" disabled={loading}>
            {inputFields[0].company !== "" || inputFields.length > 1 ? "Next" : "Skip"}
          </button>) : (<button onClick={handleFormSubmit} className="model-button  font-weight500    model-button-submit" disabled={loading}>
            {inputFields[0].company !== "" || inputFields.length > 1 ? "Next" : "Skip"}
          </button>)}
        </div>
      </form>

    </>);
};


const DocumentAdd = ({ getUserDocs }) => {


  let { email } = useParams()


  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const [formData, setFormData] = useState({
    user: email,
    document_name: "",
    document_type: "",
    document_file: "",

  });

  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["document_name", "document_type", "document_file"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  // ?  ************** Validation End **************  ! //
  const [loading, setLoading] = useState(false); //loading logic

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

      const formDataa = new FormData();
      formDataa.append("user", formData.user);

      formDataa.append("document_name", formData.document_name);
      formDataa.append("document_type", formData.document_type);


      const symbols = '!@#$%^&*()_-+=';
      const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz';
      const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      const numbers = '0123456789';
      const now = new Date();
      const date = now.toLocaleDateString('en-GB').split('/').reverse().join('-'); // Format: YYYY-MM-DD
      const time = now.toLocaleTimeString('en-GB', { hour12: false }).replace(/:/g, '-'); // Format: HH-MM-SS
      const allChars = symbols + lowercaseLetters + uppercaseLetters + numbers;
      let randomCode = '';
      for (let i = 0; i < 8; i++) {
        const randomIndex = Math.floor(Math.random() * allChars.length);
        randomCode += allChars[randomIndex];
      }
      const originalFile = formData.document_file;
      const customFileName = `Aimantra ${date} ${time}_${randomCode} ${originalFile.name}`;
      const customFile = new File([originalFile], customFileName, { type: originalFile.type });
      formDataa.append("document_file", customFile);


      try {
        let res = await axios.post(`${BASE_URL}/userdata/userdocs/`, formDataa, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (res.status === 200) {
          handleClose();
          await getUserDocs();
        } else {
          alert(res);
        }
      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);

      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const { name } = e.target;
    const file = e.target.files[0];

    if (file) {
      if (file.type === "application/pdf") {
        setFormData({
          ...formData,
          [name]: file,
        });
      } else {
        setErrors({
          ...errors,
          [name]: "Invalid file type. Please select a PDF file.",
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: null,
      });
    }
  };

  const handleFileClear = (name) => {
    setFormData({
      ...formData,
      [name]: null,
    });
  };

  return (
    <>
      <div className="models-button model-add" onClick={handleShow} >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="#2576BC"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        {/* <Modal.Header closeButton>
          <Modal.Title>Request Leave</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              <h6 className="task-Tab-heading  font-weight500    font-size-heading">Document</h6>

              <form>
                <div className="flex-column">
                  <label htmlFor="document_name" className="form-labels font-weight500    announce-date font-weight400  font-size-heading">
                    Document Name
                  </label>
                  <input
                    id="document_name"
                    type="document_name"
                    name="document_name"
                    placeholder="Document Name"
                    onChange={handleInputChange}
                    value={formData.document_name}
                    className={`form-input ${errors.document_name ? "error" : inputState.document_name ? "success" : ""
                      }`}
                  />
                  {errors.document_name && (
                    <span className="error-message font-size-text ">{errors.document_name}</span>
                  )}
                </div>

                <div className="flex-column form-group-selectt">
                  <label htmlFor="document_type" className="form-labels font-weight500    font-size-subheading">
                    Document Type<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    id="document_type"
                    name="document_type"
                    value={formData.document_type}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.document_type ? "error" : inputState.document_type ? "success" : ""
                      }`}
                  >
                    <option value="">Select a Document Type</option>
                    <option value="identity">Identity</option>
                    <option value="previousExperience">Previous Experience</option>
                    <option value="degree">Degree</option>


                  </select>
                  <div className="form-group-selection-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      fill="currentColor"
                      class="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>
                  <div>
                    {errors.document_type && (
                      <span className="error-message font-size-text ">{errors.document_type}</span>
                    )}
                  </div>
                </div>



                <div className="flex-column">
                  <label htmlFor="attachment" className="form-labels font-weight500    announce-date font-weight400  font-size-heading">
                    Attachment
                  </label>
                  <input
                    id="file"
                    type="file"
                    name="document_file"
                    accept=".pdf"
                    onChange={handleFileChange}
                    className="file-input"
                  />

                  {formData.document_file ? (
                    <span
                      className="file-clear"
                      onClick={() => handleFileClear("file")}
                    >
                      Clear
                    </span>
                  ) : null}
                </div>
              </form>
              <div className="button-models">
                <button className="model-button  font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button  font-weight500    model-button-leave font-weight500    font-size-heading"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const Documentdetails = ({ email, id, forwardroutepath, backroutepath, backroutepath2 }) => {



  const [docData, setDocData] = useState([])
  const [buffer, setBuffering] = useState(true); //buffering logic

  const getUserDocs = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const ress = await axios.get(`${BASE_URL}/userdata/userdocsfilter/${email}/`);
      setDocData(ress.data);
    } catch (err) {
      // alert(err.message);

      // Toast Logic
      // if (err.response) {
      //     const errorData = err.response.data;

      //     if (typeof errorData.error === 'string') {
      //       // Single error message
      //       toast.error(`Error: ${errorData.error}`);
      //     } else if (typeof errorData === 'object' && errorData !== null) {
      //       // Multiple error messages
      //       Object.entries(errorData).forEach(([field, messages]) => {
      //         messages.forEach(message => toast.error(`"${field}": ${message}`));
      //       });
      //     } else {
      //       toast.error('Error:- Failed to Process!');
      //     }
      //   } else {
      //     toast.error('Error processing your request.');
      //   }
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  useEffect(() => {
    getUserDocs();
  }, []);


  const navigatee = useNavigate()


  const backroute = () => {
    backroutepath === "accountdetailss" ? navigatee(`/${backroutepath}/${email}/`) : navigatee(`/${backroutepath}/${id}/${email}/`);


  }
  const finishSubmit = () => {
    navigatee(`/${backroutepath2}/`)

  }


  const [formData, setFormData] = useState({
    user: email,
    document_name: "",
    document_type: "",
    document_file: "",

  });

  const [loadingAadhar, setLoadingAadhar] = useState(false); //loading logic

  const handleSubmitaadhaar = async (e) => {
    e.preventDefault();


    const formDataa = new FormData();
    formDataa.append("user", email);

    formDataa.append("document_name", "Aadhaar");
    formDataa.append("document_type", "identity");

    const symbols = '!@#$%^&*()_-+=';
    const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz';
    const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numbers = '0123456789';
    const now = new Date();
    const date = now.toLocaleDateString('en-GB').split('/').reverse().join('-'); // Format: YYYY-MM-DD
    const time = now.toLocaleTimeString('en-GB', { hour12: false }).replace(/:/g, '-'); // Format: HH-MM-SS
    const allChars = symbols + lowercaseLetters + uppercaseLetters + numbers;
    let randomCode = '';
    for (let i = 0; i < 8; i++) {
      const randomIndex = Math.floor(Math.random() * allChars.length);
      randomCode += allChars[randomIndex];
    }
    const originalFile = formData.document_file;
    const customFileName = `Aimantra ${date} ${time}_${randomCode} ${originalFile.name}`;
    const customFile = new File([originalFile], customFileName, { type: originalFile.type });
    formDataa.append("document_file", customFile);

    if (formData.document_file != '') {
      setLoadingAadhar(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic



      try {
        let res = await axios.post(`${BASE_URL}/userdata/userdocs/`, formDataa, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (res.status === 200) {
          window.location.reload();
          //   handleClose();
        } else {
          alert(res);
        }
      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }

      } finally {
        setLoadingAadhar(false); // Ensure loading state is stopped even in case of error //loading logic
        toast.dismiss(loadingToastId);

      }
    }
    else {
      alert("Please upload a file")
    }
  };



  const [loadingCV, setLoadingCV] = useState(false); //loading logic

  const handleSubmitcv = async (e) => {
    e.preventDefault();


    console.log(formData)
    const formDataa = new FormData();
    formDataa.append("user", email);

    formDataa.append("document_name", "CV");
    formDataa.append("document_type", "identity");

    const symbols = '!@#$%^&*()_-+=';
    const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz';
    const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numbers = '0123456789';
    const now = new Date();
    const date = now.toLocaleDateString('en-GB').split('/').reverse().join('-'); // Format: YYYY-MM-DD
    const time = now.toLocaleTimeString('en-GB', { hour12: false }).replace(/:/g, '-'); // Format: HH-MM-SS
    const allChars = symbols + lowercaseLetters + uppercaseLetters + numbers;
    let randomCode = '';
    for (let i = 0; i < 8; i++) {
      const randomIndex = Math.floor(Math.random() * allChars.length);
      randomCode += allChars[randomIndex];
    }
    const originalFile = formData.document_file;
    const customFileName = `Aimantra ${date} ${time}_${randomCode} ${originalFile.name}`;
    const customFile = new File([originalFile], customFileName, { type: originalFile.type });
    formDataa.append("document_file", customFile);

    if (formData.document_file != '') {
      setLoadingCV(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

      try {
        let res = await axios.post(`${BASE_URL}/userdata/userdocs/`, formDataa, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (res.status === 200) {
          window.location.reload();
          //   handleClose();
        } else {
          alert(res);
        }
      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }


      } finally {
        setLoadingCV(false); // Ensure loading state is stopped even in case of error //loading logic
        toast.dismiss(loadingToastId);
      }
    }
    else {
      alert("Please upload a file")
    }

  };




  const handleFileChange = (e) => {
    const { name } = e.target;
    const file = e.target.files[0];

    setFormData({
      ...formData,
      [name]: file,
    });
  };





  /// Profile Pic Code


  const [formDataa, setFormDataa] = useState({
    profilepic: null,
  });

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    setFormDataa({
      profilepic: file,
    });

  };

  // useEffect(() => {
  // }, [formDataa])

  const uploadFile = async (e) => {
    e.preventDefault();
    const formDataaa = new FormData();

    const symbols = '!@#$%^&*()_-+=';
    const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz';
    const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numbers = '0123456789';
    const now = new Date();
    const date = now.toLocaleDateString('en-GB').split('/').reverse().join('-'); // Format: YYYY-MM-DD
    const time = now.toLocaleTimeString('en-GB', { hour12: false }).replace(/:/g, '-'); // Format: HH-MM-SS
    const allChars = symbols + lowercaseLetters + uppercaseLetters + numbers;
    let randomCode = '';
    for (let i = 0; i < 8; i++) {
      const randomIndex = Math.floor(Math.random() * allChars.length);
      randomCode += allChars[randomIndex];
    }
    const originalFile = formDataa.profilepic;
    const customFileName = `Aimantra ${date} ${time}_${randomCode} ${originalFile.name}`;
    const customFile = new File([originalFile], customFileName, { type: originalFile.type });
    formDataaa.append('profilepic', customFile);

    //  formDataaa.append('profilepic', formDataa.profilepic);

    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic


    try {
      let res = await axios.put(`${BASE_URL}/userdata/userdata/${email}/`, formDataaa, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (res.status === 200) {
        // await getUSerDetails;
        window.location.reload();

      } else {
        alert("Please Upload a Proper Picture Or Check File Type");
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      toast.dismiss(loadingToastId);

    }


  };
  const deleteProfilePicture = async () => {
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.put(`${BASE_URL}/userdata/userdata/${email}/`, { profilepic: null });

      if (res.status === 200) {
        console.log("Profile picture deleted successfully");
      } else {
        console.error("Failed to delete profile picture");
      }
    } catch (err) {
      console.error("An error occurred while deleting the profile picture:", err);
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  const handleLogoClear = () => {
    setFormDataa({
      profilepic: null,
    });
  };


  const [ProfilePic, SetProfilePic] = useState("")



  const [jdid, setJdid] = useState()




  useEffect(() => {
    // const storedUserData = Cookies.get("userData");

    const getUSerDetails = async () => {
      setBuffering(true); //buffering logic // Start Buffering
      try {
        const res = await axios.get(`${BASE_URL}/userdata/userdataget/${email}/`);
        SetProfilePic(res.data.profilepic);

      } catch (err) {
        // setData(false)
        // alert(err.message);

        // toast Logic
        // if (err.response) {
        //     const errorData = err.response.data;

        //     if (typeof errorData.error === 'string') {
        //       // Single error message
        //       toast.error(`Error: ${errorData.error}`);
        //     } else if (typeof errorData === 'object' && errorData !== null) {
        //       // Multiple error messages
        //       Object.entries(errorData).forEach(([field, messages]) => {
        //         messages.forEach(message => toast.error(`"${field}": ${message}`));
        //       });
        //     } else {
        //       toast.error('Error:- Failed to Process!');
        //     }
        //   } else {
        //     toast.error('Error processing your request.');
        //   }
      } finally {
        setBuffering(false);// End Buffering
      }
    };




    getUSerDetails();


    const getUserJobDetails = async () => {
      setBuffering(true); //buffering logic // Start Buffering
      try {

        const ress = await axios.get(`${BASE_URL}/jd/jobapplicant/${id}/${email}/`)
        setJdid(ress.data.id)


      } catch (err) {
        //toast Logic
        // if (err.response) {
        //     const errorData = err.response.data;

        //     if (typeof errorData.error === 'string') {
        //       // Single error message
        //       toast.error(`Error: ${errorData.error}`);
        //     } else if (typeof errorData === 'object' && errorData !== null) {
        //       // Multiple error messages
        //       Object.entries(errorData).forEach(([field, messages]) => {
        //         messages.forEach(message => toast.error(`"${field}": ${message}`));
        //       });
        //     } else {
        //       toast.error('Error:- Failed to Process!');
        //     }
        //   } else {
        //     toast.error('Error processing your request.');
        //   }
        // alert(err.message);
      } finally {
        setBuffering(false);// End Buffering
      }
    };



    getUserJobDetails();

  }, [email, id]);

  const navigate = useNavigate()

  const handleFormComplete = async (e) => {
    e.preventDefault();
    // navigate("/educationDetails")
    try {
      navigate(`/processComplete/`)
    } catch (err) {
      alert(err);
    }
  }

  const handleFormPut = async (e) => {
    e.preventDefault();
    // navigate("/educationDetails")
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.put(`${BASE_URL}/jd/jobapplicant/${jdid}/`, {
        details_filled: true
      })

      if (res.status === 200) {

        navigate(`/jobApplied/`)

      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      toast.dismiss(loadingToastId);
    }
  }


  const DocDeleteButton = ({ id }) => {


    const handleDocSubmitDelete = async (e) => {
      // e.preventDefault();
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

      try {
        let res = await axios.delete(`${BASE_URL}/userdata/userdocs/${id}/`);

        if (res.status === 200) {
          // getDocuments();

          // await getUserDocs();
          window.location.reload();

          // setShow(false);
        } else {
          alert(res);
        }
      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        toast.dismiss(loadingToastId);

      }
    };

    return (
      <>
        {buffer ? <div className="spinner-z"></div> : null}{/* //buffering logic */}
        <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        <button style={{ marginLeft: '10px' }} title="Delete Doc" onClick={(e) => handleDocSubmitDelete()} className="model-delete-button">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <path
              d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
              fill="#F72B50"
            />
          </svg>
        </button>
      </>
    )
  }

  return (
    <>

      {/*  ************** timeline Seaction  ************** */}

      <div className="jd-heading-outer">
        <h2 className="jd-heading-main font-weight600  font-size-heading">Documents Upload*</h2>
        <div>
          <div className="jd-heading-bottom-bold"></div>
          <div className="jd-heading-bottom-light"></div>
        </div>
      </div>



      <form className="register-form">
        <div className="form-flex-wrap" style={{ height: ProfilePic ? "210px" : "" }}
        >

          {docData.find(document => document.document_name === 'Aadhaar') ?
            "" : (
              <div className="flex-column">
                <label htmlFor="bestAchieve" className="form-labels font-weight500    font-size-subheading">
                  Upload Aadhar<span className="required">*</span>
                </label>


                <div className="" key={"index"}>
                  <div className="document-icons">
                    <div className="document-name-div">
                      <img
                        src={Docs}
                        alt="Document Icon"
                        className="document-icon"
                      />
                      <div>
                        <div className="document-name">Aadhaar</div>
                        <input
                          id="file"
                          type="file"
                          name="document_file"
                          accept=".pdf"
                          onChange={handleFileChange}
                          className="file-input"
                        />
                      </div>
                    </div>

                    <button
                      className="document-download-button  width-5vw  width-5vw"
                      onClick={handleSubmitaadhaar}
                      disabled={loadingAadhar}
                    >
                      Upload
                    </button>
                  </div>
                </div>


              </div>)
          }


          {docData.find(document => document.document_name === 'CV') ? "" :

            (<div className="flex-column">
              <label htmlFor="bestAchieve" className="form-labels font-weight500    font-size-subheading">
                Upload CV<span className="required">*</span>
              </label>

              <div className="" key={"index"}>
                <div className="document-icons">
                  <div className="document-name-div">
                    <img
                      src={Docs}
                      alt="Document Icon"
                      className="document-icon"
                    />
                    <div>
                      <div className="document-name">CV</div>
                      <input
                        id="file"
                        type="file"
                        name="document_file"
                        accept=".pdf"
                        onChange={handleFileChange}
                        className="file-input"
                      />
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="document-download-button  width-5vw  width-5vw"
                    onClick={handleSubmitcv}
                    disabled={loadingCV}
                  >
                    Upload
                  </button>
                </div>
              </div>
            </div>)
          }


          {ProfilePic ?
            <div className="flex-column">
              <label htmlFor="bestAchieve" className="form-labels font-weight500    font-size-subheading">
                Profile Pic
              </label>
              <div className="document-name-div">
                <img
                  src={ProfilePic}
                  alt="Logo Preview"
                  className="document-icons user_img_display"

                />

              </div>
              <button onClick={(e) => deleteProfilePicture()} className="document-download-button  width-5vw  width-5vw">Delete</button>
            </div> :

            <div className="flex-column">
              <label htmlFor="bestAchieve" className="form-labels font-weight500    font-size-subheading">
                Upload Profile<span className="required">*</span>
              </label>

              <div className="" key={"index"}>
                <div className="document-icons">
                  <div className="document-name-div">
                    <img
                      src={Profile}
                      alt="Document Icon"
                      className="document-icon"
                    />
                    <div>
                      <div className="document-name">Profile</div>
                      <input
                        // id="-"
                        type="file"
                        name="profilepic"
                        accept="image/*"
                        onChange={handleLogoChange}
                        className="file-input"
                      />
                    </div>
                    {formDataa.profilepic ?
                      < button className="file-clear" onClick={handleLogoClear}>
                        Clear
                      </button>
                      : <></>
                    }
                  </div>
                  <button
                    className="document-download-button  width-5vw  width-5vw"
                    onClick={uploadFile}
                  >
                    Submit
                  </button>
                </div>
              </div>

            </div>
          }


        </div>
        {ProfilePic ?
          <>
            <span className="user_img_display"> </span>


            <br />
            <br />
            <br />
            <br />
            {/* <br /> */}
          </>
          : <></>}
        <div className="jd-heading-outer">
          <div className="jd-bloc-tabs">
            <h2 className="jd-heading-main font-weight600  font-size-heading">Upload Additonal Documents </h2>
            <div className="right-expense-bloc-tab">
            </div>
            <div><DocumentAdd getUserDocs={getUserDocs} /></div>

          </div>
          <div>
            <div className="jd-heading-bottom-bold"></div>
            <div className="jd-heading-bottom-light"></div>

            {docData.length === 0 ? (
              <div className="no-documents-message">
                No documents available.
              </div>
            ) : (
              <div className="document-grid1">
                {docData.map((doc, index) => (
                  (doc.document_name == null) ? "" :
                    <div className="document-item1" key={index}>
                      <div className="document-icons">
                        <div className="document-name-div">
                          <img
                            src={Docs}
                            alt="Document Icon"
                            className="document-icon"
                          />
                          <div>
                            <div className="document-name">{doc.document_name}</div>
                            <div className="document-upload-date font-size-subheading  ">
                              Doc Type <b>{doc.document_type}</b>
                            </div>
                          </div>
                        </div>
                        <div style={{ display: 'flex', }}>
                          <a style={{ marginLeft: '10px' }} href={doc.document_file} target="_blank" className="document-download-button  width-5vw  width-5vw">
                            View
                          </a>


                          <DocDeleteButton id={doc.id} />


                        </div>
                      </div>
                    </div>
                ))}
              </div>
            )}
          </div>

        </div>
        <div className="button-models">
          <button className="model-button  font-weight500   " onClick={backroute}>Back</button>

          {(docData.find(document => document.document_name === 'Aadhaar') && docData.find(document => document.document_name === 'CV') && ProfilePic)
            ? <button className="model-button  font-weight500    model-button-submit" onClick={backroutepath === "accountdetailss" ? handleFormComplete : handleFormPut}>
              {backroutepath === "accountdetailss" ? "Completed" : "Apply"}
            </button>
            : <></>
          }
        </div>
      </form >

    </>
  );
};

export { PersonaldetailsAdd, EducationdetailsAdd, WorkexperienceAdd, Documentdetails };
