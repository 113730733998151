import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import { AUTH, BASE_URL, FRONTEND_URL, IMAGE_URL } from "../../config/axios";
import {
  AdminOutdutyRequestapproved,
  AdminOutdutyRequestDelete,
  AdminRequestDelete,
  AdminRequestapproved,
  HRleaveUpdate,
  HROutdutyUpdate,
  AdminapprovedHR,
  AdminDeleteHR,
  ExpenseapprovedHR,
  ExpenseDeleteHR,
  ImprestApprovedAccount,
  ImprestDeleteAccount,
  ExpenseApprovedAccountStage1,
  ExpenseDeleteAccountStage1,
  ExpenseApprovedAccountStage2,
  ExpenseDeleteAccountStage2,
  RequisitionApprovedAdmin,
  RequisitionRejectAdmin,
  RequisitionApprovedHR,
  RequisitionRejectHR,
  ClockInApprovedHR,
  ClockInRejectHR,
} from ".././workforcemanagement/Requestapproved";
import { formatDate, formatDateTime, formattedDate, formattedDateLong } from "../Date";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import usePermission from "../../config/permissions";
import { customSortByKey, formatCurrencyIndian, formatRoundoff2D, handleAllError, handleErrorToast } from "../CustomFunctions";
import { AccountBExpenseFinalize } from "../ImprestExpenseManagement/ExpenseSheet/ExpenseSlipComponents";
import { Active, CircularGrid, Employee, Eye, Filter, Office, SearchBig } from "../AllSvg";

const today = new Date();
today.setDate(today.getDate() - 60);

const year = today.getFullYear();
const month = today.getMonth() + 1;
const day = today.getDate();

const formattedDatee = `${year}-${month.toString().padStart(2, "0")}-${day
  .toString()
  .padStart(2, "0")}`;

const DeleteLeaveRequest = ({ i, getLeavename }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [loading, setLoading] = useState(false); //loading logic
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); //loading logic
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
    try {
      let res = await axios.delete(`${BASE_URL}/wfm/leavedelete/${i.id}/`);

      if (res.status === 200) {
        await getLeavename();
        handleClose();
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === "string") {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === "object" && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach((message) =>
              toast.error(`"${field}": ${message}`)
            );
          });
        } else {
          toast.error("Error:- Failed to Process!");
        }
      } else {
        toast.error("Error processing your request.");
      }
    } finally {
      setLoading(false); //loading logic
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <button
        title="Delete Leave Request"
        className="model-delete-button"
        onClick={handleShow}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
            fill="#F72B50"
          />
        </svg>
      </button>

      <Modal show={show} dialogClassName="request-leave width-40vw">
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              <h3 className="task-Tab-heading   font-weight500    font-size-heading">
                Confirm Deletion of Leave Request :
              </h3>
              <p style={{ textAlign: "center" }}>
                Employee :{" "}
                <img
                  className="profile-img"
                  src={
                    (i.profilepic = ""
                      ? `${IMAGE_URL}${i.profilepic}`
                      : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")
                  }
                  alt="profilepic"
                />
                {i.name}
              </p>
              <p style={{ textAlign: "center" }}>
                Start Date : {formatDate(i.date)}
              </p>
              <p style={{ textAlign: "center" }}>
                End Date : {formatDate(i.end_date)}
              </p>
              <p
                style={{ textAlign: "center" }}
                className="form-text-trasformation-uppercase"
              >
                Duration : {i.leave_Duration}
              </p>
              <p
                style={{ textAlign: "center" }}
                className="form-text-trasformation-uppercase"
              >
                Type : {i.leave_type ? i.leave_type : "Previous leaves"}
              </p>
              <p style={{ textAlign: "center" }}>
                Leave Asked : {i.leaveasked}
              </p>
              <p
                style={{ textAlign: "center" }}
                title={`${i.leave_type ? i.leave_type : "previous leaves"}`}
              >
                Leave Balance :{" "}
                {i.leave_type == "sick leave" ? i.sick_leave : ""}
                {i.leave_type == "casual leave" ? i.casual_leave : ""}
                {i.leave_type == "earn leave" ? i.earn_leave : ""}
                {i.leave_type == "previous leave" ? i.leavebalance : ""}
                {i.leave_type == null ? i.leavebalance : ""}
              </p>
              <p style={{ textAlign: "center" }}>
                Reporting Head : {i.rh_assigned}
              </p>
              <p style={{ textAlign: "center" }}>Remark : {i.remarks}</p>

              <div className="button-models">
                <button
                  className="model-button   font-weight500   "
                  onClick={handleClose}
                  disabled={loading}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const DeleteODRequest = ({ i, getoutdutyname }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [loading, setLoading] = useState(false); //loading logic
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); //loading logic
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
    try {
      let res = await axios.delete(`${BASE_URL}/wfm/outdutydelete/${i.id}/`);

      if (res.status === 200) {
        await getoutdutyname();
        handleClose();
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === "string") {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === "object" && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach((message) =>
              toast.error(`"${field}": ${message}`)
            );
          });
        } else {
          toast.error("Error:- Failed to Process!");
        }
      } else {
        toast.error("Error processing your request.");
      }
    } finally {
      setLoading(false); //loading logic
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <button
        title="Delete OD Request"
        className="model-delete-button"
        onClick={handleShow}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
            fill="#F72B50"
          />
        </svg>
      </button>

      <Modal show={show} dialogClassName="request-leave width-40vw">
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              <h3 className="task-Tab-heading   font-weight500    font-size-heading">
                Confirm Deletion of OutDuty Request :
              </h3>
              <p style={{ textAlign: "center" }}>
                Employee :{" "}
                <img
                  className="profile-img"
                  src={
                    (i.profilepic = ""
                      ? `${IMAGE_URL}${i.profilepic}`
                      : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")
                  }
                  alt="profilepic"
                />
                {i.name}
              </p>
              <p style={{ textAlign: "center" }}>
                Start Date : {formatDate(i.date)}
              </p>
              <p style={{ textAlign: "center" }}>
                End Date : {formatDate(i.end_date)}
              </p>
              <p
                style={{ textAlign: "center" }}
                className="form-text-trasformation-uppercase"
              >
                Duration : {i.outduty_Duration}
              </p>
              <p style={{ textAlign: "center" }}>
                Reporting Head : {i.rh_assigned}
              </p>
              <p style={{ textAlign: "center" }}>Remark : {i.remarks}</p>
              <div className="button-models">
                <button
                  className="model-button   font-weight500   "
                  onClick={handleClose}
                  disabled={loading}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const Requestoutdutytable = () => {
  const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();
  const [checkedItems, setCheckedItems] = useState([]);
  const [buffer, setBuffering] = useState(true); //buffering logic

  // *********** Employee Details Api Start ***********
  const [allemployee, setAllemployee] = useState([]);
  const [outdutyname, setOutdutyName] = useState([]);

  const [selectedStatus, setSelectedStatus] = useState("pending");
  const [selectedRhStatus, setSelectedRHStatus] = useState("approved");

  const [employeeGet, setEmployeeGet] = useState("null");
  const [fromdate, setFromDate] = useState(`${formattedDatee}`);
  const [todate, setToDate] = useState(`${formattedDate}`);

  const [selectedDivision, setDivision] = useState(
    `${sessionStorage.getItem("company_id")}`
  );

  const [active, setActive] = useState("active");

  const [SubCompany, setSubCompany] = useState([]);

  const getSubCompany = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(`${BASE_URL}/wfm/subcompany/`);
      setSubCompany(res.data);
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  const getoutdutyname = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/outdutyadminget/${selectedRhStatus}/${fromdate}/${todate}/${selectedStatus}/${employeeGet}/${selectedDivision}/${active}/`
      );
      setOutdutyName(res.data);
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;
        if (typeof errorData.error === "string") {
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === "object" && errorData !== null) {
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach((message) =>
              toast.error(`"${field}": ${message}`)
            );
          });
        } else {
          toast.error("Error:- Failed to Process!");
        }
      } else {
        toast.error("Error processing your request.");
      }
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };

  useEffect(() => {
    getoutdutyname();
    getSubCompany();
    getEmpcode();
  }, [selectedRhStatus, fromdate, todate, selectedStatus, employeeGet, active, selectedDivision]);

  // *********** Employee Details Api End ***********



  const getEmpcode = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/ourcompanyempdetails/${active}/${selectedDivision}/`
      );
      const sortedList = customSortByKey(res.data, "emp_code");
      setAllemployee(sortedList);
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  const handleCheckboxChange = (id) => {
    const currentIndex = checkedItems.indexOf(id);
    const newCheckedItems = [...checkedItems];

    if (currentIndex === -1) {
      newCheckedItems.push(id);
    } else {
      newCheckedItems.splice(currentIndex, 1);
    }
    setCheckedItems(newCheckedItems);
  };

  const updatedate = checkedItems.map((status) => {
    return {
      id: status,
      rh_leave_status: "approved",
      admin_leave_status: "approved",
      hr_update_datetime: new Date().toISOString(),
    };
  });

  const updatedatee = checkedItems.map((status) => {
    return {
      id: status,
      admin_leave_status: "rejected",
      hr_update_datetime: new Date().toISOString(),
    };
  });

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmitApprovePut = async (e) => {
    e.preventDefault();
    setLoading(true); //loading logic
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.put(
        `${BASE_URL}/wfm/bulkoutdutyupdateandrectify/`,
        updatedate
      );

      if (res.status === 200) {
        setCheckedItems([])
        await getoutdutyname();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setLoading(false); //loading logic
      toast.dismiss(loadingToastId);
    }
  };

  const handleFormSubmitRejectedPut = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
    try {
      let res = await axios.put(
        `${BASE_URL}/wfm/bulkoutdutyupdateandrectify/`,
        updatedatee
      );

      if (res.status === 200) {
        setCheckedItems([])
        await getoutdutyname();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;
        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div className="attendance-history-cont">
        <div className="attendance-heading  font-weight500    font-size-heading"> </div>
        <div className="attendance-subcont">
          <div className="field-cont">

            <div className="field-cont-div">
              {PERMISSION_AUTHORITY
                ?
                <div className="field-cont-div">
                  <Office />
                  <select
                    className="attendance-input-field width-10vw   date-field"
                    type="text"
                    value={selectedDivision}
                    onChange={(e) => {
                      setDivision(e.target.value)
                      setCheckedItems([])
                    }}
                  >
                    <option value=""> All Sub Companies</option>
                    {SubCompany.sort((a, b) => a.title - b.title).map(
                      (i, index) => (
                        <option value={i.id}>{i.title}</option>
                      )
                    )}
                    ;
                  </select>

                  <hr className="field-cont-hr" />
                </div> : null}



              <div className="date-uppercase ">
                <input
                  className="attendance-input-field width-10vw   date-field date-field-filter"
                  placeholder="From Date"
                  type="date"
                  value={fromdate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </div>

              <hr className="field-cont-hr" />
            </div>



            <div className="field-cont-div">
              <div className="date-uppercase ">
                <input
                  className="attendance-input-field width-10vw   date-field date-field-filter"
                  placeholder="To Date"
                  type="date"
                  value={todate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </div>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Filter />
              <select
                title="Reporting Head Action Status"
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedRhStatus}
                onChange={(e) => {
                  setSelectedRHStatus(e.target.value)
                  setCheckedItems([])
                }}
              >
                <option value="null">All</option>

                <option value="pending">Pending</option>
                <option value="approved">Approved</option>
                <option value="rejected">Rejected</option>
              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Filter />
              <select
                title="Admin Action Status"
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedStatus}
                onChange={(e) => {
                  setSelectedStatus(e.target.value)
                  setCheckedItems([])
                }}
              >
                <option value="null">All</option>

                <option value="pending">Pending</option>
                <option value="approved">Approved</option>
                <option value="rejected">Rejected</option>
              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Employee />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={employeeGet}
                onChange={(e) => {
                  setEmployeeGet(e.target.value)
                  setCheckedItems([])
                }}
              >
                <option value="null">Select Employee Code</option>
                {allemployee
                  .sort((a, b) => a.emp_code - b.emp_code)
                  .map((i, index) => (
                    <option value={i.emp_code}>
                      {i.emp_code} - {i.name}
                    </option>
                  ))}
                ;
                {/* {allemployee.map((e, index) => (
                <option value={e.empcode}>{e.empcode}-{e.name}</option>
              ))} */}
              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Active />
              <select
                className="attendance-input-field width-10vw  "
                type="text"
                value={active}
                onChange={(e) => {
                  setActive(e.target.value)
                  setCheckedItems([])
                }}
              >
                <option value="null">All</option>
                <option value="active">Active</option>
                <option value="inactive">In Active</option>
              </select>
            </div>


          </div>
          <div className="btn-cont">
            <HROutdutyUpdate getoutdutyname={getoutdutyname} />
            {/* <button className="attendance-btn" onClick={getoutdutyname}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 11 12"
                fill="none"
              >
                <circle
                  cx="5"
                  cy="5"
                  r="4.3"
                  stroke="#FFFFFF"
                  stroke-width="1.4"
                />
                <line
                  x1="10.0101"
                  y1="11"
                  x2="8"
                  y2="8.98995"
                  stroke="#FFFFFF"
                  stroke-width="1.4"
                  stroke-linecap="round"
                />
              </svg>
              Add
            </button> */}
          </div>
        </div>
      </div>

      <div className="table-css-white-background">
        <div className="table-heading-flex">
          <div className="repo-heading font-weight500    font-size-heading">Outduty </div>
          {/* Easter Egg! */}
          {/* {outdutyname.length == 0 ? "Cant Find Something? Try Changing Filter! :)" : ""} */}

          <div className="flex-row">
            {(checkedItems.length > 0) ?
              <>
                <div className="align-center">
                  <div className=" font-size-heading">{`Selected : ${checkedItems.length}`} </div>
                </div>
                <hr className="field-cont-hr" />
                {selectedRhStatus === "approved" && selectedStatus === "pending" ?
                  <>
                    <button className="model-button model-button-cancel   font-weight500"
                      onClick={handleFormSubmitApprovePut}
                    >
                      Accept
                    </button>
                    {/* <hr className="field-cont-hr" />
                    <button className="model-button model-button-delete   font-weight500"
                      onClick={handleFormSubmitRejectedPut}
                    >
                      Rejected
                    </button> */}
                  </> : null}
              </>
              : null
            }

          </div>
        </div>

        <table className="table-css">
          <thead>
            <tr className="custom-table-head-tr">
              <th className="align-left">Sr.no</th>
              {
                selectedRhStatus === "approved" && selectedStatus === "pending" ?
                  <th className="align-left">
                    <input
                      type="checkbox"
                      checked={(checkedItems.length > 0) ? true : false}
                      onChange={(e) => {
                        if (e.target.checked) {
                          const ids = outdutyname.map((data) => data.id);
                          setCheckedItems(ids);
                        } else {
                          setCheckedItems([]);
                        }
                      }}
                    />
                  </th>
                  : null
              }

              <th className="align-center">Profile </th>
              <th className="align-center">Empolyee</th>
              <th className="align-center">OD start</th>
              <th className="align-center">OD end</th>
              <th className="align-center">OD Duration</th>
              {/* <th className="align-center">Leave Asked</th>
              <th className="align-center">Leave Balance</th> */}
              <th className="align-center">Reporting Head</th>
              <th className="align-center">Remarks</th>
              <th className="align-center">Status</th>
              <th className="align-right">Action</th>
            </tr>
          </thead>
          {buffer ? (
            <div className="spinner"></div> //buffering logic
          ) : (
            <tbody>
              {outdutyname.map((i, index) => (
                <React.Fragment key={index}>
                  <tr className="tr-border-bottom">
                    <td colSpan="6"></td>
                  </tr>
                  <tr className="custom-table-head-td">
                    {/* <div className="width-5vw"> */}
                    <td className="align-left">{index + 1}</td>

                    {selectedRhStatus === "approved" && selectedStatus === "pending" ?
                      <td className="align-left ">
                        <input
                          type="checkbox"
                          checked={checkedItems.indexOf(i.id) !== -1}
                          onChange={() => handleCheckboxChange(i.id)}
                        />
                      </td>
                      : null}

                    {/* </div> */}

                    <td className="align-center">
                      <img
                        className="profile-img"
                        src={
                          i.profilepic !== ""
                            ? `https://cipl-aimantra.s3.ap-south-1.amazonaws.com/${i.profilepic}`
                            : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        }
                        alt="profilepic"
                      />
                      <p>{i.name}</p>
                    </td>

                    <td className="align-center">{i.empcode}</td>
                    {/* <td className="align-center">{i.name}</td> */}
                    <td className="align-center">{formatDate(i.date)}</td>
                    <td className="align-center">{formatDate(i.end_date)}</td>
                    <td className="align-center form-text-trasformation-uppercase">
                      {i.outduty_Duration}
                    </td>
                    {/* <td className="align-center">{i.leaveasked}</td>
                  <td className="align-center">{i.leavebalance}</td> */}
                    {/* <td className="align-center">{i.negativeattendance}</td> */}
                    <td className="align-center">{i.rh_assigned}</td>
                    <td className="align-center">{i.remarks}</td>
                    <td className="align-center">
                      <span
                        request-status={i.rh_leave_status}
                        className="request-status align-center"
                      >
                        {i.rh_leave_status === "pending"
                          ? "Pending For Rh Approval ⌛"
                          : i.rh_leave_status === "rejected"
                            ? "Rh Rejected ✖, "
                            : "Rh Approved ✔, "}
                      </span>
                      <br />
                      <span
                        request-status={i.admin_leave_status}
                        className="request-status align-center"
                      >
                        {i.rh_leave_status === "rejected" &&
                          i.admin_leave_status === "rejected"
                          ? "Admin Rejected ✖, "
                          : i.rh_leave_status === "approved" &&
                            i.admin_leave_status === "pending"
                            ? "Pending For Admin Approval ⌛,"
                            : i.rh_leave_status === "approved" &&
                              i.admin_leave_status === "rejected"
                              ? "Admin Rejected ✖, "
                              : i.rh_leave_status === "approved" &&
                                i.admin_leave_status === "approved"
                                ? "Admin Approved ✔, "
                                : ""}
                      </span>
                    </td>
                    <td className="align-right">
                      {i.rh_leave_status === "rejected" ? (
                        <>
                          {PERMISSION_AUTHORITY ? <DeleteODRequest i={i} getoutdutyname={getoutdutyname} /> : null}
                        </>
                      ) : i.admin_leave_status === "pending" ?
                        (
                          <div className="flex-row">
                            <AdminOutdutyRequestapproved
                              i={i}
                              getoutdutyname={getoutdutyname}
                            />
                            <AdminOutdutyRequestDelete
                              i={i}
                              getoutdutyname={getoutdutyname}
                            />
                          </div>
                        )
                        :
                        <span
                          className="request-status align-center form-text-trasformation-uppercase"
                          request-status={i.admin_leave_status}
                        >
                          Admin : {i.admin_leave_status}
                        </span>}
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          )}
        </table>
      </div>
    </>
  );
};

const RequestLeavetable = () => {
  const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();
  const [checkedItems, setCheckedItems] = useState([]);
  // console.log(checkedItems);
  const [buffer, setBuffering] = useState(true); //buffering logic

  // *********** Employee Details Api Start ***********
  const [allemployee, setAllemployee] = useState([]);
  const [outdutyname, setOutdutyName] = useState([]);

  const [selectedStatus, setSelectedStatus] = useState("pending");
  const [selectedRhStatus, setSelectedRHStatus] = useState("approved");

  const [employeeGet, setEmployeeGet] = useState("null");
  const [fromdate, setFromDate] = useState(`${formattedDatee}`);
  const [todate, setToDate] = useState(`${formattedDate}`);

  const [selectedDivision, setDivision] = useState(
    `${sessionStorage.getItem("company_id")}`
  );

  const [active, setActive] = useState("active");

  const [SubCompany, setSubCompany] = useState([]);

  const getSubCompany = async () => {
    // setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(`${BASE_URL}/wfm/subcompany/`);
      setSubCompany(res.data);
    } catch (err) {
      handleErrorToast(err)
    } finally {
      // setBuffering(false); //buffering logic // End Buffering
    }
  };

  const getoutdutyname = async () => {
    // setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/leaveadminget/${selectedRhStatus}/${fromdate}/${todate}/${selectedStatus}/${employeeGet}/${selectedDivision}/${active}/`
      );
      setOutdutyName(res.data);
      // console.log(res.data)
    } catch (err) {
      handleErrorToast(err)
    } finally {
      // setBuffering(false); //buffering logic // End Buffering
    }
  };

  useEffect(() => {
    getoutdutyname();
    getEmpcode();
    getSubCompany();
  }, [selectedRhStatus, fromdate, todate, selectedStatus, employeeGet, active, selectedDivision]);

  const getEmpcode = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/ourcompanyempdetails/${active}/${selectedDivision}/`
      );
      const sortedList = customSortByKey(res.data, "emp_code");
      setAllemployee(sortedList);
    } catch (err) {
      handleErrorToast(err)
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };

  // useEffect(() => {
  // }, []);

  // *********** Employee Details Api End ***********

  const handleCheckboxChange = (id) => {
    const currentIndex = checkedItems.indexOf(id);
    const newCheckedItems = [...checkedItems];

    if (currentIndex === -1) {
      newCheckedItems.push(id);
    } else {
      newCheckedItems.splice(currentIndex, 1);
    }

    setCheckedItems(newCheckedItems);
  };

  const updatedate = checkedItems.map((status) => {
    return {
      id: status,
      rh_leave_status: "approved",
      admin_leave_status: "approved",
      hr_update_datetime: new Date().toISOString(),
    };
  });

  const updatedatee = checkedItems.map((status) => {
    return {
      id: status,
      admin_leave_status: "rejected",
      hr_update_datetime: new Date().toISOString(),
    };
  });

  const handleFormSubmitApprovePut = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.put(
        `${BASE_URL}/wfm/bulkleaveupdateandrectify/`,
        updatedate
      );
      // BulkLeaveRectify
      if (res.status === 200) {
        setCheckedItems([])
        await getoutdutyname();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      handleAllError(err)
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  const handleFormSubmitRejectedPut = async (e) => {
    e.preventDefault();

    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
    try {
      let res = await axios.put(
        // `${BASE_URL}/wfm/bulkleaveupdateandrectify/`,
        `${BASE_URL}/wfm/multipleleavestatusreject/`,
        updatedatee
      );

      if (res.status === 200) {
        setCheckedItems([])
        await getoutdutyname();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      handleAllError(err, loadingToastId)
    } finally {
      toast.dismiss(loadingToastId);
    }
  };
  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

      <div className="attendance-history-cont">
        <div className="attendance-heading  font-weight500    font-size-heading"> </div>
        <div className="attendance-subcont">
          <div className="field-cont">


            {PERMISSION_AUTHORITY
              ?
              <div className="field-cont-div">
                <Office />
                <select
                  className="attendance-input-field width-10vw   date-field"
                  type="text"
                  value={selectedDivision}
                  onChange={(e) => {
                    setDivision(e.target.value)
                    setCheckedItems([])
                  }}                >
                  <option value=""> All Sub Companies</option>
                  {SubCompany.sort((a, b) => a.title - b.title).map(
                    (i, index) => (
                      <option value={i.id}>{i.title}</option>
                    )
                  )}
                  ;
                </select>

                <hr className="field-cont-hr" />
              </div> : null}



            <div className="field-cont-div">
              <div className="date-uppercase ">
                <input
                  className="attendance-input-field width-10vw   date-field date-field-filter"
                  placeholder="From Date"
                  type="date"
                  value={fromdate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </div>

              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <div className="date-uppercase ">
                <input
                  className="attendance-input-field width-10vw   date-field date-field-filter"
                  placeholder="To Date"
                  type="date"
                  value={todate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </div>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Filter />
              <select
                title="Reporting Head Action Status"
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedRhStatus}
                onChange={(e) => {
                  setSelectedRHStatus(e.target.value)
                  setCheckedItems([])
                }}              >
                <option value="null">All</option>

                <option value="pending">Pending</option>
                <option value="approved">Approved</option>
                <option value="rejected">Rejected</option>
              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Filter />
              <select
                title="Admin Action Status"
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedStatus}
                onChange={(e) => {
                  setSelectedStatus(e.target.value)
                  setCheckedItems([])
                }}
              >
                <option value="null">All</option>

                <option value="pending">Pending</option>
                <option value="approved">Approved</option>
                <option value="rejected">Rejected</option>
              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Employee />
              <select
                className="attendance-input-field width-10vw date-field"
                type="text"
                value={employeeGet}
                onChange={(e) => {
                  setEmployeeGet(e.target.value)
                  setCheckedItems([])
                }}              >
                <option value="null">Select Employee Code</option>
                {allemployee
                  .sort((a, b) => a.emp_code - b.emp_code)
                  .map((i, index) => (
                    <option value={i.emp_code}>
                      {i.emp_code} - {i.name}
                    </option>
                  ))}
                ;
                {/* {allemployee.map((e, index) => (
                <option value={e.empcode}>{e.empcode}-{e.name}</option>
              ))} */}
              </select>
              <hr className="field-cont-hr" />

            </div>

            <div className="field-cont-div">
              <Active />
              <select
                className="attendance-input-field width-10vw  "
                type="text"
                value={active}
                onChange={(e) => {
                  setActive(e.target.value)
                  setCheckedItems([])
                }}              >
                <option value="null">All</option>
                <option value="active">Active</option>
                <option value="inactive">In Active</option>
              </select>
            </div>

          </div>
          <div className="btn-cont">
            <HRleaveUpdate getleavelist={getoutdutyname} />
          </div>
        </div>

        <div className="table-css-white-background">
          <div className="table-heading-flex">
            <div className="repo-heading font-weight500    font-size-heading">Leave </div>
            {/* Easter Egg! */}
            {/* {outdutyname.length == 0 ? "Cant Find Something? Try Changing Filter! :)" : ""} */}

            <div className="flex-row">
              {(checkedItems.length > 0) ?
                <>
                  <div className="align-center">
                    <div className=" font-size-heading">{`Selected : ${checkedItems.length}`} </div>
                  </div>
                  <hr className="field-cont-hr" />
                  {selectedRhStatus === "approved" && selectedStatus === "pending" ?
                    <>
                      <button className="model-button model-button-cancel   font-weight500"
                        onClick={handleFormSubmitApprovePut}
                      >
                        Accept
                      </button>
                      {/* <hr className="field-cont-hr" />
                      <button className="model-button model-button-delete   font-weight500"
                        onClick={handleFormSubmitRejectedPut}
                      >
                        Rejected
                      </button> */}
                    </> : null}
                </>
                : null}
            </div>

          </div>
          <table className="table-css">
            <thead>
              <tr className="custom-table-head-tr">
                <th className="align-leftt">Sr.no</th>
                {selectedRhStatus === "approved" && selectedStatus === "pending" ?
                  <th className="align-leftt">
                    <input
                      type="checkbox"
                      checked={(checkedItems.length > 0) ? true : false}
                      onChange={(e) => {
                        if (e.target.checked) {
                          const ids = outdutyname.map((data) => data.id);
                          setCheckedItems(ids);
                        } else {
                          setCheckedItems([]);
                        }
                      }}
                    />
                  </th>
                  : null}

                {/* <th className="align-center">Date</th> */}
                <th className="align-center">Profile </th>
                <th className="align-center">Empolyee</th>
                {/* <th className="align-center">Empolyee Name</th> */}
                {/* <th className="align-center">OD applied</th> */}
                <th className="align-center">Leave Start</th>
                <th className="align-center">Leave end</th>
                <th className="align-center">Leave Duration</th>
                <th className="align-center">Leave Type</th>
                <th className="align-center">Leave Asked</th>
                <th className="align-center">Leave Balance</th>
                {/* <th className="align-center">Negative Attendance</th> */}
                <th className="align-center">Reporting Head</th>
                {/* <th className="align-center">RH Leave Status</th> */}
                {/* <th className="align-center">Admin Leave Status</th> */}
                {/* <th className="align-center">Attendance Certified</th> */}
                <th className="align-center">Remarks</th>
                <th className="align-center">Status</th>
                <th className="align-right">Action</th>
              </tr>
            </thead>
            {buffer ? (
              <div className="spinner"></div> //buffering logic
            ) : (
              <tbody>
                {outdutyname.map((i, index) => (
                  <React.Fragment key={index}>
                    <tr className="tr-border-bottom">
                      <td colSpan="6"></td>
                    </tr>
                    <tr className="custom-table-head-td">
                      {/* <div className="width-5vw"> */}
                      <td className="align-leftt">{index + 1}</td>
                      {selectedRhStatus === "approved" && selectedStatus === "pending" ?
                        <td className="align-leftt ">
                          <input
                            type="checkbox"
                            checked={checkedItems.indexOf(i.id) !== -1}
                            onChange={() => handleCheckboxChange(i.id)}
                          />
                        </td>
                        : null}

                      {/* </div> */}

                      {/* <td className="align-center">{i.date}</td> */}
                      <td className="align-center">
                        <img
                          className="profile-img"
                          src={
                            i.profilepic !== ""
                              ? `https://cipl-aimantra.s3.ap-south-1.amazonaws.com/${i.profilepic}`
                              : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                          }
                          alt="profilepic"
                        />
                        <p>{i.name}</p>
                      </td>

                      {/* <img src={i.profilepic != "" ? `${IMAGE_URL}${i.profilepic}` : 'https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'} alt="image" className="employee-card-imageee" /> */}
                      {/* <td className="align-center">{i.profilepic}</td> */}
                      <td className="align-center">{i.empcode}</td>
                      {/* <td className="align-center">{i.name}</td> */}
                      <td className="align-center">{formatDate(i.date)}</td>
                      <td className="align-center">{formatDate(i.end_date)}</td>
                      <td className="align-center form-text-trasformation-uppercase">
                        {i.leave_Duration}
                      </td>
                      <td className="align-center form-text-trasformation-uppercase">
                        {i.leave_type ? i.leave_type : "Previous leaves"}
                      </td>
                      <td className="align-center">{i.leaveasked}</td>
                      {/* <td className="align-center">{i.leavebalance}</td> */}
                      <td
                        className="align-center"
                        title={`${i.leave_type ? i.leave_type : "previous leaves"
                          }`}
                      >
                        {i.leave_type == "sick leave" ? i.sick_leave : ""}
                        {i.leave_type == "casual leave" ? i.casual_leave : ""}
                        {i.leave_type == "earn leave" ? i.earn_leave : ""}
                        {i.leave_type == "previous leave" ? i.leavebalance : ""}
                        {i.leave_type == "" || i.leave_type == null
                          ? i.leavebalance
                          : ""}
                      </td>
                      {/* <td className="align-center">{i.negativeattendance}</td> */}
                      <td className="align-center">{i.rh_assigned}</td>
                      <td className="align-center">{i.remarks}</td>
                      <td className="align-center">
                        <span
                          request-status={i.rh_leave_status}
                          className="request-status align-center"
                        >
                          {i.rh_leave_status === "pending"
                            ? "Rh ⌛"
                            : i.rh_leave_status === "rejected"
                              ? "Rh ✖, "
                              : "Rh ✔, "}
                        </span>
                        <br />
                        <span
                          request-status={i.admin_leave_status}
                          className="request-status align-center"
                        >
                          {i.rh_leave_status === "rejected" &&
                            i.admin_leave_status === "rejected"
                            ? "Admin ✖, "
                            : i.rh_leave_status === "approved" &&
                              i.admin_leave_status === "pending"
                              ? "Admin ⌛,"
                              : i.rh_leave_status === "approved" &&
                                i.admin_leave_status === "rejected"
                                ? "Admin ✖, "
                                : i.rh_leave_status === "approved" &&
                                  i.admin_leave_status === "approved"
                                  ? "Admin ✔, "
                                  : ""}
                        </span>
                      </td>
                      <td className="align-right flex-row">
                        {/* <AdminRequestapproved i={i} getoutdutyname={getoutdutyname} />
                      <AdminRequestDelete i={i} getoutdutyname={getoutdutyname} /> */}

                        {i.rh_leave_status === "rejected" && PERMISSION_AUTHORITY ? (
                          <>
                            <DeleteLeaveRequest
                              i={i}
                              getLeavename={getoutdutyname}
                            />
                          </>
                        ) : i.admin_leave_status === "pending" ?
                          (
                            <>
                              <AdminRequestapproved
                                i={i}
                                getoutdutyname={getoutdutyname}
                              />
                              <AdminRequestDelete
                                i={i}
                                getoutdutyname={getoutdutyname}
                              />
                            </>
                          ) :
                          <span
                            className="request-status align-center form-text-trasformation-uppercase"
                            request-status={i.admin_leave_status}
                          >
                            Admin : {i.admin_leave_status}
                          </span>}
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </>
  );
};

const RequestMisspunchtable = () => {
  const [checkedItems, setCheckedItems] = useState([]);

  // *********** Employee Details Api Start ***********
  const [allemployee, setAllemployee] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [startdate, setStartDate] = useState("");

  const getoutdutyname = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/department/`);
      setAllemployee(res.data);
    } catch (err) {
      // alert(err.message);
    }
  };

  useEffect(() => {
    getoutdutyname();
  }, []);
  // *********** Employee Details Api End ***********

  const handleCheckboxChange = (id) => {
    const currentIndex = checkedItems.indexOf(id);
    const newCheckedItems = [...checkedItems];

    if (currentIndex === -1) {
      newCheckedItems.push(id);
    } else {
      newCheckedItems.splice(currentIndex, 1);
    }

    setCheckedItems(newCheckedItems);
  };

  return (
    <>
      <div className="attendance-history-cont">
        <div className="attendance-heading  font-weight500    font-size-heading"> </div>
        <div className="attendance-subcont">
          <div className="field-cont">
            <div className="field-cont-div">
              <div className="date-uppercase ">
                <input
                  className="attendance-input-field width-10vw   date-field date-field-filter"
                  placeholder="From Date"
                  type="date"
                  value={startdate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>

              <hr className="field-cont-hr" />
            </div>
            <div className="field-cont-div">
              <div className="attendance-input-field width-10vw   date-uppercase">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 19 19"
                  fill="none"
                >
                  <g clip-path="url(#clip0_915_905)">
                    <path
                      d="M14.4907 3.3374H3.99072C3.1623 3.3374 2.49072 4.00898 2.49072 4.8374V15.3374C2.49072 16.1658 3.1623 16.8374 3.99072 16.8374H14.4907C15.3191 16.8374 15.9907 16.1658 15.9907 15.3374V4.8374C15.9907 4.00898 15.3191 3.3374 14.4907 3.3374Z"
                      stroke="#707070"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12.2407 1.8374V4.8374"
                      stroke="#707070"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.24072 1.8374V4.8374"
                      stroke="#707070"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M2.49072 7.8374H15.9907"
                      stroke="#707070"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_915_905">
                      <rect
                        width="18"
                        height="18"
                        fill="white"
                        transform="translate(0.240723 0.337402)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>

              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Active />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                <option value="">Status</option>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 14 14"
                fill="none"
              >
                <g clip-path="url(#clip0_650_3324)">
                  <path
                    d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z"
                    fill="#707070"
                  />
                  <path
                    d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z"
                    fill="#707070"
                  />
                  <path
                    d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z"
                    fill="#707070"
                  />
                  <path
                    d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z"
                    fill="#707070"
                  />
                  <path
                    d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z"
                    fill="#707070"
                  />
                  <path
                    d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z"
                    fill="#707070"
                  />
                  <path
                    d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z"
                    fill="#707070"
                  />
                  <path
                    d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z"
                    fill="#707070"
                  />
                  <path
                    d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z"
                    fill="#707070"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_650_3324">
                    <rect width="14" height="14" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <input
                className="attendance-input-field width-10vw  "
                placeholder="Job Title"
                type="text"
                value={jobTitle}
                onChange={(e) => setJobTitle(e.target.value)}
              />
            </div>
          </div>
          <div className="btn-cont">
            <button className="attendance-btn" onClick={getoutdutyname}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 11 12"
                fill="none"
              >
                <circle
                  cx="5"
                  cy="5"
                  r="4.3"
                  stroke="#FFFFFF"
                  stroke-width="1.4"
                />
                <line
                  x1="10.0101"
                  y1="11"
                  x2="8"
                  y2="8.98995"
                  stroke="#FFFFFF"
                  stroke-width="1.4"
                  stroke-linecap="round"
                />
              </svg>
              Search
            </button>
          </div>
        </div>

        <div className="table-css-white-background">
          <div className="table-heading-flex">
            <div className="repo-heading font-weight500    font-size-heading">Miss Punch </div>
            <div className="flex-row">
              <dir classname="accept  font-weight300font-size-text ">
                <a href="">Accept</a>
              </dir>
              <div classname="accept-rej   font-weight300 font-size-text ">
                <a href="">Rejected</a>
              </div>
            </div>
          </div>
          <table className="table-css">
            <thead>
              <tr className="custom-table-head-tr">
                <th className="align-left">Sr.no</th>
                <th className="align-left">
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      if (e.target.checked) {
                        const ids = allemployee.map((data) => data.id);
                        setCheckedItems(ids);
                      } else {
                        setCheckedItems([]);
                      }
                    }}
                  />
                </th>

                <th className="align-center">Date</th>
                <th className="align-center">Empolyee Name</th>
                <th className="align-center">OD applied</th>
                <th className="align-center">OD end</th>
                <th className="align-center">Remarks</th>
                <th className="align-center">Leave type</th>
                <th className="align-right">Action</th>
              </tr>
            </thead>
            <tbody>
              {allemployee.map((i, index) => (
                <React.Fragment key={index}>
                  <tr className="tr-border-bottom">
                    <td colSpan="6"></td>
                  </tr>
                  <tr className="custom-table-head-td">
                    <td className="align-left">{index + 1}</td>
                    <td className="align-left">
                      <input
                        type="checkbox"
                        checked={checkedItems.indexOf(i.id) !== -1}
                        onChange={() => handleCheckboxChange(i.id)}
                      />
                    </td>
                    <td className="align-center">{i.date}</td>
                    <td className="align-center">{i.empoloyee_name}</td>
                    <td className="align-center">{i.odapplied}</td>
                    <td className="align-center">{i.odend}</td>
                    <td className="align-center">{i.remark}</td>
                    <td className="align-center">{i.leavetype}</td>
                    <td className="align-right">
                      {
                        (i.rh_leave_status = "rejected" ? (
                          <>
                            {/* <DeleteODRequest i={i} getoutdutyname={getoutdutyname} /> */}
                          </>
                        ) : (
                          <>
                            <AdminRequestapproved
                              i={i}
                              getoutdutyname={getoutdutyname}
                            />
                            <AdminRequestDelete
                              i={i}
                              getoutdutyname={getoutdutyname}
                            />
                          </>
                        ))
                      }
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

const LogCompensatorytable = () => {
  const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();
  const [checkedItems, setCheckedItems] = useState([]);
  // const [date, setDate] = useState();
  const [buffer, setBuffering] = useState(true); //buffering logic
  const [employeeList, setEmployeeList] = useState([]);
  // const [date, setDate] = useState(`${formattedDate}`);
  const [fromdate, setFromDate] = useState("null");
  const [todate, setToDate] = useState("null");
  const [selectedStatus, setSelectedStatus] = useState("null");
  const [employeeGet, setEmployeeGet] = useState("null");
  const [active, setActive] = useState("active");
  const [selectedDivision, setDivision] = useState(
    `${sessionStorage.getItem("company_id")}`
  );

  const [SubCompany, setSubCompany] = useState([]);

  const getSubCompany = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/subcompany/`);
      setSubCompany(res.data);
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;
        if (typeof errorData.error === "string") {
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === "object" && errorData !== null) {
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach((message) =>
              toast.error(`"${field}": ${message}`)
            );
          });
        } else {
          toast.error("Error:- Failed to Process!");
        }
      } else {
        toast.error("Error processing your request.");
      }
    } finally {
    }
  };

  // *********** Employee Details Api Start ***********
  const [compensatoryList, setCompensatoryList] = useState([]);
  const [filteredCompensatoryList, setfilteredCompensatoryList] = useState([]);

  const getEmpList = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/ourcompanyempdetails/${active}/${selectedDivision}/`
      );
      const sortedList = customSortByKey(res.data, "emp_code");
      setEmployeeList(sortedList);
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;
        if (typeof errorData.error === "string") {
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === "object" && errorData !== null) {
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach((message) =>
              toast.error(`"${field}": ${message}`)
            );
          });
        } else {
          toast.error("Error:- Failed to Process!");
        }
      } else {
        toast.error("Error processing your request.");
      }
    } finally {
    }
  };

  const getCompensatoryList = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      // const res = await axios.get(`${BASE_URL}/wfm/shortleaveandcompoff/${sessionStorage.getItem("emp_code")}/`);
      // const res = await axios.get(`${BASE_URL}/wfm/shortandcompoff/${fromdate}/${todate}/${sessionStorage.getItem("emp_code")}/${selectedStatus}/${employeeGet}/`);
      const res = await axios.get(
        `${BASE_URL}/wfm/shortandcompoff/${fromdate}/${todate}/null/${selectedStatus}/${employeeGet}/${selectedDivision}/${active}/`
      );
      setCompensatoryList(res.data);
      // setOutdutyName(res.data);
    } catch (err) {
      handleErrorToast(err);
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };

  // *********** Employee Details Api End ***********



  useEffect(() => {
    getCompensatoryList();
    getEmpList();
    getSubCompany();
  }, [employeeGet, fromdate, todate, selectedStatus, active, selectedDivision]);

  useEffect(() => {
  }, []);

  // useEffect(() => {
  //   getEmpList();
  // }, [active, selectedDivision]);

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div className="attendance-history-cont">
        <div className="attendance-heading  font-weight500    font-size-heading"> </div>
        <div className="attendance-subcont">
          <div className="field-cont">


            {PERMISSION_AUTHORITY ?
              <div className="field-cont-div">
                <Office />
                <select
                  className="attendance-input-field width-10vw   date-field"
                  type="text"
                  value={selectedDivision}
                  onChange={(e) => setDivision(e.target.value)}
                >
                  <option value="null"> All Sub Companies</option>
                  {SubCompany.sort((a, b) => a.title - b.title).map(
                    (i, index) => (
                      <option value={i.id}>{i.title}</option>
                    )
                  )}
                  ;
                </select>

                <hr className="field-cont-hr" />
              </div> : null}


            <div className="field-cont-div">
              <div className="date-uppercase ">
                <input
                  className="attendance-input-field width-10vw   date-field date-field-filter"
                  placeholder="From Date"
                  type="date"
                  value={fromdate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </div>
              <hr className="field-cont-hr" />

            </div>

            <div className="field-cont-div">
              <div className=" date-uppercase ">
                <input
                  className="attendance-input-field width-10vw   date-field date-field-filter"
                  placeholder="From Date"
                  type="date"
                  value={todate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </div>
              <hr className="field-cont-hr" />

            </div>

            <div className="field-cont-div">
              <Filter />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                <option value="null">All</option>

                <option value="pending">Pending</option>
                <option value="approved">Approved</option>
                <option value="rejected">Rejected</option>
              </select>
              <hr className="field-cont-hr" />

            </div>

            <div className="field-cont-div">
              <Employee />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={employeeGet}
                onChange={(e) => setEmployeeGet(e.target.value)}
              >
                <option value="null">Select Employee Code</option>
                {employeeList
                  .sort((a, b) => a.emp_code - b.emp_code)
                  .map((i, index) => (
                    <option value={i.emp_code}>
                      {i.emp_code} - {i.name}
                    </option>
                  ))}
                ;
              </select>

              <hr className="field-cont-hr" />

            </div>

            <div className="field-cont-div">
              <Active />
              <select
                className="attendance-input-field width-10vw  "
                type="text"
                value={active}
                onChange={(e) => setActive(e.target.value)}
              >
                <option value="null">All</option>
                <option value="active">Active</option>
                <option value="inactive">In Active</option>
              </select>
            </div>

          </div>

          <div className="btn-cont"></div>
        </div>

        <div className="table-css-white-background">
          <div className="table-heading-flex">
            <div className="repo-heading font-weight500    font-size-heading">Compensatory Logs </div>
            {/* <div className="flex-row">
              <dir classname="accept  font-weight300font-size-text "><a href="">Accept</a></dir>
              <div classname="accept-rej   font-weight300 font-size-text "><a href="">Rejected</a></div>
            </div> */}
          </div>
          <table className="table-css">
            <thead>
              <tr className="custom-table-head-tr">
                <th className="align-left">Sr.no</th>
                {/* <th className="align-left">
                  <input type="checkbox" onChange={(e) => {
                    if (e.target.checked) {
                      const ids = compensatoryList.map((data) => data.id);
                      setCheckedItems(ids);
                    } else {
                      setCheckedItems([]);
                    }
                  }} />
                </th> */}

                {/* <th className="align-center">Date</th> */}
                <th className="align-center">Empolyee Name</th>
                <th className="align-center">Date</th>
                <th className="align-center">In-Time</th>
                <th className="align-center">Out-Time</th>
                <th className="align-center">Working Hours</th>
                <th className="align-center">Compensatory Type</th>
                <th className="align-center">Reporting Head</th>
                <th className="align-center">Status</th>
              </tr>
            </thead>
            {buffer ? (
              <div className="spinner"></div> //buffering logic
            ) : (
              <tbody>
                {compensatoryList.map((i, index) => (
                  <React.Fragment key={index}>
                    <tr className="tr-border-bottom">
                      <td colSpan="6"></td>
                    </tr>
                    <tr className="custom-table-head-td">
                      <td className="align-left">{index + 1}</td>
                      {/* <td className="align-left">
                      <input
                        type="checkbox"
                        checked={checkedItems.indexOf(i.id) !== -1}
                        onChange={() => handleCheckboxChange(i.id)}
                      /></td> */}
                      {/* <td className="align-center">{i.date}</td> */}
                      <td className="align-center">
                        <img
                          className="profile-img"
                          src={
                            `${i.profilepic}`
                              ? `https://cipl-aimantra.s3.ap-south-1.amazonaws.com/${i.profilepic}`
                              : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                          }
                          alt="profilepic"
                        />
                        <p>{i.empcode}-{i.name}</p>
                      </td>
                      {/* <td className="align-center">{i.empcode}-{i.name}</td> */}
                      <td className="align-center">
                        {formattedDateLong(i.date)}
                      </td>
                      <td className="align-center">{i.in_time}</td>
                      <td className="align-center">{i.out_time}</td>
                      <td className="align-center">{i.work_duration}</td>
                      <td className="align-center form-text-trasformation-uppercase">
                        {i.duration}
                      </td>
                      <td className="align-center">{i.rh_assigned}</td>
                      <td className="align-center">
                        <span
                          request-status={i.rh_leave_status}
                          className="request-status align-center"
                        >
                          {i.rh_leave_status === "pending"
                            ? "Pending For Rh Approval ⌛"
                            : i.rh_leave_status === "rejected"
                              ? "Rh Rejected ✖, "
                              : "Rh Approved ✔, "}
                        </span>
                        {/* <br />
                      <span request-status={i.admin_leave_status} className="request-status align-center" >{(i.rh_leave_status === "rejected" && i.admin_leave_status === "rejected") ? "Admin Rejected ✖, " : (i.rh_leave_status === "approved" && i.admin_leave_status === "pending") ? "Pending For Admin Approval ⌛," : (i.rh_leave_status === "approved" && i.admin_leave_status === "rejected") ? "Admin Rejected ✖, " : (i.rh_leave_status === "approved" && i.admin_leave_status === "approved") ? "Admin Approved ✔, " : ""}</span> */}
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </>
  );
};

const HODCompensatoryLogTable = () => {
  const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();
  const [checkedItems, setCheckedItems] = useState([]);
  // const [date, setDate] = useState();
  const [buffer, setBuffering] = useState(true); //buffering logic
  const [employeeList, setEmployeeList] = useState([]);
  // const [date, setDate] = useState(`${formattedDate}`);
  const [fromdate, setFromDate] = useState("null");
  const [todate, setToDate] = useState("null");
  const [selectedStatus, setSelectedStatus] = useState("null");
  const [employeeGet, setEmployeeGet] = useState("null");
  const [active, setActive] = useState("active");
  const [selectedDivision, setDivision] = useState(
    `${sessionStorage.getItem("company_id")}`
  );

  const [SubCompany, setSubCompany] = useState([]);

  const getSubCompany = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/subcompany/`);
      setSubCompany(res.data);
    } catch (err) {
      handleErrorToast(err)
    }
  };

  // *********** Employee Details Api Start ***********
  const [compensatoryList, setCompensatoryList] = useState([]);
  const [filteredCompensatoryList, setfilteredCompensatoryList] = useState([]);

  const getEmpList = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/ourcompanyempdetails/${active}/${selectedDivision}/`
      );
      const sortedList = customSortByKey(res.data, "emp_code");
      setEmployeeList(sortedList);
    } catch (err) {
      handleErrorToast(err)
    }
  };

  const getCompensatoryList = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      // const res = await axios.get(`${BASE_URL}/wfm/shortleaveandcompoff/${sessionStorage.getItem("emp_code")}/`);
      // const res = await axios.get(`${BASE_URL}/wfm/shortandcompoff/${fromdate}/${todate}/${sessionStorage.getItem("emp_code")}/${selectedStatus}/${employeeGet}/`);
      const res = await axios.get(
        `${BASE_URL}/wfm/shortandcompoff/${fromdate}/${todate}/null/${selectedStatus}/${employeeGet}/${selectedDivision}/${active}/`
      );
      setCompensatoryList(res.data);
      // setOutdutyName(res.data);
    } catch (err) {
      handleErrorToast(err);
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };

  // *********** Employee Details Api End ***********



  useEffect(() => {
    getCompensatoryList();
    getEmpList();
    getSubCompany();
  }, [employeeGet, fromdate, todate, selectedStatus, active, selectedDivision]);

  useEffect(() => {
  }, []);

  // useEffect(() => {
  //   getEmpList();
  // }, [active, selectedDivision]);

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div className="attendance-history-cont">
        <div className="attendance-heading  font-weight500    font-size-heading"> </div>
        <div className="attendance-subcont">
          <div className="field-cont">

            {PERMISSION_AUTHORITY ?
              <div className="field-cont-div">
                <Office />
                <select
                  className="attendance-input-field width-10vw   date-field"
                  type="text"
                  value={selectedDivision}
                  onChange={(e) => setDivision(e.target.value)}
                >
                  <option value=""> All Sub Companies</option>
                  {SubCompany.sort((a, b) => a.title - b.title).map(
                    (i, index) => (
                      <option value={i.id}>{i.title}</option>
                    )
                  )}
                  ;
                </select>
                <hr className="field-cont-hr" />
              </div> : null}

            <div className="field-cont-div">
              <div className="date-uppercase ">
                <input
                  className="attendance-input-field width-10vw   date-field date-field-filter"
                  placeholder="From Date"
                  type="date"
                  value={fromdate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </div>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <div className=" date-uppercase ">
                <input
                  className="attendance-input-field width-10vw   date-field date-field-filter"
                  placeholder="From Date"
                  type="date"
                  value={todate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </div>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Filter />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                <option value="null">All</option>
                <option value="pending">Pending</option>
                <option value="approved">Approved</option>
                <option value="rejected">Rejected</option>
              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <CircularGrid />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={employeeGet}
                onChange={(e) => setEmployeeGet(e.target.value)}
              >
                <option value="">Select Employee Code</option>
                {employeeList
                  .sort((a, b) => a.emp_code - b.emp_code)
                  .map((i, index) => (
                    <option value={i.emp_code}>
                      {i.emp_code} - {i.name}
                    </option>
                  ))}
                ;
              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Active />
              <select
                className="attendance-input-field width-10vw  "
                type="text"
                value={active}
                onChange={(e) => setActive(e.target.value)}
              >
                <option value="null">All</option>
                <option value="active">Active</option>
                <option value="inactive">In Active</option>
              </select>
            </div>
          </div>
          <div className="btn-cont"></div>
        </div>

        <div className="table-css-white-background">
          <div className="table-heading-flex">
            <div className="repo-heading font-weight500    font-size-heading">Compensatory Logs </div>
          </div>
          <table className="table-css">
            <thead>
              <tr className="custom-table-head-tr">
                <th className="align-left">Sr.no</th>
                {/* <th className="align-center">Date</th> */}
                <th className="align-center">Empolyee Name</th>
                <th className="align-center">Date</th>
                <th className="align-center">In-Time</th>
                <th className="align-center">Out-Time</th>
                <th className="align-center">Working Hours</th>
                <th className="align-center">Compensatory Type</th>
                <th className="align-center">Reporting Head</th>
                <th className="align-center">Status</th>
              </tr>
            </thead>
            {buffer ? (
              <div className="spinner"></div> //buffering logic
            ) : (
              <tbody>
                {compensatoryList.map((i, index) => (
                  <React.Fragment key={index}>
                    <tr className="tr-border-bottom">
                      <td colSpan="6"></td>
                    </tr>
                    <tr className="custom-table-head-td">
                      <td className="align-left">{index + 1}</td>
                      <td className="align-center">
                        <img
                          className="profile-img"
                          src={
                            `${i.profilepic}`
                              ? `https://cipl-aimantra.s3.ap-south-1.amazonaws.com/${i.profilepic}`
                              : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                          }
                          alt="profilepic"
                        />
                        <p>{i.empcode}-{i.name}</p>
                      </td>
                      {/* <td className="align-center">{i.empcode}-{i.name}</td> */}
                      <td className="align-center">
                        {formattedDateLong(i.date)}
                      </td>
                      <td className="align-center">{i.in_time}</td>
                      <td className="align-center">{i.out_time}</td>
                      <td className="align-center">{i.work_duration}</td>
                      <td className="align-center form-text-trasformation-uppercase">
                        {i.duration}
                      </td>
                      <td className="align-center">{i.rh_assigned}</td>
                      <td className="align-center">
                        <span
                          request-status={i.rh_leave_status}
                          className="request-status align-center"
                        >
                          {i.rh_leave_status === "pending"
                            ? "Pending For Rh Approval ⌛"
                            : i.rh_leave_status === "rejected"
                              ? "Rh Rejected ✖, "
                              : "Rh Approved ✔, "}
                        </span>
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </>
  );
};


const HRLeavesLogTable = () => {
  const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();
  const [checkedItems, setCheckedItems] = useState([]);
  // const [date, setDate] = useState();
  const [buffer, setBuffering] = useState(true); //buffering logic
  const [employeeList, setEmployeeList] = useState([]);
  // const [date, setDate] = useState(`${formattedDate}`);
  const [fromdate, setFromDate] = useState("null");
  const [todate, setToDate] = useState("null");
  const [selectedStatus, setSelectedStatus] = useState("null");
  const [employeeGet, setEmployeeGet] = useState("null");
  const [active, setActive] = useState("active");
  const [selectedDivision, setDivision] = useState(
    `${sessionStorage.getItem("company_id")}`
  );

  const [SubCompany, setSubCompany] = useState([]);

  const getSubCompany = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/subcompany/`);
      setSubCompany(res.data);
    } catch (err) {
      handleErrorToast(err)
    }
  };

  // *********** Employee Details Api Start ***********
  const [leaveLogList, setLeaveLogList] = useState([]);
  const [filteredleaveLogList, setfilteredleaveLogList] = useState([]);

  const getEmpList = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/ourcompanyempdetails/${active}/${selectedDivision}/`
      );
      const sortedList = customSortByKey(res.data, "emp_code");
      const sortedData = customSortByKey(res.data, "emp_code");

      setEmployeeList(sortedData);
    } catch (err) {
      handleErrorToast(err)
    }
  };

  const getLeaveLogList = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/shortandcompoff/${fromdate}/${todate}/null/${selectedStatus}/${employeeGet}/${selectedDivision}/${active}/`
      );
      setLeaveLogList(res.data);
      // setOutdutyName(res.data);
    } catch (err) {
      handleErrorToast(err);
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };

  // *********** Employee Details Api End ***********

  useEffect(() => {
    getLeaveLogList();
    getEmpList();
    getSubCompany();
  }, [employeeGet, fromdate, todate, selectedStatus, active, selectedDivision]);

  useEffect(() => {
  }, []);

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div className="attendance-history-cont">
        <div className="attendance-heading  font-weight500    font-size-heading"> </div>
        <div className="attendance-subcont">
          <div className="field-cont">


            {PERMISSION_AUTHORITY
              ?
              <div className="field-cont-div">
                <Office />
                <select
                  className="attendance-input-field width-10vw   date-field"
                  type="text"
                  value={selectedDivision}
                  onChange={(e) => setDivision(e.target.value)}
                >
                  <option value="">All Companies</option>
                  {SubCompany.sort((a, b) => a.title - b.title).map(
                    (i, index) => (
                      <option value={i.id}>{i.title}</option>
                    )
                  )}
                  ;
                </select>

                <hr className="field-cont-hr" />
              </div> : null}


            <div className="field-cont-div">
              <div className="date-uppercase ">
                <input
                  className="attendance-input-field width-10vw   date-field date-field-filter"
                  placeholder="From Date"
                  type="date"
                  value={fromdate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </div>
              <hr className="field-cont-hr" />

            </div>

            <div className="field-cont-div">
              <div className=" date-uppercase ">
                <input
                  className="attendance-input-field width-10vw   date-field date-field-filter"
                  placeholder="From Date"
                  type="date"
                  value={todate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </div>
              <hr className="field-cont-hr" />

            </div>

            <div className="field-cont-div">
              <Filter />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                <option value="null">All</option>

                <option value="pending">Pending</option>
                <option value="approved">Approved</option>
                <option value="rejected">Rejected</option>
              </select>
              <hr className="field-cont-hr" />

            </div>

            <div className="field-cont-div">
              <CircularGrid />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={employeeGet}
                onChange={(e) => setEmployeeGet(e.target.value)}
              >
                <option value="">Select Employee Code</option>
                {employeeList
                  .sort((a, b) => a.emp_code - b.emp_code)
                  .map((i, index) => (
                    <option value={i.emp_code}>
                      {i.emp_code} - {i.name}
                    </option>
                  ))}
                ;
              </select>

              <hr className="field-cont-hr" />

            </div>

            <div className="field-cont-div">
              <Active />
              <select
                className="attendance-input-field width-10vw  "
                type="text"
                value={active}
                onChange={(e) => setActive(e.target.value)}
              >
                <option value="null">All</option>
                <option value="active">Active</option>
                <option value="inactive">In Active</option>
              </select>
            </div>

          </div>

          <div className="btn-cont"></div>
        </div>

        <div className="table-css-white-background">
          <div className="table-heading-flex">
            <div className="repo-heading font-weight500    font-size-heading">Employee Leave Logs </div>
          </div>
          <table className="table-css">
            <thead>
              <tr className="custom-table-head-tr">
                <th className="align-left">Sr.no</th>
                <th className="align-center">Empolyee Name</th>
                <th className="align-center">Date-Time</th>
                <th className="align-center">Leave Type</th>
                <th className="align-center">Balance Before</th>
                <th className="align-center">Leaves affected</th>
                <th className="align-center">Balance After</th>
                <th className="align-center width-20vw">Description</th>
              </tr>
            </thead>
            {buffer ? (
              <div className="spinner"></div>
            ) : (
              <tbody>
                {leaveLogList.map((i, index) => (
                  <React.Fragment key={index}>
                    <tr className="tr-border-bottom">
                      <td colSpan="6"></td>
                    </tr>
                    <tr className="custom-table-head-td">
                      <td className="align-left">{index + 1}</td>
                      <td className="align-center">
                        <img
                          className="profile-img"
                          src={
                            `${i.profilepic}`
                              ? `https://cipl-aimantra.s3.ap-south-1.amazonaws.com/${i.profilepic}`
                              : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                          }
                          alt="profilepic"
                        />
                        <p>{i.empcode}-{i.name}</p>
                      </td>
                      <td className="align-center">{formattedDateLong(i.date)}-{i.in_time}</td>
                      <td className="align-center form-text-trasformation-uppercase">{i.duration}</td>
                      <td className="align-center">{i.out_time}</td>
                      <td className="align-center">{i.work_duration}</td>
                      <td className="align-center">{i.rh_assigned}</td>
                      <td className="align-center form-text-trasformation-uppercase">{i.duration}</td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </>
  );
};

const HODLeavesLogTable = () => {
  const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();
  const [checkedItems, setCheckedItems] = useState([]);
  // const [date, setDate] = useState();
  const [buffer, setBuffering] = useState(true); //buffering logic
  const [employeeList, setEmployeeList] = useState([]);
  // const [date, setDate] = useState(`${formattedDate}`);
  const [fromdate, setFromDate] = useState("null");
  const [todate, setToDate] = useState("null");
  const [selectedStatus, setSelectedStatus] = useState("null");
  const [employeeGet, setEmployeeGet] = useState("null");
  const [active, setActive] = useState("active");
  const [selectedDivision, setDivision] = useState(
    `${sessionStorage.getItem("company_id")}`
  );

  const [SubCompany, setSubCompany] = useState([]);

  const getSubCompany = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/subcompany/`);
      setSubCompany(res.data);
    } catch (err) {
      handleErrorToast(err)
    }
  };

  // *********** Employee Details Api Start ***********
  const [leaveLogList, setLeaveLogList] = useState([]);
  const [filteredleaveLogList, setfilteredleaveLogList] = useState([]);

  const getEmpList = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/ourcompanyempdetails/${active}/${selectedDivision}/`
      );
      const sortedList = customSortByKey(res.data, "emp_code");
      const sortedData = customSortByKey(res.data, "emp_code");

      setEmployeeList(sortedData);
    } catch (err) {
      handleErrorToast(err)
    }
  };

  const getLeaveLogList = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/shortandcompoff/${fromdate}/${todate}/null/${selectedStatus}/${employeeGet}/${selectedDivision}/${active}/`
      );
      setLeaveLogList(res.data);
      // setOutdutyName(res.data);
    } catch (err) {
      handleErrorToast(err);
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };

  // *********** Employee Details Api End ***********

  useEffect(() => {
    getLeaveLogList();
    getEmpList();
    getSubCompany();
  }, [employeeGet, fromdate, todate, selectedStatus, active, selectedDivision]);

  useEffect(() => {
  }, []);

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div className="attendance-history-cont">
        <div className="attendance-heading  font-weight500    font-size-heading"> </div>
        <div className="attendance-subcont">
          <div className="field-cont">

            <div className="field-cont-div">
              <div className="date-uppercase ">
                <input
                  className="attendance-input-field width-10vw   date-field date-field-filter"
                  placeholder="From Date"
                  type="date"
                  value={fromdate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </div>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <div className=" date-uppercase ">
                <input
                  className="attendance-input-field width-10vw   date-field date-field-filter"
                  placeholder="From Date"
                  type="date"
                  value={todate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </div>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Filter />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                <option value="null">All</option>
                <option value="pending">Pending</option>
                <option value="approved">Approved</option>
                <option value="rejected">Rejected</option>
              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <CircularGrid />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={employeeGet}
                onChange={(e) => setEmployeeGet(e.target.value)}
              >
                <option value="">Select Employee Code</option>
                {employeeList
                  .sort((a, b) => a.emp_code - b.emp_code)
                  .map((i, index) => (
                    <option value={i.emp_code}>
                      {i.emp_code} - {i.name}
                    </option>
                  ))}
                ;
              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Active />
              <select
                className="attendance-input-field width-10vw  "
                type="text"
                value={active}
                onChange={(e) => setActive(e.target.value)}
              >
                <option value="null">All</option>
                <option value="active">Active</option>
                <option value="inactive">In Active</option>
              </select>
            </div>
          </div>

          <div className="btn-cont"></div>
        </div>

        <div className="table-css-white-background">
          <div className="table-heading-flex">
            <div className="repo-heading font-weight500    font-size-heading">Employee Leave Logs </div>
          </div>
          <table className="table-css">
            <thead>
              <tr className="custom-table-head-tr">
                <th className="align-left">Sr.no</th>
                <th className="align-center">Empolyee Name</th>
                <th className="align-center">Date-Time</th>
                <th className="align-center">Leave Type</th>
                <th className="align-center">Balance Before</th>
                <th className="align-center">Leaves affected</th>
                <th className="align-center">Balance After</th>
                <th className="align-center width-20vw">Description</th>
              </tr>
            </thead>
            {buffer ? (
              <div className="spinner"></div>
            ) : (
              <tbody>
                {leaveLogList.map((i, index) => (
                  <React.Fragment key={index}>
                    <tr className="tr-border-bottom">
                      <td colSpan="6"></td>
                    </tr>
                    <tr className="custom-table-head-td">
                      <td className="align-left">{index + 1}</td>
                      <td className="align-center">
                        <img
                          className="profile-img"
                          src={
                            `${i.profilepic}`
                              ? `https://cipl-aimantra.s3.ap-south-1.amazonaws.com/${i.profilepic}`
                              : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                          }
                          alt="profilepic"
                        />
                        <p>{i.empcode}-{i.name}</p>
                      </td>
                      <td className="align-center">{formattedDateLong(i.date)}-{i.in_time}</td>
                      <td className="align-center form-text-trasformation-uppercase">{i.duration}</td>
                      <td className="align-center">{i.out_time}</td>
                      <td className="align-center">{i.work_duration}</td>
                      <td className="align-center">{i.rh_assigned}</td>
                      <td className="align-center form-text-trasformation-uppercase">{i.duration}</td>

                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </>
  );
};


const HRNotificationLogTable = () => {
  const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();
  const [checkedItems, setCheckedItems] = useState([]);
  // const [date, setDate] = useState();
  const [buffer, setBuffering] = useState(true); //buffering logic
  const [employeeList, setEmployeeList] = useState([]);
  // const [date, setDate] = useState(`${formattedDate}`);
  const [fromdate, setFromDate] = useState("null");
  const [todate, setToDate] = useState("null");
  const [selectedStatus, setSelectedStatus] = useState("null");
  const [employeeGet, setEmployeeGet] = useState("null");
  const [active, setActive] = useState("active");
  const [selectedDivision, setDivision] = useState(
    `${sessionStorage.getItem("company_id")}`
  );

  const [SubCompany, setSubCompany] = useState([]);

  const getSubCompany = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/subcompany/`);
      setSubCompany(res.data);
    } catch (err) {
      handleErrorToast(err)
    }
  };

  // *********** Employee Details Api Start ***********
  const [leaveLogList, setLeaveLogList] = useState([]);
  const [filteredleaveLogList, setfilteredleaveLogList] = useState([]);

  const getEmpList = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/ourcompanyempdetails/${active}/${selectedDivision}/`
      );
      const sortedList = customSortByKey(res.data, "emp_code");
      const sortedData = customSortByKey(res.data, "emp_code");

      setEmployeeList(sortedData);
    } catch (err) {
      handleErrorToast(err)
    }
  };

  const getLeaveLogList = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/shortandcompoff/${fromdate}/${todate}/null/${selectedStatus}/${employeeGet}/${selectedDivision}/${active}/`
      );
      setLeaveLogList(res.data);
      // setOutdutyName(res.data);
    } catch (err) {
      handleErrorToast(err);
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };

  // *********** Employee Details Api End ***********

  useEffect(() => {
    getLeaveLogList();
    getEmpList();
    getSubCompany();
  }, [employeeGet, fromdate, todate, selectedStatus, active, selectedDivision]);

  useEffect(() => {
  }, []);

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div className="attendance-history-cont">
        <div className="attendance-heading  font-weight500    font-size-heading"> </div>
        <div className="attendance-subcont">
          <div className="field-cont">


            {PERMISSION_AUTHORITY
              ?
              <div className="field-cont-div">
                <Office />
                <select
                  className="attendance-input-field width-10vw   date-field"
                  type="text"
                  value={selectedDivision}
                  onChange={(e) => setDivision(e.target.value)}
                >
                  <option value="">All Companies</option>
                  {SubCompany.sort((a, b) => a.title - b.title).map(
                    (i, index) => (
                      <option value={i.id}>{i.title}</option>
                    )
                  )}
                  ;
                </select>

                <hr className="field-cont-hr" />
              </div> : null}


            <div className="field-cont-div">
              <div className="date-uppercase ">
                <input
                  className="attendance-input-field width-10vw   date-field date-field-filter"
                  placeholder="From Date"
                  type="date"
                  value={fromdate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </div>
              <hr className="field-cont-hr" />

            </div>

            <div className="field-cont-div">
              <div className=" date-uppercase ">
                <input
                  className="attendance-input-field width-10vw   date-field date-field-filter"
                  placeholder="From Date"
                  type="date"
                  value={todate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </div>
              <hr className="field-cont-hr" />

            </div>

            <div className="field-cont-div">
              <Filter />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                <option value="null">All</option>

                <option value="pending">Pending</option>
                <option value="approved">Approved</option>
                <option value="rejected">Rejected</option>
              </select>
              <hr className="field-cont-hr" />

            </div>

            <div className="field-cont-div">
              <CircularGrid />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={employeeGet}
                onChange={(e) => setEmployeeGet(e.target.value)}
              >
                <option value="">Select Employee Code</option>
                {employeeList
                  .sort((a, b) => a.emp_code - b.emp_code)
                  .map((i, index) => (
                    <option value={i.emp_code}>
                      {i.emp_code} - {i.name}
                    </option>
                  ))}
                ;
              </select>

              <hr className="field-cont-hr" />

            </div>

            <div className="field-cont-div">
              <Active />
              <select
                className="attendance-input-field width-10vw  "
                type="text"
                value={active}
                onChange={(e) => setActive(e.target.value)}
              >
                <option value="null">All</option>
                <option value="active">Active</option>
                <option value="inactive">In Active</option>
              </select>
            </div>

          </div>

          <div className="btn-cont"></div>
        </div>

        <div className="table-css-white-background">
          <div className="table-heading-flex">
            <div className="repo-heading font-weight500    font-size-heading">Employee Notification Logs </div>
          </div>
          <table className="table-css">
            <thead>
              <tr className="custom-table-head-tr">
                <th className="align-left">Sr.no</th>
                <th className="align-center">Empolyee Name</th>
                <th className="align-center">Date-Time</th>
                <th className="align-center">Leave Type</th>
                <th className="align-center">Balance Before</th>
                <th className="align-center">Leaves affected</th>
                <th className="align-center">Balance After</th>
                <th className="align-center width-20vw">Description</th>
              </tr>
            </thead>
            {buffer ? (
              <div className="spinner"></div>
            ) : (
              <tbody>
                {leaveLogList.map((i, index) => (
                  <React.Fragment key={index}>
                    <tr className="tr-border-bottom">
                      <td colSpan="6"></td>
                    </tr>
                    <tr className="custom-table-head-td">
                      <td className="align-left">{index + 1}</td>
                      <td className="align-center">
                        <img
                          className="profile-img"
                          src={
                            `${i.profilepic}`
                              ? `https://cipl-aimantra.s3.ap-south-1.amazonaws.com/${i.profilepic}`
                              : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                          }
                          alt="profilepic"
                        />
                        <p>{i.empcode}-{i.name}</p>
                      </td>
                      <td className="align-center">{formattedDateLong(i.date)}-{i.in_time}</td>
                      <td className="align-center form-text-trasformation-uppercase">{i.duration}</td>
                      <td className="align-center">{i.out_time}</td>
                      <td className="align-center">{i.work_duration}</td>
                      <td className="align-center">{i.rh_assigned}</td>
                      <td className="align-center form-text-trasformation-uppercase">{i.duration}</td>
                      {/* <td className="align-center">
                        <span
                          request-status={i.rh_leave_status}
                          className="request-status align-center"
                        >
                          {i.rh_leave_status === "pending"
                            ? "Pending For Rh Approval ⌛"
                            : i.rh_leave_status === "rejected"
                              ? "Rh Rejected ✖, "
                              : "Rh Approved ✔, "}
                        </span>
                      </td> */}
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </>
  );
};

const HODNotificationLogTable = () => {
  const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();
  const [checkedItems, setCheckedItems] = useState([]);
  // const [date, setDate] = useState();
  const [buffer, setBuffering] = useState(true); //buffering logic
  const [employeeList, setEmployeeList] = useState([]);
  // const [date, setDate] = useState(`${formattedDate}`);
  const [fromdate, setFromDate] = useState("null");
  const [todate, setToDate] = useState("null");
  const [selectedStatus, setSelectedStatus] = useState("null");
  const [employeeGet, setEmployeeGet] = useState("null");
  const [active, setActive] = useState("active");
  const [selectedDivision, setDivision] = useState(
    `${sessionStorage.getItem("company_id")}`
  );

  const [SubCompany, setSubCompany] = useState([]);

  const getSubCompany = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/subcompany/`);
      setSubCompany(res.data);
    } catch (err) {
      handleErrorToast(err)
    }
  };

  // *********** Employee Details Api Start ***********
  const [leaveLogList, setLeaveLogList] = useState([]);
  const [filteredleaveLogList, setfilteredleaveLogList] = useState([]);

  const getEmpList = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/ourcompanyempdetails/${active}/${selectedDivision}/`
      );
      const sortedList = customSortByKey(res.data, "emp_code");
      const sortedData = customSortByKey(res.data, "emp_code");

      setEmployeeList(sortedData);
    } catch (err) {
      handleErrorToast(err)
    }
  };

  const getLeaveLogList = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/shortandcompoff/${fromdate}/${todate}/null/${selectedStatus}/${employeeGet}/${selectedDivision}/${active}/`
      );
      setLeaveLogList(res.data);
      // setOutdutyName(res.data);
    } catch (err) {
      handleErrorToast(err);
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };

  // *********** Employee Details Api End ***********

  useEffect(() => {
    getLeaveLogList();
    getEmpList();
    getSubCompany();
  }, [employeeGet, fromdate, todate, selectedStatus, active, selectedDivision]);

  useEffect(() => {
  }, []);

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div className="attendance-history-cont">
        <div className="attendance-heading  font-weight500    font-size-heading"> </div>
        <div className="attendance-subcont">
          <div className="field-cont">


            <div className="field-cont-div">
              <div className="date-uppercase ">
                <input
                  className="attendance-input-field width-10vw   date-field date-field-filter"
                  placeholder="From Date"
                  type="date"
                  value={fromdate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </div>
              <hr className="field-cont-hr" />

            </div>

            <div className="field-cont-div">
              <div className=" date-uppercase ">
                <input
                  className="attendance-input-field width-10vw   date-field date-field-filter"
                  placeholder="From Date"
                  type="date"
                  value={todate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </div>
              <hr className="field-cont-hr" />

            </div>

            <div className="field-cont-div">
              <Filter />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                <option value="null">All</option>

                <option value="pending">Pending</option>
                <option value="approved">Approved</option>
                <option value="rejected">Rejected</option>
              </select>
              <hr className="field-cont-hr" />

            </div>

            <div className="field-cont-div">
              <CircularGrid />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={employeeGet}
                onChange={(e) => setEmployeeGet(e.target.value)}
              >
                <option value="">Select Employee Code</option>
                {employeeList
                  .sort((a, b) => a.emp_code - b.emp_code)
                  .map((i, index) => (
                    <option value={i.emp_code}>
                      {i.emp_code} - {i.name}
                    </option>
                  ))}
                ;
              </select>

              <hr className="field-cont-hr" />

            </div>

            <div className="field-cont-div">
              <Active />
              <select
                className="attendance-input-field width-10vw  "
                type="text"
                value={active}
                onChange={(e) => setActive(e.target.value)}
              >
                <option value="null">All</option>
                <option value="active">Active</option>
                <option value="inactive">In Active</option>
              </select>
            </div>

          </div>

          <div className="btn-cont"></div>
        </div>

        <div className="table-css-white-background">
          <div className="table-heading-flex">
            <div className="repo-heading font-weight500    font-size-heading">Employee Notification Logs </div>
          </div>
          <table className="table-css">
            <thead>
              <tr className="custom-table-head-tr">
                <th className="align-left">Sr.no</th>
                <th className="align-center">Empolyee Name</th>
                <th className="align-center">Date-Time</th>
                <th className="align-center">Leave Type</th>
                <th className="align-center">Balance Before</th>
                <th className="align-center">Leaves affected</th>
                <th className="align-center">Balance After</th>
                <th className="align-center width-20vw">Description</th>
              </tr>
            </thead>
            {buffer ? (
              <div className="spinner"></div>
            ) : (
              <tbody>
                {leaveLogList.map((i, index) => (
                  <React.Fragment key={index}>
                    <tr className="tr-border-bottom">
                      <td colSpan="6"></td>
                    </tr>
                    <tr className="custom-table-head-td">
                      <td className="align-left">{index + 1}</td>
                      <td className="align-center">
                        <img
                          className="profile-img"
                          src={
                            `${i.profilepic}`
                              ? `https://cipl-aimantra.s3.ap-south-1.amazonaws.com/${i.profilepic}`
                              : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                          }
                          alt="profilepic"
                        />
                        <p>{i.empcode}-{i.name}</p>
                      </td>
                      <td className="align-center">{formattedDateLong(i.date)}-{i.in_time}</td>
                      <td className="align-center form-text-trasformation-uppercase">{i.duration}</td>
                      <td className="align-center">{i.out_time}</td>
                      <td className="align-center">{i.work_duration}</td>
                      <td className="align-center">{i.rh_assigned}</td>
                      <td className="align-center form-text-trasformation-uppercase">{i.duration}</td>
                      {/* <td className="align-center">
                        <span
                          request-status={i.rh_leave_status}
                          className="request-status align-center"
                        >
                          {i.rh_leave_status === "pending"
                            ? "Pending For Rh Approval ⌛"
                            : i.rh_leave_status === "rejected"
                              ? "Rh Rejected ✖, "
                              : "Rh Approved ✔, "}
                        </span>
                      </td> */}
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </>
  );
};



const RequestImpresttableAdmin = () => {
  const { PERMISSION_HR_ADMIN, PERMISSION_AUTHORITY } = usePermission();
  // *********** Employee Details Api Start ***********

  const [buffer, setBuffering] = useState(true); //buffering logic

  const [allemployee, setAllemployee] = useState([]);
  const [outdutyname, setOutdutyName] = useState([]);

  const [selectedStatus, setSelectedStatus] = useState("pending");

  const [employeeGet, setEmployeeGet] = useState("null");
  const [fromdate, setFromDate] = useState("null");
  const [todate, setToDate] = useState("null");

  const [selectedDivision, setDivision] = useState(
    `${sessionStorage.getItem("company_id")}`
  );

  const [active, setActive] = useState("active");

  const [SubCompany, setSubCompany] = useState([]);

  const getSubCompany = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(`${BASE_URL}/wfm/subcompany/`);
      setSubCompany(res.data);
    } catch (err) {
      handleErrorToast(err);
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };

  //! ******************************API start***************************//
  const getimprestname = async () => {
    try {
      setBuffering(true); //buffering logic // Start Buffering
      const res = await axios.get(
        `${BASE_URL}/wfm/imprestbyadmin/${selectedStatus}/${fromdate}/${todate}/${employeeGet}/${selectedDivision}/${active}/`
      );
      setOutdutyName(res.data);
    } catch (err) {
      handleErrorToast(err);
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  useEffect(() => {
    getSubCompany();
    getimprestname();
  }, [fromdate, todate, selectedStatus, employeeGet, selectedDivision]);

  const getEmpcode = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/ourcompanyempdetails/${active}/${selectedDivision}/`
      );
      const sortedList = customSortByKey(res.data, "emp_code");
      setAllemployee(sortedList);
    } catch (err) {
      handleErrorToast(err);
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };

  useEffect(() => {
    getEmpcode();
  }, [active, selectedDivision]);
  //! *********** Employee Details Api End ***********

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div className="attendance-history-cont">
        <div className="attendance-heading  font-weight500    font-size-heading"> </div>
        <div className="attendance-subcont">
          <div className="field-cont">
            <div className="field-cont-div">
              <div className=" date-uppercase ">
                <input
                  className="attendance-input-field width-10vw   date-field date-field-filter"
                  placeholder="From Date"
                  type="date"
                  value={fromdate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </div>

              <hr className="field-cont-hr" />
            </div>
            <div className="field-cont-div">
              <div className="date-uppercase ">
                <input
                  className="attendance-input-field width-10vw   date-field date-field-filter"
                  placeholder="From Date"
                  type="date"
                  value={todate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </div>
              <hr className="field-cont-hr" />
            </div>
            <div className="field-cont-div">
              <Filter />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                <option value="null">All</option>

                <option value="pending">Pending</option>
                <option value="approved">Approved</option>
                <option value="rejected">Rejected</option>
              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Employee />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={employeeGet}
                onChange={(e) => setEmployeeGet(e.target.value)}
              >
                <option value="null">Select Employee Code </option>
                {allemployee
                  .sort((a, b) => a.emp_code - b.emp_code)
                  .map((i, index) => (
                    <option value={i.emp_code}>
                      {i.emp_code} - {i.name}
                    </option>
                  ))}
                ;
              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Office />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedDivision}
                onChange={(e) => setDivision(e.target.value)}
              >
                <option value="null"> All Sub Companies</option>
                {SubCompany.sort((a, b) => a.title - b.title).map(
                  (i, index) => (
                    <option value={i.id}>{i.title}</option>
                  )
                )}
                ;
              </select>

              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Active />
              <select
                className="attendance-input-field width-10vw  "
                type="text"
                value={active}
                onChange={(e) => setActive(e.target.value)}
              >
                <option value="null">All</option>
                <option value="active">Active</option>
                <option value="inactive">In Active</option>
              </select>
            </div>
          </div>
          <div className="btn-cont"></div>
        </div>

        <div className="table-css-white-background">
          <div className="table-heading-flex">
            {/* <div className="repo-heading font-weight500    font-size-heading">Imprest </div> */}
          </div>
          <table className="table-css">
            <thead>
              <tr className="custom-table-head-tr">
                <th className="table-heading">S. No.</th>
                <th className="table-heading" style={{ textAlign: "center" }}>Employee</th>
                <th className="table-heading">Imprest Date</th>
                <th className="table-heading">Dates</th>
                <th className="table-heading width-5vw">Imprest Name</th>
                <th className="table-heading" style={{ textAlign: "center" }}>Total Amount</th>
                <th className="table-heading">Days And Employee Count</th>
                <th className="table-heading" style={{ textAlign: "center" }}>Person Name</th>
                <th className="table-heading">Remarks</th>
                <th className="table-heading">Status</th>
                <th className="table-heading width-5vw" style={{ textAlign: "center" }}>Action</th>
              </tr>
            </thead>
            {buffer ? <div className="spinner"></div> : //buffering logic
              <tbody>
                {outdutyname
                  .sort((a, b) => {
                    const dateA = a.admin_update_datetime ? new Date(a.admin_update_datetime) : null;
                    const dateB = b.admin_update_datetime ? new Date(b.admin_update_datetime) : null;
                    if (dateA && dateB) {
                      return dateB - dateA;
                    }
                    if (dateA) return -1;
                    if (dateB) return 1;
                    return 0;
                  })
                  .map((i, index) => (
                    <React.Fragment key={index}>
                      <tr className="tr-border-bottom">
                        <td colSpan="6"></td>
                      </tr>
                      <tr className="custom-table-head-td" title={`Admin Status : ${i.admin_leave_status}`}>
                        <td className="table-body">{index + 1}</td>

                        <td className="table-body" style={{ textAlign: "center" }}>
                          <img
                            className="profile-img"
                            src={
                              `${i.profilepic}`
                                ? `${IMAGE_URL}${i.profilepic}`
                                : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                            }
                            alt="profilepic"
                          />
                          <p>{i.empcode} {i.imprest_name}</p>
                        </td>

                        <td className="table-body">{formatDate(i.date)}</td>

                        <td title="Approval Dates" className="table-body width-10vw">
                          {i.rh_leave_status === "approved" ?
                            <span
                              request-status={i.rh_leave_status}
                              className="request-status "
                            >
                              Rh Approved
                              {i.rh_update_datetime ? <><br />At: {formatDateTime(i.rh_update_datetime).date}</> : ''}
                            </span>
                            : ""}
                          <br />
                          <br />
                          {i.admin_leave_status === "approved" ?
                            <span
                              request-status={i.admin_leave_status}
                              className="request-status "
                            >
                              Admin {i.admin_update_by ? `(${i.admin_update_by})` : ''} Approved
                              {i.admin_update_datetime ? <><br />At: {formatDateTime(i.admin_update_datetime).date}</> : ''}
                            </span>
                            : ""}
                          <br />
                          <br />
                          {i.account_leave_status === "approved" ?
                            <span
                              request-status={i.account_leave_status}
                              className="request-status "
                            >
                              Accounts{i.account_update_by ? `(${i.account_update_by})` : ''} Approved
                              {i.account_update_datetime ? <><br />At: {formatDateTime(i.account_update_datetime).date}</> : ''}
                            </span>
                            : ""}
                        </td>

                        <td className="table-body">{i.project_name}</td>

                        {i.rh_leave_status === "approved" ?
                          <td
                            className="align-leftt"
                            title={`Reason For Change : \n${i.amount_change_reason}`}
                          >
                            <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                              <tbody>
                                <tr>
                                  <td className="table-body" style={{ paddingBottom: "5px" }}>Approved Transportation</td>
                                  <td style={{ padding: "0px 4px" }}>:</td>
                                  <td className="align-right">
                                    {formatCurrencyIndian(i.approved_transportation || 0)}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="table-body" style={{ paddingBottom: "5px" }}>Approved Accommodation</td>
                                  <td style={{ padding: "0px 4px" }}>:</td>
                                  <td className="align-right">
                                    {formatCurrencyIndian(i.approved_accomodation || 0)}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="table-body" style={{ paddingBottom: "5px" }}>Approved Food</td>
                                  <td style={{ padding: "0px 4px" }}>:</td>
                                  <td className="align-right">
                                    {formatCurrencyIndian(i.approved_fooding || 0)}
                                  </td>
                                </tr>
                                <tr>
                                  <b>
                                    <td className="table-body" style={{ paddingBottom: "5px" }}>Approved Others</td>
                                  </b>
                                  <td style={{ padding: "0px 4px" }}>:</td>
                                  <td className="align-right table-body" style={{ paddingBottom: "5px" }}>
                                    <b>
                                      {formatCurrencyIndian(
                                        i.other_details?.reduce(
                                          (sum, other) =>
                                            sum + (parseFloat(other.approved_other) || 0),
                                          0
                                        )
                                      )}
                                    </b>
                                  </td>
                                </tr>
                                {i.other_details?.length > 0 ? (
                                  <>
                                    {i.other_details.map((other, index) => (
                                      <tr key={index}>
                                        <td className="table-body" style={{ paddingLeft: '5px', paddingBottom: "5px" }}>
                                          {other.other_name
                                            ? other.other_name
                                            : `Other-${index + 1}`}
                                        </td>
                                        <td style={{ padding: "0px 4px" }}>:</td>
                                        <td className="align-right" style={{ paddingBottom: "5px" }}>
                                          {formatCurrencyIndian(other.approved_other || 0)}
                                        </td>
                                      </tr>
                                    ))}
                                  </>
                                ) : (
                                  <tr>
                                    <td style={{ paddingBottom: "5px", paddingLeft: "5px" }} colSpan={3}>No Other Amount</td>
                                  </tr>
                                )}
                                <tr>
                                  <td>
                                    <b>Total</b>
                                  </td>
                                  <td style={{ padding: "0px 4px" }}>:</td>
                                  <td className="align-right">
                                    <b>
                                      {formatCurrencyIndian(
                                        Number(i.approved_transportation) +
                                        Number(i.approved_accomodation) +
                                        Number(i.approved_fooding) +
                                        (i.other_details?.length > 0
                                          ? i.other_details.reduce(
                                            (total, other) =>
                                              total + Number(other.approved_other || 0),
                                            0
                                          )
                                          : 0)
                                      )}
                                    </b>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                          :
                          <td className="table-body">
                            <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                              <tbody>
                                <tr>
                                  <td className="table-body" style={{ paddingBottom: "5px" }}>Transportation</td>
                                  <td style={{ padding: "0px 4px" }}>:</td>
                                  <td className="align-right">{formatCurrencyIndian(i.transportation)}</td>
                                </tr>
                                <tr>
                                  <td className="table-body" style={{ paddingBottom: "5px" }}>Accommodation</td>
                                  <td style={{ padding: "0px 4px" }}>:</td>
                                  <td className="align-right">{formatCurrencyIndian(i.accomodation)}</td>
                                </tr>
                                <tr>
                                  <td className="table-body" style={{ paddingBottom: "5px" }}>Food</td>
                                  <td style={{ padding: "0px 4px" }}>:</td>
                                  <td className="align-right">{formatCurrencyIndian(i.fooding)}</td>
                                </tr>
                                <tr>
                                  <b>
                                    <td className="table-body" style={{ paddingBottom: "5px" }}>Others</td>
                                  </b>
                                  <td style={{ padding: "0px 4px" }}>
                                    :
                                  </td>
                                  <td className="align-right">
                                    <b>
                                      {i.other_details
                                        .reduce((sum, other) => sum + (parseFloat(other.amount) || 0), 0)
                                        .toLocaleString()}
                                    </b>
                                  </td>
                                </tr>
                                {i.other_details?.length > 0 ? (
                                  <>
                                    {i.other_details.map((other, index) => (
                                      <>
                                        <tr>
                                          <td style={{ paddingLeft: "5px", paddingBottom: "5px" }} className="table-body" >
                                            {other.other_name ? other.other_name : `Other-${index + 1}`}
                                          </td>
                                          <td style={{ padding: "0px 4px" }}>:</td>

                                          <td className="align-right">
                                            {formatCurrencyIndian(other.amount || 0)}
                                          </td>
                                        </tr>
                                      </>
                                    ))}
                                  </>
                                ) : (
                                  <td style={{ paddingBottom: "5px", paddingLeft: "5px" }}>
                                    No Other Amount
                                  </td>
                                )}
                                <tr>
                                  <td className="table-body" style={{ paddingBottom: "5px" }} >
                                    <b>Total </b>
                                  </td>
                                  <td style={{ padding: "0px 4px" }}>:</td>
                                  <td className="align-right">
                                    <b>
                                      {formatCurrencyIndian(
                                        Number(i.transportation) +
                                        Number(i.accomodation) +
                                        Number(i.fooding) +
                                        (i.other_details?.length > 0
                                          ? i.other_details.reduce((total, other) => total + Number(other.amount || 0), 0)
                                          : 0)
                                      )}
                                    </b>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>}
                        <td className="table-body">For <b>{i.no_of_days}</b> Days with <br /> <b>{i.person}</b> {i.person > 1 ? "people." : "person."}</td>
                        <td className="table-body">
                          <ul style={{ paddingLeft: "20px" }}>
                            {i.multiple_person_details.map((items) => {
                              return (
                                <li>
                                  {items.emp_code} - {items.name}
                                  <br />
                                </li>
                              );
                            })}
                          </ul>
                        </td>
                        <td className="table-body">{i.remarks}</td>
                        <td className="table-body"  >
                          <span
                            request-status={i.rh_leave_status}
                            className="request-status"
                            title={`${i.rh_leave_status === "rejected" ? `TL Reason For Rejection : \n${i.rejection_reason}` : ''}`}
                          >
                            {i.rh_leave_status === "pending"
                              ? "Pending For Rh Approval ⌛"
                              : i.rh_leave_status === "rejected"
                                ? <>Rh ✖,<br />Rh Reject Reason:<br />"{i.rejection_reason}"</>
                                : "Approved By Rh ✔,"}
                          </span>
                          <br />
                          <span
                            request-status={i.admin_leave_status}
                            className="request-status"
                            title={`${i.admin_leave_status === "rejected" ? `Admin Reason: ${i.admin_rejection_reason}` : ''}`}
                          >
                            {i.admin_leave_status === "rejected"
                              ? <>Admin ✖,<br />Admin Reject Reason: "{i.admin_rejection_reason}"</>
                              :
                              i.admin_leave_status === "pending"
                                ? "Pending For Admin Approval ⌛,"
                                :
                                i.admin_leave_status === "approved"
                                  ? "Approved By Admin ✔,"
                                  : "Admin Status: " + i.admin_leave_status}
                          </span>
                          <br />
                          <span
                            request-status={i.account_leave_status}
                            className="request-status"
                            title={`${i.account_leave_status === "rejected" ? `Account Reason: ${i.account_rejection_reason}` : ''}`}
                          >
                            {i.account_leave_status === "rejected"
                              ? <>Accounts ✖,<br />Accounts Reject Reason:<br />"{i.account_rejection_reason}"</>
                              : i.account_leave_status === "pending"
                                ? "Pending For Account Approval ⌛"
                                :
                                i.account_leave_status === "approved"
                                  ? "Approved By Accounts ✔,"
                                  : "Accounts Status : " + i.account_leave_status}
                          </span>
                        </td>
                        <td className="table-body">
                          <div className="flex-row">
                            <>
                              {PERMISSION_AUTHORITY || PERMISSION_HR_ADMIN ? (
                                i.admin_leave_status === "approved" ? (
                                  <></>
                                ) : (
                                  <AdminapprovedHR
                                    i={i}
                                    getImprestname={getimprestname}
                                  />
                                )
                              ) : (
                                <></>
                              )}
                            </>
                            {/* <RequestOutdutyapproved i={i} getimprestname={getimprestname} /> */}
                            <>
                              {PERMISSION_AUTHORITY || PERMISSION_HR_ADMIN ? (
                                i.admin_leave_status === "rejected" ? (
                                  <></>
                                ) : (
                                  <AdminDeleteHR
                                    i={i}
                                    getImprestname={getimprestname}
                                  />
                                )
                              ) : (
                                <></>
                              )}
                            </>
                          </div>
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
              </tbody>}
          </table>
        </div>
      </div>
    </>
  );
};

const RequestExpensetableAdmin = () => {
  const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();
  // *********** Employee Details Api Start ***********
  const [allemployee, setAllemployee] = useState([]);
  const [outdutyname, setOutdutyName] = useState([]);
  const [buffer, setBuffering] = useState(true); //buffering logic

  const [selectedStatus, setSelectedStatus] = useState("pending");

  const [employeeGet, setEmployeeGet] = useState("null");
  const [fromdate, setFromDate] = useState("null");
  const [todate, setToDate] = useState("null");

  const [selectedDivision, setDivision] = useState(
    `${sessionStorage.getItem("company_id")}`
  );

  const [active, setActive] = useState("active");

  const [SubCompany, setSubCompany] = useState([]);

  const getSubCompany = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(`${BASE_URL}/wfm/subcompany/`);
      setSubCompany(res.data);
    } catch (err) {
      handleErrorToast(err);
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };

  //! ******************************API start***************************//
  const getexpansename = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      // const res = await axios.get(`${BASE_URL}/wfm/expense/${selectedStatus}/${fromdate}/${todate}/${employeeGet}/`);
      const res = await axios.get(
        `${BASE_URL}/wfm/expensebyadmin/${selectedStatus}/${fromdate}/${todate}/${employeeGet}/${selectedDivision}/${active}/`
      );
      setOutdutyName(res.data);
    } catch (err) {
      handleErrorToast(err);
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  useEffect(() => {
    getexpansename();
    getSubCompany();
  }, [fromdate, todate, selectedStatus, employeeGet, selectedDivision]);

  const getEmpcode = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/ourcompanyempdetails/${active}/${selectedDivision}/`
      );
      const sortedList = customSortByKey(res.data, "emp_code");
      setAllemployee(sortedList);
    } catch (err) {
      handleErrorToast(err);
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };

  useEffect(() => {
    getEmpcode();
  }, [active, selectedDivision]);
  //! *********** Employee Details Api End ***********

  const navigate = useNavigate();
  const currentUrl = window.location.href;
  const urlParts = currentUrl.split("/");
  const baseUrl = "/" + urlParts[3] + `/requestExpenseSlip/`;
  const opener = "admin"
  const openExenseSlip = (i) => {
    navigate(baseUrl + i.id + '/', { state: { i, opener } });
  };
  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div className="attendance-history-cont">
        <div className="attendance-heading  font-weight500    font-size-heading"> </div>
        <div className="attendance-subcont">
          <div className="field-cont">
            <div className="field-cont-div">
              <div className="date-uppercase ">
                <input
                  className="attendance-input-field width-10vw   date-field date-field-filter"
                  placeholder="From Date"
                  type="date"
                  value={fromdate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </div>

              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <div className="date-uppercase ">
                <input
                  className="attendance-input-field width-10vw   date-field date-field-filter"
                  placeholder="From Date"
                  type="date"
                  value={todate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </div>
              <hr className="field-cont-hr" />
            </div>
            <div className="field-cont-div">
              <Filter />

              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                <option value="null">All</option>

                <option value="pending">Pending</option>
                <option value="approved">Approved</option>
                <option value="rejected">Rejected</option>
              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Employee />
              <select
                className="attendance-input-field width-15vw   date-field"
                type="text"
                value={employeeGet}
                onChange={(e) => setEmployeeGet(e.target.value)}
              >
                <option value="null">Select Employee Code </option>
                {allemployee
                  .sort((a, b) => a.emp_code - b.emp_code)
                  .map((i, index) => (
                    <option value={i.emp_code}>
                      {i.emp_code} - {i.name}
                    </option>
                  ))}
                ;
              </select>
              <hr className="field-cont-hr" />
            </div>
            <div className="field-cont-div">
              <Office />
              <select
                className="attendance-input-field width-10vw date-field"
                type="text"
                value={selectedDivision}
                onChange={(e) => setDivision(e.target.value)}
              >
                <option value="null">All Sub Companies</option>
                {SubCompany.sort((a, b) => a.title - b.title).map(
                  (i, index) => (
                    <option value={i.id}>{i.title}</option>
                  )
                )}
                ;
              </select>

              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Active />
              <select
                className="attendance-input-field width-10vw  "
                type="text"
                value={active}
                onChange={(e) => setActive(e.target.value)}
              >
                <option value="null">All</option>
                <option value="active">Active</option>
                <option value="inactive">In Active</option>
              </select>
            </div>
          </div>
          <div className="btn-cont"></div>
        </div>

        <div className="table-css-white-background">
          <div className="table-heading-flex">
            {/* <div className="repo-heading font-weight500    font-size-heading">Expense</div> */}
            <div className="flex-row">
            </div>
          </div>
          <table className="table-css">
            <thead>
              <tr className="custom-table-head-tr">
                <th className="table-heading">S. No.</th>
                <th className="table-heading">Date</th>
                <th className="table-heading">Expense <b>Against</b> Imprest</th>
                <th className="table-heading" style={{ textAlign: "center" }}>Imprest Amount</th>
                <th className="table-heading">Utilized Money</th>
                <th className="table-heading">Remaining Money</th>
                <th className="table-heading">Status</th>
                <th className="table-heading width-15vw">Remarks</th>
                <th className="table-heading">View/Action</th>
              </tr>
            </thead>
            {buffer ? <div className="spinner"></div> : //buffering logic
              <tbody>
                {outdutyname.map((i, index) => (
                  <React.Fragment key={index}>
                    <tr className="tr-border-bottom">
                      <td colSpan="8"></td>
                    </tr>
                    <tr className="custom-table-head-td">
                      <td className="table-body">{index + 1}</td>
                      <td className="table-body">{formatDate(i.date)}</td>
                      <td className="table-body width-5vw"><span>{i.name}</span>
                        <br />
                        <b> Against </b>
                        <br />
                        <span>{i.project_name}</span>
                      </td>
                      <td className="table-body">
                        <table style={{ borderCollapse: "collapse", width: "100%" }}>
                          <tbody>
                            <tr>
                              <td className="table-body" style={{ paddingBottom: "5px" }}>Transportation</td>
                              <td style={{ padding: "0px 4px" }}>:</td>
                              <td className="align-right">
                                {formatCurrencyIndian(i.approved_transportation || 0)}
                              </td>
                            </tr>
                            <tr>
                              <td className="table-body" style={{ paddingBottom: "5px" }}>Accommodation</td>
                              <td style={{ padding: "0px 4px" }}>:</td>
                              <td className="align-right">
                                {formatCurrencyIndian(i.approved_accomodation || 0)}
                              </td>
                            </tr>
                            <tr>
                              <td className="table-body" style={{ paddingBottom: "5px" }}>Food</td>
                              <td style={{ padding: "0px 4px" }}>:</td>
                              <td className="align-right">
                                {formatCurrencyIndian(i.approved_fooding || 0)}
                              </td>
                            </tr>
                            <tr>
                              <b>
                                <td className="table-body" style={{ paddingBottom: "5px" }}>Others</td>
                              </b>
                              <td style={{ padding: "0px 4px" }}>:</td>
                              <td className="align-right">
                                <b>
                                  {formatCurrencyIndian(
                                    i.other_details?.reduce(
                                      (sum, other) => sum + Number(other.approved_other || 0),
                                      0
                                    )
                                  )}
                                </b>
                              </td>
                            </tr>
                            {i.other_details?.length > 0 ? (
                              <>
                                {i.other_details.map((other, index) => (
                                  <tr key={index}>
                                    <td className="table-body" style={{ paddingLeft: "5px", paddingBottom: "5px" }} >
                                      {other.other_name ? other.other_name : `Other-${index + 1}`}
                                    </td>
                                    <td style={{ padding: "0px 4px" }}>:</td>
                                    <td className="align-right">
                                      {formatCurrencyIndian(other.approved_other || 0)}
                                    </td>
                                  </tr>
                                ))}
                              </>
                            ) : (
                              <tr>
                                <td style={{ padding: "0px 4px", paddingBottom: "5px" }} colSpan={3}>No Other Expense</td>
                              </tr>
                            )}
                            <tr>
                              <td className="table-body" style={{ paddingBottom: "5px" }}>
                                <b>Total</b>
                              </td>
                              <td style={{ padding: "0px 4px" }}>:</td>
                              <td className="align-right">
                                <b>
                                  {formatCurrencyIndian(
                                    Number(i.approved_transportation) +
                                    Number(i.approved_accomodation) +
                                    Number(i.approved_fooding) +
                                    (i.other_details?.length > 0
                                      ? i.other_details.reduce(
                                        (total, other) => total + Number(other.approved_other || 0),
                                        0
                                      )
                                      : 0)
                                  )}
                                </b>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td className="table-body align-center">{i.utilized_amount ? formatRoundoff2D(i.utilized_amount) : '-'} Rs</td>
                      <td className="table-body align-center">{i.remaining_amount ? formatRoundoff2D(i.remaining_amount) : '-'} Rs</td>
                      <td className="table-body">
                        <span
                          request-status={i.rh_status}
                          className="request-status align-center"
                          title={i.rh_status === "rejected" ? `Rh Reject Reason : ${i.rh_rejection_reason}` : ""}
                        >
                          {i.rh_status === "pending"
                            ? "Pending For Rh Approval ⌛"
                            : i.rh_status === "rejected"
                              ? "Rh ✖, "
                              : "Rh ✔, "}
                        </span>
                        <br />
                        <span
                          request-status={i.account_status_a}
                          className="request-status align-center"
                          title={i.account_status_a === "rejected" ? `Account Reject Reason : ${i.account1_rejection_reason}` : ""}
                        >
                          {i.rh_status === "rejected" &&
                            i.admin_status === "rejected" &&
                            i.account_status_a === "rejected"
                            ? "Account ✖, "
                            : i.rh_status === "approved" &&
                              i.admin_status === "approved" &&
                              i.account_status_a === "pending"
                              ? "Pending For Account Approval ⌛"
                              : i.rh_status === "approved" &&
                                i.admin_status === "approved" &&
                                i.account_status_a === "rejected"
                                ? "Accounts ✖, "
                                : i.rh_status === "approved" &&
                                  i.admin_status === "approved" &&
                                  i.account_status_a === "approved"
                                  ? "Accounts ✔, "
                                  : "Accounts Stage 1: " + i.account_status_a}
                        </span>
                        <br />
                        <span
                          request-status={i.admin_status}
                          className="request-status align-center"
                          title={i.admin_status === "rejected" ? `Admin Reject Reason : ${i.admin_rejection_reason}` : ""}
                        >
                          {i.rh_status === "rejected" &&
                            i.admin_status === "rejected"
                            ? "Admin ✖, "
                            : i.rh_status === "approved" &&
                              i.admin_status === "pending"
                              ? "Pending For Admin Approval ⌛,"
                              : i.rh_status === "approved" &&
                                i.admin_status === "rejected"
                                ? "Admin ✖, "
                                : i.rh_status === "approved" &&
                                  i.admin_status === "approved"
                                  ? "Admin ✔, "
                                  : "Admin Status: " + i.admin_status}
                        </span>
                        <br />
                        <span
                          request-status={i.account_status_b}
                          className="request-status align-center"
                        >
                          {i.rh_status === "rejected" &&
                            i.admin_status === "rejected" &&
                            i.account_status_b === "rejected"
                            ? "Account ✖, "
                            : i.rh_status === "approved" &&
                              i.admin_status === "approved" &&
                              i.account_status_b === "pending"
                              ? "Pending For Account Approval ⌛"
                              : i.rh_status === "approved" &&
                                i.admin_status === "approved" &&
                                i.account_status_b === "rejected"
                                ? "Accounts ✖, "
                                : i.rh_status === "approved" &&
                                  i.admin_status === "approved" &&
                                  i.account_status_b === "approved"
                                  ? "Accounts ✔, "
                                  : "Accounts Stage 2: " + i.account_status_b}
                        </span>
                      </td>
                      <td title="Remarks and Approval Dates" className="table-body">
                        <span
                          request-status={i.rh_status}
                          className="request-status "
                        >
                          {i.rh_status === "pending"
                            ? "-"
                            : i.rh_status === "rejected"
                              ? `Rh Reject Reason : ${i.rh_rejection_reason}`
                              : `Rh${i.rh_assigned ? `(${i.rh_assigned}) ` : ''}: Verified Amounts for Bills ${i.rh_update_datetime ? `At: ${formatDateTime(i.rh_update_datetime).date}` : ''}`}
                        </span>
                        <br />
                        <span
                          request-status={i.account_status_a}
                          className="request-status "
                        >
                          {i.account_status_a === "pending"
                            ? "-"
                            : i.account_status_a === "rejected"
                              ? `Accounts Reject Reason : ${i.account1_rejection_reason}`
                              : `Accounts${i.account_update_by ? `(${i.account_update_by}) ` : ''}: Bills Verified ${i.account_update_datetime ? `At: ${formatDateTime(i.account_update_datetime).date}` : ''}`}
                        </span>
                        <br />
                        <span
                          request-status={i.admin_status}
                          className="request-status "
                          title={i.admin_status === "rejected" ? `Admin Reject Reason : ${i.admin_rejection_reason}` : ""}
                        >
                          {i.admin_status === "pending"
                            ? "-"
                            : i.admin_status === "rejected"
                              ? `Admin Rejection Reason : ${i.admin_rejection_reason}`
                              : `Admin${i.admin_update_by ? `(${i.admin_update_by}) ` : ''}: Approved After Final Verification  ${i.admin_update_datetime ? `At: ${formatDateTime(i.admin_update_datetime).date}` : ''}`}
                        </span>
                        <br />
                        <span
                          request-status={i.account_status_b}
                          className="request-status "
                        >
                          {i.account_status_b === "pending"
                            ? "-"
                            : i.account_status_b === "rejected"
                              ? `Final Rejection Reason : ${i.account2_rejection_reason}`
                              : `Final${i.final_update_by ? `(${i.final_update_by}) ` : ''}: Approved And Settled ${i.final_update_datetime ? `At: ${formatDateTime(i.final_update_datetime).date}` : ''}`}
                        </span>
                      </td>
                      <td className="table-body align-center">
                        <button
                          className="allproject-dashboard"
                          title="View using Navigate"
                          onClick={(e) => openExenseSlip(i)}
                        >
                          <Eye />
                        </button>
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>}
          </table>
        </div>
      </div>
    </>
  );
};

const RequestImpresttableAcc = () => {
  // *********** Employee Details Api Start ***********
  const [allemployee, setAllemployee] = useState([]);
  const [outdutyname, setOutdutyName] = useState([]);
  const [buffer, setBuffering] = useState(true); //buffering logic

  const [selectedStatus, setSelectedStatus] = useState("pending");

  const [employeeGet, setEmployeeGet] = useState("null");
  const [fromdate, setFromDate] = useState("null");
  const [todate, setToDate] = useState("null");

  //! ******************************API start***************************//
  const getimprestname = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/imprestbyaccount/${selectedStatus}/${fromdate}/${todate}/${employeeGet}/`
      );
      setOutdutyName(res.data);
    } catch (err) {
      handleErrorToast(err);
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  useEffect(() => {
    getimprestname();
  }, [fromdate, todate, selectedStatus, employeeGet]);

  const getEmpcode = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/ourcompanyempdetails/active/${sessionStorage.getItem('company_id')}/`
        // `${BASE_URL}/wfm/ourcompanysRH/${sessionStorage.getItem("email")}/`
      );


      const sortedList = customSortByKey(res.data, "emp_code");

      setAllemployee(sortedList);
      // setAllemployee(res.data);
    } catch (err) {
      handleErrorToast(err);
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  useEffect(() => {
    getEmpcode();
  }, []);
  //! *********** Employee Details Api End ***********

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div className="attendance-history-cont">
        <div className="attendance-heading  font-weight500    font-size-heading"> </div>
        <div className="attendance-subcont">
          <div className="field-cont">
            <div className="field-cont-div">
              <div className=" date-uppercase ">
                <input
                  className="attendance-input-field width-10vw   date-field date-field-filter"
                  placeholder="From Date"
                  type="date"
                  value={fromdate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </div>

              <hr className="field-cont-hr" />
            </div>
            <div className="field-cont-div">
              <div className="date-uppercase ">
                <input
                  className="attendance-input-field width-10vw   date-field date-field-filter"
                  placeholder="From Date"
                  type="date"
                  value={todate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </div>
              <hr className="field-cont-hr" />
            </div>
            <div className="field-cont-div">
              <Filter />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                <option value="null">All</option>

                <option value="pending">Pending</option>
                <option value="approved">Approved</option>
                <option value="rejected">Rejected</option>
              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Employee />
              <select
                className="attendance-input-field width-15vw   date-field"
                type="text"
                value={employeeGet}
                onChange={(e) => setEmployeeGet(e.target.value)}
              >
                <option value="null">Select Employee Code </option>
                {allemployee
                  .sort((a, b) => a.emp_code - b.emp_code)
                  .map((i, index) => (
                    <option value={i.emp_code}>
                      {i.emp_code} - {i.name}
                    </option>
                  ))}
                ;
              </select>
              <hr className="field-cont-hr" />
            </div>
          </div>
          <div className="btn-cont"></div>
        </div>

        <div className="table-css-white-background">
          {/* <div className="table-heading-flex">
            <div className="repo-heading font-weight500    font-size-heading">Imprest </div>
          </div> */}
          <table className="table-css">
            <thead>
              <tr className="custom-table-head-tr">
                <th className="table-heading">S. No.</th>
                <th className="table-heading" style={{ textAlign: "center" }}>Employee</th>
                <th className="table-heading">Imprest Date</th>
                <th className="table-heading">Dates</th>
                <th className="table-heading width-5vw">Imprest Name</th>
                <th className="table-heading" style={{ textAlign: "center" }}>Total Amount</th>
                <th className="table-heading">Days And Employee Count</th>
                <th className="table-heading" style={{ textAlign: "center" }}>Person Name</th>
                <th className="table-heading">Remarks</th>
                <th className="table-heading">Status</th>
                <th className="table-heading width-5vw" style={{ textAlign: "center" }}>Action</th>
              </tr>
            </thead>
            {buffer ? <div className="spinner"></div> : //buffering logic
              <tbody>
                {outdutyname
                  .sort((a, b) => {
                    const dateA = a.admin_update_datetime ? new Date(a.admin_update_datetime) : null;
                    const dateB = b.admin_update_datetime ? new Date(b.admin_update_datetime) : null;
                    if (dateA && dateB) {
                      return dateB - dateA;
                    }
                    if (dateA) return -1;
                    if (dateB) return 1;
                    return 0;
                  })
                  .map((i, index) => (
                    <React.Fragment key={index}>
                      <tr className="tr-border-bottom">
                        <td colSpan="6"></td>
                      </tr>
                      <tr className="custom-table-head-td" title={`Accounts Status : ${i.account_leave_status}`}>
                        <td className="table-body">{index + 1}</td>

                        <td className="table-body" style={{ textAlign: "center" }}>
                          <img
                            className="profile-img"
                            src={
                              `${i.profilepic}`
                                ? `${IMAGE_URL}${i.profilepic}`
                                : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                            }
                            alt="profilepic"
                          />
                          <p>{i.empcode} {i.imprest_name}</p>
                        </td>

                        <td className="table-body">{formatDate(i.date)}</td>

                        <td title="Approval Dates" className="table-body width-10vw">
                          {i.rh_leave_status === "approved" ?
                            <span
                              request-status={i.rh_leave_status}
                              className="request-status "
                            >
                              Rh Approved
                              {i.rh_update_datetime ? <><br />At: {formatDateTime(i.rh_update_datetime).date}</> : ''}
                            </span>
                            : ""}
                          <br />
                          <br />
                          {i.admin_leave_status === "approved" ?
                            <span
                              request-status={i.admin_leave_status}
                              className="request-status "
                            >
                              Admin {i.admin_update_by ? `(${i.admin_update_by})` : ''} Approved
                              {i.admin_update_datetime ? <><br />At: {formatDateTime(i.admin_update_datetime).date}</> : ''}
                            </span>
                            : ""}
                          <br />
                          <br />
                          {i.account_leave_status === "approved" ?
                            <span
                              request-status={i.account_leave_status}
                              className="request-status "
                            >
                              Accounts{i.account_update_by ? `(${i.account_update_by})` : ''} Approved
                              {i.account_update_datetime ? <><br />At: {formatDateTime(i.account_update_datetime).date}</> : ''}
                            </span>
                            : ""}
                        </td>

                        <td className="table-body">{i.project_name}</td>

                        {i.rh_leave_status === "approved" ?
                          <td
                            className="align-leftt"
                            title={`Reason For Change : \n${i.amount_change_reason}`}
                          >
                            <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                              <tbody>
                                <tr>
                                  <td className="table-body" style={{ paddingBottom: "5px" }}>Approved Transportation</td>
                                  <td style={{ padding: "0px 4px" }}>:</td>
                                  <td className="align-right">
                                    {formatCurrencyIndian(i.approved_transportation || 0)}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="table-body" style={{ paddingBottom: "5px" }}>Approved Accommodation</td>
                                  <td style={{ padding: "0px 4px" }}>:</td>
                                  <td className="align-right">
                                    {formatCurrencyIndian(i.approved_accomodation || 0)}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="table-body" style={{ paddingBottom: "5px" }}>Approved Food</td>
                                  <td style={{ padding: "0px 4px" }}>:</td>
                                  <td className="align-right">
                                    {formatCurrencyIndian(i.approved_fooding || 0)}
                                  </td>
                                </tr>
                                <tr>
                                  <b>
                                    <td className="table-body" style={{ paddingBottom: "5px" }}>Approved Others</td>
                                  </b>
                                  <td style={{ padding: "0px 4px" }}>:</td>
                                  <td className="align-right table-body" style={{ paddingBottom: "5px" }}>
                                    <b>
                                      {formatCurrencyIndian(
                                        i.other_details?.reduce(
                                          (sum, other) =>
                                            sum + (parseFloat(other.approved_other) || 0),
                                          0
                                        )
                                      )}
                                    </b>
                                  </td>
                                </tr>
                                {i.other_details?.length > 0 ? (
                                  <>
                                    {i.other_details.map((other, index) => (
                                      <tr key={index}>
                                        <td className="table-body" style={{ paddingLeft: '5px', paddingBottom: "5px" }}>
                                          {other.other_name
                                            ? other.other_name
                                            : `Other-${index + 1}`}
                                        </td>
                                        <td style={{ padding: "0px 4px" }}>:</td>
                                        <td className="align-right" style={{ paddingBottom: "5px" }}>
                                          {formatCurrencyIndian(other.approved_other || 0)}
                                        </td>
                                      </tr>
                                    ))}
                                  </>
                                ) : (
                                  <tr>
                                    <td style={{ paddingBottom: "5px", paddingLeft: "5px" }} colSpan={3}>No Other Amount</td>
                                  </tr>
                                )}
                                <tr>
                                  <td>
                                    <b>Total</b>
                                  </td>
                                  <td style={{ padding: "0px 4px" }}>:</td>
                                  <td className="align-right">
                                    <b>
                                      {formatCurrencyIndian(
                                        Number(i.approved_transportation) +
                                        Number(i.approved_accomodation) +
                                        Number(i.approved_fooding) +
                                        (i.other_details?.length > 0
                                          ? i.other_details.reduce(
                                            (total, other) =>
                                              total + Number(other.approved_other || 0),
                                            0
                                          )
                                          : 0)
                                      )}
                                    </b>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                          :
                          <td className="table-body">
                            <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                              <tbody>
                                <tr>
                                  <td className="table-body" style={{ paddingBottom: "5px" }}>Transportation</td>
                                  <td style={{ padding: "0px 4px" }}>:</td>
                                  <td className="align-right">{formatCurrencyIndian(i.transportation)}</td>
                                </tr>
                                <tr>
                                  <td className="table-body" style={{ paddingBottom: "5px" }}>Accommodation</td>
                                  <td style={{ padding: "0px 4px" }}>:</td>
                                  <td className="align-right">{formatCurrencyIndian(i.accomodation)}</td>
                                </tr>
                                <tr>
                                  <td className="table-body" style={{ paddingBottom: "5px" }}>Food</td>
                                  <td style={{ padding: "0px 4px" }}>:</td>
                                  <td className="align-right">{formatCurrencyIndian(i.fooding)}</td>
                                </tr>
                                <tr>
                                  <b>
                                    <td className="table-body" style={{ paddingBottom: "5px" }}>Others</td>
                                  </b>
                                  <td style={{ padding: "0px 4px" }}>
                                    :
                                  </td>
                                  <td className="align-right">
                                    <b>
                                      {i.other_details
                                        .reduce((sum, other) => sum + (parseFloat(other.amount) || 0), 0)
                                        .toLocaleString()}
                                    </b>
                                  </td>
                                </tr>
                                {i.other_details?.length > 0 ? (
                                  <>
                                    {i.other_details.map((other, index) => (
                                      <>
                                        <tr>
                                          <td style={{ paddingLeft: "5px", paddingBottom: "5px" }} className="table-body" >
                                            {other.other_name ? other.other_name : `Other-${index + 1}`}
                                          </td>
                                          <td style={{ padding: "0px 4px" }}>:</td>

                                          <td className="align-right">
                                            {formatCurrencyIndian(other.amount || 0)}
                                          </td>
                                        </tr>
                                      </>
                                    ))}
                                  </>
                                ) : (
                                  <td style={{ paddingBottom: "5px", paddingLeft: "5px" }}>
                                    No Other Amount
                                  </td>
                                )}
                                <tr>
                                  <td className="table-body" style={{ paddingBottom: "5px" }} >
                                    <b>Total </b>
                                  </td>
                                  <td style={{ padding: "0px 4px" }}>:</td>
                                  <td className="align-right">
                                    <b>
                                      {formatCurrencyIndian(
                                        Number(i.transportation) +
                                        Number(i.accomodation) +
                                        Number(i.fooding) +
                                        (i.other_details?.length > 0
                                          ? i.other_details.reduce((total, other) => total + Number(other.amount || 0), 0)
                                          : 0)
                                      )}
                                    </b>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>}
                        <td className="table-body">For <b>{i.no_of_days}</b> Days with <br /> <b>{i.person}</b> {i.person > 1 ? "people." : "person."}</td>
                        <td className="table-body">
                          <ul style={{ paddingLeft: "20px" }}>
                            {i.multiple_person_details.map((items) => {
                              return (
                                <li>
                                  {items.emp_code} - {items.name}
                                  <br />
                                </li>
                              );
                            })}
                          </ul>
                        </td>
                        <td className="table-body">{i.remarks}</td>
                        <td className="table-body"  >
                          <span
                            request-status={i.rh_leave_status}
                            className="request-status"
                            title={`${i.rh_leave_status === "rejected" ? `TL Reason For Rejection : \n${i.rejection_reason}` : ''}`}
                          >
                            {i.rh_leave_status === "pending"
                              ? "Pending For Rh Approval ⌛"
                              : i.rh_leave_status === "rejected"
                                ? <>Rh ✖,<br />Rh Reject Reason:<br />"{i.rejection_reason}"</>
                                : "Approved By Rh ✔,"}
                          </span>
                          <br />
                          <span
                            request-status={i.admin_leave_status}
                            className="request-status"
                            title={`${i.admin_leave_status === "rejected" ? `Admin Reason: ${i.admin_rejection_reason}` : ''}`}
                          >
                            {i.admin_leave_status === "rejected"
                              ? <>Admin ✖,<br />Admin Reject Reason: "{i.admin_rejection_reason}"</>
                              :
                              i.admin_leave_status === "pending"
                                ? "Pending For Admin Approval ⌛,"
                                :
                                i.admin_leave_status === "approved"
                                  ? "Approved By Admin ✔,"
                                  : "Admin Status: " + i.admin_leave_status}
                          </span>
                          <br />
                          <span
                            request-status={i.account_leave_status}
                            className="request-status"
                            title={`${i.account_leave_status === "rejected" ? `Account Reason: ${i.account_rejection_reason}` : ''}`}
                          >
                            {i.account_leave_status === "rejected"
                              ? <>Accounts ✖,<br />Accounts Reject Reason:<br />"{i.account_rejection_reason}"</>
                              : i.account_leave_status === "pending"
                                ? "Pending For Account Approval ⌛"
                                :
                                i.account_leave_status === "approved"
                                  ? "Approved By Accounts ✔,"
                                  : "Accounts Status : " + i.account_leave_status}
                          </span>
                        </td>
                        <td className="table-body">
                          <div className="flex-row ">
                            <>
                              {
                                i.account_leave_status === "approved" ? (
                                  <></>
                                ) : (
                                  <ImprestApprovedAccount
                                    i={i}
                                    getImprestname={getimprestname}
                                  />
                                )
                              }
                            </>
                            <>
                              {
                                i.account_leave_status === "rejected" ? (
                                  <></>
                                ) : (
                                  // <ImprestDeleteAccount
                                  //   i={i}
                                  //   getImprestname={getimprestname}
                                  // />
                                  <></>
                                )
                              }
                            </>
                          </div>
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
              </tbody>}
          </table>
        </div>
      </div>
    </>
  );
};

const RequestExpensetableAccStage1 = () => {
  const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();

  // *********** Employee Details Api Start ***********
  const [allemployee, setAllemployee] = useState([]);
  const [outdutyname, setOutdutyName] = useState([]);
  const [buffer, setBuffering] = useState(true); //buffering logic

  const [selectedStatus, setSelectedStatus] = useState("pending");

  const [employeeGet, setEmployeeGet] = useState("null");
  const [fromdate, setFromDate] = useState("null");
  const [todate, setToDate] = useState("null");

  //! ******************************API start***************************//
  const getexpansename = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      // const res = await axios.get(`${BASE_URL}/wfm/expense/${selectedStatus}/${fromdate}/${todate}/${employeeGet}/`);
      const res = await axios.get(
        `${BASE_URL}/wfm/expensebyaccount_a/${selectedStatus}/${fromdate}/${todate}/${employeeGet}/`
      );
      setOutdutyName(res.data);
    } catch (err) {
      handleErrorToast(err);
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  useEffect(() => {
    getexpansename();
  }, [fromdate, todate, selectedStatus, employeeGet]);

  const getEmpcode = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/ourcompanyempdetails/active/${sessionStorage.getItem('company_id')}/`
        // `${BASE_URL}/wfm/ourcompanysRH/${sessionStorage.getItem("email")}/`
      );


      const sortedList = customSortByKey(res.data, "emp_code");

      setAllemployee(sortedList);
      // setAllemployee(res.data);
    } catch (err) {
      handleErrorToast(err);
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  useEffect(() => {
    getEmpcode();
  }, []);
  //! *********** Employee Details Api End ***********

  const navigate = useNavigate();
  const currentUrl = window.location.href;
  const urlParts = currentUrl.split("/");
  const baseUrl = "/" + urlParts[3] + `/requestExpenseSlip/`;
  const opener = "acc"
  const openExenseSlip = (i) => {
    navigate(baseUrl + i.id + '/', { state: { i, opener } });
  };
  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div className="attendance-history-cont">
        <div className="attendance-heading  font-weight500    font-size-heading"> </div>
        <div className="attendance-subcont">
          <div className="field-cont">
            <div className="field-cont-div">
              <div className="date-uppercase ">
                <input
                  className="attendance-input-field width-10vw   date-field date-field-filter"
                  placeholder="From Date"
                  type="date"
                  value={fromdate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </div>

              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <div className=" date-uppercase ">
                <input
                  className="attendance-input-field width-10vw   date-field date-field-filter"
                  placeholder="From Date"
                  type="date"
                  value={todate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </div>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Filter />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                <option value="null">All</option>

                <option value="pending">Pending</option>
                <option value="approved">Approved</option>
                <option value="rejected">Rejected</option>
              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Employee />

              <select
                className="attendance-input-field width-15vw   date-field"
                type="text"
                value={employeeGet}
                onChange={(e) => setEmployeeGet(e.target.value)}
              >
                <option value="null">Select Employee Code </option>
                {allemployee
                  .sort((a, b) => a.emp_code - b.emp_code)
                  .map((i, index) => (
                    <option value={i.emp_code}>
                      {i.emp_code} - {i.name}
                    </option>
                  ))}
                ;
              </select>
              <hr className="field-cont-hr" />
            </div>
          </div>
          <div className="btn-cont"></div>
        </div>

        <div className="table-css-white-background">
          <div className="table-heading-flex">
            {/* <div className="repo-heading font-weight500 font-size-heading">Expense Requests</div> */}
            <div className="flex-row">
            </div>
          </div>
          <table className="table-css">
            <thead>
              <tr className="custom-table-head-tr">
                <th className="table-heading">S. No.</th>
                <th className="table-heading">Date</th>
                <th className="table-heading">Expense <b>Against</b> Imprest</th>
                <th className="table-heading" style={{ textAlign: "center" }}>Imprest Amount</th>
                <th className="table-heading">Utilized Money</th>
                <th className="table-heading">Remaining Money</th>
                <th className="table-heading">Status</th>
                <th className="table-heading width-15vw">Remarks</th>
                <th className="table-heading">View/Action</th>
              </tr>
            </thead>
            {buffer ? <div className="spinner"></div> : //buffering logic
              <tbody>
                {outdutyname.map((i, index) => (
                  <React.Fragment key={index}>
                    <tr className="tr-border-bottom">
                      <td colSpan="9"></td>
                    </tr>
                    <tr className="custom-table-head-td">
                      <td className="table-body">{index + 1}</td>
                      <td className="table-body">{formatDate(i.date)}</td>
                      <td className="table-body width-10vw"><span>{i.name}</span>
                        <br />
                        <b> Against </b>
                        <br />
                        <span>{i.project_name}</span>
                      </td>
                      <td className="table-body">
                        <table style={{ borderCollapse: "collapse", width: "100%" }}>
                          <tbody>
                            <tr>
                              <td className="table-body" style={{ paddingBottom: "5px" }}>Transportation</td>
                              <td style={{ padding: "0px 4px" }}>:</td>
                              <td className="align-right">
                                {formatCurrencyIndian(i.approved_transportation || 0)}
                              </td>
                            </tr>
                            <tr>
                              <td className="table-body" style={{ paddingBottom: "5px" }}>Accommodation</td>
                              <td style={{ padding: "0px 4px" }}>:</td>
                              <td className="align-right">
                                {formatCurrencyIndian(i.approved_accomodation || 0)}
                              </td>
                            </tr>
                            <tr>
                              <td className="table-body" style={{ paddingBottom: "5px" }}>Food</td>
                              <td style={{ padding: "0px 4px" }}>:</td>
                              <td className="align-right">
                                {formatCurrencyIndian(i.approved_fooding || 0)}
                              </td>
                            </tr>
                            <tr>
                              <b>
                                <td className="table-body" style={{ paddingBottom: "5px" }}>Others</td>
                              </b>
                              <td style={{ padding: "0px 4px" }}>:</td>
                              <td className="align-right">
                                <b>
                                  {formatCurrencyIndian(
                                    i.other_details?.reduce(
                                      (sum, other) => sum + Number(other.approved_other || 0),
                                      0
                                    )
                                  )}
                                </b>
                              </td>
                            </tr>
                            {i.other_details?.length > 0 ? (
                              <>
                                {i.other_details.map((other, index) => (
                                  <tr key={index}>
                                    <td className="table-body" style={{ paddingLeft: "5px", paddingBottom: "5px" }} >
                                      {other.other_name ? other.other_name : `Other-${index + 1}`}
                                    </td>
                                    <td style={{ padding: "0px 4px" }}>:</td>
                                    <td className="align-right">
                                      {formatCurrencyIndian(other.approved_other || 0)}
                                    </td>
                                  </tr>
                                ))}
                              </>
                            ) : (
                              <tr>
                                <td style={{ padding: "0px 4px", paddingBottom: "5px" }} colSpan={3}>No Other Expense</td>
                              </tr>
                            )}
                            <tr>
                              <td className="table-body" style={{ paddingBottom: "5px" }}>
                                <b>Total</b>
                              </td>
                              <td style={{ padding: "0px 4px" }}>:</td>
                              <td className="align-right">
                                <b>
                                  {formatCurrencyIndian(
                                    Number(i.approved_transportation) +
                                    Number(i.approved_accomodation) +
                                    Number(i.approved_fooding) +
                                    (i.other_details?.length > 0
                                      ? i.other_details.reduce(
                                        (total, other) => total + Number(other.approved_other || 0),
                                        0
                                      )
                                      : 0)
                                  )}
                                </b>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>

                      <td className="table-body align-center">{i.utilized_amount ? formatRoundoff2D(i.utilized_amount) : '-'} Rs</td>
                      <td className="table-body align-center">{i.remaining_amount ? formatRoundoff2D(i.remaining_amount) : '-'} Rs</td>
                      <td className="table-body">
                        <span
                          request-status={i.rh_status}
                          className="request-status"
                          title={i.rh_status === "rejected" ? `Rh Reject Reason : ${i.rh_rejection_reason}` : ""}
                        >
                          {i.rh_status === "pending"
                            ? "Pending For Rh Approval ⌛"
                            : i.rh_status === "rejected"
                              ? "Rh ✖, "
                              : "Rh ✔, "}
                        </span>
                        <br />
                        <span
                          request-status={i.account_status_a}
                          className="request-status"
                          title={i.account_status_a === "rejected" ? `Account Reject Reason : ${i.account1_rejection_reason}` : ""}
                        >
                          {i.rh_status === "rejected" &&
                            i.admin_status === "rejected" &&
                            i.account_status_a === "rejected"
                            ? "Account ✖, "
                            : i.rh_status === "approved" &&
                              i.admin_status === "approved" &&
                              i.account_status_a === "pending"
                              ? "Pending For Account Approval ⌛"
                              : i.rh_status === "approved" &&
                                i.admin_status === "approved" &&
                                i.account_status_a === "rejected"
                                ? "Accounts ✖, "
                                : i.rh_status === "approved" &&
                                  i.admin_status === "approved" &&
                                  i.account_status_a === "approved"
                                  ? "Accounts ✔, "
                                  : "Accounts Stage 1: " + i.account_status_a}
                        </span>
                        <br />
                        <span
                          request-status={i.admin_status}
                          className="request-status"
                          title={i.admin_status === "rejected" ? `Admin Reject Reason : ${i.admin_rejection_reason}` : ""}
                        >
                          {i.rh_status === "rejected" &&
                            i.admin_status === "rejected"
                            ? "Admin ✖, "
                            : i.rh_status === "approved" &&
                              i.admin_status === "pending"
                              ? "Pending For Admin Approval ⌛,"
                              : i.rh_status === "approved" &&
                                i.admin_status === "rejected"
                                ? "Admin ✖, "
                                : i.rh_status === "approved" &&
                                  i.admin_status === "approved"
                                  ? "Admin ✔, "
                                  : "Admin Status: " + i.admin_status}
                        </span>
                        <br />
                        <span
                          request-status={i.account_status_b}
                          className="request-status "
                        >
                          {i.rh_status === "rejected" &&
                            i.admin_status === "rejected" &&
                            i.account_status_b === "rejected"
                            ? "Account ✖, "
                            : i.rh_status === "approved" &&
                              i.admin_status === "approved" &&
                              i.account_status_b === "pending"
                              ? "Pending For Account Approval ⌛"
                              : i.rh_status === "approved" &&
                                i.admin_status === "approved" &&
                                i.account_status_b === "rejected"
                                ? "Accounts ✖, "
                                : i.rh_status === "approved" &&
                                  i.admin_status === "approved" &&
                                  i.account_status_b === "approved"
                                  ? "Accounts ✔, "
                                  : "Accounts Stage 2: " + i.account_status_b}
                        </span>
                      </td>
                      <td title="Remarks and Approval Dates" className="table-body">
                        <span
                          request-status={i.rh_status}
                          className="request-status "
                        >
                          {i.rh_status === "pending"
                            ? "-"
                            : i.rh_status === "rejected"
                              ? `Rh Reject Reason : ${i.rh_rejection_reason}`
                              : `Rh${i.rh_assigned ? `(${i.rh_assigned}) ` : ''}: Verified Amounts for Bills ${i.rh_update_datetime ? `At: ${formatDateTime(i.rh_update_datetime).date}` : ''}`}
                        </span>
                        <br />
                        <span
                          request-status={i.account_status_a}
                          className="request-status "
                        >
                          {i.account_status_a === "pending"
                            ? "-"
                            : i.account_status_a === "rejected"
                              ? `Accounts Reject Reason : ${i.account1_rejection_reason}`
                              : `Accounts${i.account_update_by ? `(${i.account_update_by}) ` : ''}: Bills Verified ${i.account_update_datetime ? `At: ${formatDateTime(i.account_update_datetime).date}` : ''}`}
                        </span>
                        <br />
                        <span
                          request-status={i.admin_status}
                          className="request-status "
                          title={i.admin_status === "rejected" ? `Admin Reject Reason : ${i.admin_rejection_reason}` : ""}
                        >
                          {i.admin_status === "pending"
                            ? "-"
                            : i.admin_status === "rejected"
                              ? `Admin Rejection Reason : ${i.admin_rejection_reason}`
                              : `Admin${i.admin_update_by ? `(${i.admin_update_by}) ` : ''}: Approved After Final Verification  ${i.admin_update_datetime ? `At: ${formatDateTime(i.admin_update_datetime).date}` : ''}`}
                        </span>
                        <br />
                        <span
                          request-status={i.account_status_b}
                          className="request-status "
                        >
                          {i.account_status_b === "pending"
                            ? "-"
                            : i.account_status_b === "rejected"
                              ? `Final Rejection Reason : ${i.account2_rejection_reason}`
                              : `Final${i.final_update_by ? `(${i.final_update_by}) ` : ''}: Approved And Settled ${i.final_update_datetime ? `At: ${formatDateTime(i.final_update_datetime).date}` : ''}`}
                        </span>
                      </td>
                      <td className="table-body align-center" >
                        <button
                          className="allproject-dashboard"
                          title="View using Navigate"
                          onClick={(e) => openExenseSlip(i)}
                        >
                          <Eye />
                        </button>
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>}
          </table>
        </div>
      </div>
    </>
  );
};

const RequestExpensetableAccStage2 = () => {
  const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();

  // *********** Employee Details Api Start ***********
  const [allemployee, setAllemployee] = useState([]);
  const [outdutyname, setOutdutyName] = useState([]);
  const [buffer, setBuffering] = useState(true); //buffering logic

  const [selectedStatus, setSelectedStatus] = useState("pending");

  const [employeeGet, setEmployeeGet] = useState("null");
  const [fromdate, setFromDate] = useState("null");
  const [todate, setToDate] = useState("null");

  //! ******************************API start***************************//
  const getexpansename = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/expensebyaccount_b/${selectedStatus}/${fromdate}/${todate}/${employeeGet}/`
      );
      setOutdutyName(res.data);
    } catch (err) {
      handleErrorToast(err);
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  useEffect(() => {
    getexpansename();
  }, [fromdate, todate, selectedStatus, employeeGet]);

  const getEmpcode = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(`${BASE_URL}/wfm/ourcompanyempdetails/active/${sessionStorage.getItem('company_id')}/`);
      const sortedList = customSortByKey(res.data, "emp_code");

      setAllemployee(sortedList);
      // setAllemployee(res.data);
    } catch (err) {
      handleErrorToast(err);
    } finally {
      setBuffering(false);// End Buffering
    }
  };

  useEffect(() => {
    getEmpcode();
  }, []);
  //! *********** Employee Details Api End ***********

  const navigate = useNavigate();
  const currentUrl = window.location.href;
  const urlParts = currentUrl.split("/");
  const baseUrl = "/" + urlParts[3] + `/requestExpenseSlip/`;
  const opener = "accb"
  const openExenseSlip = (i) => {
    navigate(baseUrl + i.id + '/', { state: { i, opener } });
  };

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div className="attendance-history-cont">
        <div className="attendance-heading  font-weight500    font-size-heading"> </div>
        <div className="attendance-subcont">
          <div className="field-cont">
            <div className="field-cont-div">
              <div className="date-uppercase ">
                <input
                  className="attendance-input-field width-10vw   date-field date-field-filter"
                  placeholder="From Date"
                  type="date"
                  value={fromdate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </div>

              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <div className=" date-uppercase ">
                <input
                  className="attendance-input-field width-10vw   date-field date-field-filter"
                  placeholder="From Date"
                  type="date"
                  value={todate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </div>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Filter />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                <option value="null">All</option>

                <option value="pending">Pending</option>
                <option value="approved">Approved</option>
                <option value="rejected">Rejected</option>
              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Employee />
              <select
                className="attendance-input-field width-15vw   date-field"
                type="text"
                value={employeeGet}
                onChange={(e) => setEmployeeGet(e.target.value)}
              >
                <option value="null">Select Employee Code </option>
                {allemployee
                  .sort((a, b) => a.emp_code - b.emp_code)
                  .map((i, index) => (
                    <option value={i.emp_code}>
                      {i.emp_code} - {i.name}
                    </option>
                  ))}
                ;
              </select>
              <hr className="field-cont-hr" />
            </div>
          </div>
          <div className="btn-cont"></div>
        </div>

        <div className="table-css-white-background">
          <div className="table-heading-flex">
            {/* <div className="repo-heading font-weight500    font-size-heading">Expense Settlement</div> */}
            <div className="flex-row">
            </div>
          </div>
          <table className="table-css">
            <thead>
              <tr className="custom-table-head-tr">
                <th className="table-heading">S. No.</th>
                <th className="table-heading">Date</th>
                <th className="table-heading">Expense <b>Against</b> Imprest</th>
                <th className="table-heading" style={{ textAlign: "center" }}>Imprest Amount</th>
                <th className="table-heading">Utilized Money</th>
                <th className="table-heading">Remaining Money</th>
                <th className="table-heading">View</th>
                <th className="table-heading">Status</th>
                <th className="table-heading width-15vw">Remarks</th>
                <th className="table-heading">View/Action</th>
              </tr>
            </thead>
            {buffer ? <div className="spinner"></div> : //buffering logic
              <tbody>
                {outdutyname.map((i, index) => (
                  <React.Fragment key={index}>
                    <tr className="tr-border-bottom">
                      <td colSpan="8"></td>
                    </tr>
                    <tr className="custom-table-head-td">
                      <td className="table-body">{index + 1}</td>
                      <td className="table-body">{formatDate(i.date)}</td>
                      <td className="table-body width-5vw"><span>{i.name}</span>
                        <br />
                        <b> Against </b>
                        <br />
                        <span>{i.project_name}</span>
                      </td>
                      <td className="table-body">
                        <table style={{ borderCollapse: "collapse", width: "100%" }}>
                          <tbody>
                            <tr>
                              <td className="table-body" style={{ paddingBottom: "5px" }}>Transportation</td>
                              <td style={{ padding: "0px 4px" }}>:</td>
                              <td className="align-right">
                                {formatCurrencyIndian(i.approved_transportation || 0)}
                              </td>
                            </tr>
                            <tr>
                              <td className="table-body" style={{ paddingBottom: "5px" }}>Accommodation</td>
                              <td style={{ padding: "0px 4px" }}>:</td>
                              <td className="align-right">
                                {formatCurrencyIndian(i.approved_accomodation || 0)}
                              </td>
                            </tr>
                            <tr>
                              <td className="table-body" style={{ paddingBottom: "5px" }}>Food</td>
                              <td style={{ padding: "0px 4px" }}>:</td>
                              <td className="align-right">
                                {formatCurrencyIndian(i.approved_fooding || 0)}
                              </td>
                            </tr>
                            <tr>
                              <b>
                                <td className="table-body" style={{ paddingBottom: "5px" }}>Others</td>
                              </b>
                              <td style={{ padding: "0px 4px" }}>:</td>
                              <td className="align-right">
                                <b>
                                  {formatCurrencyIndian(
                                    i.other_details?.reduce(
                                      (sum, other) => sum + Number(other.approved_other || 0),
                                      0
                                    )
                                  )}
                                </b>
                              </td>
                            </tr>
                            {i.other_details?.length > 0 ? (
                              <>
                                {i.other_details.map((other, index) => (
                                  <tr key={index}>
                                    <td className="table-body" style={{ paddingLeft: "5px", paddingBottom: "5px" }} >
                                      {other.other_name ? other.other_name : `Other-${index + 1}`}
                                    </td>
                                    <td style={{ padding: "0px 4px" }}>:</td>
                                    <td className="align-right">
                                      {formatCurrencyIndian(other.approved_other || 0)}
                                    </td>
                                  </tr>
                                ))}
                              </>
                            ) : (
                              <tr>
                                <td style={{ padding: "0px 4px", paddingBottom: "5px" }} colSpan={3}>No Other Expense</td>
                              </tr>
                            )}
                            <tr>
                              <td className="table-body" style={{ paddingBottom: "5px" }}>
                                <b>Total</b>
                              </td>
                              <td style={{ padding: "0px 4px" }}>:</td>
                              <td className="align-right">
                                <b>
                                  {formatCurrencyIndian(
                                    Number(i.approved_transportation) +
                                    Number(i.approved_accomodation) +
                                    Number(i.approved_fooding) +
                                    (i.other_details?.length > 0
                                      ? i.other_details.reduce(
                                        (total, other) => total + Number(other.approved_other || 0),
                                        0
                                      )
                                      : 0)
                                  )}
                                </b>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td className="table-body align-center">{i.utilized_amount ? formatRoundoff2D(i.utilized_amount) : '-'} Rs</td>
                      <td className="table-body align-center">{i.remaining_amount ? formatRoundoff2D(i.remaining_amount) : '-'} Rs</td>
                      <td className="table-body">
                        <button
                          className="allproject-dashboard"
                          title="View using Navigate"
                          onClick={(e) => openExenseSlip(i)}
                        >
                          <Eye />
                        </button>
                      </td>
                      <td className="table-body">
                        <span
                          request-status={i.rh_status}
                          className="request-status "
                          title={i.rh_status === "rejected" ? `Rh Reject Reason : ${i.rh_rejection_reason}` : ""}
                        >
                          {i.rh_status === "pending"
                            ? "Pending For Rh Approval ⌛"
                            : i.rh_status === "rejected"
                              ? "Rh ✖, "
                              : "Rh ✔, "}
                        </span>
                        <br />
                        <span
                          request-status={i.account_status_a}
                          className="request-status "
                          title={i.account_status_a === "rejected" ? `Account Reject Reason : ${i.account1_rejection_reason}` : ""}
                        >
                          {i.rh_status === "rejected" &&
                            i.admin_status === "rejected" &&
                            i.account_status_a === "rejected"
                            ? "Account ✖, "
                            : i.rh_status === "approved" &&
                              i.admin_status === "approved" &&
                              i.account_status_a === "pending"
                              ? "Pending For Account Approval ⌛"
                              : i.rh_status === "approved" &&
                                i.admin_status === "approved" &&
                                i.account_status_a === "rejected"
                                ? "Accounts ✖, "
                                : i.rh_status === "approved" &&
                                  i.admin_status === "approved" &&
                                  i.account_status_a === "approved"
                                  ? "Accounts ✔, "
                                  : "Accounts Stage 1: " + i.account_status_a}
                        </span>
                        <br />
                        <span
                          request-status={i.admin_status}
                          className="request-status "
                          title={i.admin_status === "rejected" ? `Admin Reject Reason : ${i.admin_rejection_reason}` : ""}
                        >
                          {i.rh_status === "rejected" &&
                            i.admin_status === "rejected"
                            ? "Admin ✖, "
                            : i.rh_status === "approved" &&
                              i.admin_status === "pending"
                              ? "Pending For Admin Approval ⌛,"
                              : i.rh_status === "approved" &&
                                i.admin_status === "rejected"
                                ? "Admin ✖, "
                                : i.rh_status === "approved" &&
                                  i.admin_status === "approved"
                                  ? "Admin ✔, "
                                  : "Admin Status: " + i.admin_status}
                        </span>
                        <br />
                        <span
                          request-status={i.account_status_b}
                          className="request-status "
                        >
                          {i.rh_status === "rejected" &&
                            i.admin_status === "rejected" &&
                            i.account_status_b === "rejected"
                            ? "Account ✖, "
                            : i.rh_status === "approved" &&
                              i.admin_status === "approved" &&
                              i.account_status_b === "pending"
                              ? "Pending For Account Approval ⌛"
                              : i.rh_status === "approved" &&
                                i.admin_status === "approved" &&
                                i.account_status_b === "rejected"
                                ? "Accounts ✖, "
                                : i.rh_status === "approved" &&
                                  i.admin_status === "approved" &&
                                  i.account_status_b === "approved"
                                  ? "Accounts ✔, "
                                  : "Accounts Stage 2: " + i.account_status_b}
                        </span>
                      </td>
                      <td title="Remarks and Approval Dates" className="table-body">
                        <span
                          request-status={i.rh_status}
                          className="request-status "
                        >
                          {i.rh_status === "pending"
                            ? "-"
                            : i.rh_status === "rejected"
                              ? `Rh Reject Reason : ${i.rh_rejection_reason}`
                              : `Rh${i.rh_assigned ? `(${i.rh_assigned}) ` : ''}: Verified Amounts for Bills ${i.rh_update_datetime ? `At: ${formatDateTime(i.rh_update_datetime).date}` : ''}`}
                        </span>
                        <br />
                        <span
                          request-status={i.account_status_a}
                          className="request-status "
                        >
                          {i.account_status_a === "pending"
                            ? "-"
                            : i.account_status_a === "rejected"
                              ? `Accounts Reject Reason : ${i.account1_rejection_reason}`
                              : `Accounts${i.account_update_by ? `(${i.account_update_by}) ` : ''}: Bills Verified ${i.account_update_datetime ? `At: ${formatDateTime(i.account_update_datetime).date}` : ''}`}
                        </span>
                        <br />
                        <span
                          request-status={i.admin_status}
                          className="request-status "
                          title={i.admin_status === "rejected" ? `Admin Reject Reason : ${i.admin_rejection_reason}` : ""}
                        >
                          {i.admin_status === "pending"
                            ? "-"
                            : i.admin_status === "rejected"
                              ? `Admin Rejection Reason : ${i.admin_rejection_reason}`
                              : `Admin${i.admin_update_by ? `(${i.admin_update_by}) ` : ''}: Approved After Final Verification  ${i.admin_update_datetime ? `At: ${formatDateTime(i.admin_update_datetime).date}` : ''}`}
                        </span>
                        <br />
                        <span
                          request-status={i.account_status_b}
                          className="request-status "
                        >
                          {i.account_status_b === "pending"
                            ? "-"
                            : i.account_status_b === "rejected"
                              ? `Final Rejection Reason : ${i.account2_rejection_reason}`
                              : `Final${i.final_update_by ? `(${i.final_update_by}) ` : ''}: Approved And Settled ${i.final_update_datetime ? `At: ${formatDateTime(i.final_update_datetime).date}` : ''}`}
                        </span>
                      </td>
                      <td className="table-body align-center">
                        <AccountBExpenseFinalize
                          i={i}
                          getExpanseData={getexpansename}
                        />
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>}
          </table>
        </div>
      </div>
    </>
  );
};

const RequestClockInTableHR = () => {
  const [checkedItems, setCheckedItems] = useState([]);
  const [employeeGet, setEmployeeGet] = useState("null");
  const [fromdate, setFromDate] = useState("null");
  const [todate, setToDate] = useState("null");
  const [selectedStatus, setSelectedStatus] = useState("null");
  const [buffer, setBuffering] = useState(true); //buffering logic

  // *********** Employee Details Api Start ***********
  const [clockInList, setClockInList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);

  const getEmpList = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/ourcompanysRH/${sessionStorage.getItem("email")}/`
      );
      const sortedData = customSortByKey(res.data, "emp_code");
      setEmployeeList(sortedData);
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;
        if (typeof errorData.error === "string") {
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === "object" && errorData !== null) {
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach((message) =>
              toast.error(`"${field}": ${message}`)
            );
          });
        } else {
          toast.error("Error:- Failed to Process!");
        }
      } else {
        toast.error("Error processing your request.");
      }
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };

  const getClockInList = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/clockinrequest/${fromdate}/${todate}/null/${selectedStatus}/${employeeGet}/`
      );
      setClockInList(res.data);
    } catch (err) {
      //toast Logic
      if (err.response) {
        const errorData = err.response.data;
        if (typeof errorData.error === "string") {
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === "object" && errorData !== null) {
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach((message) =>
              toast.error(`"${field}": ${message}`)
            );
          });
        } else {
          toast.error("Error:- Failed to Process!");
        }
      } else {
        toast.error("Error processing your request.");
      }
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };
  useEffect(() => {
    getEmpList();
    getClockInList();
  }, [selectedStatus, employeeGet, fromdate, todate]);

  // *********** Employee Details Api End ***********

  const handleCheckboxChange = (id) => {
    const currentIndex = checkedItems.indexOf(id);
    const newCheckedItems = [...checkedItems];

    if (currentIndex === -1) {
      newCheckedItems.push(id);
    } else {
      newCheckedItems.splice(currentIndex, 1);
    }

    setCheckedItems(newCheckedItems);
  };

  const calculateTimeDifference = (inTime, outTime) => {
    const parseTime = (time) => {
      const [hours, minutes, seconds] = time.split(":").map(Number);
      return new Date(0, 0, 0, hours, minutes, seconds);
    };

    const inTimeDate = parseTime(inTime);
    const outTimeDate = parseTime(outTime);

    const differenceInMilliseconds = outTimeDate - inTimeDate;
    const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60);

    return differenceInHours.toFixed(2) + " hrs";
  };

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div className="attendance-history-cont">
        <div className="attendance-heading  font-weight500    font-size-heading"> </div>
        <div className="attendance-subcont">
          <div className="field-cont">
            <div className="field-cont-div">
              <div className="date-uppercase ">
                <input
                  className="attendance-input-field width-10vw   date-field date-field-filter"
                  placeholder="From Date"
                  type="date"
                  value={fromdate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </div>

              <hr />
            </div>
            <div className="field-cont-div">
              <div className="date-uppercase ">
                <input
                  className="attendance-input-field width-10vw date-field date-field-filter"
                  placeholder="From Date"
                  type="date"
                  value={todate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </div>
              <hr className="field-cont-hr" />
            </div>
            <div className="field-cont-div">
              <Filter />
              <select
                title="Request Status"
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                <option value="null">All</option>
                <option value="pending">Pending</option>
                <option value="approved">Approved</option>
                <option value="rejected">Rejected</option>
              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Employee />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={employeeGet}
                onChange={(e) => setEmployeeGet(e.target.value)}
              >
                <option value="null">Select Employee Code</option>
                {employeeList
                  .sort((a, b) => a.emp_code - b.emp_code)
                  .map((i, index) => (
                    <option value={i.emp_code}>
                      {i.emp_code} - {i.name}
                    </option>
                  ))}
                ;
              </select>
            </div>
          </div>

          <div className="btn-cont">
          </div>
        </div>

        <div className="table-css-white-background">
          <div className="table-heading-flex">
            <div className="repo-heading font-weight500    font-size-heading">Clock In Requests</div>
          </div>
          <table className="table-css">
            <thead>
              <tr className="custom-table-head-tr">
                <th className="align-left">Sr.no</th>
                {/* <th className="align-left">
                  <input type="checkbox" onChange={(e) => {
                    if (e.target.checked) {
                      const ids = clockInList.map((data) => data.id);
                      setCheckedItems(ids);
                    } else {
                      setCheckedItems([]);
                    }
                  }} />
                </th> */}

                {/* <th className="align-center">Date</th> */}
                <th className="align-center">Empolyee Name</th>
                <th className="align-center">Date</th>
                <th className="align-center">Clock In</th>
                <th className="align-center">Clock Out</th>
                <th className="align-center">Working Hours</th>
                <th className="align-center">Log</th>
                {/* <th className="align-center">Location</th> */}
                <th className="align-right ">Status</th>
              </tr>
            </thead>
            {buffer ? (
              <div className="spinner"></div> //buffering logic
            ) : (
              <tbody>
                {clockInList.map((i, index) => (
                  <React.Fragment key={index}>
                    <tr className="tr-border-bottom">
                      <td colSpan="6"></td>
                    </tr>
                    <tr className="custom-table-head-td">
                      <td className="align-left">{index + 1}</td>

                      {/* <td className="align-center">{i.date}</td> */}
                      <td className="align-center">
                        <img
                          className="profile-img"
                          src={
                            i.profilepic
                              ? `${IMAGE_URL}${i.profilepic}`
                              : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                          }
                          alt="profilepic"
                        />
                        <p>
                          {i.empcode}-{i.name}
                        </p>
                      </td>
                      {/* <td className="align-center">{i.empcode}-{i.name}</td> */}
                      <td className="align-center">
                        {formattedDateLong(i.date)}
                      </td>
                      <td className="align-center">
                        <p>{i.in_time}</p>
                        <p>{i.in_time_location}</p>
                      </td>
                      {/* <td className="align-center">{i.out_time}</td> */}
                      {/* <td className="align-center">{i.work_duration}</td> */}
                      <td className="align-center form-text-trasformation-uppercase">
                        <p>{i.out_time}</p>
                        <p>{i.out_time_location}</p>
                      </td>
                      <td className="align-center">
                        {calculateTimeDifference(i.in_time, i.out_time)}
                      </td>
                      <td className="align-center form-text-trasformation-uppercase">
                        <ClockInLogTable log={i.logs} />
                      </td>
                      <td className="align-right ">
                        <span
                          request-status={i.rh_leave_status}
                          className="request-status align-center"
                        >
                          {i.rh_leave_status === "pending"
                            ? "Pending For Rh Approval ⌛"
                            : i.rh_leave_status === "rejected"
                              ? "Rh Rejected ✖, "
                              : "Rh Approved ✔, "}
                        </span>
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </>
  );
};

const ClockInLogTable = ({ log }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    // getDesignationEmployees(designation.id);
  };


  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // useEffect(() => {
  //   getDesignationEmployees();
  // }, []);

  const navigate = useNavigate();
  const Viewdetails = (emp_code, user_detail) => {
    navigate(`/hr/userdetailscrud/${emp_code}/${user_detail}/`);
  };
  return (
    <>
      <button
        className=""
        title="View Employees"
        style={{ paddingLeft: "5px", color: "blue", fontSize: "14px" }}
        onClick={handleShow}
      >
        View logs
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Body>
          <>
            <div className="table-css-white-background">
              <div className="table-heading-flex">
                <div className="repo-heading font-weight500    font-size-heading">Clock In Log</div>
                <div className="flex-row">
                  <div className="table-searchh">
                    <div>
                      <SearchBig />
                    </div>
                    <div>
                      <input
                        placeholder="Search Place"
                        className="navbar-inputt font-weight400  font-size-text"
                        value={searchQuery}
                        onChange={handleSearchChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <table className="table-css">
                <thead>
                  <tr className="custom-table-head-tr">
                    <th className="align-left">Sr. No.</th>
                    <th className="align-left">Picture</th>

                    <th className="align-center">Punch Time</th>
                    <th className="align-center">Punch Location</th>
                    {/* <th className="align-center">Employee View</th> */}
                  </tr>
                </thead>
                <tbody>
                  {log
                    .filter((e) =>
                      e.placename
                        .toString()
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase())
                    )
                    ?.sort((a, b) => a.punch_time - b.punch_time)
                    .map((i, index) => (
                      <React.Fragment key={index}>
                        {/* <tr className="tr-border-bottom">
                          <td colSpan="6"></td>
                        </tr> */}
                        <tr className="custom-table-head-td">
                          <td className="align-left">{index + 1}</td>
                          <td className="align-center">
                            <img
                              className="profile-img"
                              src={
                                `${i.image}`
                                  ? `${i.image}`
                                  : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                              }
                              alt="Profile Pic"
                            />
                          </td>
                          <td className="align-center">{i.punch_time}</td>
                          <td className="align-center">{i.placename}</td>
                        </tr>
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            </div>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};
export {
  Requestoutdutytable,
  RequestLeavetable,
  RequestMisspunchtable,
  LogCompensatorytable,
  HODCompensatoryLogTable,
  HRLeavesLogTable,
  HODLeavesLogTable,
  HRNotificationLogTable,
  HODNotificationLogTable,
  RequestImpresttableAdmin,
  RequestExpensetableAdmin,
  RequestImpresttableAcc,
  RequestExpensetableAccStage1,
  RequestExpensetableAccStage2,
  RequestClockInTableHR,
  ClockInLogTable,
};
