// import React from 'react'
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../config/axios";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { AddwithWhiteCircle, Eye, UpdatePencil } from "../AllSvg";
import { Modal, Button } from "react-bootstrap";
import { handleAllError } from "../CustomFunctions";

const UploadAppraisalDetailsByEmployee = ({ }) => {
  const [show, setShow] = useState(false);

  const handleShow = () => {
    setShow(true);
  }

  const handleClose = () => {
    setShow(false);
    setFormData({
      accuracy_emp: 0,
      speedlyWork_emp: 0,
      SelfInitiative_emp: 0,
      responsbilityaccountability_emp: 0,
      independentWorking_emp: 0,
      decisonMakingCapability_emp: 0,
      totalperformance_emp: 0,

      punctuality_emp: 0,
      teamwork_emp: 0,
      loyalitytowardsWork_emp: 0,
      dedicationtowardsWork_emp: 0,
      obeideintorLeadership_emp: 0,
      totaldecipline_emp: 0,
      total_emp: 0,

    });
  }

  const [submitConfirmation, setSubmitConfirmation] = useState(false);

  const [formData, setFormData] = useState({
    emp_code: sessionStorage.getItem("emp_code"),

    accuracy_emp: 0,
    speedlyWork_emp: 0,
    SelfInitiative_emp: 0,
    responsbilityaccountability_emp: 0,
    independentWorking_emp: 0,
    decisonMakingCapability_emp: 0,
    totalperformance_emp: 0,

    punctuality_emp: 0,
    teamwork_emp: 0,
    loyalitytowardsWork_emp: 0,
    dedicationtowardsWork_emp: 0,
    obeideintorLeadership_emp: 0,
    totaldecipline_emp: 0,

    total_emp: 0,

    emp_update_datetime: ""
  });

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "emp_code",
    ];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)} is required !`;
      }
    });

    console.log("newErrors")
    console.log(newErrors)
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value, min, max } = e.target;
    const minValue = parseFloat(min);
    const maxValue = parseFloat(max);
    const numericValue = parseFloat(value);

    if (!isNaN(numericValue)) {
      // Allow numbers up to one decimal place
      const decimalPrecisionCheck = /^[0-9]+(\.[0-9]{1})?$/;

      if (!decimalPrecisionCheck.test(numericValue.toString())) {
        setErrors({
          ...errors,
          [name]: "Only up to one decimal place allowed.",
        });

        setInputState({
          ...inputState,
          [name]: "error",
        });
      } else if (numericValue < minValue) {
        setErrors({
          ...errors,
          [name]: `Min limit ${minValue}`,
        });

        setInputState({
          ...inputState,
          [name]: "error",
        });
      } else if (numericValue > maxValue) {
        setErrors({
          ...errors,
          [name]: `Max limit ${maxValue}`,
        });

        setInputState({
          ...inputState,
          [name]: "error",
        });
      } else {
        setErrors({
          ...errors,
          [name]: "",
        });

        setFormData({
          ...formData,
          [name]: numericValue,
        });

        setInputState({
          ...inputState,
          [name]: "success",
        });
      }
    } else {
      setErrors({});
      setFormData({
        ...formData,
        [name]: 0,
      });

      setInputState({
        ...inputState,
        [name]: "",
      });
    }
  };

  const [loading, setLoading] = useState(false);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const updatedFormData = {
      ...formData,

      totalperformance_emp: (
        (parseFloat(formData.accuracy_emp) || 0) +
        (parseFloat(formData.speedlyWork_emp) || 0) +
        (parseFloat(formData.SelfInitiative_emp) || 0) +
        (parseFloat(formData.responsbilityaccountability_emp) || 0) +
        (parseFloat(formData.independentWorking_emp) || 0) +
        (parseFloat(formData.decisonMakingCapability_emp) || 0)
      ).toFixed(1),

      totaldecipline_emp: (
        (parseFloat(formData.punctuality_emp) || 0) +
        (parseFloat(formData.teamwork_emp) || 0) +
        (parseFloat(formData.loyalitytowardsWork_emp) || 0) +
        (parseFloat(formData.dedicationtowardsWork_emp) || 0) +
        (parseFloat(formData.obeideintorLeadership_emp) || 0)
      ).toFixed(1),

      total_emp: (
        (parseFloat(formData.accuracy_emp) || 0) +
        (parseFloat(formData.speedlyWork_emp) || 0) +
        (parseFloat(formData.SelfInitiative_emp) || 0) +
        (parseFloat(formData.responsbilityaccountability_emp) || 0) +
        (parseFloat(formData.independentWorking_emp) || 0) +
        (parseFloat(formData.decisonMakingCapability_emp) || 0) +
        (parseFloat(formData.punctuality_emp) || 0) +
        (parseFloat(formData.teamwork_emp) || 0) +
        (parseFloat(formData.loyalitytowardsWork_emp) || 0) +
        (parseFloat(formData.dedicationtowardsWork_emp) || 0) +
        (parseFloat(formData.obeideintorLeadership_emp) || 0)
      ).toFixed(1),

      emp_update_datetime: new Date().toISOString(),
    };


    if (validateForm()) {

      console.log("updatedFormData")
      console.log(updatedFormData)

      setLoading(true);
      const loadingToastId = toast.loading("Loading: Please wait...");
      let ress = await axios.post(`${BASE_URL}/wfm/appraisalspost/`, updatedFormData);
      if (ress.status === 200) {
        window.location.reload();
        handleClose();
      } else {
        alert(ress);
      }
      try {

        // alert("appraisalspost");
      } catch (err) {
        handleAllError(err);
      } finally {
        setLoading(false);
        toast.dismiss(loadingToastId);
      }
    }
  };

  return (
    <>
      <button title="Upload Self Appraisal Ratings" className="upload-svg" onClick={handleShow}>
        <AddwithWhiteCircle /> {" "}Self Appraisal
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="ourcompany-model">
        <Modal.Header closeButton className="justify-center task-Tab-heading font-weight500 font-size-heading">
          <Modal.Title>Employee Self Appraisal Ratings Upload</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ToastContainer position="top-center" autoClose={3000} hideProgressBar />

          <form className="register-form">
            <div className="flex-row justify-center">
              <table className="nested-table-lightblue width-40vw">
                <thead>
                  <tr>
                    <th className="align-center">Rating Analysis</th>
                    <th colSpan={2} className="align-center">Total Rating Marks</th>
                    <th className="align-center width-5vw">Rating</th>
                  </tr>
                  <tr>
                    <th className="align-center"></th>
                    <th className="align-center">+VE Marks</th>
                    <th className="align-center">-VE Marks</th>
                    <th className="align-center"></th>
                  </tr>
                  <tr>
                    <th colSpan={4} className="align-leftt">PERFORMANCE</th>
                  </tr>
                </thead>
                <tbody>
                  <React.Fragment>
                    {[
                      { label: "Accuracy", name: "accuracy_emp", min: -20, max: 20 },
                      { label: "Speedly Work", name: "speedlyWork_emp", min: -10, max: 10 },
                      { label: "Self Initiative", name: "SelfInitiative_emp", min: -15, max: 15 },
                      { label: "Responsibility & Accountability", name: "responsbilityaccountability_emp", min: -10, max: 10 },
                      { label: "Independent Working", name: "independentWorking_emp", min: -5, max: 5 },
                      { label: "Decision Making Capability", name: "decisonMakingCapability_emp", min: -10, max: 10 },
                    ].map(({ label, name, min, max }) => (
                      <tr key={name}>
                        <td className="text-center">{label}</td>
                        <td className="text-center">{max}</td>
                        <td className="text-center">{min}</td>
                        <td>
                          <input
                            type="number"
                            step={0}
                            min={min}
                            max={max}
                            name={name}
                            placeholder="Enter Rating"
                            value={formData[name]}
                            onChange={handleInputChange}
                            onWheel={(e) => e.target.blur()}
                            className={`form-input-tabular text-end ${errors[name] ? 'is-invalid' : ''}`}
                          />
                          <div className="align-center">
                            {errors[name] && <div className="error-message-input font-size-text">{errors[name]}</div>}
                          </div>
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <th className="align-right"></th>
                      <th className="align-center">70</th>
                      <th className="align-center">-70</th>
                      <th className="align-right">
                        {(
                          formData.accuracy_emp +
                          formData.speedlyWork_emp +
                          formData.SelfInitiative_emp +
                          formData.responsbilityaccountability_emp +
                          formData.independentWorking_emp +
                          formData.decisonMakingCapability_emp
                        ).toFixed(1)}
                      </th>
                    </tr>
                    <tr>
                      <th colSpan={4} className="align-leftt">DISCIPLINE AND BEHAVIOUR</th>
                    </tr>
                    {[
                      { label: "Punctuality", name: "punctuality_emp", min: -5, max: 5 },
                      { label: "Teamwork", name: "teamwork_emp", min: -5, max: 5 },
                      { label: "Loyalty Towards Work", name: "loyalitytowardsWork_emp", min: -5, max: 5 },
                      { label: "Dedication Towards Work", name: "dedicationtowardsWork_emp", min: -5, max: 5 },
                      { label: "Obedient & Leadership", name: "obeideintorLeadership_emp", min: -10, max: 10 }
                    ].map(({ label, name, min, max }) => (
                      <tr key={name}>
                        <td className="text-center">{label}</td>
                        <td className="text-center">{max}</td>
                        <td className="text-center">{min}</td>
                        <td>
                          <input
                            type="number"
                            step={0}
                            min={min}
                            max={max}
                            name={name}
                            placeholder="Enter Rating"
                            value={formData[name]}
                            onChange={handleInputChange}
                            onWheel={(e) => e.target.blur()}
                            className={`form-input-tabular text-end ${errors[name] ? 'is-invalid' : ''}`}
                          />
                          <div className="align-center">
                            {errors[name] && <div className="error-message-input font-size-text">{errors[name]}</div>}
                          </div>
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <th className="align-right"></th>
                      <th className="align-center">30</th>
                      <th className="align-center">-30</th>
                      <th className="align-right">
                        {(
                          formData.punctuality_emp +
                          formData.teamwork_emp +
                          formData.loyalitytowardsWork_emp +
                          formData.dedicationtowardsWork_emp +
                          formData.obeideintorLeadership_emp
                        ).toFixed(1)}
                      </th>
                    </tr>
                    <tr>
                      <th className="align-right">Total Rating Marks</th>
                      <th className="align-center">100</th>
                      <th className="align-center">-100</th>
                      <th className="align-right">
                        {(
                          formData.accuracy_emp +
                          formData.speedlyWork_emp +
                          formData.SelfInitiative_emp +
                          formData.responsbilityaccountability_emp +
                          formData.independentWorking_emp +
                          formData.decisonMakingCapability_emp +
                          formData.punctuality_emp +
                          formData.teamwork_emp +
                          formData.loyalitytowardsWork_emp +
                          formData.dedicationtowardsWork_emp +
                          formData.obeideintorLeadership_emp
                        ).toFixed(1)}
                      </th>
                    </tr>
                  </React.Fragment>
                </tbody>
              </table>
            </div>

            <div className="float-right">
              <label className="form-labels  font-weight500    font-size-subheading" style={{ marginTop: "5px" }}>
                <input
                  type="checkbox"
                  checked={submitConfirmation}
                  onChange={(e) => setSubmitConfirmation(!submitConfirmation)}
                />
                <></>
                <span>
                  Click to Confirm and Sumbit
                </span>
              </label>
            </div>
            <br />

            <div className="button-models">
              <button type="button" className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                Cancel
              </button>

              {submitConfirmation ?
                <button className="model-button font-weight500 model-button-leave font-size-heading" onClick={handleFormSubmit} disabled={loading}>
                  {loading ? 'Submiting...' : 'Submit'}
                </button>
                :
                <button
                  className={`model-button model-button-cancel  font-weight500`}
                  disabled={!submitConfirmation}
                >
                  {"Confirmation Needed"}
                </button>
              }
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

const EditAppraisalDetailsByEmployee = ({ i, getDepartmentAppraisalData }) => {
  const [show, setShow] = useState(false);

  const handleShow = () => {
    setFormData(i);
    setShow(true);
  }

  const handleClose = () => {
    setShow(false);
  }

  const [formData, setFormData] = useState({
    emp_code: sessionStorage.getItem("emp_code"),

    accuracy_emp: 0,
    speedlyWork_emp: 0,
    SelfInitiative_emp: 0,
    responsbilityaccountability_emp: 0,
    independentWorking_emp: 0,
    decisonMakingCapability_emp: 0,
    totalperformance_emp: 0,

    punctuality_emp: 0,
    teamwork_emp: 0,
    loyalitytowardsWork_emp: 0,
    dedicationtowardsWork_emp: 0,
    obeideintorLeadership_emp: 0,
    totaldecipline_emp: 0,

    total_emp: 0,

    emp_update_datetime: ""
  });

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "emp_code",
    ];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)} is required !`;
      }
    });

    console.log("newErrors")
    console.log(newErrors)
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value, min, max } = e.target;
    const minValue = parseFloat(min);
    const maxValue = parseFloat(max);
    const numericValue = parseFloat(value);

    if (!isNaN(numericValue)) {
      // Allow numbers up to one decimal place
      const decimalPrecisionCheck = /^[0-9]+(\.[0-9]{1})?$/;

      if (!decimalPrecisionCheck.test(numericValue.toString())) {
        setErrors({
          ...errors,
          [name]: "Only up to one decimal place allowed.",
        });

        setInputState({
          ...inputState,
          [name]: "error",
        });
      } else if (numericValue < minValue) {
        setErrors({
          ...errors,
          [name]: `Min limit ${minValue}`,
        });

        setInputState({
          ...inputState,
          [name]: "error",
        });
      } else if (numericValue > maxValue) {
        setErrors({
          ...errors,
          [name]: `Max limit ${maxValue}`,
        });

        setInputState({
          ...inputState,
          [name]: "error",
        });
      } else {
        setErrors({
          ...errors,
          [name]: "",
        });

        setFormData({
          ...formData,
          [name]: numericValue,
        });

        setInputState({
          ...inputState,
          [name]: "success",
        });
      }
    } else {
      setErrors({});
      setFormData({
        ...formData,
        [name]: 0,
      });

      setInputState({
        ...inputState,
        [name]: "",
      });
    }
  };
  const [loading, setLoading] = useState(false);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const updatedFormData = {
      ...formData,

      totalperformance_emp: (
        (parseFloat(formData.accuracy_emp) || 0) +
        (parseFloat(formData.speedlyWork_emp) || 0) +
        (parseFloat(formData.SelfInitiative_emp) || 0) +
        (parseFloat(formData.responsbilityaccountability_emp) || 0) +
        (parseFloat(formData.independentWorking_emp) || 0) +
        (parseFloat(formData.decisonMakingCapability_emp) || 0)
      ).toFixed(1),

      totaldecipline_emp: (
        (parseFloat(formData.punctuality_emp) || 0) +
        (parseFloat(formData.teamwork_emp) || 0) +
        (parseFloat(formData.loyalitytowardsWork_emp) || 0) +
        (parseFloat(formData.dedicationtowardsWork_emp) || 0) +
        (parseFloat(formData.obeideintorLeadership_emp) || 0)
      ).toFixed(1),

      total_emp: (
        (parseFloat(formData.accuracy_emp) || 0) +
        (parseFloat(formData.speedlyWork_emp) || 0) +
        (parseFloat(formData.SelfInitiative_emp) || 0) +
        (parseFloat(formData.responsbilityaccountability_emp) || 0) +
        (parseFloat(formData.independentWorking_emp) || 0) +
        (parseFloat(formData.decisonMakingCapability_emp) || 0) +
        (parseFloat(formData.punctuality_emp) || 0) +
        (parseFloat(formData.teamwork_emp) || 0) +
        (parseFloat(formData.loyalitytowardsWork_emp) || 0) +
        (parseFloat(formData.dedicationtowardsWork_emp) || 0) +
        (parseFloat(formData.obeideintorLeadership_emp) || 0)
      ).toFixed(1),

      emp_update_datetime: new Date().toISOString(),
    };


    if (validateForm()) {

      console.log("updatedFormData")
      console.log(updatedFormData)

      setLoading(true);
      const loadingToastId = toast.loading("Loading: Please wait...");
      let ress = await axios.put(`${BASE_URL}/wfm/QuickAppresalUpdate/${i.id}/`, updatedFormData);
      if (ress.status === 200) {
        window.location.reload();
        handleClose();
      } else {
        alert(ress);
      }
      try {

        // alert("appraisalspost");
      } catch (err) {
        handleAllError(err);
      } finally {
        setLoading(false);
        toast.dismiss(loadingToastId);
      }
    }
  };

  return (
    <>
      <button title="Update Self Appraisal Ratings" className="update-svg" onClick={handleShow}>
        <UpdatePencil />{" "}Self Appraisal
        {/* <AddwithWhiteCircle />{" "}Self Appraisal */}
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="ourcompany-model">
        <Modal.Header closeButton className="justify-center task-Tab-heading font-weight500 font-size-heading">
          <Modal.Title>Employee Self Appraisal Ratings Update</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ToastContainer position="top-center" autoClose={3000} hideProgressBar />

          <form className="register-form">
            <div className="flex-row justify-center">
              <table className="nested-table-lightblue width-40vw">
                <thead>
                  <tr>
                    <th className="align-center">Rating Analysis</th>
                    <th colSpan={2} className="align-center">Total Rating Marks</th>
                    <th className="align-center width-5vw">Rating</th>
                  </tr>
                  <tr>
                    <th className="align-center"></th>
                    <th className="align-center">+VE Marks</th>
                    <th className="align-center">-VE Marks</th>
                    <th className="align-center"></th>
                  </tr>
                  <tr>
                    <th colSpan={4} className="align-leftt">PERFORMANCE</th>
                  </tr>
                </thead>
                <tbody>
                  <React.Fragment>
                    {[
                      { label: "Accuracy", name: "accuracy_emp", min: -20, max: 20 },
                      { label: "Speedly Work", name: "speedlyWork_emp", min: -10, max: 10 },
                      { label: "Self Initiative", name: "SelfInitiative_emp", min: -15, max: 15 },
                      { label: "Responsibility & Accountability", name: "responsbilityaccountability_emp", min: -10, max: 10 },
                      { label: "Independent Working", name: "independentWorking_emp", min: -5, max: 5 },
                      { label: "Decision Making Capability", name: "decisonMakingCapability_emp", min: -10, max: 10 },
                    ].map(({ label, name, min, max }) => (
                      <tr key={name}>
                        <td className="text-center">{label}</td>
                        <td className="text-center">{max}</td>
                        <td className="text-center">{min}</td>
                        <td>
                          <input
                            type="number"
                            step={0}
                            min={min}
                            max={max}
                            name={name}
                            placeholder="Enter Rating"
                            value={formData[name]}
                            onChange={handleInputChange}
                            onWheel={(e) => e.target.blur()}
                            className={`form-input-tabular text-end ${errors[name] ? 'is-invalid' : ''}`}
                          />
                          <div className="align-center">
                            {errors[name] && <div className="error-message-input font-size-text">{errors[name]}</div>}
                          </div>
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <th className="align-right"></th>
                      <th className="align-center">70</th>
                      <th className="align-center">-70</th>
                      <th className="align-right">
                        {(
                          (parseFloat(formData.accuracy_emp) || 0) +
                          (parseFloat(formData.speedlyWork_emp) || 0) +
                          (parseFloat(formData.SelfInitiative_emp) || 0) +
                          (parseFloat(formData.responsbilityaccountability_emp) || 0) +
                          (parseFloat(formData.independentWorking_emp) || 0) +
                          (parseFloat(formData.decisonMakingCapability_emp) || 0)
                        ).toFixed(1)}
                      </th>
                    </tr>
                    <tr>
                      <th colSpan={4} className="align-leftt">DISCIPLINE AND BEHAVIOUR</th>
                    </tr>
                    {[
                      { label: "Punctuality", name: "punctuality_emp", min: -5, max: 5 },
                      { label: "Teamwork", name: "teamwork_emp", min: -5, max: 5 },
                      { label: "Loyalty Towards Work", name: "loyalitytowardsWork_emp", min: -5, max: 5 },
                      { label: "Dedication Towards Work", name: "dedicationtowardsWork_emp", min: -5, max: 5 },
                      { label: "Obedient & Leadership", name: "obeideintorLeadership_emp", min: -10, max: 10 }
                    ].map(({ label, name, min, max }) => (
                      <tr key={name}>
                        <td className="text-center">{label}</td>
                        <td className="text-center">{max}</td>
                        <td className="text-center">{min}</td>
                        <td>
                          <input
                            type="number"
                            step={0}
                            min={min}
                            max={max}
                            name={name}
                            placeholder="Enter Rating"
                            value={formData[name]}
                            onChange={handleInputChange}
                            onWheel={(e) => e.target.blur()}
                            className={`form-input-tabular text-end ${errors[name] ? 'is-invalid' : ''}`}
                          />
                          <div className="align-center">
                            {errors[name] && <div className="error-message-input font-size-text">{errors[name]}</div>}
                          </div>
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <th className="align-right"></th>
                      <th className="align-center">30</th>
                      <th className="align-center">-30</th>
                      <th className="align-right">
                        {(
                          (parseFloat(formData.punctuality_emp) || 0) +
                          (parseFloat(formData.teamwork_emp) || 0) +
                          (parseFloat(formData.loyalitytowardsWork_emp) || 0) +
                          (parseFloat(formData.dedicationtowardsWork_emp) || 0) +
                          (parseFloat(formData.obeideintorLeadership_emp) || 0)
                        ).toFixed(1)}
                      </th>
                    </tr>
                    <tr>
                      <th className="align-right">Total Rating Marks</th>
                      <th className="align-center">100</th>
                      <th className="align-center">-100</th>
                      <th className="align-right">
                        {(
                          (parseFloat(formData.accuracy_emp) || 0) +
                          (parseFloat(formData.speedlyWork_emp) || 0) +
                          (parseFloat(formData.SelfInitiative_emp) || 0) +
                          (parseFloat(formData.responsbilityaccountability_emp) || 0) +
                          (parseFloat(formData.independentWorking_emp) || 0) +
                          (parseFloat(formData.decisonMakingCapability_emp) || 0) +
                          (parseFloat(formData.punctuality_emp) || 0) +
                          (parseFloat(formData.teamwork_emp) || 0) +
                          (parseFloat(formData.loyalitytowardsWork_emp) || 0) +
                          (parseFloat(formData.dedicationtowardsWork_emp) || 0) +
                          (parseFloat(formData.obeideintorLeadership_emp) || 0)
                        ).toFixed(1)}
                      </th>
                    </tr>
                  </React.Fragment>
                </tbody>
              </table>
            </div>

            <div className="button-models">
              <button type="button" className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                Cancel
              </button>

              <button className="model-button font-weight500 model-button-leave font-size-heading" onClick={handleFormSubmit} disabled={loading}>
                {loading ? 'Updating...' : 'Update'}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

const UploadAppraisalDetailsByRH = ({ i, getDepartmentAppraisalData }) => {
  const [show, setShow] = useState(false);

  const handleShow = () => {
    setFormData(i);
    setShow(true);
  }

  const handleClose = () => {
    setShow(false);
  }

  const [submitConfirmation, setSubmitConfirmation] = useState(false);

  const [formData, setFormData] = useState({
    emp_code: sessionStorage.getItem("emp_code"),

    accuracy_rh: 0,
    speedlyWork_rh: 0,
    SelfInitiative_rh: 0,
    responsbilityaccountability_rh: 0,
    independentWorking_rh: 0,
    decisonMakingCapsability_rh: 0,
    totalperformance_rh: 0,

    punctuality_rh: 0,
    teamwork_rh: 0,
    loyalitytowardsWork_rh: 0,
    dedicationtowardsWork_rh: 0,
    obeideintorLeadership_rh: 0,
    totaldecipline_rh: 0,

    total_rh: 0,

    rh_update_by: "",
    rh_update_datetime: ""
  });

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "emp_code",
    ];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)} is required !`;
      }
    });

    console.log("newErrors")
    console.log(newErrors)
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value, min, max } = e.target;
    const minValue = parseFloat(min);
    const maxValue = parseFloat(max);
    const numericValue = parseFloat(value);

    if (!isNaN(numericValue)) {
      // Allow numbers up to one decimal place
      const decimalPrecisionCheck = /^[0-9]+(\.[0-9]{1})?$/;

      if (!decimalPrecisionCheck.test(numericValue.toString())) {
        setErrors({
          ...errors,
          [name]: "Only up to one decimal place allowed.",
        });

        setInputState({
          ...inputState,
          [name]: "error",
        });
      } else if (numericValue < minValue) {
        setErrors({
          ...errors,
          [name]: `Min limit ${minValue}`,
        });

        setInputState({
          ...inputState,
          [name]: "error",
        });
      } else if (numericValue > maxValue) {
        setErrors({
          ...errors,
          [name]: `Max limit ${maxValue}`,
        });

        setInputState({
          ...inputState,
          [name]: "error",
        });
      } else {
        setErrors({
          ...errors,
          [name]: "",
        });

        setFormData({
          ...formData,
          [name]: numericValue,
        });

        setInputState({
          ...inputState,
          [name]: "success",
        });
      }
    } else {
      setErrors({});
      setFormData({
        ...formData,
        [name]: 0,
      });

      setInputState({
        ...inputState,
        [name]: "",
      });
    }
  };

  const [loading, setLoading] = useState(false);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const updatedFormData = {
      ...formData,

      totalperformance_rh: (
        (parseFloat(formData.accuracy_rh) || 0) +
        (parseFloat(formData.speedlyWork_rh) || 0) +
        (parseFloat(formData.SelfInitiative_rh) || 0) +
        (parseFloat(formData.responsbilityaccountability_rh) || 0) +
        (parseFloat(formData.independentWorking_rh) || 0) +
        (parseFloat(formData.decisonMakingCapability_rh) || 0)
      ).toFixed(1),

      totaldecipline_rh: (
        (parseFloat(formData.punctuality_rh) || 0) +
        (parseFloat(formData.teamwork_rh) || 0) +
        (parseFloat(formData.loyalitytowardsWork_rh) || 0) +
        (parseFloat(formData.dedicationtowardsWork_rh) || 0) +
        (parseFloat(formData.obeideintorLeadership_rh) || 0)
      ).toFixed(1),

      total_rh: (
        (parseFloat(formData.accuracy_rh) || 0) +
        (parseFloat(formData.speedlyWork_rh) || 0) +
        (parseFloat(formData.SelfInitiative_rh) || 0) +
        (parseFloat(formData.responsbilityaccountability_rh) || 0) +
        (parseFloat(formData.independentWorking_rh) || 0) +
        (parseFloat(formData.decisonMakingCapability_rh) || 0) +
        (parseFloat(formData.punctuality_rh) || 0) +
        (parseFloat(formData.teamwork_rh) || 0) +
        (parseFloat(formData.loyalitytowardsWork_rh) || 0) +
        (parseFloat(formData.dedicationtowardsWork_rh) || 0) +
        (parseFloat(formData.obeideintorLeadership_rh) || 0)
      ).toFixed(1),


      rh_update_by: `${sessionStorage.getItem('emp_code')}-${sessionStorage.getItem('name')}`,
      rh_update_datetime: new Date().toISOString(),
    };


    if (validateForm()) {

      console.log("updatedFormData")
      console.log(updatedFormData)

      setLoading(true);
      const loadingToastId = toast.loading("Loading: Please wait...");
      let ress = await axios.put(`${BASE_URL}/wfm/QuickAppresalUpdate/${i.id}/`, updatedFormData);
      if (ress.status === 200) {
        // window.location.reload();
        await getDepartmentAppraisalData();
        handleClose();
      } else {
        alert(ress);
      }
      try {

        // alert("appraisalspost");
      } catch (err) {
        handleAllError(err);
      } finally {
        setLoading(false);
        toast.dismiss(loadingToastId);
      }
    }
  };

  return (
    <>
      <button title="Update Reporting Head Raitings" className="model-edit-button" onClick={handleShow}>
        <UpdatePencil />
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="ourcompany-model">
        <Modal.Header closeButton className="justify-center task-Tab-heading font-weight500 font-size-heading">
          <Modal.Title>Reporting Head Appraisal Ratings Upload for "{i.emp_code}-{i.name}"</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ToastContainer position="top-center" autoClose={3000} hideProgressBar />

          <form className="register-form">
            <div className="flex-row justify-center">
              <table className="nested-table-lightblue width-40vw">
                <thead>
                  <tr>
                    <th className="align-center">Rating Analysis</th>
                    <th colSpan={2} className="align-center">Total Rating Marks</th>
                    <th className="align-center">Emp Rating</th>
                    <th className="align-center width-5vw">Reporting Head Rating</th>
                  </tr>
                  <tr>
                    <th className="align-center"></th>
                    <th className="align-center">+VE Marks</th>
                    <th className="align-center">-VE Marks</th>
                    <th className="align-center"></th>
                    <th className="align-center"></th>
                  </tr>
                  <tr>
                    <th colSpan={5} className="align-leftt">PERFORMANCE</th>
                  </tr>
                </thead>
                <tbody>
                  <React.Fragment>
                    {[
                      { label: "Accuracy", name: "accuracy_rh", emp: "accuracy_emp", min: -20, max: 20 },
                      { label: "Speedly Work", name: "speedlyWork_rh", emp: "speedlyWork_emp", min: -10, max: 10 },
                      { label: "Self Initiative", name: "SelfInitiative_rh", emp: "SelfInitiative_emp", min: -15, max: 15 },
                      { label: "Responsibility & Accountability", name: "responsbilityaccountability_rh", emp: "responsbilityaccountability_emp", min: -10, max: 10 },
                      { label: "Independent Working", name: "independentWorking_rh", emp: "independentWorking_emp", min: -5, max: 5 },
                      { label: "Decision Making Capability", name: "decisonMakingCapability_rh", emp: "decisonMakingCapability_emp", min: -10, max: 10 },
                    ].map(({ label, name, emp, min, max }) => (
                      <tr key={name}>
                        <td className="text-center">{label}</td>
                        <td className="text-center">{max}</td>
                        <td className="text-center">{min}</td>
                        <td className="text-center">{formData[emp]}</td>
                        <td>
                          <input
                            type="number"
                            step={0}
                            min={min}
                            max={max}
                            name={name}
                            placeholder="Enter Rating"
                            value={formData[name]}
                            onChange={handleInputChange}
                            onWheel={(e) => e.target.blur()}
                            className={`form-input-tabular text-end ${errors[name] ? 'is-invalid' : ''}`}
                          />
                          <div className="align-center">
                            {errors[name] && <div className="error-message-input font-size-text">{errors[name]}</div>}
                          </div>
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <th className="align-right"></th>
                      <th className="align-center">70</th>
                      <th className="align-center">-70</th>
                      <th className="align-center">{formData.totalperformance_emp}</th>
                      <th className="align-right">
                        {(
                          (parseFloat(formData.accuracy_rh) || 0) +
                          (parseFloat(formData.speedlyWork_rh) || 0) +
                          (parseFloat(formData.SelfInitiative_rh) || 0) +
                          (parseFloat(formData.responsbilityaccountability_rh) || 0) +
                          (parseFloat(formData.independentWorking_rh) || 0) +
                          (parseFloat(formData.decisonMakingCapability_rh) || 0)
                        ).toFixed(1)}
                      </th>
                    </tr>
                    <tr>
                      <th colSpan={5} className="align-leftt">DISCIPLINE AND BEHAVIOUR</th>
                    </tr>
                    {[
                      { label: "Punctuality", name: "punctuality_rh", emp: "punctuality_emp", min: -5, max: 5 },
                      { label: "Teamwork", name: "teamwork_rh", emp: "teamwork_emp", min: -5, max: 5 },
                      { label: "Loyalty Towards Work", name: "loyalitytowardsWork_rh", emp: "loyalitytowardsWork_emp", min: -5, max: 5 },
                      { label: "Dedication Towards Work", name: "dedicationtowardsWork_rh", emp: "dedicationtowardsWork_emp", min: -5, max: 5 },
                      { label: "Obedient & Leadership", name: "obeideintorLeadership_rh", emp: "obeideintorLeadership_emp", min: -10, max: 10 }
                    ].map(({ label, name, emp, min, max }) => (
                      <tr key={name}>
                        <td className="text-center">{label}</td>
                        <td className="text-center">{max}</td>
                        <td className="text-center">{min}</td>
                        <td className="text-center">{formData[emp]}</td>
                        <td>
                          <input
                            type="number"
                            step={0}
                            min={min}
                            max={max}
                            name={name}
                            placeholder="Enter Rating"
                            value={formData[name]}
                            onChange={handleInputChange}
                            onWheel={(e) => e.target.blur()}
                            className={`form-input-tabular text-end ${errors[name] ? 'is-invalid' : ''}`}
                          />
                          <div className="align-center">
                            {errors[name] && <div className="error-message-input font-size-text">{errors[name]}</div>}
                          </div>
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <th className="align-right"></th>
                      <th className="align-center">30</th>
                      <th className="align-center">-30</th>
                      <th className="align-center">{formData.totaldecipline_emp}</th>
                      <th className="align-right">
                        {(
                          (parseFloat(formData.punctuality_rh) || 0) +
                          (parseFloat(formData.teamwork_rh) || 0) +
                          (parseFloat(formData.loyalitytowardsWork_rh) || 0) +
                          (parseFloat(formData.dedicationtowardsWork_rh) || 0) +
                          (parseFloat(formData.obeideintorLeadership_rh) || 0)
                        ).toFixed(1)}
                      </th>
                    </tr>
                    <tr>
                      <th className="align-right">Total Rating Marks</th>
                      <th className="align-center">100</th>
                      <th className="align-center">-100</th>
                      <th className="align-center">{formData.total_emp}</th>
                      <th className="align-right">
                        {(
                          (parseFloat(formData.accuracy_rh) || 0) +
                          (parseFloat(formData.speedlyWork_rh) || 0) +
                          (parseFloat(formData.SelfInitiative_rh) || 0) +
                          (parseFloat(formData.responsbilityaccountability_rh) || 0) +
                          (parseFloat(formData.independentWorking_rh) || 0) +
                          (parseFloat(formData.decisonMakingCapability_rh) || 0) +
                          (parseFloat(formData.punctuality_rh) || 0) +
                          (parseFloat(formData.teamwork_rh) || 0) +
                          (parseFloat(formData.loyalitytowardsWork_rh) || 0) +
                          (parseFloat(formData.dedicationtowardsWork_rh) || 0) +
                          (parseFloat(formData.obeideintorLeadership_rh) || 0)
                        ).toFixed(1)}
                      </th>
                    </tr>
                  </React.Fragment>
                </tbody>
              </table>
            </div>

            <div className="float-right">
              <label className="form-labels  font-weight500    font-size-subheading" style={{ marginTop: "5px" }}>
                <input
                  type="checkbox"
                  checked={submitConfirmation}
                  onChange={(e) => setSubmitConfirmation(!submitConfirmation)}
                />
                <></>
                <span>
                  Click to Confirm and Sumbit
                </span>
              </label>
            </div>
            <br />

            <div className="button-models">
              <button type="button" className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                Cancel
              </button>

              {submitConfirmation ?
                <button className="model-button font-weight500 model-button-leave font-size-heading" onClick={handleFormSubmit} disabled={loading}>
                  {loading ? 'Submiting...' : 'Submit'}
                </button>
                :
                <button
                  className={`model-button model-button-cancel  font-weight500`}
                  disabled={!submitConfirmation}
                >
                  {"Confirmation Needed"}
                </button>
              }
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

const ViewAppraisalDetailsByRH = ({ i }) => {
  const [show, setShow] = useState(false);

  const handleShow = () => {
    setFormData(i)
    setShow(true);
  }

  const handleClose = () => {
    setShow(false);
  }

  const [formData, setFormData] = useState({});


  return (
    <>
      <button title="View By Reporting Head" className="" onClick={handleShow}>
        <Eye />
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="ourcompany-model">
        <Modal.Header closeButton className="justify-center task-Tab-heading font-weight500 font-size-heading">
          <Modal.Title>Reporting Head Appraisal Ratings of : "{i.emp_code}-{i.name}"</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ToastContainer position="top-center" autoClose={3000} hideProgressBar />

          <form className="register-form">
            <div className="flex-row justify-center">
              <table className="nested-table-lightblue width-40vw">
                <thead>
                  <tr>
                    <th className="align-center">Rating Analysis</th>
                    <th colSpan={2} className="align-center">Total Rating Marks</th>
                    <th className="align-center">Emp Rating</th>
                    <th className="align-center">Reporting Head Rating</th>
                  </tr>
                  <tr>
                    <th className="align-center"></th>
                    <th className="align-center">+VE Marks</th>
                    <th className="align-center">-VE Marks</th>
                    <th className="align-center"></th>
                    <th className="align-center"></th>
                  </tr>
                  <tr>
                    <th colSpan={5} className="align-leftt">PERFORMANCE</th>
                  </tr>
                </thead>
                <tbody>
                  <React.Fragment>
                    {[
                      { label: "Accuracy", name: "accuracy_admin", emp: "accuracy_emp", rh: "accuracy_rh", min: -20, max: 20 },
                      { label: "Speedly Work", name: "speedlyWork_admin", emp: "speedlyWork_emp", rh: "speedlyWork_rh", min: -10, max: 10 },
                      { label: "Self Initiative", name: "SelfInitiative_admin", emp: "SelfInitiative_emp", rh: "SelfInitiative_rh", min: -15, max: 15 },
                      { label: "Responsibility & Accountability", name: "responsbilityaccountability_admin", emp: "responsbilityaccountability_emp", rh: "responsbilityaccountability_rh", min: -10, max: 10 },
                      { label: "Independent Working", name: "independentWorking_admin", emp: "independentWorking_emp", rh: "independentWorking_rh", min: -5, max: 5 },
                      { label: "Decision Making Capability", name: "decisonMakingCapability_admin", emp: "decisonMakingCapability_emp", rh: "decisonMakingCapability_rh", min: -10, max: 10 },
                    ].map(({ label, name, emp, rh, min, max }) => (
                      <tr key={name}>
                        <td className="text-center">{label}</td>
                        <td className="text-center">{max}</td>
                        <td className="text-center">{min}</td>
                        <td className="text-center">{formData[emp]}</td>
                        <td className="text-center">{formData[rh]}</td>
                      </tr>
                    ))}
                    <tr>
                      <th className="align-right"></th>
                      <th className="align-center">70</th>
                      <th className="align-center">-70</th>
                      <th className="align-center">{formData.totalperformance_emp}</th>
                      <th className="align-center">{formData.totalperformance_rh}</th>
                    </tr>
                    <tr>
                      <th colSpan={5} className="align-leftt">DISCIPLINE AND BEHAVIOUR</th>
                    </tr>
                    {[
                      { label: "Punctuality", name: "punctuality_admin", emp: "punctuality_emp", rh: "punctuality_rh", min: -5, max: 5 },
                      { label: "Teamwork", name: "teamwork_admin", emp: "teamwork_emp", rh: "teamwork_rh", min: -5, max: 5 },
                      { label: "Loyalty Towards Work", name: "loyalitytowardsWork_admin", emp: "loyalitytowardsWork_emp", rh: "loyalitytowardsWork_rh", min: -5, max: 5 },
                      { label: "Dedication Towards Work", name: "dedicationtowardsWork_admin", emp: "dedicationtowardsWork_emp", rh: "dedicationtowardsWork_rh", min: -5, max: 5 },
                      { label: "Obedient & Leadership", name: "obeideintorLeadership_admin", emp: "obeideintorLeadership_emp", rh: "obeideintorLeadership_rh", min: -10, max: 10 }
                    ].map(({ label, name, emp, rh, min, max }) => (
                      <tr key={name}>
                        <td className="text-center">{label}</td>
                        <td className="text-center">{max}</td>
                        <td className="text-center">{min}</td>
                        <td className="text-center">{formData[emp]}</td>
                        <td className="text-center">{formData[rh]}</td>
                      </tr>
                    ))}
                    <tr>
                      <th className="align-right"></th>
                      <th className="align-center">30</th>
                      <th className="align-center">-30</th>
                      <th className="align-center">{formData.totaldecipline_emp}</th>
                      <th className="align-center">{formData.totaldecipline_rh}</th>
                    </tr>
                    <tr>
                      <th className="align-right">Total Rating Marks</th>
                      <th className="align-center">100</th>
                      <th className="align-center">-100</th>
                      <th className="align-center">{formData.total_emp}</th>
                      <th className="align-center">{formData.total_rh}</th>
                    </tr>
                  </React.Fragment>
                </tbody>
              </table>
            </div>

            <div className="button-models">
              <button type="button" className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                Close
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

const UploadAppraisalDetailsByHOD = ({ i, getDepartmentAppraisalData }) => {
  const [show, setShow] = useState(false);

  const handleShow = () => {
    setFormData(i);
    setShow(true);
  }

  const handleClose = () => {
    setShow(false);
  }

  const [submitConfirmation, setSubmitConfirmation] = useState(false);

  const [formData, setFormData] = useState({
    emp_code: sessionStorage.getItem("emp_code"),

    accuracy_hod: 0,
    speedlyWork_hod: 0,
    SelfInitiative_hod: 0,
    responsbilityaccountability_hod: 0,
    independentWorking_hod: 0,
    decisonMakingCapsability_hod: 0,
    totalperformance_hod: 0,

    punctuality_hod: 0,
    teamwork_hod: 0,
    loyalitytowardsWork_hod: 0,
    dedicationtowardsWork_hod: 0,
    obeideintorLeadership_hod: 0,
    totaldecipline_hod: 0,

    total_hod: 0,

    hod_update_by: "",
    hod_update_datetime: ""
  });

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "emp_code",
    ];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)} is required !`;
      }
    });

    console.log("newErrors")
    console.log(newErrors)
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleInputChange = (e) => {
    const { name, value, min, max } = e.target;
    const minValue = parseFloat(min);
    const maxValue = parseFloat(max);
    const numericValue = parseFloat(value);

    if (!isNaN(numericValue)) {
      // Allow numbers up to one decimal place
      const decimalPrecisionCheck = /^[0-9]+(\.[0-9]{1})?$/;

      if (!decimalPrecisionCheck.test(numericValue.toString())) {
        setErrors({
          ...errors,
          [name]: "Only up to one decimal place allowed.",
        });

        setInputState({
          ...inputState,
          [name]: "error",
        });
      } else if (numericValue < minValue) {
        setErrors({
          ...errors,
          [name]: `Min limit ${minValue}`,
        });

        setInputState({
          ...inputState,
          [name]: "error",
        });
      } else if (numericValue > maxValue) {
        setErrors({
          ...errors,
          [name]: `Max limit ${maxValue}`,
        });

        setInputState({
          ...inputState,
          [name]: "error",
        });
      } else {
        setErrors({
          ...errors,
          [name]: "",
        });

        setFormData({
          ...formData,
          [name]: numericValue,
        });

        setInputState({
          ...inputState,
          [name]: "success",
        });
      }
    } else {
      setErrors({});
      setFormData({
        ...formData,
        [name]: 0,
      });

      setInputState({
        ...inputState,
        [name]: "",
      });
    }
  };

  const [loading, setLoading] = useState(false);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const updatedFormData = {
      ...formData,

      totalperformance_hod:
        (
          (parseFloat(formData.accuracy_hod) || 0) +
          (parseFloat(formData.speedlyWork_hod) || 0) +
          (parseFloat(formData.SelfInitiative_hod) || 0) +
          (parseFloat(formData.responsbilityaccountability_hod) || 0) +
          (parseFloat(formData.independentWorking_hod) || 0) +
          (parseFloat(formData.decisonMakingCapability_hod) || 0)
        ).toFixed(1),

      totaldecipline_hod:
        (
          (parseFloat(formData.punctuality_hod) || 0) +
          (parseFloat(formData.teamwork_hod) || 0) +
          (parseFloat(formData.loyalitytowardsWork_hod) || 0) +
          (parseFloat(formData.dedicationtowardsWork_hod) || 0) +
          (parseFloat(formData.obeideintorLeadership_hod) || 0)
        ).toFixed(1),

      total_hod:
        (
          (parseFloat(formData.accuracy_hod) || 0) +
          (parseFloat(formData.speedlyWork_hod) || 0) +
          (parseFloat(formData.SelfInitiative_hod) || 0) +
          (parseFloat(formData.responsbilityaccountability_hod) || 0) +
          (parseFloat(formData.independentWorking_hod) || 0) +
          (parseFloat(formData.decisonMakingCapability_hod) || 0) +
          (parseFloat(formData.punctuality_hod) || 0) +
          (parseFloat(formData.teamwork_hod) || 0) +
          (parseFloat(formData.loyalitytowardsWork_hod) || 0) +
          (parseFloat(formData.dedicationtowardsWork_hod) || 0) +
          (parseFloat(formData.obeideintorLeadership_hod) || 0)
        ).toFixed(1),

      hod_update_by: `${sessionStorage.getItem('emp_code')}-${sessionStorage.getItem('name')}`,
      hod_update_datetime: new Date().toISOString(),
    };


    if (validateForm()) {

      console.log("updatedFormData")
      console.log(updatedFormData)

      setLoading(true);
      const loadingToastId = toast.loading("Loading: Please wait...");
      let ress = await axios.put(`${BASE_URL}/wfm/QuickAppresalUpdate/${i.id}/`, updatedFormData);
      if (ress.status === 200) {
        // window.location.reload();
        await getDepartmentAppraisalData();
        handleClose();
      } else {
        alert(ress);
      }
      try {

        // alert("appraisalspost");
      } catch (err) {
        handleAllError(err);
      } finally {
        setLoading(false);
        toast.dismiss(loadingToastId);
      }
    }
  };

  return (
    <>
      <button title="Update HOD Raitings" className="model-edit-button" onClick={handleShow}>
        <UpdatePencil />
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="ourcompany-model">
        <Modal.Header closeButton className="justify-center task-Tab-heading font-weight500 font-size-heading">
          <Modal.Title>HOD Appraisal Ratings Upload for "{i.emp_code}-{i.name}"</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ToastContainer position="top-center" autoClose={3000} hideProgressBar />

          <form className="register-form">
            <div className="flex-row justify-center">
              <table className="nested-table-lightblue width-40vw">
                <thead>
                  <tr>
                    <th className="align-center">Rating Analysis</th>
                    <th colSpan={2} className="align-center">Total Rating Marks</th>
                    <th className="align-center">Emp Rating</th>
                    <th className="align-center width-5vw">HOD Rating</th>
                  </tr>
                  <tr>
                    <th className="align-center"></th>
                    <th className="align-center">+VE Marks</th>
                    <th className="align-center">-VE Marks</th>
                    <th className="align-center"></th>
                    <th className="align-center"></th>
                  </tr>
                  <tr>
                    <th colSpan={5} className="align-leftt">PERFORMANCE</th>
                  </tr>
                </thead>
                <tbody>
                  <React.Fragment>
                    {[
                      { label: "Accuracy", name: "accuracy_hod", emp: "accuracy_emp", min: -20, max: 20 },
                      { label: "Speedly Work", name: "speedlyWork_hod", emp: "speedlyWork_emp", min: -10, max: 10 },
                      { label: "Self Initiative", name: "SelfInitiative_hod", emp: "SelfInitiative_emp", min: -15, max: 15 },
                      { label: "Responsibility & Accountability", name: "responsbilityaccountability_hod", emp: "responsbilityaccountability_emp", min: -10, max: 10 },
                      { label: "Independent Working", name: "independentWorking_hod", emp: "independentWorking_emp", min: -5, max: 5 },
                      { label: "Decision Making Capability", name: "decisonMakingCapability_hod", emp: "decisonMakingCapability_emp", min: -10, max: 10 },
                    ].map(({ label, name, emp, min, max }) => (
                      <tr key={name}>
                        <td className="text-center">{label}</td>
                        <td className="text-center">{max}</td>
                        <td className="text-center">{min}</td>
                        <td className="text-center">{formData[emp]}</td>
                        <td>
                          <input
                            type="number"
                            step={0}
                            min={min}
                            max={max}
                            name={name}
                            placeholder="Enter Rating"
                            value={formData[name]}
                            onChange={handleInputChange}
                            onWheel={(e) => e.target.blur()}
                            className={`form-input-tabular text-end ${errors[name] ? 'is-invalid' : ''}`}
                          />
                          <div className="align-center">
                            {errors[name] && <div className="error-message-input font-size-text">{errors[name]}</div>}
                          </div>
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <th className="align-right"></th>
                      <th className="align-center">70</th>
                      <th className="align-center">-70</th>
                      <th className="align-center">{formData.totalperformance_emp}</th>
                      <th className="align-right">
                        {(
                          (parseFloat(formData.accuracy_hod) || 0) +
                          (parseFloat(formData.speedlyWork_hod) || 0) +
                          (parseFloat(formData.SelfInitiative_hod) || 0) +
                          (parseFloat(formData.responsbilityaccountability_hod) || 0) +
                          (parseFloat(formData.independentWorking_hod) || 0) +
                          (parseFloat(formData.decisonMakingCapability_hod) || 0)
                        ).toFixed(1)}
                      </th>
                    </tr>
                    <tr>
                      <th colSpan={5} className="align-leftt">DISCIPLINE AND BEHAVIOUR</th>
                    </tr>
                    {[
                      { label: "Punctuality", name: "punctuality_hod", emp: "punctuality_emp", min: -5, max: 5 },
                      { label: "Teamwork", name: "teamwork_hod", emp: "teamwork_emp", min: -5, max: 5 },
                      { label: "Loyalty Towards Work", name: "loyalitytowardsWork_hod", emp: "loyalitytowardsWork_emp", min: -5, max: 5 },
                      { label: "Dedication Towards Work", name: "dedicationtowardsWork_hod", emp: "dedicationtowardsWork_emp", min: -5, max: 5 },
                      { label: "Obedient & Leadership", name: "obeideintorLeadership_hod", emp: "obeideintorLeadership_emp", min: -10, max: 10 }
                    ].map(({ label, name, emp, min, max }) => (
                      <tr key={name}>
                        <td className="text-center">{label}</td>
                        <td className="text-center">{max}</td>
                        <td className="text-center">{min}</td>
                        <td className="text-center">{formData[emp]}</td>
                        <td>
                          <input
                            type="number"
                            step={0}
                            min={min}
                            max={max}
                            name={name}
                            placeholder="Enter Rating"
                            value={formData[name]}
                            onChange={handleInputChange}
                            onWheel={(e) => e.target.blur()}
                            className={`form-input-tabular text-end ${errors[name] ? 'is-invalid' : ''}`}
                          />
                          <div className="align-center">
                            {errors[name] && <div className="error-message-input font-size-text">{errors[name]}</div>}
                          </div>
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <th className="align-right"></th>
                      <th className="align-center">30</th>
                      <th className="align-center">-30</th>
                      <th className="align-center">{formData.totaldecipline_emp}</th>
                      <th className="align-right">
                        {(
                          (parseFloat(formData.punctuality_hod) || 0) +
                          (parseFloat(formData.teamwork_hod) || 0) +
                          (parseFloat(formData.loyalitytowardsWork_hod) || 0) +
                          (parseFloat(formData.dedicationtowardsWork_hod) || 0) +
                          (parseFloat(formData.obeideintorLeadership_hod) || 0)
                        ).toFixed(1)}
                      </th>
                    </tr>
                    <tr>
                      <th className="align-right">Total Rating Marks</th>
                      <th className="align-center">100</th>
                      <th className="align-center">-100</th>
                      <th className="align-center">{formData.total_emp}</th>
                      <th className="align-right">
                        {(
                          (parseFloat(formData.accuracy_hod) || 0) +
                          (parseFloat(formData.speedlyWork_hod) || 0) +
                          (parseFloat(formData.SelfInitiative_hod) || 0) +
                          (parseFloat(formData.responsbilityaccountability_hod) || 0) +
                          (parseFloat(formData.independentWorking_hod) || 0) +
                          (parseFloat(formData.decisonMakingCapability_hod) || 0) +
                          (parseFloat(formData.punctuality_hod) || 0) +
                          (parseFloat(formData.teamwork_hod) || 0) +
                          (parseFloat(formData.loyalitytowardsWork_hod) || 0) +
                          (parseFloat(formData.dedicationtowardsWork_hod) || 0) +
                          (parseFloat(formData.obeideintorLeadership_hod) || 0)
                        ).toFixed(1)}
                      </th>
                    </tr>
                  </React.Fragment>
                </tbody>
              </table>
            </div>

            <div className="float-right">
              <label className="form-labels  font-weight500    font-size-subheading" style={{ marginTop: "5px" }}>
                <input
                  type="checkbox"
                  checked={submitConfirmation}
                  onChange={(e) => setSubmitConfirmation(!submitConfirmation)}
                />
                <></>
                <span>
                  Click to Confirm and Sumbit
                </span>
              </label>
            </div>
            <br />

            <div className="button-models">
              <button type="button" className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                Cancel
              </button>

              {submitConfirmation ?
                <button className="model-button font-weight500 model-button-leave font-size-heading" onClick={handleFormSubmit} disabled={loading}>
                  {loading ? 'Submiting...' : 'Submit'}
                </button>
                :
                <button
                  className={`model-button model-button-cancel  font-weight500`}
                  disabled={!submitConfirmation}
                >
                  {"Confirmation Needed"}
                </button>
              }
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

const ViewAppraisalDetailsByHOD = ({ i }) => {
  const [show, setShow] = useState(false);

  const handleShow = () => {
    setFormData(i)
    setShow(true);
  }

  const handleClose = () => {
    setShow(false);
  }

  const [formData, setFormData] = useState({});


  return (
    <>
      <button title="View By HOD" className="" onClick={handleShow}>
        <Eye />
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="ourcompany-model">
        <Modal.Header closeButton className="justify-center task-Tab-heading font-weight500 font-size-heading">
          <Modal.Title>HOD Appraisal Ratings of : "{i.emp_code}-{i.name}"</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ToastContainer position="top-center" autoClose={3000} hideProgressBar />

          <form className="register-form">
            <div className="flex-row justify-center">
              <table className="nested-table-lightblue width-40vw">
                <thead>
                  <tr>
                    <th className="align-center">Rating Analysis</th>
                    <th colSpan={2} className="align-center">Total Rating Marks</th>
                    <th className="align-center">Emp Rating</th>
                    <th className="align-center">HOD Rating</th>
                  </tr>
                  <tr>
                    <th className="align-center"></th>
                    <th className="align-center">+VE Marks</th>
                    <th className="align-center">-VE Marks</th>
                    <th className="align-center"></th>
                    <th className="align-center"></th>
                  </tr>
                  <tr>
                    <th colSpan={5} className="align-leftt">PERFORMANCE</th>
                  </tr>
                </thead>
                <tbody>
                  <React.Fragment>
                    {[
                      { label: "Accuracy", name: "accuracy_admin", emp: "accuracy_emp", hod: "accuracy_hod", min: -20, max: 20 },
                      { label: "Speedly Work", name: "speedlyWork_admin", emp: "speedlyWork_emp", hod: "speedlyWork_hod", min: -10, max: 10 },
                      { label: "Self Initiative", name: "SelfInitiative_admin", emp: "SelfInitiative_emp", hod: "SelfInitiative_hod", min: -15, max: 15 },
                      { label: "Responsibility & Accountability", name: "responsbilityaccountability_admin", emp: "responsbilityaccountability_emp", hod: "responsbilityaccountability_hod", min: -10, max: 10 },
                      { label: "Independent Working", name: "independentWorking_admin", emp: "independentWorking_emp", hod: "independentWorking_hod", min: -5, max: 5 },
                      { label: "Decision Making Capability", name: "decisonMakingCapability_admin", emp: "decisonMakingCapability_emp", hod: "decisonMakingCapability_hod", min: -10, max: 10 },
                    ].map(({ label, name, emp, hod, min, max }) => (
                      <tr key={name}>
                        <td className="text-center">{label}</td>
                        <td className="text-center">{max}</td>
                        <td className="text-center">{min}</td>
                        <td className="text-center">{formData[emp]}</td>
                        <td className="text-center">{formData[hod]}</td>
                      </tr>
                    ))}
                    <tr>
                      <th className="align-right"></th>
                      <th className="align-center">70</th>
                      <th className="align-center">-70</th>
                      <th className="align-center">{formData.totalperformance_emp}</th>
                      <th className="align-center">{formData.totalperformance_hod}</th>
                    </tr>
                    <tr>
                      <th colSpan={5} className="align-leftt">DISCIPLINE AND BEHAVIOUR</th>
                    </tr>
                    {[
                      { label: "Punctuality", name: "punctuality_admin", emp: "punctuality_emp", hod: "punctuality_hod", min: -5, max: 5 },
                      { label: "Teamwork", name: "teamwork_admin", emp: "teamwork_emp", hod: "teamwork_hod", min: -5, max: 5 },
                      { label: "Loyalty Towards Work", name: "loyalitytowardsWork_admin", emp: "loyalitytowardsWork_emp", hod: "loyalitytowardsWork_hod", min: -5, max: 5 },
                      { label: "Dedication Towards Work", name: "dedicationtowardsWork_admin", emp: "dedicationtowardsWork_emp", hod: "dedicationtowardsWork_hod", min: -5, max: 5 },
                      { label: "Obedient & Leadership", name: "obeideintorLeadership_admin", emp: "obeideintorLeadership_emp", hod: "obeideintorLeadership_hod", min: -10, max: 10 }
                    ].map(({ label, name, emp, hod, min, max }) => (
                      <tr key={name}>
                        <td className="text-center">{label}</td>
                        <td className="text-center">{max}</td>
                        <td className="text-center">{min}</td>
                        <td className="text-center">{formData[emp]}</td>
                        <td className="text-center">{formData[hod]}</td>
                      </tr>
                    ))}
                    <tr>
                      <th className="align-right"></th>
                      <th className="align-center">30</th>
                      <th className="align-center">-30</th>
                      <th className="align-center">{formData.totaldecipline_emp}</th>
                      <th className="align-center">{formData.totaldecipline_hod}</th>
                    </tr>
                    <tr>
                      <th className="align-right">Total Rating Marks</th>
                      <th className="align-center">100</th>
                      <th className="align-center">-100</th>
                      <th className="align-center">{formData.total_emp}</th>
                      <th className="align-center">{formData.total_hod}</th>
                    </tr>
                  </React.Fragment>
                </tbody>
              </table>
            </div>

            <div className="button-models">
              <button type="button" className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                Close
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

const UploadAppraisalDetailsByRHHOD = ({ i, getDepartmentAppraisalData }) => {
  const [show, setShow] = useState(false);

  const handleShow = () => {
    setFormData(i);
    setShow(true);
  }

  const handleClose = () => {
    setShow(false);
  }

  const [submitConfirmationError, setSubmitConfirmationError] = useState("");
  const [submitConfirmation, setSubmitConfirmation] = useState(false);

  const [formData, setFormData] = useState({
    emp_code: sessionStorage.getItem("emp_code"),

    accuracy_hod: 0,
    speedlyWork_hod: 0,
    SelfInitiative_hod: 0,
    responsbilityaccountability_hod: 0,
    independentWorking_hod: 0,
    decisonMakingCapsability_hod: 0,
    totalperformance_hod: 0,

    punctuality_hod: 0,
    teamwork_hod: 0,
    loyalitytowardsWork_hod: 0,
    dedicationtowardsWork_hod: 0,
    obeideintorLeadership_hod: 0,
    totaldecipline_hod: 0,

    total_hod: 0,

    hod_update_by: "",
    hod_update_datetime: ""
  });

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "emp_code",
    ];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)} is required !`;
      }
    });

    console.log("newErrors")
    console.log(newErrors)
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleInputChange = (e) => {
    const { name, value, min, max } = e.target;
    const minValue = parseFloat(min);
    const maxValue = parseFloat(max);
    const numericValue = parseFloat(value);

    if (i.rh_update_datetime === null) {
      setSubmitConfirmationError(`Reporting Head of "${i.name}" has not Uploaded the Ratings.`);
    }
    else {
      setSubmitConfirmationError("");
    }

    if (!isNaN(numericValue)) {
      // Allow numbers up to one decimal place
      const decimalPrecisionCheck = /^[0-9]+(\.[0-9]{1})?$/;

      if (!decimalPrecisionCheck.test(numericValue.toString())) {
        setErrors({
          ...errors,
          [name]: "Only up to one decimal place allowed.",
        });

        setInputState({
          ...inputState,
          [name]: "error",
        });
      } else if (numericValue < minValue) {
        setErrors({
          ...errors,
          [name]: `Min limit ${minValue}`,
        });

        setInputState({
          ...inputState,
          [name]: "error",
        });
      } else if (numericValue > maxValue) {
        setErrors({
          ...errors,
          [name]: `Max limit ${maxValue}`,
        });

        setInputState({
          ...inputState,
          [name]: "error",
        });
      } else {
        setErrors({
          ...errors,
          [name]: "",
        });

        setFormData({
          ...formData,
          [name]: numericValue,
        });

        setInputState({
          ...inputState,
          [name]: "success",
        });
      }
    } else {
      setErrors({});
      setFormData({
        ...formData,
        [name]: 0,
      });

      setInputState({
        ...inputState,
        [name]: "",
      });
    }
  };

  const [loading, setLoading] = useState(false);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const updatedFormData = {
      ...formData,

      totalperformance_hod:
        (
          (parseFloat(formData.accuracy_hod) || 0) +
          (parseFloat(formData.speedlyWork_hod) || 0) +
          (parseFloat(formData.SelfInitiative_hod) || 0) +
          (parseFloat(formData.responsbilityaccountability_hod) || 0) +
          (parseFloat(formData.independentWorking_hod) || 0) +
          (parseFloat(formData.decisonMakingCapability_hod) || 0)
        ).toFixed(1),

      totaldecipline_hod:
        (
          (parseFloat(formData.punctuality_hod) || 0) +
          (parseFloat(formData.teamwork_hod) || 0) +
          (parseFloat(formData.loyalitytowardsWork_hod) || 0) +
          (parseFloat(formData.dedicationtowardsWork_hod) || 0) +
          (parseFloat(formData.obeideintorLeadership_hod) || 0)
        ).toFixed(1),

      total_hod:
        (
          (parseFloat(formData.accuracy_hod) || 0) +
          (parseFloat(formData.speedlyWork_hod) || 0) +
          (parseFloat(formData.SelfInitiative_hod) || 0) +
          (parseFloat(formData.responsbilityaccountability_hod) || 0) +
          (parseFloat(formData.independentWorking_hod) || 0) +
          (parseFloat(formData.decisonMakingCapability_hod) || 0) +
          (parseFloat(formData.punctuality_hod) || 0) +
          (parseFloat(formData.teamwork_hod) || 0) +
          (parseFloat(formData.loyalitytowardsWork_hod) || 0) +
          (parseFloat(formData.dedicationtowardsWork_hod) || 0) +
          (parseFloat(formData.obeideintorLeadership_hod) || 0)
        ).toFixed(1),

      hod_update_by: `${sessionStorage.getItem('emp_code')}-${sessionStorage.getItem('name')}`,
      hod_update_datetime: new Date().toISOString(),
    };


    if (validateForm()) {

      console.log("updatedFormData")
      console.log(updatedFormData)

      setLoading(true);
      const loadingToastId = toast.loading("Loading: Please wait...");
      let ress = await axios.put(`${BASE_URL}/wfm/QuickAppresalUpdate/${i.id}/`, updatedFormData);
      if (ress.status === 200) {
        // window.location.reload();
        await getDepartmentAppraisalData();
        handleClose();
      } else {
        alert(ress);
      }
      try {

        // alert("appraisalspost");
      } catch (err) {
        handleAllError(err);
      } finally {
        setLoading(false);
        toast.dismiss(loadingToastId);
      }
    }
  };

  return (
    <>
      <button title="Update HOD Raitings" className="model-edit-button" onClick={handleShow}>
        <UpdatePencil />
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="ourcompany-model">
        <Modal.Header closeButton className="justify-center task-Tab-heading font-weight500 font-size-heading">
          <Modal.Title>HOD Appraisal Ratings Upload for "{i.emp_code}-{i.name}"</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ToastContainer position="top-center" autoClose={3000} hideProgressBar />

          <form className="register-form">
            <div className="flex-row justify-center">
              <table className="nested-table-lightblue width-40vw">
                <thead>
                  <tr>
                    <th className="align-center">Rating Analysis</th>
                    <th colSpan={2} className="align-center">Total Rating Marks</th>
                    <th className="align-center">Emp Rating</th>
                    <th className="align-center">Reporting Head Rating</th>
                    <th className="align-center width-5vw">HOD Rating</th>
                  </tr>
                  <tr>
                    <th className="align-center"></th>
                    <th className="align-center">+VE Marks</th>
                    <th className="align-center">-VE Marks</th>
                    <th className="align-center"></th>
                    <th className="align-center"></th>
                    <th className="align-center"></th>
                  </tr>
                  <tr>
                    <th colSpan={6} className="align-leftt">PERFORMANCE</th>
                  </tr>
                </thead>
                <tbody>
                  <React.Fragment>
                    {[
                      { label: "Accuracy", name: "accuracy_hod", emp: "accuracy_emp", rh: "accuracy_rh", min: -20, max: 20 },
                      { label: "Speedly Work", name: "speedlyWork_hod", emp: "speedlyWork_emp", rh: "speedlyWork_rh", min: -10, max: 10 },
                      { label: "Self Initiative", name: "SelfInitiative_hod", emp: "SelfInitiative_emp", rh: "SelfInitiative_rh", min: -15, max: 15 },
                      { label: "Responsibility & Accountability", name: "responsbilityaccountability_hod", emp: "responsbilityaccountability_emp", rh: "responsbilityaccountability_rh", min: -10, max: 10 },
                      { label: "Independent Working", name: "independentWorking_hod", emp: "independentWorking_emp", rh: "independentWorking_rh", min: -5, max: 5 },
                      { label: "Decision Making Capability", name: "decisonMakingCapability_hod", emp: "decisonMakingCapability_emp", rh: "decisonMakingCapability_rh", min: -10, max: 10 },
                    ].map(({ label, name, emp, rh, min, max }) => (
                      <tr key={name}>
                        <td className="text-center">{label}</td>
                        <td className="text-center">{max}</td>
                        <td className="text-center">{min}</td>
                        <td className="text-center">{formData[emp]}</td>
                        <td className="text-center">{formData[rh]}</td>
                        <td>
                          <input
                            type="number"
                            step={0}
                            min={min}
                            max={max}
                            name={name}
                            placeholder="Enter Rating"
                            value={formData[name]}
                            onChange={handleInputChange}
                            onWheel={(e) => e.target.blur()}
                            className={`form-input-tabular text-end ${errors[name] ? 'is-invalid' : ''}`}
                          />
                          <div className="align-center">
                            {errors[name] && <div className="error-message-input font-size-text">{errors[name]}</div>}
                          </div>
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <th className="align-right"></th>
                      <th className="align-center">70</th>
                      <th className="align-center">-70</th>
                      <th className="align-center">{formData.totalperformance_emp}</th>
                      <th className="align-center">{formData.totalperformance_rh}</th>
                      <th className="align-right">
                        {(
                          (parseFloat(formData.accuracy_hod) || 0) +
                          (parseFloat(formData.speedlyWork_hod) || 0) +
                          (parseFloat(formData.SelfInitiative_hod) || 0) +
                          (parseFloat(formData.responsbilityaccountability_hod) || 0) +
                          (parseFloat(formData.independentWorking_hod) || 0) +
                          (parseFloat(formData.decisonMakingCapability_hod) || 0)
                        ).toFixed(1)}
                      </th>
                    </tr>
                    <tr>
                      <th colSpan={6} className="align-leftt">DISCIPLINE AND BEHAVIOUR</th>
                    </tr>
                    {[
                      { label: "Punctuality", name: "punctuality_hod", emp: "punctuality_emp", rh: "punctuality_rh", min: -5, max: 5 },
                      { label: "Teamwork", name: "teamwork_hod", emp: "teamwork_emp", rh: "teamwork_rh", min: -5, max: 5 },
                      { label: "Loyalty Towards Work", name: "loyalitytowardsWork_hod", emp: "loyalitytowardsWork_emp", rh: "loyalitytowardsWork_rh", min: -5, max: 5 },
                      { label: "Dedication Towards Work", name: "dedicationtowardsWork_hod", emp: "dedicationtowardsWork_emp", rh: "dedicationtowardsWork_rh", min: -5, max: 5 },
                      { label: "Obedient & Leadership", name: "obeideintorLeadership_hod", emp: "obeideintorLeadership_emp", rh: "obeideintorLeadership_rh", min: -10, max: 10 }
                    ].map(({ label, name, emp, rh, min, max }) => (
                      <tr key={name}>
                        <td className="text-center">{label}</td>
                        <td className="text-center">{max}</td>
                        <td className="text-center">{min}</td>
                        <td className="text-center">{formData[emp]}</td>
                        <td className="text-center">{formData[rh]}</td>
                        <td>
                          <input
                            type="number"
                            step={0}
                            min={min}
                            max={max}
                            name={name}
                            placeholder="Enter Rating"
                            value={formData[name]}
                            onChange={handleInputChange}
                            onWheel={(e) => e.target.blur()}
                            className={`form-input-tabular text-end ${errors[name] ? 'is-invalid' : ''}`}
                          />
                          <div className="align-center">
                            {errors[name] && <div className="error-message-input font-size-text">{errors[name]}</div>}
                          </div>
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <th className="align-right"></th>
                      <th className="align-center">30</th>
                      <th className="align-center">-30</th>
                      <th className="align-center">{formData.totaldecipline_emp}</th>
                      <th className="align-center">{formData.totaldecipline_rh}</th>
                      <th className="align-right">
                        {(
                          (parseFloat(formData.punctuality_hod) || 0) +
                          (parseFloat(formData.teamwork_hod) || 0) +
                          (parseFloat(formData.loyalitytowardsWork_hod) || 0) +
                          (parseFloat(formData.dedicationtowardsWork_hod) || 0) +
                          (parseFloat(formData.obeideintorLeadership_hod) || 0)
                        ).toFixed(1)}
                      </th>
                    </tr>
                    <tr>
                      <th className="align-right">Total Rating Marks</th>
                      <th className="align-center">100</th>
                      <th className="align-center">-100</th>
                      <th className="align-center">{formData.total_emp}</th>
                      <th className="align-center">{formData.total_rh}</th>
                      <th className="align-right">
                        {(
                          (parseFloat(formData.accuracy_hod) || 0) +
                          (parseFloat(formData.speedlyWork_hod) || 0) +
                          (parseFloat(formData.SelfInitiative_hod) || 0) +
                          (parseFloat(formData.responsbilityaccountability_hod) || 0) +
                          (parseFloat(formData.independentWorking_hod) || 0) +
                          (parseFloat(formData.decisonMakingCapability_hod) || 0) +
                          (parseFloat(formData.punctuality_hod) || 0) +
                          (parseFloat(formData.teamwork_hod) || 0) +
                          (parseFloat(formData.loyalitytowardsWork_hod) || 0) +
                          (parseFloat(formData.dedicationtowardsWork_hod) || 0) +
                          (parseFloat(formData.obeideintorLeadership_hod) || 0)
                        ).toFixed(1)}
                      </th>
                    </tr>
                  </React.Fragment>
                </tbody>
              </table>
            </div>
            {submitConfirmationError === "" ?

              <div className="float-right">
                <label className="form-labels  font-weight500    font-size-subheading" style={{ marginTop: "5px" }}>
                  <input
                    type="checkbox"
                    checked={submitConfirmation}
                    onChange={(e) => setSubmitConfirmation(!submitConfirmation)}
                  />
                  <></>
                  <span>
                    Click to Confirm and Sumbit
                  </span>
                </label>

              </div>
              : (
                <div className="flex-column" style={{ marginTop: "5px" }}>
                  <div style={{ color: "red" }}>
                    Note:
                    <br />
                    {submitConfirmationError}
                  </div>
                  <label className="form-labels  font-weight500    font-size-subheading" style={{ marginTop: "5px" }}>
                    <input
                      type="checkbox"
                      checked={submitConfirmation}
                      onChange={(e) => setSubmitConfirmation(!submitConfirmation)}
                    />
                    <></>
                    <span>
                      Click to Confirm and Sumbit
                    </span>
                  </label>
                </div>
              )}


            <br />

            <div className="button-models">
              <button type="button" className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                Cancel
              </button>

              {submitConfirmation ?
                <button className="model-button font-weight500 model-button-leave font-size-heading" onClick={handleFormSubmit} disabled={loading}>
                  {loading ? 'Submiting...' : 'Submit'}
                </button>
                :
                <button
                  className={`model-button model-button-cancel  font-weight500`}
                  disabled={!submitConfirmation}
                >
                  {"Confirmation Needed"}
                </button>
              }
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

const ViewAppraisalDetailsByRHHOD = ({ i }) => {
  const [show, setShow] = useState(false);

  const handleShow = () => {
    setFormData(i)
    setShow(true);
  }

  const handleClose = () => {
    setShow(false);
  }

  const [formData, setFormData] = useState({});


  return (
    <>
      <button title="View EMP, RH, HOD Raitings" className="" onClick={handleShow}>
        <Eye />
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="ourcompany-model">
        <Modal.Header closeButton className="justify-center task-Tab-heading font-weight500 font-size-heading">
          <Modal.Title>HOD Appraisal Ratings of : "{i.emp_code}-{i.name}"</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ToastContainer position="top-center" autoClose={3000} hideProgressBar />

          <form className="register-form">
            <div className="flex-row justify-center">
              <table className="nested-table-lightblue width-40vw">
                <thead>
                  <tr>
                    <th className="align-center">Rating Analysis</th>
                    <th colSpan={2} className="align-center">Total Rating Marks</th>
                    <th className="align-center">Emp Rating</th>
                    <th className="align-center">Reporting Head Rating</th>
                    <th className="align-center">HOD Rating</th>
                  </tr>
                  <tr>
                    <th className="align-center"></th>
                    <th className="align-center">+VE Marks</th>
                    <th className="align-center">-VE Marks</th>
                    <th className="align-center"></th>
                    <th className="align-center"></th>
                    <th className="align-center"></th>
                  </tr>
                  <tr>
                    <th colSpan={6} className="align-leftt">PERFORMANCE</th>
                  </tr>
                </thead>
                <tbody>
                  <React.Fragment>
                    {[
                      { label: "Accuracy", name: "accuracy_admin", emp: "accuracy_emp", rh: "accuracy_rh", hod: "accuracy_hod", min: -20, max: 20 },
                      { label: "Speedly Work", name: "speedlyWork_admin", emp: "speedlyWork_emp", rh: "speedlyWork_rh", hod: "speedlyWork_hod", min: -10, max: 10 },
                      { label: "Self Initiative", name: "SelfInitiative_admin", emp: "SelfInitiative_emp", rh: "SelfInitiative_rh", hod: "SelfInitiative_hod", min: -15, max: 15 },
                      { label: "Responsibility & Accountability", name: "responsbilityaccountability_admin", emp: "responsbilityaccountability_emp", rh: "responsbilityaccountability_rh", hod: "responsbilityaccountability_hod", min: -10, max: 10 },
                      { label: "Independent Working", name: "independentWorking_admin", emp: "independentWorking_emp", rh: "independentWorking_rh", hod: "independentWorking_hod", min: -5, max: 5 },
                      { label: "Decision Making Capability", name: "decisonMakingCapability_admin", emp: "decisonMakingCapability_emp", rh: "decisonMakingCapability_rh", hod: "decisonMakingCapability_hod", min: -10, max: 10 },
                    ].map(({ label, name, emp, rh, hod, min, max }) => (
                      <tr key={name}>
                        <td className="text-center">{label}</td>
                        <td className="text-center">{max}</td>
                        <td className="text-center">{min}</td>
                        <td className="text-center">{formData[emp]}</td>
                        <td className="text-center">{formData[rh]}</td>
                        <td className="text-center">{formData[hod]}</td>
                      </tr>
                    ))}
                    <tr>
                      <th className="align-right"></th>
                      <th className="align-center">70</th>
                      <th className="align-center">-70</th>
                      <th className="align-center">{formData.totalperformance_emp}</th>
                      <th className="align-center">{formData.totalperformance_rh}</th>
                      <th className="align-center">{formData.totalperformance_hod}</th>
                    </tr>
                    <tr>
                      <th colSpan={6} className="align-leftt">DISCIPLINE AND BEHAVIOUR</th>
                    </tr>
                    {[
                      { label: "Punctuality", name: "punctuality_admin", emp: "punctuality_emp", rh: "punctuality_rh", hod: "punctuality_hod", min: -5, max: 5 },
                      { label: "Teamwork", name: "teamwork_admin", emp: "teamwork_emp", rh: "teamwork_rh", hod: "teamwork_hod", min: -5, max: 5 },
                      { label: "Loyalty Towards Work", name: "loyalitytowardsWork_admin", emp: "loyalitytowardsWork_emp", rh: "loyalitytowardsWork_rh", hod: "loyalitytowardsWork_hod", min: -5, max: 5 },
                      { label: "Dedication Towards Work", name: "dedicationtowardsWork_admin", emp: "dedicationtowardsWork_emp", rh: "dedicationtowardsWork_rh", hod: "dedicationtowardsWork_hod", min: -5, max: 5 },
                      { label: "Obedient & Leadership", name: "obeideintorLeadership_admin", emp: "obeideintorLeadership_emp", rh: "obeideintorLeadership_rh", hod: "obeideintorLeadership_hod", min: -10, max: 10 }
                    ].map(({ label, name, emp, rh, hod, min, max }) => (
                      <tr key={name}>
                        <td className="text-center">{label}</td>
                        <td className="text-center">{max}</td>
                        <td className="text-center">{min}</td>
                        <td className="text-center">{formData[emp]}</td>
                        <td className="text-center">{formData[rh]}</td>
                        <td className="text-center">{formData[hod]}</td>
                      </tr>
                    ))}
                    <tr>
                      <th className="align-right"></th>
                      <th className="align-center">30</th>
                      <th className="align-center">-30</th>
                      <th className="align-center">{formData.totaldecipline_emp}</th>
                      <th className="align-center">{formData.totaldecipline_rh}</th>
                      <th className="align-center">{formData.totaldecipline_hod}</th>
                    </tr>
                    <tr>
                      <th className="align-right">Total Rating Marks</th>
                      <th className="align-center">100</th>
                      <th className="align-center">-100</th>
                      <th className="align-center">{formData.total_emp}</th>
                      <th className="align-center">{formData.total_rh}</th>
                      <th className="align-center">{formData.total_hod}</th>
                    </tr>
                  </React.Fragment>
                </tbody>
              </table>
            </div>

            <div className="button-models">
              <button type="button" className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                Close
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

const UploadAppraisalDetailsByHODAdmin = ({ i, getDepartmentAppraisalData }) => {
  const [show, setShow] = useState(false);

  const handleShow = () => {
    setFormData(i);
    setShow(true);
  }

  const handleClose = () => {
    setShow(false);
  }


  const [submitConfirmation, setSubmitConfirmation] = useState(false);

  const [formData, setFormData] = useState({
    emp_code: sessionStorage.getItem("emp_code"),

    accuracy_admin: 0,
    speedlyWork_admin: 0,
    SelfInitiative_admin: 0,
    responsbilityaccountability_admin: 0,
    independentWorking_admin: 0,
    decisonMakingCapsability_admin: 0,
    totalperformance_admin: 0,

    punctuality_admin: 0,
    teamwork_admin: 0,
    loyalitytowardsWork_admin: 0,
    dedicationtowardsWork_admin: 0,
    obeideintorLeadership_admin: 0,
    totaldecipline_admin: 0,

    total_admin: 0,
    final_rating: 0,

    admin_update_by: "",
    admin_update_datetime: ""
  });

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "emp_code",
    ];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)} is required !`;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value, min, max } = e.target;
    const minValue = parseFloat(min);
    const maxValue = parseFloat(max);
    const numericValue = parseFloat(value);

    if (!isNaN(numericValue)) {
      // Allow numbers up to one decimal place
      const decimalPrecisionCheck = /^[0-9]+(\.[0-9]{1})?$/;

      if (!decimalPrecisionCheck.test(numericValue.toString())) {
        setErrors({
          ...errors,
          [name]: "Only up to one decimal place allowed.",
        });

        setInputState({
          ...inputState,
          [name]: "error",
        });
      } else if (numericValue < minValue) {
        setErrors({
          ...errors,
          [name]: `Min limit ${minValue}`,
        });

        setInputState({
          ...inputState,
          [name]: "error",
        });
      } else if (numericValue > maxValue) {
        setErrors({
          ...errors,
          [name]: `Max limit ${maxValue}`,
        });

        setInputState({
          ...inputState,
          [name]: "error",
        });
      } else {
        setErrors({
          ...errors,
          [name]: "",
        });

        setFormData({
          ...formData,
          [name]: numericValue,
        });

        setInputState({
          ...inputState,
          [name]: "success",
        });
      }
    } else {
      setErrors({});
      setFormData({
        ...formData,
        [name]: 0,
      });

      setInputState({
        ...inputState,
        [name]: "",
      });
    }
  };

  const [loading, setLoading] = useState(false);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const updatedFormData = {
      ...formData,

      totalperformance_admin: (
        (parseFloat(formData.accuracy_admin) || 0) +
        (parseFloat(formData.speedlyWork_admin) || 0) +
        (parseFloat(formData.SelfInitiative_admin) || 0) +
        (parseFloat(formData.responsbilityaccountability_admin) || 0) +
        (parseFloat(formData.independentWorking_admin) || 0) +
        (parseFloat(formData.decisonMakingCapability_admin) || 0)
      ).toFixed(1),

      totaldecipline_admin: (
        (parseFloat(formData.punctuality_admin) || 0) +
        (parseFloat(formData.teamwork_admin) || 0) +
        (parseFloat(formData.loyalitytowardsWork_admin) || 0) +
        (parseFloat(formData.dedicationtowardsWork_admin) || 0) +
        (parseFloat(formData.obeideintorLeadership_admin) || 0)
      ).toFixed(1),

      total_admin: (
        (parseFloat(formData.accuracy_admin) || 0) +
        (parseFloat(formData.speedlyWork_admin) || 0) +
        (parseFloat(formData.SelfInitiative_admin) || 0) +
        (parseFloat(formData.responsbilityaccountability_admin) || 0) +
        (parseFloat(formData.independentWorking_admin) || 0) +
        (parseFloat(formData.decisonMakingCapability_admin) || 0) +
        (parseFloat(formData.punctuality_admin) || 0) +
        (parseFloat(formData.teamwork_admin) || 0) +
        (parseFloat(formData.loyalitytowardsWork_admin) || 0) +
        (parseFloat(formData.dedicationtowardsWork_admin) || 0) +
        (parseFloat(formData.obeideintorLeadership_admin) || 0)
      ).toFixed(1),

      final_rating: (
        (parseFloat(formData.accuracy_admin) || 0) +
        (parseFloat(formData.speedlyWork_admin) || 0) +
        (parseFloat(formData.SelfInitiative_admin) || 0) +
        (parseFloat(formData.responsbilityaccountability_admin) || 0) +
        (parseFloat(formData.independentWorking_admin) || 0) +
        (parseFloat(formData.decisonMakingCapability_admin) || 0) +
        (parseFloat(formData.punctuality_admin) || 0) +
        (parseFloat(formData.teamwork_admin) || 0) +
        (parseFloat(formData.loyalitytowardsWork_admin) || 0) +
        (parseFloat(formData.dedicationtowardsWork_admin) || 0) +
        (parseFloat(formData.obeideintorLeadership_admin) || 0)
      ).toFixed(1),

      admin_update_by: `${sessionStorage.getItem('emp_code')}-${sessionStorage.getItem('name')}`,
      admin_update_datetime: new Date().toISOString(),
    };


    if (validateForm()) {

      console.log("updatedFormData")
      console.log(updatedFormData)

      setLoading(true);
      const loadingToastId = toast.loading("Loading: Please wait...");
      let ress = await axios.put(`${BASE_URL}/wfm/QuickAppresalUpdate/${i.id}/`, updatedFormData);
      if (ress.status === 200) {
        // window.location.reload();
        await getDepartmentAppraisalData();
        handleClose();
      } else {
        alert(ress);
      }
      try {

        // alert("appraisalspost");
      } catch (err) {
        handleAllError(err);
      } finally {
        setLoading(false);
        toast.dismiss(loadingToastId);
      }
    }
  };


  return (
    <>
      <button title="Update Admin Raitings" className="model-edit-button" onClick={handleShow}>
        <UpdatePencil />
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="ourcompany-model">
        <Modal.Header closeButton className="justify-center task-Tab-heading font-weight500 font-size-heading">
          <Modal.Title>Admin Appraisal Ratings Upload for "{i.emp_code}-{i.name}" </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ToastContainer position="top-center" autoClose={3000} hideProgressBar />

          <form className="register-form">
            <div className="flex-row justify-center">
              <table className="nested-table-lightblue width-40vw">
                <thead>
                  <tr>
                    <th className="align-center">Rating Analysis</th>
                    <th colSpan={2} className="align-center">Total Rating Marks</th>
                    <th className="align-center">Emp Rating</th>
                    <th className="align-center">HOD Rating</th>
                    <th className="align-center width-5vw">Admin Rating</th>
                  </tr>
                  <tr>
                    <th className="align-center"></th>
                    <th className="align-center">+VE Marks</th>
                    <th className="align-center">-VE Marks</th>
                    <th className="align-center"></th>
                    <th className="align-center"></th>
                    <th className="align-center"></th>
                  </tr>
                  <tr>
                    <th colSpan={6} className="align-leftt">PERFORMANCE</th>
                  </tr>
                </thead>
                <tbody>
                  <React.Fragment>
                    {[
                      { label: "Accuracy", name: "accuracy_admin", emp: "accuracy_emp", hod: "accuracy_hod", min: -20, max: 20 },
                      { label: "Speedly Work", name: "speedlyWork_admin", emp: "speedlyWork_emp", hod: "speedlyWork_hod", min: -10, max: 10 },
                      { label: "Self Initiative", name: "SelfInitiative_admin", emp: "SelfInitiative_emp", hod: "SelfInitiative_hod", min: -15, max: 15 },
                      { label: "Responsibility & Accountability", name: "responsbilityaccountability_admin", emp: "responsbilityaccountability_emp", hod: "responsbilityaccountability_hod", min: -10, max: 10 },
                      { label: "Independent Working", name: "independentWorking_admin", emp: "independentWorking_emp", hod: "independentWorking_hod", min: -5, max: 5 },
                      { label: "Decision Making Capability", name: "decisonMakingCapability_admin", emp: "decisonMakingCapability_emp", hod: "decisonMakingCapability_hod", min: -10, max: 10 },
                    ].map(({ label, name, emp, hod, min, max }) => (
                      <tr key={name}>
                        <td className="text-center">{label}</td>
                        <td className="text-center">{max}</td>
                        <td className="text-center">{min}</td>
                        <td className="text-center">{formData[emp]}</td>
                        <td className="text-center">{formData[hod]}</td>
                        <td>
                          <input
                            type="number"
                            step={0}
                            min={min}
                            max={max}
                            name={name}
                            placeholder="Enter Rating"
                            value={formData[name]}
                            onChange={handleInputChange}
                            onWheel={(e) => e.target.blur()}
                            className={`form-input-tabular text-end ${errors[name] ? 'is-invalid' : ''}`}
                          />
                          <div className="align-center">
                            {errors[name] && <div className="error-message-input font-size-text">{errors[name]}</div>}
                          </div>
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <th className="align-right"></th>
                      <th className="align-center">70</th>
                      <th className="align-center">-70</th>
                      <th className="align-center">{formData.totalperformance_emp}</th>
                      <th className="align-center">{formData.totalperformance_hod}</th>
                      <th className="align-right">
                        {(
                          (parseFloat(formData.accuracy_admin) || 0) +
                          (parseFloat(formData.speedlyWork_admin) || 0) +
                          (parseFloat(formData.SelfInitiative_admin) || 0) +
                          (parseFloat(formData.responsbilityaccountability_admin) || 0) +
                          (parseFloat(formData.independentWorking_admin) || 0) +
                          (parseFloat(formData.decisonMakingCapability_admin) || 0)
                        ).toFixed(1)}
                      </th>
                    </tr>
                    <tr>
                      <th colSpan={6} className="align-leftt">DISCIPLINE AND BEHAVIOUR</th>
                    </tr>
                    {[
                      { label: "Punctuality", name: "punctuality_admin", emp: "punctuality_emp", hod: "punctuality_hod", min: -5, max: 5 },
                      { label: "Teamwork", name: "teamwork_admin", emp: "teamwork_emp", hod: "teamwork_hod", min: -5, max: 5 },
                      { label: "Loyalty Towards Work", name: "loyalitytowardsWork_admin", emp: "loyalitytowardsWork_emp", hod: "loyalitytowardsWork_hod", min: -5, max: 5 },
                      { label: "Dedication Towards Work", name: "dedicationtowardsWork_admin", emp: "dedicationtowardsWork_emp", hod: "dedicationtowardsWork_hod", min: -5, max: 5 },
                      { label: "Obedient & Leadership", name: "obeideintorLeadership_admin", emp: "obeideintorLeadership_emp", hod: "obeideintorLeadership_hod", min: -10, max: 10 }
                    ].map(({ label, name, emp, hod, min, max }) => (
                      <tr key={name}>
                        <td className="text-center">{label}</td>
                        <td className="text-center">{max}</td>
                        <td className="text-center">{min}</td>
                        <td className="text-center">{formData[emp]}</td>
                        <td title={`${hod}: ${formData[hod]}`} className="text-center">{formData[hod]}</td>
                        <td>
                          <input
                            type="number"
                            step={0}
                            min={min}
                            max={max}
                            name={name}
                            placeholder="Enter Rating"
                            value={formData[name]}
                            onChange={handleInputChange}
                            onWheel={(e) => e.target.blur()}
                            className={`form-input-tabular text-end ${errors[name] ? 'is-invalid' : ''}`}
                          />
                          <div className="align-center">
                            {errors[name] && <div className="error-message-input font-size-text">{errors[name]}</div>}
                          </div>
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <th className="align-right"></th>
                      <th className="align-center">30</th>
                      <th className="align-center">-30</th>
                      <th className="align-center">{formData.totaldecipline_emp}</th>
                      <th className="align-center">{formData.totaldecipline_hod}</th>
                      <th className="align-right">
                        {(
                          (parseFloat(formData.punctuality_admin) || 0) +
                          (parseFloat(formData.teamwork_admin) || 0) +
                          (parseFloat(formData.loyalitytowardsWork_admin) || 0) +
                          (parseFloat(formData.dedicationtowardsWork_admin) || 0) +
                          (parseFloat(formData.obeideintorLeadership_admin) || 0)
                        ).toFixed(1)}
                      </th>
                    </tr>
                    <tr>
                      <th className="align-right">Total Rating Marks</th>
                      <th className="align-center">100</th>
                      <th className="align-center">-100</th>
                      <th className="align-center">{formData.total_emp}</th>
                      <th className="align-center">{formData.total_hod}</th>
                      <th className="align-right">
                        {(
                          (parseFloat(formData.accuracy_admin) || 0) +
                          (parseFloat(formData.speedlyWork_admin) || 0) +
                          (parseFloat(formData.SelfInitiative_admin) || 0) +
                          (parseFloat(formData.responsbilityaccountability_admin) || 0) +
                          (parseFloat(formData.independentWorking_admin) || 0) +
                          (parseFloat(formData.decisonMakingCapability_admin) || 0) +
                          (parseFloat(formData.punctuality_admin) || 0) +
                          (parseFloat(formData.teamwork_admin) || 0) +
                          (parseFloat(formData.loyalitytowardsWork_admin) || 0) +
                          (parseFloat(formData.dedicationtowardsWork_admin) || 0) +
                          (parseFloat(formData.obeideintorLeadership_admin) || 0)
                        ).toFixed(1)}
                      </th>
                    </tr>
                  </React.Fragment>
                </tbody>
              </table>
            </div>

            <div className="float-right">
              <label className="form-labels  font-weight500    font-size-subheading" style={{ marginTop: "5px" }}>
                <input
                  type="checkbox"
                  checked={submitConfirmation}
                  onChange={(e) => setSubmitConfirmation(!submitConfirmation)}
                />
                <></>
                <span>
                  Click to Confirm and Sumbit
                </span>
              </label>
            </div>
            <br />

            <div className="button-models">
              <button type="button" className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                Cancel
              </button>

              {submitConfirmation ?
                <button className="model-button font-weight500 model-button-leave font-size-heading" onClick={handleFormSubmit} disabled={loading}>
                  {loading ? 'Submiting...' : 'Submit'}
                </button>
                :
                <button
                  className={`model-button model-button-cancel  font-weight500`}
                  disabled={!submitConfirmation}
                >
                  {"Confirmation Needed"}
                </button>
              }
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

const ViewAppraisalDetailsHODAdmin = ({ i }) => {
  const [show, setShow] = useState(false);

  const handleShow = () => {
    setFormData(i);
    setShow(true);
  }

  const handleClose = () => {
    setShow(false);
  }

  const [formData, setFormData] = useState({});




  return (
    <>
      <button title="View Appraisal Raitings" className="" onClick={handleShow}>
        <Eye />
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="ourcompany-model">
        <Modal.Header closeButton className="justify-center task-Tab-heading font-weight500 font-size-heading">
          <Modal.Title>Appraisal Ratings - "{i.emp_code}-{i.name}"</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ToastContainer position="top-center" autoClose={3000} hideProgressBar />

          <form className="register-form">
            <div className="flex-row justify-center">
              <table className="nested-table-lightblue width-40vw">
                <thead>
                  <tr>
                    <th className="align-center">Rating Analysis</th>
                    <th colSpan={2} className="align-center">Total Rating Marks</th>
                    <th className="align-center">Emp Rating</th>
                    <th className="align-center">HOD Rating</th>
                    <th className="align-center">Admin Rating</th>
                  </tr>
                  <tr>
                    <th className="align-center"></th>
                    <th className="align-center">+VE Marks</th>
                    <th className="align-center">-VE Marks</th>
                    <th className="align-center"></th>
                    <th className="align-center"></th>
                    <th className="align-center"></th>
                  </tr>
                  <tr>
                    <th colSpan={6} className="align-leftt">PERFORMANCE</th>
                  </tr>
                </thead>
                <tbody>
                  <React.Fragment>
                    {[
                      { label: "Accuracy", name: "accuracy_admin", emp: "accuracy_emp", hod: "accuracy_hod", min: -20, max: 20 },
                      { label: "Speedly Work", name: "speedlyWork_admin", emp: "speedlyWork_emp", hod: "speedlyWork_hod", min: -10, max: 10 },
                      { label: "Self Initiative", name: "SelfInitiative_admin", emp: "SelfInitiative_emp", hod: "SelfInitiative_hod", min: -15, max: 15 },
                      { label: "Responsibility & Accountability", name: "responsbilityaccountability_admin", emp: "responsbilityaccountability_emp", hod: "responsbilityaccountability_hod", min: -10, max: 10 },
                      { label: "Independent Working", name: "independentWorking_admin", emp: "independentWorking_emp", hod: "independentWorking_hod", min: -5, max: 5 },
                      { label: "Decision Making Capability", name: "decisonMakingCapability_admin", emp: "decisonMakingCapability_emp", hod: "decisonMakingCapability_hod", min: -10, max: 10 },
                    ].map(({ label, name, emp, hod, min, max }) => (
                      <tr key={name}>
                        <td className="text-center">{label}</td>
                        <td className="text-center">{max}</td>
                        <td className="text-center">{min}</td>
                        <td className="text-center">{formData[emp]}</td>
                        <td className="text-center">{formData[hod]}</td>
                        <td className="text-center">{formData[name]}</td>
                      </tr>
                    ))}
                    <tr>
                      <th className="align-center"></th>
                      <th className="align-center">70</th>
                      <th className="align-center">-70</th>
                      <th className="align-center">{formData.totalperformance_emp}</th>
                      <th className="align-center">{formData.totalperformance_hod}</th>
                      <th className="align-center">
                        {(
                          (parseFloat(formData.accuracy_admin) || 0) +
                          (parseFloat(formData.speedlyWork_admin) || 0) +
                          (parseFloat(formData.SelfInitiative_admin) || 0) +
                          (parseFloat(formData.responsbilityaccountability_admin) || 0) +
                          (parseFloat(formData.independentWorking_admin) || 0) +
                          (parseFloat(formData.decisonMakingCapability_admin) || 0)
                        ).toFixed(1)}
                      </th>
                    </tr>
                    <tr>
                      <th colSpan={6} className="align-leftt">DISCIPLINE AND BEHAVIOUR</th>
                    </tr>
                    {[
                      { label: "Punctuality", name: "punctuality_admin", emp: "punctuality_emp", hod: "punctuality_hod", min: -5, max: 5 },
                      { label: "Teamwork", name: "teamwork_admin", emp: "teamwork_emp", hod: "teamwork_hod", min: -5, max: 5 },
                      { label: "Loyalty Towards Work", name: "loyalitytowardsWork_admin", emp: "loyalitytowardsWork_emp", hod: "loyalitytowardsWork_hod", min: -5, max: 5 },
                      { label: "Dedication Towards Work", name: "dedicationtowardsWork_admin", emp: "dedicationtowardsWork_emp", hod: "dedicationtowardsWork_hod", min: -5, max: 5 },
                      { label: "Obedient & Leadership", name: "obeideintorLeadership_admin", emp: "obeideintorLeadership_emp", hod: "obeideintorLeadership_hod", min: -10, max: 10 }
                    ].map(({ label, name, emp, hod, min, max }) => (
                      <tr key={name}>
                        <td className="text-center">{label}</td>
                        <td className="text-center">{max}</td>
                        <td className="text-center">{min}</td>
                        <td className="text-center">{formData[emp]}</td>
                        <td className="text-center">{formData[hod]}</td>
                        <td className="text-center">{formData[name]}</td>
                      </tr>
                    ))}
                    <tr>
                      <th className="align-center"></th>
                      <th className="align-center">30</th>
                      <th className="align-center">-30</th>
                      <th className="align-center">{formData.totaldecipline_emp}</th>
                      <th className="align-center">{formData.totaldecipline_hod}</th>
                      <th className="align-center">
                        {(
                          (parseFloat(formData.punctuality_admin) || 0) +
                          (parseFloat(formData.teamwork_admin) || 0) +
                          (parseFloat(formData.loyalitytowardsWork_admin) || 0) +
                          (parseFloat(formData.dedicationtowardsWork_admin) || 0) +
                          (parseFloat(formData.obeideintorLeadership_admin) || 0)
                        ).toFixed(1)}
                      </th>
                    </tr>
                    <tr>
                      <th className="align-right">Total Rating Marks</th>
                      <th className="align-center">100</th>
                      <th className="align-center">-100</th>
                      <th className="align-center">{formData.total_emp}</th>
                      <th className="align-center">{formData.total_hod}</th>
                      <th className="align-center">
                        {(
                          (parseFloat(formData.accuracy_admin) || 0) +
                          (parseFloat(formData.speedlyWork_admin) || 0) +
                          (parseFloat(formData.SelfInitiative_admin) || 0) +
                          (parseFloat(formData.responsbilityaccountability_admin) || 0) +
                          (parseFloat(formData.independentWorking_admin) || 0) +
                          (parseFloat(formData.decisonMakingCapability_admin) || 0) +
                          (parseFloat(formData.punctuality_admin) || 0) +
                          (parseFloat(formData.teamwork_admin) || 0) +
                          (parseFloat(formData.loyalitytowardsWork_admin) || 0) +
                          (parseFloat(formData.dedicationtowardsWork_admin) || 0) +
                          (parseFloat(formData.obeideintorLeadership_admin) || 0)
                        ).toFixed(1)}
                      </th>
                    </tr>
                  </React.Fragment>
                </tbody>
              </table>
            </div>

            <div className="button-models">
              <button type="button" className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                Close
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

const UploadAppraisalDetailsByRHHODAdmin = ({ i, getDepartmentAppraisalData }) => {
  const [show, setShow] = useState(false);

  const handleShow = () => {
    setFormData(i);
    setShow(true);
  }

  const handleClose = () => {
    setShow(false);
  }


  const [submitConfirmation, setSubmitConfirmation] = useState(false);

  const [formData, setFormData] = useState({
    emp_code: sessionStorage.getItem("emp_code"),

    accuracy_admin: 0,
    speedlyWork_admin: 0,
    SelfInitiative_admin: 0,
    responsbilityaccountability_admin: 0,
    independentWorking_admin: 0,
    decisonMakingCapsability_admin: 0,
    totalperformance_admin: 0,

    punctuality_admin: 0,
    teamwork_admin: 0,
    loyalitytowardsWork_admin: 0,
    dedicationtowardsWork_admin: 0,
    obeideintorLeadership_admin: 0,
    totaldecipline_admin: 0,

    total_admin: 0,
    final_rating: 0,

    admin_update_by: "",
    admin_update_datetime: ""
  });

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "emp_code",
    ];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)} is required !`;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value, min, max } = e.target;
    const minValue = parseFloat(min);
    const maxValue = parseFloat(max);
    const numericValue = parseFloat(value);

    if (!isNaN(numericValue)) {
      // Allow numbers up to one decimal place
      const decimalPrecisionCheck = /^[0-9]+(\.[0-9]{1})?$/;

      if (!decimalPrecisionCheck.test(numericValue.toString())) {
        setErrors({
          ...errors,
          [name]: "Only up to one decimal place allowed.",
        });

        setInputState({
          ...inputState,
          [name]: "error",
        });
      } else if (numericValue < minValue) {
        setErrors({
          ...errors,
          [name]: `Min limit ${minValue}`,
        });

        setInputState({
          ...inputState,
          [name]: "error",
        });
      } else if (numericValue > maxValue) {
        setErrors({
          ...errors,
          [name]: `Max limit ${maxValue}`,
        });

        setInputState({
          ...inputState,
          [name]: "error",
        });
      } else {
        setErrors({
          ...errors,
          [name]: "",
        });

        setFormData({
          ...formData,
          [name]: numericValue,
        });

        setInputState({
          ...inputState,
          [name]: "success",
        });
      }
    } else {
      setErrors({});
      setFormData({
        ...formData,
        [name]: 0,
      });

      setInputState({
        ...inputState,
        [name]: "",
      });
    }
  };


  const [loading, setLoading] = useState(false);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const updatedFormData = {
      ...formData,
      totalperformance_admin:
        (
          (parseFloat(formData.accuracy_admin) || 0) +
          (parseFloat(formData.speedlyWork_admin) || 0) +
          (parseFloat(formData.SelfInitiative_admin) || 0) +
          (parseFloat(formData.responsbilityaccountability_admin) || 0) +
          (parseFloat(formData.independentWorking_admin) || 0) +
          (parseFloat(formData.decisonMakingCapability_admin) || 0)
        ).toFixed(1),

      totaldecipline_admin:
        (
          (parseFloat(formData.punctuality_admin) || 0) +
          (parseFloat(formData.teamwork_admin) || 0) +
          (parseFloat(formData.loyalitytowardsWork_admin) || 0) +
          (parseFloat(formData.dedicationtowardsWork_admin) || 0) +
          (parseFloat(formData.obeideintorLeadership_admin) || 0)
        ).toFixed(1),

      total_admin:
        (
          (parseFloat(formData.accuracy_admin) || 0) +
          (parseFloat(formData.speedlyWork_admin) || 0) +
          (parseFloat(formData.SelfInitiative_admin) || 0) +
          (parseFloat(formData.responsbilityaccountability_admin) || 0) +
          (parseFloat(formData.independentWorking_admin) || 0) +
          (parseFloat(formData.decisonMakingCapability_admin) || 0) +
          (parseFloat(formData.punctuality_admin) || 0) +
          (parseFloat(formData.teamwork_admin) || 0) +
          (parseFloat(formData.loyalitytowardsWork_admin) || 0) +
          (parseFloat(formData.dedicationtowardsWork_admin) || 0) +
          (parseFloat(formData.obeideintorLeadership_admin) || 0)
        ).toFixed(1),

      final_rating:
        (
          (parseFloat(formData.accuracy_admin) || 0) +
          (parseFloat(formData.speedlyWork_admin) || 0) +
          (parseFloat(formData.SelfInitiative_admin) || 0) +
          (parseFloat(formData.responsbilityaccountability_admin) || 0) +
          (parseFloat(formData.independentWorking_admin) || 0) +
          (parseFloat(formData.decisonMakingCapability_admin) || 0) +
          (parseFloat(formData.punctuality_admin) || 0) +
          (parseFloat(formData.teamwork_admin) || 0) +
          (parseFloat(formData.loyalitytowardsWork_admin) || 0) +
          (parseFloat(formData.dedicationtowardsWork_admin) || 0) +
          (parseFloat(formData.obeideintorLeadership_admin) || 0)
        ).toFixed(1),

      admin_update_by: `${sessionStorage.getItem('emp_code')}-${sessionStorage.getItem('name')}`,
      admin_update_datetime: new Date().toISOString(),
    };


    if (validateForm()) {

      console.log("updatedFormData")
      console.log(updatedFormData)

      setLoading(true);
      const loadingToastId = toast.loading("Loading: Please wait...");
      let ress = await axios.put(`${BASE_URL}/wfm/QuickAppresalUpdate/${i.id}/`, updatedFormData);
      if (ress.status === 200) {
        // window.location.reload();
        await getDepartmentAppraisalData();
        handleClose();
      } else {
        alert(ress);
      }
      try {

        // alert("appraisalspost");
      } catch (err) {
        handleAllError(err);
      } finally {
        setLoading(false);
        toast.dismiss(loadingToastId);
      }
    }
  };


  return (
    <>
      <button title="Update Admin Raitings" className="model-edit-button" onClick={handleShow}>
        <UpdatePencil />
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="ourcompany-model">
        <Modal.Header closeButton className="justify-center task-Tab-heading font-weight500 font-size-heading">
          <Modal.Title>Admin Appraisal Ratings Upload for "{i.emp_code}-{i.name}" </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ToastContainer position="top-center" autoClose={3000} hideProgressBar />

          <form className="register-form">
            <div className="flex-row justify-center">
              <table className="nested-table-lightblue width-40vw">
                <thead>
                  <tr>
                    <th className="align-center">Rating Analysis</th>
                    <th colSpan={2} className="align-center">Total Rating Marks</th>
                    <th className="align-center">Emp Rating</th>
                    <th className="align-center">Reporting Head Rating</th>
                    <th className="align-center">HOD Rating</th>
                    <th className="align-center width-5vw">Admin Rating</th>
                  </tr>
                  <tr>
                    <th className="align-center"></th>
                    <th className="align-center">+VE Marks</th>
                    <th className="align-center">-VE Marks</th>
                    <th className="align-center"></th>
                    <th className="align-center"></th>
                    <th className="align-center"></th>
                    <th className="align-center"></th>
                  </tr>
                  <tr>
                    <th colSpan={7} className="align-leftt">PERFORMANCE</th>
                  </tr>
                </thead>
                <tbody>
                  <React.Fragment>
                    {[
                      { label: "Accuracy", name: "accuracy_admin", emp: "accuracy_emp", rh: "accuracy_rh", hod: "accuracy_hod", min: -20, max: 20 },
                      { label: "Speedly Work", name: "speedlyWork_admin", emp: "speedlyWork_emp", rh: "speedlyWork_rh", hod: "speedlyWork_hod", min: -10, max: 10 },
                      { label: "Self Initiative", name: "SelfInitiative_admin", emp: "SelfInitiative_emp", rh: "SelfInitiative_rh", hod: "SelfInitiative_hod", min: -15, max: 15 },
                      { label: "Responsibility & Accountability", name: "responsbilityaccountability_admin", emp: "responsbilityaccountability_emp", rh: "responsbilityaccountability_rh", hod: "responsbilityaccountability_hod", min: -10, max: 10 },
                      { label: "Independent Working", name: "independentWorking_admin", emp: "independentWorking_emp", rh: "independentWorking_rh", hod: "independentWorking_hod", min: -5, max: 5 },
                      { label: "Decision Making Capability", name: "decisonMakingCapability_admin", emp: "decisonMakingCapability_emp", rh: "decisonMakingCapability_rh", hod: "decisonMakingCapability_hod", min: -10, max: 10 },
                    ].map(({ label, name, emp, rh, hod, min, max }) => (
                      <tr key={name}>
                        <td className="text-center">{label}</td>
                        <td className="text-center">{max}</td>
                        <td className="text-center">{min}</td>
                        <td className="text-center">{formData[emp]}</td>
                        <td className="text-center">{formData[rh]}</td>
                        <td className="text-center">{formData[hod]}</td>
                        <td>
                          <input
                            type="number"
                            step={0}
                            min={min}
                            max={max}
                            name={name}
                            placeholder="Enter Rating"
                            value={formData[name]}
                            onChange={handleInputChange}
                            onWheel={(e) => e.target.blur()}
                            className={`form-input-tabular text-end ${errors[name] ? 'is-invalid' : ''}`}
                          />
                          <div className="align-center">
                            {errors[name] && <div className="error-message-input font-size-text">{errors[name]}</div>}
                          </div>
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <th className="align-right"></th>
                      <th className="align-center">70</th>
                      <th className="align-center">-70</th>
                      <th className="align-center">{formData.totalperformance_emp}</th>
                      <th className="align-center">{formData.totalperformance_rh}</th>
                      <th className="align-center">{formData.totalperformance_hod}</th>
                      <th className="align-right">
                        {(
                          (parseFloat(formData.accuracy_admin) || 0) +
                          (parseFloat(formData.speedlyWork_admin) || 0) +
                          (parseFloat(formData.SelfInitiative_admin) || 0) +
                          (parseFloat(formData.responsbilityaccountability_admin) || 0) +
                          (parseFloat(formData.independentWorking_admin) || 0) +
                          (parseFloat(formData.decisonMakingCapability_admin) || 0)
                        ).toFixed(1)}
                      </th>
                    </tr>
                    <tr>
                      <th colSpan={7} className="align-leftt">DISCIPLINE AND BEHAVIOUR</th>
                    </tr>
                    {[
                      { label: "Punctuality", name: "punctuality_admin", emp: "punctuality_emp", rh: "punctuality_rh", hod: "punctuality_hod", min: -5, max: 5 },
                      { label: "Teamwork", name: "teamwork_admin", emp: "teamwork_emp", rh: "teamwork_rh", hod: "teamwork_hod", min: -5, max: 5 },
                      { label: "Loyalty Towards Work", name: "loyalitytowardsWork_admin", emp: "loyalitytowardsWork_emp", rh: "loyalitytowardsWork_rh", hod: "loyalitytowardsWork_hod", min: -5, max: 5 },
                      { label: "Dedication Towards Work", name: "dedicationtowardsWork_admin", emp: "dedicationtowardsWork_emp", rh: "dedicationtowardsWork_rh", hod: "dedicationtowardsWork_hod", min: -5, max: 5 },
                      { label: "Obedient & Leadership", name: "obeideintorLeadership_admin", emp: "obeideintorLeadership_emp", rh: "obeideintorLeadership_rh", hod: "obeideintorLeadership_hod", min: -10, max: 10 }
                    ].map(({ label, name, emp, rh, hod, min, max }) => (
                      <tr key={name}>
                        <td className="text-center">{label}</td>
                        <td className="text-center">{max}</td>
                        <td className="text-center">{min}</td>
                        <td className="text-center">{formData[emp]}</td>
                        <td className="text-center">{formData[rh]}</td>
                        <td className="text-center">{formData[hod]}</td>
                        <td>
                          <input
                            type="number"
                            step={0}
                            min={min}
                            max={max}
                            name={name}
                            placeholder="Enter Rating"
                            value={formData[name]}
                            onChange={handleInputChange}
                            onWheel={(e) => e.target.blur()}
                            className={`form-input-tabular text-end ${errors[name] ? 'is-invalid' : ''}`}
                          />
                          <div className="align-center">
                            {errors[name] && <div className="error-message-input font-size-text">{errors[name]}</div>}
                          </div>
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <th className="align-right"></th>
                      <th className="align-center">30</th>
                      <th className="align-center">-30</th>
                      <th className="align-center">{formData.totaldecipline_emp}</th>
                      <th className="align-center">{formData.totaldecipline_rh}</th>
                      <th className="align-center">{formData.totaldecipline_hod}</th>
                      <th className="align-right">
                        {(
                          (parseFloat(formData.punctuality_admin) || 0) +
                          (parseFloat(formData.teamwork_admin) || 0) +
                          (parseFloat(formData.loyalitytowardsWork_admin) || 0) +
                          (parseFloat(formData.dedicationtowardsWork_admin) || 0) +
                          (parseFloat(formData.obeideintorLeadership_admin) || 0)
                        ).toFixed(1)}
                      </th>
                    </tr>
                    <tr>
                      <th className="align-right">Total Rating Marks</th>
                      <th className="align-center">100</th>
                      <th className="align-center">-100</th>
                      <th className="align-center">{formData.total_emp}</th>
                      <th className="align-center">{formData.total_rh}</th>
                      <th className="align-center">{formData.total_hod}</th>
                      <th className="align-right">
                        {(
                          (parseFloat(formData.accuracy_admin) || 0) +
                          (parseFloat(formData.speedlyWork_admin) || 0) +
                          (parseFloat(formData.SelfInitiative_admin) || 0) +
                          (parseFloat(formData.responsbilityaccountability_admin) || 0) +
                          (parseFloat(formData.independentWorking_admin) || 0) +
                          (parseFloat(formData.decisonMakingCapability_admin) || 0) +
                          (parseFloat(formData.punctuality_admin) || 0) +
                          (parseFloat(formData.teamwork_admin) || 0) +
                          (parseFloat(formData.loyalitytowardsWork_admin) || 0) +
                          (parseFloat(formData.dedicationtowardsWork_admin) || 0) +
                          (parseFloat(formData.obeideintorLeadership_admin) || 0)
                        ).toFixed(1)}
                      </th>
                    </tr>
                  </React.Fragment>
                </tbody>
              </table>
            </div>

            <div className="float-right">
              <label className="form-labels  font-weight500    font-size-subheading" style={{ marginTop: "5px" }}>
                <input
                  type="checkbox"
                  checked={submitConfirmation}
                  onChange={(e) => setSubmitConfirmation(!submitConfirmation)}
                />
                <></>
                <span>
                  Click to Confirm and Sumbit
                </span>
              </label>
            </div>
            <br />

            <div className="button-models">
              <button type="button" className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                Cancel
              </button>

              {submitConfirmation ?
                <button className="model-button font-weight500 model-button-leave font-size-heading" onClick={handleFormSubmit} disabled={loading}>
                  {loading ? 'Submiting...' : 'Submit'}
                </button>
                :
                <button
                  className={`model-button model-button-cancel  font-weight500`}
                  disabled={!submitConfirmation}
                >
                  {"Confirmation Needed"}
                </button>
              }
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

const ViewAppraisalDetailsRHHODAdmin = ({ i }) => {
  const [show, setShow] = useState(false);

  const handleShow = () => {
    setFormData(i);
    setShow(true);
  }

  const handleClose = () => {
    setShow(false);
  }

  const [formData, setFormData] = useState({});




  return (
    <>
      <button title="View Appraisal Raitings" className="" onClick={handleShow}>
        <Eye />
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="ourcompany-model">
        <Modal.Header closeButton className="justify-center task-Tab-heading font-weight500 font-size-heading">
          <Modal.Title>Appraisal Ratings - "{i.emp_code}-{i.name}"</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ToastContainer position="top-center" autoClose={3000} hideProgressBar />

          <form className="register-form">
            <div className="flex-row justify-center">
              <table className="nested-table-lightblue width-40vw">
                <thead>
                  <tr>
                    <th className="align-center">Rating Analysis</th>
                    <th colSpan={2} className="align-center">Total Rating Marks</th>
                    <th className="align-center">Emp Rating</th>
                    <th className="align-center">Reporting Head Rating</th>
                    <th className="align-center">HOD Rating</th>
                    <th className="align-center">Admin Rating</th>
                  </tr>
                  <tr>
                    <th className="align-center"></th>
                    <th className="align-center">+VE Marks</th>
                    <th className="align-center">-VE Marks</th>
                    <th className="align-center"></th>
                    <th className="align-center"></th>
                    <th className="align-center"></th>
                    <th className="align-center"></th>
                  </tr>
                  <tr>
                    <th colSpan={7} className="align-leftt">PERFORMANCE</th>
                  </tr>
                </thead>
                <tbody>
                  <React.Fragment>
                    {[
                      { label: "Accuracy", name: "accuracy_admin", emp: "accuracy_emp", rh: "accuracy_rh", hod: "accuracy_hod", min: -20, max: 20 },
                      { label: "Speedly Work", name: "speedlyWork_admin", emp: "speedlyWork_emp", rh: "speedlyWork_rh", hod: "speedlyWork_hod", min: -10, max: 10 },
                      { label: "Self Initiative", name: "SelfInitiative_admin", emp: "SelfInitiative_emp", rh: "SelfInitiative_rh", hod: "SelfInitiative_hod", min: -15, max: 15 },
                      { label: "Responsibility & Accountability", name: "responsbilityaccountability_admin", emp: "responsbilityaccountability_emp", rh: "responsbilityaccountability_rh", hod: "responsbilityaccountability_hod", min: -10, max: 10 },
                      { label: "Independent Working", name: "independentWorking_admin", emp: "independentWorking_emp", rh: "independentWorking_rh", hod: "independentWorking_hod", min: -5, max: 5 },
                      { label: "Decision Making Capability", name: "decisonMakingCapability_admin", emp: "decisonMakingCapability_emp", rh: "decisonMakingCapability_rh", hod: "decisonMakingCapability_hod", min: -10, max: 10 },
                    ].map(({ label, name, emp, rh, hod, min, max }) => (
                      <tr key={name}>
                        <td className="text-center">{label}</td>
                        <td className="text-center">{max}</td>
                        <td className="text-center">{min}</td>
                        <td className="text-center">{formData[emp]}</td>
                        <td className="text-center">{formData[rh]}</td>
                        <td className="text-center">{formData[hod]}</td>
                        <td className="text-center">{formData[name]}</td>
                      </tr>
                    ))}
                    <tr>
                      <th className="align-center"></th>
                      <th className="align-center">70</th>
                      <th className="align-center">-70</th>
                      <th className="align-center">{formData.totalperformance_emp}</th>
                      <th className="align-center">{formData.totalperformance_rh}</th>
                      <th className="align-center">{formData.totalperformance_hod}</th>
                      <th className="align-center">
                        {(
                          (parseFloat(formData.accuracy_admin) || 0) +
                          (parseFloat(formData.speedlyWork_admin) || 0) +
                          (parseFloat(formData.SelfInitiative_admin) || 0) +
                          (parseFloat(formData.responsbilityaccountability_admin) || 0) +
                          (parseFloat(formData.independentWorking_admin) || 0) +
                          (parseFloat(formData.decisonMakingCapability_admin) || 0)
                        ).toFixed(1)}
                      </th>
                    </tr>
                    <tr>
                      <th colSpan={7} className="align-leftt">DISCIPLINE AND BEHAVIOUR</th>
                    </tr>
                    {[
                      { label: "Punctuality", name: "punctuality_admin", emp: "punctuality_emp", rh: "punctuality_rh", hod: "punctuality_hod", min: -5, max: 5 },
                      { label: "Teamwork", name: "teamwork_admin", emp: "teamwork_emp", rh: "teamwork_rh", hod: "teamwork_hod", min: -5, max: 5 },
                      { label: "Loyalty Towards Work", name: "loyalitytowardsWork_admin", emp: "loyalitytowardsWork_emp", rh: "loyalitytowardsWork_rh", hod: "loyalitytowardsWork_hod", min: -5, max: 5 },
                      { label: "Dedication Towards Work", name: "dedicationtowardsWork_admin", emp: "dedicationtowardsWork_emp", rh: "dedicationtowardsWork_rh", hod: "dedicationtowardsWork_hod", min: -5, max: 5 },
                      { label: "Obedient & Leadership", name: "obeideintorLeadership_admin", emp: "obeideintorLeadership_emp", rh: "obeideintorLeadership_rh", hod: "obeideintorLeadership_hod", min: -10, max: 10 }
                    ].map(({ label, name, emp, rh, hod, min, max }) => (
                      <tr key={name}>
                        <td className="text-center">{label}</td>
                        <td className="text-center">{max}</td>
                        <td className="text-center">{min}</td>
                        <td className="text-center">{formData[emp]}</td>
                        <td className="text-center">{formData[rh]}</td>
                        <td className="text-center">{formData[hod]}</td>
                        <td className="text-center">{formData[name]}</td>
                      </tr>
                    ))}
                    <tr>
                      <th className="align-center"></th>
                      <th className="align-center">30</th>
                      <th className="align-center">-30</th>
                      <th className="align-center">{formData.totaldecipline_emp}</th>
                      <th className="align-center">{formData.totaldecipline_rh}</th>
                      <th className="align-center">{formData.totaldecipline_hod}</th>
                      <th className="align-center">
                        {(
                          (parseFloat(formData.punctuality_admin) || 0) +
                          (parseFloat(formData.teamwork_admin) || 0) +
                          (parseFloat(formData.loyalitytowardsWork_admin) || 0) +
                          (parseFloat(formData.dedicationtowardsWork_admin) || 0) +
                          (parseFloat(formData.obeideintorLeadership_admin) || 0)
                        ).toFixed(1)}
                      </th>
                    </tr>
                    <tr>
                      <th className="align-right">Total Rating Marks</th>
                      <th className="align-center">100</th>
                      <th className="align-center">-100</th>
                      <th className="align-center">{formData.total_emp}</th>
                      <th className="align-center">{formData.total_rh}</th>
                      <th className="align-center">{formData.total_hod}</th>
                      <th className="align-center">
                        {(
                          (parseFloat(formData.accuracy_admin) || 0) +
                          (parseFloat(formData.speedlyWork_admin) || 0) +
                          (parseFloat(formData.SelfInitiative_admin) || 0) +
                          (parseFloat(formData.responsbilityaccountability_admin) || 0) +
                          (parseFloat(formData.independentWorking_admin) || 0) +
                          (parseFloat(formData.decisonMakingCapability_admin) || 0) +
                          (parseFloat(formData.punctuality_admin) || 0) +
                          (parseFloat(formData.teamwork_admin) || 0) +
                          (parseFloat(formData.loyalitytowardsWork_admin) || 0) +
                          (parseFloat(formData.dedicationtowardsWork_admin) || 0) +
                          (parseFloat(formData.obeideintorLeadership_admin) || 0)
                        ).toFixed(1)}
                      </th>
                    </tr>
                  </React.Fragment>
                </tbody>
              </table>
            </div>

            <div className="button-models">
              <button type="button" className="model-button-cancel model-button font-weight500" onClick={handleClose}>
                Close
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};


export {
  UploadAppraisalDetailsByEmployee,
  EditAppraisalDetailsByEmployee,

  UploadAppraisalDetailsByRH,
  ViewAppraisalDetailsByRH,

  UploadAppraisalDetailsByHOD,
  ViewAppraisalDetailsByHOD,
  UploadAppraisalDetailsByRHHOD,
  ViewAppraisalDetailsByRHHOD,

  UploadAppraisalDetailsByHODAdmin,
  ViewAppraisalDetailsHODAdmin,
  UploadAppraisalDetailsByRHHODAdmin,
  ViewAppraisalDetailsRHHODAdmin,
};
