import React, { useRef, useCallback, useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useGeolocated } from "react-geolocated";
import axios from "axios";
import Webcam from "react-webcam";
import { BASE_URL } from "../../../config/axios";
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select';
import { formattedDate, formattedDateLong } from "../../Date";
import usePermission from "../../../config/permissions";
import { customSortByKey, handleAllError, handleErrorToast } from "../../CustomFunctions";



const Clockin = ({ handleClose }) => {

  const webcamRef = useRef(null);

  const [locationData, setLocationData] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: true,
      },
      userDecisionTimeout: 5000,
    });



  const getLocation = async () => {
    try {
      const { latitude, longitude } = coords;
      const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyBwmmybQWla4yJB38xB3HvzLTQwyZf14JI`;
      const response = await fetch(url);
      const data = await response.json();
      console.log(data);
      if (data.status === "OK") {
        setLocationData(data.results[0].formatted_address);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const today = new Date();
  const formattedDate = today.toISOString().split("T")[0];
  const formattedTime = today.toTimeString().split(" ")[0];

  const [formData, setFormData] = useState({
    employee: sessionStorage.getItem("emp_code"),
    date: formattedDate,
    punch_time: formattedTime,
    placename: locationData,
    sub_company: sessionStorage.getItem("company_id"),
  });

  useEffect(() => {
    getLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coords, isGeolocationAvailable, isGeolocationEnabled]);

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      placename: locationData,
    }));
  }, [locationData]);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImageSrc(imageSrc);
    return imageSrc;
  }, [webcamRef]);

  const [loading, setLoading] = useState(false); //loading logic
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); //loading logic

    const imageSrc = capture(); // Capture the image when submit is clicked

    const updatedFormValue = {
      ...formData,
      employee: sessionStorage.getItem("emp_code"),
      date: formattedDate,
      punch_time: formattedTime,
      placename: locationData,
    };

    const formDataToSend = new FormData();
    for (const key in updatedFormValue) {
      formDataToSend.append(key, updatedFormValue[key]);
    }
    // if (imageSrc) {
    //   const blob = await fetch(imageSrc).then((res) => res.blob());
    //   formDataToSend.append('image', blob, 'clockin_photo.jpg');
    // }

    if (imageSrc) {
      const blob = await fetch(imageSrc).then((res) => res.blob());
      const empCode = sessionStorage.getItem("emp_code");
      const filename = `${formattedDate}_${formattedTime}_${empCode}.jpg`;
      formDataToSend.append("image", blob, filename);
    }
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.post(
        `${BASE_URL}/wfm/clockinlog/`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res.status === 200) {
        console.log(res);
        handleClose();
      }
    } catch (err) {
      // alert(err);
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;
        if (typeof errorData.error === 'string') { // Single error message   
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {// Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setLoading(false); //loading logic
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <div className="clockin-div">
      <form onSubmit={handleSubmit} className="register-form">
        <div className="flex-column">
          <label htmlFor="employee" className="form-labels  font-weight500    font-size-subheading">
            Employee
          </label>
          <input
            id="employee"
            type="text"
            name="employee"
            readOnly
            value={sessionStorage.getItem("emp_code")}
            className="form-input   font-weight500   form-input-background"
          />
        </div>
        <div className="flex-column">
          <label htmlFor="date" className="form-labels  font-weight500    font-size-subheading">
            Date
          </label>
          <input
            id="date"
            type="date"
            name="date"
            readOnly
            value={formattedDate}
            className="form-input   font-weight500   form-input-background"
          />
        </div>
        <div className="flex-column">
          <label htmlFor="punch_time" className="form-labels  font-weight500    font-size-subheading">
            Time
          </label>
          <input
            type="time"
            id="punch_time"
            name="punch_time"
            readOnly
            value={formattedTime}
            className="form-input   font-weight500   form-input-background"
          />
        </div>
        <div className="flex-column">
          <label htmlFor="placename" className="form-labels  font-weight500    font-size-subheading">
            Location
          </label>
          <input
            id="placename"
            name="placename"
            readOnly
            value={locationData}
            className="form-input   font-weight500   form-input-background"
          />
        </div>

        <div
          className="flex-column"
          style={{ justifyContent: "center", alignItems: "center" }}
        >
          <Webcam
            className="Clockin-Webcam"
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            width={400}
            height={300}
          />
        </div>
        <div className="button-models">
          <button type="button" className="model-button   font-weight500   " onClick={handleClose}>
            Close
          </button>
          {formattedTime && locationData ? (
            <button
              type="submit"
              disabled={loading}
              className="model-button   font-weight500    model-button-submit"
            >
              Submit
            </button>
          ) : (
            <div className="spinner-small"></div>
          )}
        </div>
      </form>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

    </div>
  );
};

const Leave = ({ handleClose }) => {
  const [loading, setLoading] = useState(false); // loading logic
  const [leavebalance, setLeavebalance] = useState({});
  const [age, setAge] = useState('single');
  const [maxEndDate, setMaxEndDate] = useState(null);
  const [dateselectionError, setDateselectionError] = useState("");
  const [dateselectionConfirmation, setDateselectionConfirmation] = useState(false);
  const [insufficientBalanceError, setInsufficientBalanceError] = useState("");
  const [insufficientBalanceConfirmation, setInsufficientBalanceConfirmation] = useState(false);
  const [dataa, setdataa] = useState({
    empcode: sessionStorage.getItem("emp_code"),
    date: "",
    end_date: "",
    // leave_type: "previous leave",
    // leave_type: leavebalance.casual_leave > 0 ? "casual leave" : leavebalance.sick_leave > 0 ? "sick leave" : "previous leave",
    // leave_type: leavebalance.casual_leave > 0 ? "casual leave" : leavebalance.sick_leave > 0 ? "sick leave" : "",
    leave_type: "",
    remarks: "",
  });


  const getLeavebalance = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/leavebalance/${sessionStorage.getItem("emp_code")}/`);
      setLeavebalance(res.data);
    } catch (err) {
      // alert(err.message);
    }
  };

  useEffect(() => {
    getLeavebalance();
  }, []);


  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "leave_type",
      "remarks",
    ];
    requiredFields.forEach((field) => {
      if (!dataa[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    if (!dataa.remarks.trim()) {
      newErrors.remarks = "Remarks cannot be empty or contain only spaces!";
    }
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };





  const getEndTime = (intime) => {
    const start = new Date(`2024-03-25T${intime}`);
    const end = new Date(start.getTime() + 2 * 60 * 60 * 1000); // Add 2 hours in milliseconds
    const hh = String(end.getHours()).padStart(2, "0");
    const mm = String(end.getMinutes()).padStart(2, "0");
    const ss = String(end.getSeconds()).padStart(2, "0");

    setdataa({ ...dataa, in_time: intime, out_time: `${hh}:${mm}:${ss}` });
  };

  const date = new Date();
  const today = date.toISOString().split("T")[0];
  // Calculate the previous day
  // const yesterday = new Date(date.setDate(date.getDate()))
  const yesterday = new Date(date.setDate(date.getDate() - 1))
    .toISOString()
    .split("T")[0];

  const [remarksError, setRemarksError] = useState("");
  const [typeError, setTypeError] = useState("");

  let lastDateOfPreviousMonth;
  if (date.getDate() > 25) {
    lastDateOfPreviousMonth = new Date(date.getFullYear(), date.getMonth(), 0);
  } else {
    lastDateOfPreviousMonth = new Date(date.getFullYear(), date.getMonth() - 1, 1);
  }
  const formattedLastDate = lastDateOfPreviousMonth.toISOString().substr(0, 10);

  const calculateDaysBetweenDates = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const differenceInTime = end.getTime() - start.getTime();
    return differenceInTime / (1000 * 3600 * 24) + 1; // +1 to include the start date
  };

  const checkLeaveBalance = () => {
    let numberOfDays = calculateDaysBetweenDates(dataa.date, dataa.end_date);

    if (age === "halfdayleave") {
      numberOfDays = 0.5;
    } else if (age === "shortleave") {
      numberOfDays = 0.25;
    }

    let balance = 0;

    switch (dataa.leave_type) {
      case "previous leave":
        balance = leavebalance.leavebalance || 0;
        break;
      case "casual leave":
        balance = leavebalance.casual_leave || 0;
        break;
      case "sick leave":
        balance = leavebalance.sick_leave || 0;
        break;
      case "earn leave":
        balance = leavebalance.earn_leave || 0;
        break;
      default:
        balance = 0;
        break;
    }

    if (numberOfDays > balance) {
      setInsufficientBalanceError(`You are applying for ${numberOfDays} ${numberOfDays > 1 ? 'days' : 'day'}, but you only have ${balance} ${dataa.leave_type} balance`);
    } else {
      setInsufficientBalanceError("");
    }
  };

  // Trigger the balance check whenever the relevant fields change
  useEffect(() => {
    if (dataa.date && dataa.end_date && dataa.leave_type) {
      checkLeaveBalance();
    }
  }, [dataa.date, dataa.end_date, dataa.leave_type]);

  // console.log("insufficientBalanceError && !insufficientBalanceConfirmation");
  // console.log(insufficientBalanceError && !insufficientBalanceConfirmation);

  let handleSubmit = async (e) => {
    // const enteredDate = dataa.date;
    // const minDate = formattedLastDate;

    e.preventDefault();

    const updatedFormData = {
      ...dataa,
      leave_Duration: age,
      apply_datetime: new Date().toISOString(),
    };

    if (validateForm()) {
      setLoading(true); // loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      try {
        let res = await axios.post(
          `${BASE_URL}/wfm/leaveapply/`,
          updatedFormData
        );

        if (res.status === 200) {
          toast.success("Leave Applied!")
          handleClose()

        } else {
          // alert(res);
          // toast.done('Done processing your request.')
          toast.info(res)

        }
      } catch (err) {
        // handleErrorToast(err, loadingToastId)
        handleAllError(err, loadingToastId)
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };

  const validateAndSetDate = (selectedDate, field) => {
    const minDate = sessionStorage.getItem("company") === "Civilmantra" ? yesterday : formattedLastDate;

    if (new Date(selectedDate) < new Date(minDate)) {
      setdataa({ ...dataa, [field]: minDate, end_date: minDate });
      setDateselectionError(`Selected date changed from "${formattedDateLong(selectedDate)}" to "${formattedDateLong(minDate)}", As per Company Restrictions.`);
    } else {
      setdataa({ ...dataa, [field]: selectedDate });
      setDateselectionError("");
    }
  };

  return (
    <div>
      <form>
        <div className="flex-column ">
          <div className="leave-balance-container">
            <h2 className="leave-balance-heading">All Available Leaves:</h2>
            <div className="leave-balance-cards">

              <div className="leave-balance-card leave-balance-card-previous">
                <div className="leave-balance-icon">&#128197;</div> {/* Calendar icon */}
                <div className="leave-balance-info">
                  <span className="leave-balance-label">Previous : {" "}</span>
                  <span className="leave-balance-duration">
                    {leavebalance.leavebalance ? `${leavebalance.leavebalance} ${leavebalance.leavebalance > 1 ? 'days' : 'day'}` : "0 Available"}
                  </span>
                </div>
              </div>

              <div className="leave-balance-card leave-balance-card-earned">
                <div className="leave-balance-icon">&#127942;</div> {/* Trophy icon */}
                <div className="leave-balance-info">
                  <span className="leave-balance-label">Earned : {" "}</span>
                  <span className="leave-balance-duration">
                    {leavebalance.earn_leave ? `${leavebalance.earn_leave} ${leavebalance.earn_leave > 1 ? 'days' : 'day'}` : "0 Available"}
                  </span>
                </div>
              </div>

              <div className="leave-balance-card leave-balance-card-casual">
                <div className="leave-balance-icon">&#128215;</div> {/* Notebook icon */}
                <div className="leave-balance-info">
                  <span className="leave-balance-label">Casual : {" "}</span>
                  <span className="leave-balance-duration">
                    {leavebalance.casual_leave ? `${leavebalance.casual_leave} ${leavebalance.casual_leave > 1 ? 'days' : 'day'}` : "0 Available"}
                  </span>
                </div>
              </div>

              <div className="leave-balance-card leave-balance-card-sick">
                <div className="leave-balance-icon">&#128138;</div> {/* Sick emoji icon */}
                <div className="leave-balance-info">
                  <span className="leave-balance-label">Sick : {" "}</span>
                  <span className="leave-balance-duration">
                    {leavebalance.sick_leave ? `${leavebalance.sick_leave} ${leavebalance.sick_leave > 1 ? 'days' : 'day'}` : "0 Available"}
                  </span>
                </div>
              </div>

            </div>
          </div>
          {/* <br /> */}

          <div className="leave-duration-heading  font-weight500 font-size-heading">Leave Duration</div>
          <div onChange={(e) => setAge(e.target.value)} className="leave-duration-tabs">
            <input type="radio" id="leave-single-day" name="leave-type" value="single" defaultChecked />
            <label htmlFor="leave-single-day" className="leave-tab">Single Day</label>

            <input type="radio" id="leave-multi-day" name="leave-type" value="multiple" />
            <label htmlFor="leave-multi-day" className="leave-tab">Multi Day</label>

            <input type="radio" id="leave-half-day" name="leave-type" value="halfdayleave" />
            <label htmlFor="leave-half-day" className="leave-tab">Half Day</label>

            <input type="radio" id="leave-short-day" name="leave-type" value="shortleave" />
            <label htmlFor="leave-short-day" className="leave-tab">Short Day</label>

            <span className="leave-tab-glider"></span>
          </div>

        </div>

        {age === "single" && (
          <div className="flex-column">
            <label htmlFor="date" className="form-labels  font-weight500    font-size-subheading">
              Date
            </label>
            {/* <input
              id="date"
              type="date"
              className="form-input   font-weight500   form-input-background"
              onChange={(e) =>
                setdataa({
                  ...dataa,
                  date: e.target.value,
                  end_date: e.target.value,
                })
              }
              // min={formattedLastDate}
              min={sessionStorage.getItem("company") === "Civilmantra" ? yesterday : formattedLastDate} /> */}
            <input
              id="date"
              type="date"
              className="form-input font-weight500 form-input-background"
              value={dataa.date}
              onInput={(e) => setdataa({ ...dataa, date: e.target.value, end_date: e.target.value })}
              onBlur={(e) => validateAndSetDate(e.target.value, 'date')}
              min={sessionStorage.getItem("company") === "Civilmantra" ? yesterday : formattedLastDate}
            />
          </div>
        )}

        {age === "multiple" && (
          // <div className="flex-row multi-day-input">
          //   <div className="flex-column multi-day">
          //     <label className="form-labels  font-weight500    font-size-subheading">From Date</label>
          //     <input
          //       type="date"
          //       className="multi-day-input1 form-input-background"
          //       onChange={(e) => {
          //         const startDate = e.target.value;
          //         const lastDate = new Date(
          //           new Date(startDate).getFullYear(),
          //           new Date(startDate).getMonth() + 1,
          //           1
          //         )
          //           .toISOString()
          //           .split("T")[0];
          //         setdataa({
          //           ...dataa,
          //           date: startDate,
          //         });
          //         setMaxEndDate(lastDate);
          //       }}
          //       // min={formattedLastDate}
          //       min={sessionStorage.getItem("company") === "Civilmantra" ? yesterday : formattedLastDate} />
          //   </div>
          //   <div className="flex-column multi-day">
          //     <label className="form-labels  font-weight500    font-size-subheading">To Date</label>
          //     <input
          //       type="date"
          //       className="multi-day-input1 form-input-background"
          //       onChange={(e) =>
          //         setdataa({ ...dataa, end_date: e.target.value })
          //       }
          //       min={dataa.date}
          //       max={maxEndDate}
          //     />
          //   </div>
          // </div>

          <div className="flex-row multi-day-input">
            <div className="flex-column multi-day">
              <label className="form-labels font-weight500 font-size-subheading">From Date</label>
              <input
                type="date"
                className="multi-day-input1 form-input-background"
                value={dataa.date}
                onInput={(e) => setdataa({ ...dataa, date: e.target.value })}
                onBlur={(e) => {
                  const startDate = e.target.value;
                  validateAndSetDate(startDate, 'date');
                  const lastDate = new Date(
                    new Date(startDate).getFullYear(),
                    new Date(startDate).getMonth() + 1,
                    1
                  )
                    .toISOString()
                    .split("T")[0];
                  setMaxEndDate(lastDate);

                  if (dataa.end_date && new Date(dataa.end_date) < new Date(startDate)) {
                    setdataa({ ...dataa, end_date: startDate });
                  }
                }}
                // min={formattedLastDate}
                min={sessionStorage.getItem("company") === "Civilmantra" ? yesterday : formattedLastDate} />
            </div>
            <div className="flex-column multi-day">
              <label className="form-labels font-weight500 font-size-subheading">To Date</label>
              <input
                type="date"
                className="multi-day-input1 form-input-background"
                onChange={(e) =>
                  setdataa({ ...dataa, end_date: e.target.value })
                }
                // value={dataa.end_date}
                // onInput={(e) => setdataa({ ...dataa, end_date: e.target.value })}
                // onBlur={(e) => {
                //   // Ensure end date is not before start date
                //   if (new Date(e.target.value) < new Date(dataa.date)) {
                //     setdataa({ ...dataa, end_date: dataa.date });
                //   } else {
                //     validateAndSetDate(e.target.value, 'end_date');
                //   }
                // }}
                min={dataa.date} // Ensure end date is not before the start date
                max={maxEndDate}
              />
            </div>
          </div>

        )}

        {age === "halfdayleave" && (
          <div>
            <div className="flex-column single-day-date">
              <label className="form-labels  font-weight500    font-size-subheading">Date</label>
              <input
                id="date"
                type="date"
                className="form-input   font-weight500   form-input-background"
                // onChange={(e) =>
                //   setdataa({
                //     ...dataa,
                //     date: e.target.value,
                //     end_date: e.target.value,
                //   })
                // }
                value={dataa.date}
                onInput={(e) => setdataa({ ...dataa, date: e.target.value, end_date: e.target.value })}
                onBlur={(e) => validateAndSetDate(e.target.value, 'date')}

                // min={formattedLastDate}
                min={sessionStorage.getItem("company") === "Civilmantra" ? yesterday : formattedLastDate} />
            </div>
            <div
              required
              className="leave-radio-button"
            >
              <div className="leave-radio-button-inner-div">
                <input
                  name="halfday"
                  type="radio"
                  value="firsthalft"
                  className="age-input"
                />
                <label className="age-label   font-weight500    font-size-heading">First Half</label>
              </div>
              <div className="leave-radio-button-inner-div">
                <input
                  name="halfday"
                  type="radio"
                  value="secondhalft"
                  className="age-input"
                />
                <label className="age-label   font-weight500    font-size-heading">Second Half</label>
              </div>
            </div>
          </div>
        )}

        {age === "shortleave" && (
          <div>
            <div className="flex-column single-day-date">
              <label className="form-labels  font-weight500    font-size-subheading">Date</label>
              <input
                id="date"
                type="date"
                className="form-input   font-weight500   form-input-background"
                // onChange={(e) =>
                //   setdataa({
                //     ...dataa,
                //     date: e.target.value,
                //     end_date: e.target.value,
                //   })
                // }
                value={dataa.date}
                onInput={(e) => setdataa({ ...dataa, date: e.target.value, end_date: e.target.value })}
                onBlur={(e) => validateAndSetDate(e.target.value, 'date')}
                // min={formattedLastDate}
                min={sessionStorage.getItem("company") === "Civilmantra" ? yesterday : formattedLastDate}
              />
            </div>
            <div className="flex-row multi-day-input">
              <div className="flex-column multi-day">
                <label className="form-labels  font-weight500    font-size-subheading">Start Time</label>
                <input
                  className="multi-day-input1 form-input-background"
                  type="time"
                  step="1"
                  min="09:30:00"
                  max="16:30:00"
                  onChange={(e) => getEndTime(e.target.value)}
                />
              </div>
              <div className="flex-column multi-day">
                <label className="form-labels  font-weight500    font-size-subheading">End Time</label>
                <input
                  className="multi-day-input1 form-input-background"
                  type="time"
                  step="1"
                  min="09:30:00"
                  max="16:30:00"
                  value={dataa.out_time}
                  readOnly
                />
              </div>
            </div>
          </div>
        )}

        {/* Leave Type */}
        <div className="flex-column">
          <label className="form-labels  font-weight500    font-size-subheading">Leave Type</label>
          <select
            id="leave_type"
            name="leave_type"
            type="text"
            value={dataa.leave_type}
            className={`form-input `}
            onChange={(e) => {
              if (e.target.value.length >= 3) {
                setdataa({ ...dataa, leave_type: e.target.value })
                setTypeError(""); // Clear error message if within the limit
              } else {
                setTypeError("Ensure this field is selected.");
              }
            }}
          >

            {/* {leavebalance.casual_leave > 0 || leavebalance.sick_leave > 0 ? null :
              <>
                <option value="previous leave">Previous Leave</option>
                <option value="earn leave">Earned Leave</option>
              </>
            } */}
            <option value="">Select Leave Type</option>
            <option value="casual leave">Casual Leave</option>
            <option value="sick leave">Sick Leave</option>
            <option value="earn leave">Earned Leave</option>
            <option value="previous leave">Previous Leave</option>
          </select>

          {typeError && (
            <div style={{ color: "red", marginTop: "5px" }}>{typeError}</div>
          )}
          {errors.leave_type && (
            <span className="error-message font-size-text ">{errors.leave_type}</span>
          )}
        </div>

        <div className="flex-column">
          <label className="form-labels  font-weight500    font-size-subheading">Description</label>
          <textarea
            className="form-input-textarea   font-weight400  form-input-background"
            placeholder="Reason"
            type="text"
            value={dataa.text}
            onChange={(e) => {
              if (e.target.value.length <= 50) {
                setdataa({ ...dataa, remarks: e.target.value });
                setRemarksError(""); // Clear error message if within the limit
              } else {
                setRemarksError(
                  "Ensure this field has no more than 50 characters."
                );
              }
            }}
          />

          {remarksError && (
            <div style={{ color: "red", marginTop: "5px" }}>{remarksError}</div>
          )}
          {errors.remarks && (
            <span className="error-message font-size-text ">{errors.remarks}</span>
          )}
        </div>

        {
          insufficientBalanceError && (
            <div className="flex-column" style={{ marginTop: "5px" }}>
              <div style={{ color: "red" }}>{insufficientBalanceError}</div>
              <label className="form-labels  font-weight500    font-size-subheading" style={{ marginTop: "5px" }}>
                <input
                  type="checkbox"
                  checked={insufficientBalanceConfirmation}
                  onChange={(e) => setInsufficientBalanceConfirmation(e.target.checked)}
                />
                <> </>
                <span>
                  Click to confirm and proceed with Insufficient leave balance
                </span>
                <span>
                  (Note: Leaves will be deducted from other leave balances)
                </span>
              </label>
            </div>
          )
        }
        {dateselectionError === "" ? null : (
          <div className="flex-column" style={{ marginTop: "5px" }}>
            <div style={{ color: "red" }}>
              Note:
              <br />
              {dateselectionError}
            </div>
            {/* <label className="form-labels  font-weight500    font-size-subheading" style={{ marginTop: "5px" }}>
              <input
                type="checkbox"
                checked={dateselectionConfirmation}
                onChange={(e) => setDateselectionConfirmation(e.target.checked)}
              />
              <> </>
              <span>
                Click to confirm and proceed with changed date
              </span>
            </label> */}
          </div>
        )}

        <div className="button-models">
          <button className="model-button font-weight500 model-button-cancel" onClick={handleClose}>Cancel</button>

          <button
            className={`model-button ${(insufficientBalanceError && !insufficientBalanceConfirmation) === true ? 'model-button-cancel' : 'model-button-leave  font-weight500   '} `}
            onClick={handleSubmit}
            disabled={loading || (insufficientBalanceError && !insufficientBalanceConfirmation)}
          >
            {(insufficientBalanceError && !insufficientBalanceConfirmation) === true ? "Confirmation Needed" : "Leave"}

          </button>
        </div>
      </form >
      <ToastContainer position="top-center" hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

    </div >
  );
};


const Outduty = ({ handleClose }) => {
  const [loading, setLoading] = useState(false); //loading logic

  const [age, setAge] = useState("single");

  const [maxEndDate, setMaxEndDate] = useState(null);

  const [dataa, setdataa] = useState({
    empcode: sessionStorage.getItem("emp_code"),
    date: "",
    end_date: "",
    remarks: "",
  });

  // !  ************** Validation start **************  ! //
  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      // "leave_type",
      "remarks",
    ];
    requiredFields.forEach((field) => {
      if (!dataa[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });
    if (!dataa.remarks.trim()) {
      newErrors.remarks = "Remarks cannot be empty or contain only spaces!";
    }
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const getEndTime = (intime) => {
    const start = new Date(`2023-03-25T${intime}`);
    const end = new Date(start.getTime() + 2 * 60 * 60 * 1000); // Add 2 hours in milliseconds
    const hh = String(end.getHours()).padStart(2, "0");
    const mm = String(end.getMinutes()).padStart(2, "0");
    const ss = String(end.getSeconds()).padStart(2, "0");

    setdataa({ ...dataa, in_time: intime, out_time: `${hh}:${mm}:${ss}` });
  };

  const date = new Date();
  const yesterday = new Date(date.setDate(date.getDate() - 3))
    .toISOString()
    .split("T")[0];
  const [remarksError, setRemarksError] = useState("");

  let lastDateOfPreviousMonth;
  if (date.getDate() > 20) {
    lastDateOfPreviousMonth = new Date(date.getFullYear(), date.getMonth(), 0);
  } else {
    lastDateOfPreviousMonth = new Date(
      date.getFullYear(),
      date.getMonth() - 1,
      +1
    );
  }
  const formattedLastDate = lastDateOfPreviousMonth.toISOString().substr(0, 10);

  let handleSubmit = async (e) => {
    e.preventDefault();
    const enteredDate = dataa.date;
    const minDate = formattedLastDate;

    if (enteredDate >= minDate) {

      const updatedFormData = {
        ...dataa,
        outduty_Duration: age,
        apply_datetime: new Date().toISOString(),
      };
      if (validateForm()) {
        const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
        setLoading(true); //loading logic

        try {
          let res = await axios.post(
            `${BASE_URL}/wfm/outduty/`,
            updatedFormData
          );

          if (res.status === 200) {
            handleClose()
          } else {
            alert("Please select a valid date");
          }
        } catch (err) {
          if (err.response) {
            toast.dismiss(loadingToastId);
            const errorData = err.response.data;
            if (typeof errorData.error === 'string') { // Single error message   
              toast.error(`Error: ${errorData.error}`);
            } else if (typeof errorData === 'object' && errorData !== null) {// Multiple error messages
              Object.entries(errorData).forEach(([field, messages]) => {
                messages.forEach(message => toast.error(`"${field}": ${message}`));
              });
            } else {
              toast.error('Error:- Failed to Process!');
            }
          } else {
            toast.error('Error processing your request.');
          }


        } finally {
          setLoading(false); //loading logic
          toast.dismiss(loadingToastId);
        }
      }

      // return;
    } else {
      toast.error('Error: Invalid Input!');
      // alert("Your Request Already Submit ");
      // return;
    }
  };

  const validateAndSetDate = (selectedDate, field) => {
    const minDate = sessionStorage.getItem("company") === "Civilmantra" ? yesterday : formattedLastDate;

    if (new Date(selectedDate) < new Date(minDate)) {
      setdataa({ ...dataa, [field]: minDate, end_date: minDate });
    } else {
      setdataa({ ...dataa, [field]: selectedDate });
    }
  };

  return (
    <div>
      <form>
        <div className="flex-column model-age">

          <div className="leave-duration-heading  font-weight500 font-size-heading">Outduty Duration </div>
          {/* <br/> */}

          {/* <div onChange={(e) => setAge(e.target.value)} className="leave-radio-button">
            <div className="leave-radio-button-inner-div">
              <input
                name="age"
                type="radio"
                value="single"
                className="age-input"
              />
              <label className="age-label   font-weight500    font-size-heading">Single Day</label></div>
            <div className="leave-radio-button-inner-div">
              <input
                name="age"
                type="radio"
                value="multiple"
                className="age-input"
              />
              <label className="age-label   font-weight500    font-size-heading">Multi Day</label>
            </div>
            <div className="leave-radio-button-inner-div">

              <input
                name="age"
                type="radio"
                value="halfdayleave"
                className="age-input"
              />
              <label className="age-label   font-weight500    font-size-heading">Half Day</label></div>
            <div className="leave-radio-button-inner-div">
              <input
                name="age"
                type="radio"
                value="shortleave"
                className="age-input"
              />
              <label className="age-label   font-weight500    font-size-heading">Short Day</label></div>
          </div> */}
          <div onChange={(e) => setAge(e.target.value)} className="leave-duration-tabs">
            <input type="radio" id="leave-single-day" name="leave-type" value="single" defaultChecked />
            <label htmlFor="leave-single-day" className="leave-tab">Single Day</label>

            <input type="radio" id="leave-multi-day" name="leave-type" value="multiple" />
            <label htmlFor="leave-multi-day" className="leave-tab">Multi Day</label>

            <input type="radio" id="leave-half-day" name="leave-type" value="halfdayleave" />
            <label htmlFor="leave-half-day" className="leave-tab">Half Day</label>

            <input type="radio" id="leave-short-day" name="leave-type" value="shortleave" />
            <label htmlFor="leave-short-day" className="leave-tab">Short Day</label>

            <span className="leave-tab-glider"></span>
          </div>
        </div>

        {/* <div>Leave Balance:  </div>
  <div>Short Leave Balance:  </div> */}


        {age === "single" && (
          <div className="flex-column">
            <label htmlFor="date" className="form-labels  font-weight500    font-size-subheading">
              Date
            </label>
            <input
              id="date"
              type="date"
              className="form-input   font-weight500   form-input-background"
              // onChange={(e) =>
              //   setdataa({
              //     ...dataa,    
              //     date: e.target.value,
              //     end_date: e.target.value,

              //   })
              // }
              // onChange={(e) => setdataa(e.target.value === "" ?
              //   {
              //     ...dataa,
              //   } : {
              //     ...dataa,
              //     date: e.target.value,
              //     end_date: e.target.value,
              //   })}
              value={dataa.date}
              onInput={(e) => setdataa({ ...dataa, date: e.target.value, end_date: e.target.value })}
              onBlur={(e) => validateAndSetDate(e.target.value, 'date')}
              // min={formattedLastDate}
              min={sessionStorage.getItem("company") === "Civilmantra" ? yesterday : formattedLastDate}
            />
          </div>
        )}

        {age === "multiple" && (
          <div className="flex-row multi-day-input">
            <div className="flex-column multi-day">
              <label className="form-labels  font-weight500    font-size-subheading">From Date</label>
              <input
                type="date"
                className="multi-day-input1 form-input-background"
                // onChange={(e) => {
                //   const startDate = e.target.value;
                //   const lastDate = new Date(
                //     new Date(startDate).getFullYear(),
                //     new Date(startDate).getMonth() + 1,
                //     1
                //   )
                //     .toISOString()
                //     .split("T")[0];
                //   setdataa({
                //     ...dataa,
                //     date: startDate,
                //   });
                //   setMaxEndDate(lastDate);
                // }}
                value={dataa.date}
                onInput={(e) => setdataa({ ...dataa, date: e.target.value })}
                onBlur={(e) => {
                  const startDate = e.target.value;
                  validateAndSetDate(startDate, 'date');
                  const lastDate = new Date(
                    new Date(startDate).getFullYear(),
                    new Date(startDate).getMonth() + 1,
                    1
                  )
                    .toISOString()
                    .split("T")[0];
                  setMaxEndDate(lastDate);

                  if (dataa.end_date && new Date(dataa.end_date) < new Date(startDate)) {
                    setdataa({ ...dataa, end_date: startDate });
                  }
                }}
                // min={formattedLastDate}
                min={sessionStorage.getItem("company") === "Civilmantra" ? yesterday : formattedLastDate} />
            </div>
            <div className="flex-column multi-day">
              <label className="form-labels  font-weight500    font-size-subheading">To Date</label>
              <input
                type="date"
                className="multi-day-input1 form-input-background"
                onChange={(e) =>
                  setdataa({ ...dataa, end_date: e.target.value })
                }
                // value={dataa.end_date}
                // onInput={(e) => setdataa({ ...dataa, end_date: e.target.value })}
                // onBlur={(e) => {
                //   // Ensure end date is not before start date
                //   if (new Date(e.target.value) < new Date(dataa.date)) {
                //     setdataa({ ...dataa, end_date: dataa.date });
                //   } else {
                //     validateAndSetDate(e.target.value, 'end_date');
                //   }
                // }}
                min={dataa.date}
                max={maxEndDate}
              />
            </div>
          </div>
        )}

        {age === "halfdayleave" && (
          <div>
            <div className="flex-column single-day-date">
              <label className="form-labels  font-weight500    font-size-subheading">Date</label>
              <input
                type="date"
                className="form-input   font-weight500   form-input-background"
                // onChange={(e) =>
                //   setdataa({
                //     ...dataa,
                //     date: e.target.value,
                //     end_date: e.target.value,
                //   })
                // }
                value={dataa.date}
                onInput={(e) => setdataa({ ...dataa, date: e.target.value, end_date: e.target.value })}
                onBlur={(e) => validateAndSetDate(e.target.value, 'date')}
                // min={formattedLastDate}
                min={sessionStorage.getItem("company") === "Civilmantra" ? yesterday : formattedLastDate} />
            </div>
            <div
              required
              className="leave-radio-button"
            >
              <div className="leave-radio-button-inner-div">
                <input
                  name="halfday"
                  type="radio"
                  value="firsthalft"
                  className="age-input"
                  in_time="09:30:00"
                  out_time="13:30:00"
                />
                <label className="age-label   font-weight500    font-size-heading">First Half</label>
              </div>
              <div className="leave-radio-button-inner-div">
                <input
                  name="halfday"
                  type="radio"
                  value="secondhalft"
                  className="age-input"
                  in_time="14:30:00"
                  out_time="18:30:00"
                />
                <label className="age-label   font-weight500    font-size-heading">Second Half</label>
              </div>
            </div>
          </div>
        )}

        {age === "shortleave" && (
          <div>
            <div className="flex-column single-day-date">
              <label className="form-labels  font-weight500    font-size-subheading">Date</label>
              <input
                type="date"
                className="form-input   font-weight500   form-input-background"
                // onChange={(e) =>
                //   setdataa({
                //     ...dataa,
                //     date: e.target.value,
                //     end_date: e.target.value,
                //   })
                // }
                value={dataa.date}
                onInput={(e) => setdataa({ ...dataa, date: e.target.value, end_date: e.target.value })}
                onBlur={(e) => validateAndSetDate(e.target.value, 'date')}
                // min={formattedLastDate}
                min={sessionStorage.getItem("company") === "Civilmantra" ? yesterday : formattedLastDate}
              />
            </div>
            <div className="flex-row multi-day-input">
              <div className="flex-column multi-day">
                <label className="form-labels  font-weight500    font-size-subheading">Start Time</label>
                <input
                  className="multi-day-input1 form-input-background"
                  type="time"
                  step="1"
                  min="09:30:00"
                  max="16:30:00"
                  onChange={(e) => getEndTime(e.target.value)}
                />
              </div>
              <div className="flex-column multi-day">
                <label className="form-labels  font-weight500    font-size-subheading">End Time</label>
                <input
                  className="multi-day-input1 form-input-background"
                  type="time"
                  step="1"
                  min="09:30:00"
                  max="16:30:00"
                  value={dataa.out_time}
                  readOnly
                />
              </div>
            </div>
          </div>
        )}

        <div className="flex-column">
          <label className="form-labels  font-weight500    font-size-subheading">Location & Work performed</label>
          <textarea
            className="form-input-textarea   font-weight400  form-input-background"
            placeholder="Description"
            type="text"
            value={dataa.text}
            onChange={(e) => {
              if (e.target.value.length <= 50) {
                setdataa({ ...dataa, remarks: e.target.value });
                setRemarksError(""); // Clear error message if within the limit
              } else {
                setRemarksError(
                  "Ensure this field has no more than 50 characters."
                );
              }
            }}
          />

          {remarksError && (
            <div style={{ color: "red", marginTop: "5px" }}>{remarksError}</div>
          )}
          {errors.remarks && (
            <span className="error-message font-size-text ">{errors.remarks}</span>
          )}
        </div>

        <div className="button-models">
          <button className="model-button   font-weight500 model-button-cancel   " onClick={handleClose}>Cancel</button>
          <button className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading" onClick={handleSubmit} disabled={loading} >{/* //loading logic */}
            Outduty
          </button>
        </div>
      </form>
      <ToastContainer position="top-center" hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

    </div>
  );
};


const AssignTask = ({ handleClose }) => {
  const { SUB_COMPANIES, PERMISSION_AUTHORITY } = usePermission();
  const [toggleCompany, setToggleCompany] = useState(sessionStorage.getItem("company_id"));
  const [empList, setEmpList] = useState([]);
  const [loading, setLoading] = useState(false); //loading logic

  // *********** Employee Code Api Start ***********
  // const [emp_code, setEmp_code] = useState([]);
  const getAllEmp_code = async (toggleCompany) => {
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/ourcompanyempdetailsactive/${toggleCompany}/`
      );

      const sortedList = customSortByKey(res.data, "emp_code");

      setEmpList(sortedList);
      // setEmp_code(res.data);
    } catch (err) {
      // alert(err.message);
    }
  };


  const [formData, setFormData] = useState({
    task: "",
    assignedby: sessionStorage.getItem("emp_code"),
    assigndate: formattedDate,
    concern_person: "",
    status: "pending",
    priority: "",
    due_date: "",
    assignedtocc: "",
  });

  useEffect(() => {
    getAllEmp_code(toggleCompany);
  }, [formData]);

  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});
  const [priority, setPriority] = useState("3");

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["task", "due_date", "concern_person"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const [buffer, setBuffering] = useState(false); //buffering logic

  const handleCompanyChange = async (val) => {
    setBuffering(true)
    setToggleCompany(val);
    let toggleCompanyval = val;
    await getAllEmp_code(toggleCompanyval);
    setBuffering(false)
  };
  console.log("toggleState");
  console.log(toggleCompany);


  const handleInputChange = (e) => {
    // getAllEmp_code();

    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (formData.assignedtocc.length === 0) {
      formData.assignedtocc = [formData.concern_person];
    }
    const updatedFormData = {
      ...formData,
      priority: priority,

    };
    if (validateForm()) {
      setLoading(true); //loading logic
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
      try {
        let res = await axios.post(`${BASE_URL}/wfm/tasks/`, updatedFormData);

        if (res.status === 200) {
          handleClose();
        } else {
          alert(res);
        }
      } catch (err) {
        console.log(err);
        // alert(err);

        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;
          if (typeof errorData.error === 'string') { // Single error message   
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {// Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        setLoading(false); //loading logic
        toast.dismiss(loadingToastId);
      }
    }
  };



  const [searchTerm, setSearchTerm] = useState('');

  const options = empList
    .filter((e) => e.name.toLowerCase().includes(searchTerm.toLowerCase()))
    .sort((a, b) => parseInt(a.emp_code) - parseInt(b.emp_code)).map((e) => ({
      value: e.emp_code,
      label: `${e.emp_code} - ${e.name}`,
    }));

  const handleChangecc = (selected) => {
    const assignedtocc = selected.length > 0 ? selected.map((item) => item.value) : null;
    setFormData((prevState) => ({
      ...prevState,
      assignedtocc,
    }));
  };

  return (
    <div>
      <form>
        <div className="flex-column">
          <label className="form-labels  font-weight500    font-size-subheading">Task</label>
          <textarea
            placeholder="Task Description"
            type="text"
            name="task"
            maxLength={300}
            onChange={handleInputChange}
            value={formData.task}
            className={`form-input-textarea   font-weight400  form-input form-input-background ${errors.task ? "error" : inputState.task ? "success" : ""
              }`}

          />
          {errors.task && (
            <span className="error-message font-size-text ">{errors.task}</span>
          )}
        </div>

        <div className="flex-column">
          <label htmlFor="date" className="form-labels  font-weight500    font-size-subheading">
            Deadline Date
          </label>
          <input
            id="date"
            type="date"
            name="due_date"
            onChange={handleInputChange}
            value={formData.due_date}
            className={`form-input form-input-background ${errors.due_date ? "error" : inputState.due_date ? "success" : ""
              }`}
          />
          {errors.due_date && (
            <span className="error-message font-size-text ">{errors.due_date}</span>
          )}
        </div>

        <div className="leave-duration  font-weight500   -heading  font-weight400  font-size-heading">Priority </div>
        <div
          value={priority}
          onChange={(e) => setPriority(e.target.value)}
          className="leave-radio-button"
        >
          <div className="leave-radio-button-inner-div">
            <input
              name="priority"
              type="radio"
              value="3"
              checked={priority === "3"}
              className="age-input"
            />
            <label className="age-label   font-weight500    font-size-heading">Low</label>
          </div>
          <div className="leave-radio-button-inner-div">
            <input
              name="priority"
              type="radio"
              value="2"
              checked={priority === "2"}
              className="age-input"
            />
            <label className="age-label   font-weight500    font-size-heading">Medium</label>
          </div>
          <div className="leave-radio-button-inner-div">
            <input
              name="priority"
              type="radio"
              value="1"
              checked={priority === "1"}
              className="age-input"
            />
            <label className="age-label   font-weight500    font-size-heading">High</label>
          </div>
        </div>

        {PERMISSION_AUTHORITY ?
          <div className="flex-column form-group-select">
            <br />
            <label className="form-labels  font-weight500    font-size-subheading">
              Sub Company<span className="required">*</span>
            </label>
            <br />
            <select
              name="subcompany"
              onChange={(e) => {
                handleCompanyChange(e.target.value);
              }}
              value={toggleCompany}
              className={`form-input form-group-selection form-input-background ${errors.concern_person
                ? "error"
                : inputState.concern_person
                  ? "success"
                  : ""
                }`}
            >
              <option value="null">Select Sub Company</option>
              {SUB_COMPANIES.map((company) => (
                <option value={company.id}>{company.title}</option>
              ))}

            </select>
            <div className="form-group-selection-arrow">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="25"
                fill="currentColor"
                class="bi bi-caret-down-fill"
                viewBox="0 0 16 16"
              >
                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
              </svg>
            </div>
          </div>
          : null}


        <br />

        <div className="flex-column form-group-select">
          <label className="form-labels  font-weight500    font-size-subheading">
            Assign To<span className="required">*</span>
          </label>
          <br />
          {buffer ? (
            <div className="spinner-vsmall"></div>
          ) : <>
            <select
              name="concern_person"
              onChange={handleInputChange}
              value={formData.concern_person}
              className={`form-input form-group-selection form-input-background ${errors.concern_person
                ? "error"
                : inputState.concern_person
                  ? "success"
                  : ""
                }`}
            >
              <option value="">Select Employee</option>
              {empList
                .sort((a, b) => a.emp_code - b.emp_code)
                .map((i, index) => (
                  <option value={i.emp_code}>
                    {i.name} - {i.emp_code}
                  </option>
                ))}

            </select>
            <div className="form-group-selection-arrow">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="25"
                fill="currentColor"
                class="bi bi-caret-down-fill"
                viewBox="0 0 16 16"
              >
                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
              </svg>
            </div>

          </>
          }
          {errors.concern_person && (
            <span className="error-message font-size-text ">{errors.concern_person}</span>
          )}
        </div>


        <div className='flex-column form-group-select'>
          <label className="form-labels  font-weight500    font-size-subheading">CC</label>

          {buffer ? (
            <div className="spinner-vsmall"></div>
          ) : <Select
            options={options}
            isMulti
            onChange={handleChangecc}
            className={`form-group-selection form-input-background `}
          />}
        </div>

        <div className="button-models">
          <button className="model-button   font-weight500  model-button-cancel  ">Cancel</button>
          <button
            className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
            onClick={handleFormSubmit}
            disabled={loading}
          >
            {/* //loading logic */}
            Assign
          </button>
        </div>
      </form>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

    </div>
  );
};


const Applymodels = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // toggle state 1 for SC 2 for CIPL/GENG
  const [toggleState, setToggleState] = useState(
    sessionStorage.getItem("company") === "Space Creattors" ||
      sessionStorage.getItem("company") === "Space Creattors Heights"
      ? 1 : 2);

  return (
    <>
      <button
        className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
        onClick={handleShow}
        style={{ padding: "10px 20px" }}
      >
        Apply
      </button>

      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="request-leave width-40vw"
      >

        <Modal.Body>
          <div>
            <div>
              <div className="bloc-tabs-apply4">

                {/*  //? @SC Customization */}
                {/* view in SC hide for CIPL/GENG */}
                {sessionStorage.getItem("company") === "Space Creattors" ||
                  sessionStorage.getItem("company") ===
                  "Space Creattors Heights" ? (
                  <button
                    className={
                      toggleState === 1
                        ? "dashboardcomponent-tabs-apply   font-weight400  font-size-subheading  dashboardcomponent-active-tabs"
                        : "dashboardcomponent-tabs-apply  font-weight400  font-size-subheading  "
                    }
                    onClick={() => setToggleState(1)}
                  >
                    Clock in
                  </button>
                ) : (
                  ""
                )}

                <button
                  className={
                    toggleState === 2
                      ? "dashboardcomponent-tabs-apply  font-weight400  font-size-subheading dashboardcomponent-active-tabs"
                      : "dashboardcomponent-tabs-apply  font-weight400  font-size-subheading"
                  }
                  onClick={() => setToggleState(2)}
                >
                  Apply Leave
                </button>
                <button
                  className={
                    toggleState === 4
                      ? "dashboardcomponent-tabs-apply  font-weight400  font-size-subheading   dashboardcomponent-active-tabs"
                      : "dashboardcomponent-tabs-apply  font-weight400  font-size-subheading  "
                  }
                  onClick={() => setToggleState(4)}
                >
                  Out Duty
                </button>
                <button
                  className={
                    toggleState === 3
                      ? "dashboardcomponent-tabs-apply  font-weight400  font-size-subheading dashboardcomponent-active-tabs"
                      : "dashboardcomponent-tabs-apply  font-weight400  font-size-subheading"
                  }
                  onClick={() => setToggleState(3)}
                >
                  Assign Task
                </button>
              </div>
            </div>

            <div className="content-tabs ">
              {toggleState === 1 ? <Clockin handleClose={handleClose} /> : null}

              {toggleState === 2 ? <Leave handleClose={handleClose} /> : null}

              {toggleState === 3 ? <AssignTask handleClose={handleClose} /> : null}

              {toggleState === 4 ? <Outduty handleClose={handleClose} /> : null}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Applymodels;
