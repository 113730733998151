import React from 'react';
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { routingData } from "./routing";



const RedirectRouter = () => {
  let role = sessionStorage.getItem("role");
  const location = useLocation();

  if (!role) {
    return <Outlet />;
  }

  const roleObject = routingData.find((e) => e.role === role)

  return <Navigate to={roleObject.defaultRoute} state={{ from: location }} replace />;
}

export default RedirectRouter