// import React from 'react'
import React, { useEffect, useRef, useState } from "react";
import { BASE_URL } from "../../config/axios";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import { customSortByKey, downloadAsExcel, handleErrorToast } from "../CustomFunctions";
import { ToastContainer } from "react-toastify";
import { Active, CircularGrid, DownloadIcon, Employee, Eye, Filter, Office } from "../AllSvg";
import { formatDateTime, formattedDateLong } from "../Date";
import { UploadAppraisalDetailsByHODAdmin, UploadAppraisalDetailsByHOD, ViewAppraisalDetailsHODAdmin, ViewAppraisalDetailsByHOD, ViewAppraisalDetailsByRHHOD, UploadAppraisalDetailsByRHHOD, ViewAppraisalDetailsRHHODAdmin, UploadAppraisalDetailsByRH, ViewAppraisalDetailsByRH, UploadAppraisalDetailsByRHHODAdmin } from "./AppraisalComponents";


const AppraisalFormsTableRH = () => {

  const [buffer, setBuffering] = useState(true); //buffering logic
  const [employeeList, setEmployeeList] = useState([]);

  const [selectedStatus, setSelectedStatus] = useState("null");
  const [employeeGet, setEmployeeGet] = useState("null");
  const [active, setActive] = useState("active");
  const [selectedDepartment, setDepartment] = useState(
    `${sessionStorage.getItem("department")}`
  );

  const [selectedSubCompany, setSelectedSubCompany] = useState(
    `${sessionStorage.getItem("company_id")}`
  );

  const [SubCompany, setSubCompany] = useState([]);

  const getSubCompany = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/subcompany/`);
      setSubCompany(res.data);
    } catch (err) {
      handleErrorToast(err)
    }
  };

  // *********** Employee Details Api Start ***********
  const [applraisalFormList, setApplraisalFormList] = useState([]);

  const getEmpList = async () => {
    try {
      // const res = await axios.get(`${BASE_URL}/wfm/ /${active}/${selectedDepartment}/`);
      const res = await axios.get(`${BASE_URL}/wfm/ourcompanyempdetails/${active}/${selectedDepartment}/`);
      const sortedData = customSortByKey(res.data, "emp_code");
      setEmployeeList(sortedData);
    } catch (err) {
      handleErrorToast(err)
    }
  };

  const getApplraisalFormListRH = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/appraisals/${sessionStorage.getItem("emp_code")}/null/null/null/`
      );
      const sortedData = customSortByKey(res.data, "emp_code");
      setApplraisalFormList(sortedData);
      // setApplraisalFormList(res.data);
    } catch (err) {
      handleErrorToast(err);
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };

  // *********** Employee Details Api End ***********

  useEffect(() => {
    getApplraisalFormListRH();
    getEmpList();
    getSubCompany();
  }, [employeeGet, selectedSubCompany]);

  useEffect(() => {
  }, []);

  const tableRef = useRef(null);
  const handleDownloadAppraisalSheet = () => {
    downloadAsExcel(tableRef, "users",
      selectedDepartment === "" ? `AppraisalSheet` :
        `AppraisalSheet`);
    // `${selectedDepartment} - AppraisalSheet`);
  };

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div className="attendance-history-cont">
        <div className="attendance-heading  font-weight500    font-size-heading"> </div>

        <div className="table-css-white-background">
          <div className="table-heading-flex">
            <div className="repo-heading font-weight500    font-size-heading">Appraisal Ratings</div>
            <div className="btn-cont">
              <button className=" model-button-print" title="Download in Excel Format" onClick={handleDownloadAppraisalSheet}>
                <DownloadIcon />
              </button>
            </div>
          </div>
          <table ref={tableRef} className="table-css">
            <thead>
              <tr className="custom-table-head-tr">
                <th className="align-left">Sr.no</th>
                <th className="align-center">Empolyee</th>
                <th className="align-center">Employee Self Rating</th>
                <th className="align-center">Employee Submission</th>
                <th className="align-center">Reporting Head Rating</th>
                <th className="align-center">Reporting Head Submission</th>
                {/* <th className="align-center">HOD Rating</th>
                <th className="align-center">HOD Submission</th>
                <th className="align-center">Admin Rating</th>
                <th className="align-center">Admin Submission</th>
                <th className="align-center">Final Rating</th> */}
                <th className="align-center">Actions</th>
              </tr>
            </thead>
            {buffer ? (
              <div className="spinner"></div>
            ) : (
              <tbody>
                {applraisalFormList.map((i, index) => (
                  <React.Fragment key={index}>
                    <tr className="tr-border-bottom">
                      <td colSpan="6"></td>
                    </tr>
                    <tr className="custom-table-head-td">
                      <td className="align-left">{index + 1}</td>
                      <td className="align-center">
                        {/* <img
                          className="profile-img"
                          src={
                            `${i.profilepic}`
                              ? `https://cipl-aimantra.s3.ap-south-1.amazonaws.com/${i.profilepic}`
                              : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                          }
                          alt="profilepic"
                        /> 
                        <p>{i.emp_code}-{i.name}</p> */}
                        <p>{i.emp_code}-{i.name}</p>
                      </td>

                      <td className="align-center">
                        {i.emp_update_datetime ?
                          <>
                            {i.total_emp}
                          </>
                          : "-"
                        }
                      </td>

                      <td className="align-center">
                        {i.emp_update_datetime ?
                          <>
                            < p > Submited At :
                              <br />
                              {i.emp_update_datetime ? formatDateTime(i.emp_update_datetime).full : null}</p>
                          </>
                          : "-"
                        }
                      </td>

                      <td className="align-center">
                        {i.rh_update_datetime ?
                          <>
                            {i.total_rh}
                          </>
                          : "-"
                        }
                      </td>

                      <td className="align-center">
                        {i.rh_update_datetime ?
                          <>
                            <p>By : {i.rh_update_by}
                              <br />
                              At : {i.rh_update_datetime ? formatDateTime(i.rh_update_datetime).full : null}
                            </p>
                          </>
                          : "-"
                        }
                      </td>

                      {/* <td className="align-center">
                        {i.hod_update_datetime ?
                          <>
                            {i.total_hod}
                          </>
                          : "-"
                        }
                      </td>

                      <td className="align-center">
                        {i.hod_update_datetime ?
                          <>
                            <p>By : {i.hod_update_by}
                              <br />
                              At : {i.hod_update_datetime ? formatDateTime(i.hod_update_datetime).full : null}
                            </p>
                          </>
                          : "-"
                        }
                      </td>

                      <td className="align-center">
                        {i.admin_update_datetime ?
                          <>
                            {i.total_admin}
                          </>
                          : "-"
                        }
                      </td>

                      <td className="align-center">
                        {i.admin_update_datetime ?
                          <>
                            <p>By : {i.admin_update_by}
                              {i.admin_update_datetime ? formatDateTime(i.admin_update_datetime).full : null}
                            </p>
                          </>
                          : "-"
                        }
                      </td>

                      <td className="align-center">
                        {i.final_rating || '-'}
                      </td> */}

                      <td className="align-center">
                        {i.admin_update_datetime ?
                          <ViewAppraisalDetailsByRH i={i} />
                          :
                          <UploadAppraisalDetailsByRH i={i} getDepartmentAppraisalData={getApplraisalFormListRH} />
                        }
                      </td>

                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            )}
          </table>
        </div>
      </div >
    </>
  );
};


const AppraisalFormsTableHOD = () => {

  const [buffer, setBuffering] = useState(true); //buffering logic
  const [employeeList, setEmployeeList] = useState([]);

  const [selectedStatus, setSelectedStatus] = useState("null");
  const [employeeGet, setEmployeeGet] = useState("null");
  const [active, setActive] = useState("active");
  const [selectedDepartment, setDepartment] = useState(
    `${sessionStorage.getItem("department")}`
  );

  const [selectedSubCompany, setSelectedSubCompany] = useState(
    `${sessionStorage.getItem("company_id")}`
  );

  const [SubCompany, setSubCompany] = useState([]);

  const getSubCompany = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/subcompany/`);
      setSubCompany(res.data);
    } catch (err) {
      handleErrorToast(err)
    }
  };

  // *********** Employee Details Api Start ***********
  const [applraisalFormList, setApplraisalFormList] = useState([]);

  const getEmpList = async () => {
    try {
      // const res = await axios.get(`${BASE_URL}/wfm/ /${active}/${selectedDepartment}/`);
      const res = await axios.get(`${BASE_URL}/wfm/ourcompanyempdetails/${active}/${selectedDepartment}/`);
      const sortedData = customSortByKey(res.data, "emp_code");
      setEmployeeList(sortedData);
    } catch (err) {
      handleErrorToast(err)
    }
  };

  const getApplraisalFormListHOD = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/appraisals/null/${selectedDepartment}/null/null/`
      );
      const sortedData = customSortByKey(res.data, "emp_code");
      setApplraisalFormList(sortedData);
      // setApplraisalFormList(res.data);
    } catch (err) {
      handleErrorToast(err);
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };

  // *********** Employee Details Api End ***********

  useEffect(() => {
    getApplraisalFormListHOD();
    getEmpList();
    getSubCompany();
  }, [employeeGet, selectedSubCompany]);

  useEffect(() => {
  }, []);

  const tableRef = useRef(null);
  const handleDownloadAppraisalSheet = () => {
    downloadAsExcel(tableRef, "users",
      selectedDepartment === "" ? `AppraisalSheet` :
        `AppraisalSheet`);
    // `${selectedDepartment} - AppraisalSheet`);
  };

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div className="attendance-history-cont">
        <div className="attendance-heading  font-weight500    font-size-heading"> </div>
        {/* <div className="attendance-subcont">
          <div className="field-cont">

            <div className="field-cont-div">
              <Office />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedSubCompany}
                onChange={(e) => setSelectedSubCompany(e.target.value)}
              >
                <option value="">All Sub Companies</option>
                {SubCompany.sort((a, b) => a.title - b.title).map((i, index) => (
                  <option value={i.id}>{i.title}</option>
                ))}
                ;
              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Active />
              <select
                className="attendance-input-field width-10vw  "
                type="text"
                value={active}
                onChange={(e) => setActive(e.target.value)}
              >
                <option value="null">All</option>
                <option value="active">Active</option>
                <option value="inactive">In Active</option>
              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <CircularGrid />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={employeeGet}
                onChange={(e) => setEmployeeGet(e.target.value)}
              >
                <option value="">Select Employee Code</option>
                {employeeList
                  .sort((a, b) => a.emp_code - b.emp_code)
                  .map((i, index) => (
                    <option value={i.emp_code}>
                      {i.emp_code} - {i.name}
                    </option>
                  ))}
                ;
              </select>
              <hr className="field-cont-hr" />
            </div>

          </div>
          <div className="btn-cont">
            <button className=" model-button-print" title="Download in Excel Format" onClick={handleDownloadAppraisalSheet}>
              <DownloadIcon />
            </button>
          </div>
        </div> */}

        <div className="table-css-white-background">
          <div className="table-heading-flex">
            <div className="repo-heading font-weight500    font-size-heading">Appraisal Ratings</div>
            <div className="btn-cont">
              <button className=" model-button-print" title="Download in Excel Format" onClick={handleDownloadAppraisalSheet}>
                <DownloadIcon />
              </button>
            </div>
          </div>
          <table ref={tableRef} className="table-css">
            <thead>
              <tr className="custom-table-head-tr">
                <th className="align-left">Sr.no</th>
                <th className="align-center">Empolyee</th>
                <th className="align-center">Employee Self Rating</th>
                <th className="align-center">Employee Submission</th>
                <th className="align-center">HOD Rating</th>
                <th className="align-center">HOD Submission</th>
                {/* <th className="align-center">Admin Rating</th>
                <th className="align-center">Admin Submission</th>
                <th className="align-center">Final Rating</th> */}
                <th className="align-center">Actions</th>
              </tr>
            </thead>
            {buffer ? (
              <div className="spinner"></div>
            ) : (
              <tbody>
                {applraisalFormList.map((i, index) => (
                  <React.Fragment key={index}>
                    <tr className="tr-border-bottom">
                      <td colSpan="6"></td>
                    </tr>
                    <tr className="custom-table-head-td">
                      <td className="align-left">{index + 1}</td>
                      <td className="align-center">
                        {/* <img
                          className="profile-img"
                          src={
                            `${i.profilepic}`
                              ? `https://cipl-aimantra.s3.ap-south-1.amazonaws.com/${i.profilepic}`
                              : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                          }
                          alt="profilepic"
                        /> 
                        <p>{i.emp_code}-{i.name}</p> */}
                        <p>{i.emp_code}-{i.name}</p>
                      </td>

                      <td className="align-center">
                        {i.emp_update_datetime ?
                          <>
                            {i.total_emp}
                          </>
                          : "-"
                        }
                      </td>

                      <td className="align-center">
                        {i.emp_update_datetime ?
                          <>
                            < p > Submited At :
                              <br />
                              {i.emp_update_datetime ? formatDateTime(i.emp_update_datetime).full : null}</p>
                          </>
                          : "-"
                        }
                      </td>

                      <td className="align-center">
                        {i.hod_update_datetime ?
                          <>
                            {i.total_hod}
                          </>
                          : "-"
                        }
                      </td>

                      <td className="align-center">
                        {i.hod_update_datetime ?
                          <>
                            <p>By : {i.hod_update_by}
                              <br />
                              At : {i.hod_update_datetime ? formatDateTime(i.hod_update_datetime).full : null}
                            </p>
                          </>
                          : "-"
                        }
                      </td>

                      {/* <td className="align-center">
                        {i.admin_update_datetime ?
                          <>
                            {i.total_admin}
                          </>
                          : "-"
                        }
                      </td>

                      <td className="align-center">
                        {i.admin_update_datetime ?
                          <>
                            <p>By : {i.admin_update_by}
                              {i.admin_update_datetime ? formatDateTime(i.admin_update_datetime).full : null}
                            </p>
                          </>
                          : "-"
                        }
                      </td>

                      <td className="align-center">
                        {i.final_rating || '-'}
                      </td> */}
                      <td className="align-center">
                        {i.admin_update_datetime ?
                          <ViewAppraisalDetailsByHOD i={i} />
                          :
                          <UploadAppraisalDetailsByHOD i={i} getDepartmentAppraisalData={getApplraisalFormListHOD} />
                        }
                      </td>

                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            )}
          </table>
        </div>
      </div >
    </>
  );
};

const AppraisalFormsTableRHHOD = () => {

  const [buffer, setBuffering] = useState(true); //buffering logic
  const [employeeList, setEmployeeList] = useState([]);

  const [selectedStatus, setSelectedStatus] = useState("null");
  const [employeeGet, setEmployeeGet] = useState("null");
  const [active, setActive] = useState("active");
  const [selectedDepartment, setDepartment] = useState(
    `${sessionStorage.getItem("department")}`
  );

  const [selectedSubCompany, setSelectedSubCompany] = useState(
    `${sessionStorage.getItem("company_id")}`
  );

  const [SubCompany, setSubCompany] = useState([]);

  const getSubCompany = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/subcompany/`);
      setSubCompany(res.data);
    } catch (err) {
      handleErrorToast(err)
    }
  };

  // *********** Employee Details Api Start ***********
  const [applraisalFormList, setApplraisalFormList] = useState([]);

  const getEmpList = async () => {
    try {
      // const res = await axios.get(`${BASE_URL}/wfm/ /${active}/${selectedDepartment}/`);
      const res = await axios.get(`${BASE_URL}/wfm/ourcompanyempdetails/${active}/${selectedDepartment}/`);
      const sortedData = customSortByKey(res.data, "emp_code");
      setEmployeeList(sortedData);
    } catch (err) {
      handleErrorToast(err)
    }
  };

  const getApplraisalFormListHOD = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/appraisals/null/${selectedDepartment}/null/null/`
      );
      const sortedData = customSortByKey(res.data, "emp_code");
      setApplraisalFormList(sortedData);
      // setApplraisalFormList(res.data);
    } catch (err) {
      handleErrorToast(err);
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };

  // *********** Employee Details Api End ***********

  useEffect(() => {
    getApplraisalFormListHOD();
    getEmpList();
    getSubCompany();
  }, [employeeGet, selectedSubCompany]);

  useEffect(() => {
  }, []);

  const tableRef = useRef(null);
  const handleDownloadAppraisalSheet = () => {
    downloadAsExcel(tableRef, "users",
      selectedDepartment === "" ? `AppraisalSheet` :
        `AppraisalSheet`);
    // `${selectedDepartment} - AppraisalSheet`);
  };

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div className="attendance-history-cont">
        <div className="attendance-heading  font-weight500    font-size-heading"> </div>
        {/* <div className="attendance-subcont">
          <div className="field-cont">

            <div className="field-cont-div">
              <Office />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedSubCompany}
                onChange={(e) => setSelectedSubCompany(e.target.value)}
              >
                <option value="">All Sub Companies</option>
                {SubCompany.sort((a, b) => a.title - b.title).map((i, index) => (
                  <option value={i.id}>{i.title}</option>
                ))}
                ;
              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Active />
              <select
                className="attendance-input-field width-10vw  "
                type="text"
                value={active}
                onChange={(e) => setActive(e.target.value)}
              >
                <option value="null">All</option>
                <option value="active">Active</option>
                <option value="inactive">In Active</option>
              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <CircularGrid />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={employeeGet}
                onChange={(e) => setEmployeeGet(e.target.value)}
              >
                <option value="">Select Employee Code</option>
                {employeeList
                  .sort((a, b) => a.emp_code - b.emp_code)
                  .map((i, index) => (
                    <option value={i.emp_code}>
                      {i.emp_code} - {i.name}
                    </option>
                  ))}
                ;
              </select>
              <hr className="field-cont-hr" />
            </div>

          </div>
          <div className="btn-cont">
            <button className=" model-button-print" title="Download in Excel Format" onClick={handleDownloadAppraisalSheet}>
              <DownloadIcon />
            </button>
          </div>
        </div> */}

        <div className="table-css-white-background">
          <div className="table-heading-flex">
            <div className="repo-heading font-weight500    font-size-heading">Appraisal Ratings</div>
            <div className="btn-cont">
              <button className=" model-button-print" title="Download in Excel Format" onClick={handleDownloadAppraisalSheet}>
                <DownloadIcon />
              </button>
            </div>
          </div>
          <table ref={tableRef} className="table-css">
            <thead>
              <tr className="custom-table-head-tr">
                <th className="align-left">Sr.no</th>
                <th className="align-center">Empolyee</th>
                <th className="align-center">Employee Self Rating</th>
                <th className="align-center">Employee Submission</th>
                <th className="align-center">Reporting Head Rating</th>
                <th className="align-center">Reporting Head Submission</th>
                <th className="align-center">HOD Rating</th>
                <th className="align-center">HOD Submission</th>
                {/* <th className="align-center">Admin Rating</th>
                <th className="align-center">Admin Submission</th>
                <th className="align-center">Final Rating</th> */}
                <th className="align-center">Actions</th>
              </tr>
            </thead>
            {buffer ? (
              <div className="spinner"></div>
            ) : (
              <tbody>
                {applraisalFormList.map((i, index) => (
                  <React.Fragment key={index}>
                    <tr className="tr-border-bottom">
                      <td colSpan="6"></td>
                    </tr>
                    <tr className="custom-table-head-td">
                      <td className="align-left">{index + 1}</td>
                      <td className="align-center">
                        {/* <img
                          className="profile-img"
                          src={
                            `${i.profilepic}`
                              ? `https://cipl-aimantra.s3.ap-south-1.amazonaws.com/${i.profilepic}`
                              : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                          }
                          alt="profilepic"
                        /> 
                        <p>{i.emp_code}-{i.name}</p> */}
                        <p>{i.emp_code}-{i.name}</p>
                      </td>

                      <td className="align-center">
                        {i.emp_update_datetime ?
                          <>
                            {i.total_emp}
                          </>
                          : "-"
                        }
                      </td>

                      <td className="align-center">
                        {i.emp_update_datetime ?
                          <>
                            < p > Submited At :
                              <br />
                              {i.emp_update_datetime ? formatDateTime(i.emp_update_datetime).full : null}</p>
                          </>
                          : "-"
                        }
                      </td>

                      <td className="align-center">
                        {i.rh_update_datetime ?
                          <>
                            {i.total_rh}
                          </>
                          : "-"
                        }
                      </td>

                      <td className="align-center">
                        {i.rh_update_datetime ?
                          <>
                            <p>By : {i.rh_update_by}
                              <br />
                              At : {i.rh_update_datetime ? formatDateTime(i.rh_update_datetime).full : null}
                            </p>
                          </>
                          : "-"
                        }
                      </td>

                      <td className="align-center">
                        {i.hod_update_datetime ?
                          <>
                            {i.total_hod}
                          </>
                          : "-"
                        }
                      </td>

                      <td className="align-center">
                        {i.hod_update_datetime ?
                          <>
                            <p>By : {i.hod_update_by}
                              <br />
                              At : {i.hod_update_datetime ? formatDateTime(i.hod_update_datetime).full : null}
                            </p>
                          </>
                          : "-"
                        }
                      </td>

                      {/* <td className="align-center">
                        {i.admin_update_datetime ?
                          <>
                            {i.total_admin}
                          </>
                          : "-"
                        }
                      </td>

                      <td className="align-center">
                        {i.admin_update_datetime ?
                          <>
                            <p>By : {i.admin_update_by}
                              {i.admin_update_datetime ? formatDateTime(i.admin_update_datetime).full : null}
                            </p>
                          </>
                          : "-"
                        }
                      </td>

                      <td className="align-center">
                        {i.final_rating || '-'}
                      </td> */}
                      <td className="align-center">
                        {i.admin_update_datetime ?
                          <ViewAppraisalDetailsByRHHOD i={i} />
                          :
                          <UploadAppraisalDetailsByRHHOD i={i} getDepartmentAppraisalData={getApplraisalFormListHOD} />
                        }
                      </td>

                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            )}
          </table>
        </div>
      </div >
    </>
  );
};


const AppraisalFormsTableHODAdmin = () => {

  const [buffer, setBuffering] = useState(true); //buffering logic
  const [buffer1, setBuffering1] = useState(true);
  const [employeeList, setEmployeeList] = useState([]);
  const [timeStampView, setTimeStampView] = useState(false);

  const [selectedStatus, setSelectedStatus] = useState("null");
  const [employeeGet, setEmployeeGet] = useState("null");
  const [active, setActive] = useState("active");
  // const [selectedDepartment, setDepartment] = useState(
  //   `${sessionStorage.getItem("department")}`
  // );
  const [selectedDepartment, setDepartment] = useState(`null`);

  const [selectedSubCompany, setSelectedSubCompany] = useState(
    `${sessionStorage.getItem("company_id")}`
  );

  const [SubCompany, setSubCompany] = useState([]);

  const getSubCompany = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/subcompany/`);
      setSubCompany(res.data);
    } catch (err) {
      handleErrorToast(err)
    }
  };

  const [departmentname, setDepartmentname] = useState([]);

  const getDepartment = async () => {
    try {
      setBuffering1(true)
      const dep = await axios.get(`${BASE_URL}/wfm/departmentfilterbysubcompanycount/${selectedSubCompany || 'null'}/active/`);
      setDepartmentname(dep.data)
    } catch (err) {
    } finally {
      setBuffering1(false)
    }
  };

  // *********** Employee Details Api Start ***********
  const [applraisalFormList, setApplraisalFormList] = useState([]);

  const getEmpList = async () => {
    try {
      // const res = await axios.get(`${BASE_URL}/wfm/ /${active}/${selectedDepartment}/`);
      const res = await axios.get(`${BASE_URL}/wfm/ourcompanyempdetails/${active}/${selectedSubCompany}/`);
      const sortedData = customSortByKey(res.data, "emp_code");
      setEmployeeList(sortedData);
    } catch (err) {
      handleErrorToast(err)
    }
  };

  const getApplraisalFormListAdmin = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/appraisals/null/${selectedDepartment}/${employeeGet}/${selectedSubCompany}/`
      );
      const sortedData = customSortByKey(res.data, "emp_code");
      setApplraisalFormList(sortedData);
      // setApplraisalFormList(res.data);
    } catch (err) {
      handleErrorToast(err);
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };

  // *********** Employee Details Api End ***********

  useEffect(() => {
    getApplraisalFormListAdmin();
    getEmpList();
    getDepartment();
    getSubCompany();
  }, [employeeGet, selectedDepartment, selectedSubCompany]);

  useEffect(() => {
  }, []);

  const tableRef = useRef(null);
  const handleDownloadAppraisalSheet = () => {
    downloadAsExcel(tableRef, "users",
      selectedDepartment === "" ? `AppraisalSheet` :
        `AppraisalSheet`);
    // `${selectedDepartment} - AppraisalSheet`);
  };
  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div className="attendance-history-cont">
        <div className="attendance-heading  font-weight500    font-size-heading"> </div>
        <div className="attendance-subcont">
          <div className="field-cont">

            <div className="field-cont-div">
              <Office />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedSubCompany}
                onChange={(e) => setSelectedSubCompany(e.target.value)}
              >
                {/* <option value="">All Sub Companies</option> */}
                {SubCompany.sort((a, b) => a.title - b.title).map((i, index) => (
                  <option value={i.id}>{i.title}</option>
                ))}
                ;
              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <CircularGrid />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedDepartment}
                onChange={(e) => setDepartment(e.target.value)}
              >
                <option value="null">All Department</option>
                {departmentname
                  .sort((a, b) => a.title - b.title)
                  .map((i, index) => (
                    <option value={i.id}>{i.title}</option>
                  ))};
              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Employee />
              <select
                className="attendance-input-field width-15vw   date-field"
                type="text"
                value={employeeGet}
                onChange={(e) => setEmployeeGet(e.target.value)}
              >
                <option value="null">Select Employee Code</option>
                {employeeList
                  .sort((a, b) => a.emp_code - b.emp_code)
                  .map((i, index) => (
                    <option value={i.emp_code}>
                      {i.emp_code} - {i.name}
                    </option>
                  ))}
                ;
              </select>
              <hr className="field-cont-hr" />
            </div>


          </div>

          <div className="btn-cont">
            <button className=" model-button-print" title="Download in Excel Format" onClick={handleDownloadAppraisalSheet}>
              <DownloadIcon />
            </button>
          </div>
        </div>

        <div className="table-css-white-background">
          <div className="table-heading-flex">
            <div className="repo-heading font-weight500 font-size-heading">Appraisal Ratings ({applraisalFormList.length}/{employeeList.length})</div>
            <div className="filter-showing">
              <div className="flex-row ">
                <label htmlFor="EmpCode" className="form-labels  font-weight500    font-size-subheading">
                  Time Stamps {timeStampView ? "Shown" : "Hidden"} : {" "}
                </label>
                <div className="checkbox-toggle">
                  <input
                    type="checkbox"
                    id="toggle1"
                    checked={timeStampView}
                    onChange={() => setTimeStampView(!timeStampView)}
                  />
                  <label htmlFor="toggle1"></label>
                </div>
              </div>
            </div>
          </div>
          <table ref={tableRef} className="table-css">
            <thead>
              <tr className="custom-table-head-tr">
                <th className="align-left">Sr.no</th>
                <th className="align-center">Empolyee</th>
                <th className="align-center">Employee Self Rating</th>
                {timeStampView ?
                  <th className="align-center">Employee TimeStamp</th>
                  : null
                }
                <th className="align-center">HOD Rating</th>
                <th className="align-center">HOD Submission</th>
                {timeStampView ?
                  <th className="align-center">HOD TimeStamp</th>
                  : null
                }
                <th className="align-center">Admin Rating</th>
                <th className="align-center">Admin Submission</th>
                {timeStampView ?
                  <th className="align-center">Admin TimeStamp</th>
                  : null
                }
                <th className="align-center">Final Rating</th>
                <th className="align-center">Actions</th>
              </tr>
            </thead>
            {buffer ? (
              <div className="spinner"></div>
            ) : (
              <tbody>
                {applraisalFormList.map((i, index) => (
                  <React.Fragment key={index}>
                    <tr className="tr-border-bottom">
                      <td colSpan="6"></td>
                    </tr>
                    <tr className="custom-table-head-td">
                      <td className="align-left">{index + 1}</td>
                      <td className="align-center">
                        {/* <img
                          className="profile-img"
                          src={
                            `${i.profilepic}`
                              ? `https://cipl-aimantra.s3.ap-south-1.amazonaws.com/${i.profilepic}`
                              : "https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                          }
                          alt="profilepic"
                        /> 
                        <p>{i.emp_code}-{i.name}</p> */}
                        <p>{i.emp_code}-{i.name}</p>
                      </td>

                      <td className="align-center">
                        {i.emp_update_datetime ?
                          <>
                            {i.total_emp}
                          </>
                          : "-"
                        }
                      </td>

                      {timeStampView ?
                        <td className="align-center">
                          {i.emp_update_datetime ?
                            <>
                              {i.emp_update_datetime ? formatDateTime(i.emp_update_datetime).full : null}
                            </>
                            : "-"
                          }
                        </td>
                        : null
                      }

                      <td className="align-center">
                        {i.hod_update_datetime ?
                          <>
                            {i.total_hod}
                          </>
                          : "-"
                        }
                      </td>

                      <td className="align-center">
                        {i.hod_update_by ?
                          <>
                            {i.hod_update_by}
                          </>
                          : "-"
                        }
                      </td>

                      {timeStampView ?
                        <td className="align-center">
                          {i.hod_update_datetime ?
                            <>
                              {i.hod_update_datetime ? formatDateTime(i.hod_update_datetime).full : null}
                            </>
                            : "-"
                          }
                        </td>
                        : null
                      }

                      <td className="align-center">
                        {i.admin_update_datetime ?
                          <>
                            {i.total_admin}
                          </>
                          : "-"
                        }
                      </td>

                      <td className="align-center">
                        {i.admin_update_by ?
                          <>
                            {i.admin_update_by}
                          </>
                          : "-"
                        }
                      </td>

                      {timeStampView ?
                        <td className="align-center">
                          {i.admin_update_datetime ?
                            <>
                              {i.admin_update_datetime ? formatDateTime(i.admin_update_datetime).full : null}
                            </>
                            : "-"
                          }
                        </td>
                        : null
                      }

                      <td className="align-center">
                        {i.final_rating || '-'}
                      </td>

                      <td className="align-center">
                        {/* {i.admin_update_datetime ?
                          <ViewAppraisalDetailsHODAdmin i={i} />
                          :
                          <UploadAppraisalDetailsByHODAdmin i={i} getDepartmentAppraisalData={getApplraisalFormListAdmin} />
                        } */}
                        <UploadAppraisalDetailsByHODAdmin i={i} getDepartmentAppraisalData={getApplraisalFormListAdmin} />
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            )}
          </table>
        </div>
      </div >
    </>
  );
};

const AppraisalFormsTableRHHODAdmin = () => {

  const [buffer, setBuffering] = useState(true); //buffering logic
  const [buffer1, setBuffering1] = useState(true);
  const [employeeList, setEmployeeList] = useState([]);
  const [timeStampView, setTimeStampView] = useState(false);

  const [selectedStatus, setSelectedStatus] = useState("null");
  const [employeeGet, setEmployeeGet] = useState("null");
  const [active, setActive] = useState("active");
  // const [selectedDepartment, setDepartment] = useState(
  //   `${sessionStorage.getItem("department")}`
  // );
  const [selectedDepartment, setDepartment] = useState(`null`);

  const [selectedSubCompany, setSelectedSubCompany] = useState(
    `${sessionStorage.getItem("company_id")}`
  );

  const [SubCompany, setSubCompany] = useState([]);
  const getSubCompany = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/subcompany/`);
      setSubCompany(res.data);
    } catch (err) {
      handleErrorToast(err)
    }
  };

  const [departmentname, setDepartmentname] = useState([]);
  const getDepartment = async () => {
    try {
      setBuffering1(true)
      const dep = await axios.get(`${BASE_URL}/wfm/departmentfilterbysubcompanycount/${selectedSubCompany || 'null'}/active/`);
      setDepartmentname(dep.data)
    } catch (err) {
    } finally {
      setBuffering1(false)
    }
  };


  // *********** Employee Details Api Start ***********
  const [applraisalFormList, setApplraisalFormList] = useState([]);


  const getEmpList = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/ourcompanyempdetails/${active}/${selectedSubCompany}/`);
      const sortedData = customSortByKey(res.data, "emp_code");
      setEmployeeList(sortedData);
    } catch (err) {
      handleErrorToast(err)
    }
  };

  const getApplraisalFormListAdmin = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/appraisals/null/${selectedDepartment}/${employeeGet}/${selectedSubCompany}/`
      );
      const sortedData = customSortByKey(res.data, "emp_code");
      setApplraisalFormList(sortedData);
      // setApplraisalFormList(res.data);
    } catch (err) {
      handleErrorToast(err);
    } finally {
      setBuffering(false); //buffering logic // End Buffering
    }
  };

  // *********** Employee Details Api End ***********

  useEffect(() => {
    getApplraisalFormListAdmin();
    getEmpList();
    getDepartment();
    getSubCompany();
  }, [employeeGet, selectedDepartment, selectedSubCompany]);

  useEffect(() => {
  }, []);

  const tableRef = useRef(null);
  const handleDownloadAppraisalSheet = () => {
    downloadAsExcel(tableRef, "users",
      selectedDepartment === "" ? `AppraisalSheet` :
        `AppraisalSheet`);
    // `${selectedDepartment} - AppraisalSheet`);
  };
  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div className="attendance-history-cont">
        <div className="attendance-heading  font-weight500    font-size-heading"> </div>
        <div className="attendance-subcont">
          <div className="field-cont">

            <div className="field-cont-div">
              <Office />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedSubCompany}
                onChange={(e) => setSelectedSubCompany(e.target.value)}
              >
                {/* <option value="">All Sub Companies</option> */}
                {SubCompany.sort((a, b) => a.title - b.title).map((i, index) => (
                  <option value={i.id}>{i.title}</option>
                ))}
                ;
              </select>
              <hr className="field-cont-hr" />
            </div>


            <div className="field-cont-div">
              <CircularGrid />
              <select
                className="attendance-input-field width-10vw   date-field"
                type="text"
                value={selectedDepartment}
                onChange={(e) => setDepartment(e.target.value)}
              >
                <option value="null">All Department</option>
                {departmentname
                  .sort((a, b) => a.title - b.title)
                  .map((i, index) => (
                    <option value={i.id}>{i.title}</option>
                  ))};
              </select>
              <hr className="field-cont-hr" />
            </div>

            <div className="field-cont-div">
              <Employee />
              <select
                className="attendance-input-field width-15vw   date-field"
                type="text"
                value={employeeGet}
                onChange={(e) => setEmployeeGet(e.target.value)}
              >
                <option value="null">Select Employee Code</option>
                {employeeList
                  .sort((a, b) => a.emp_code - b.emp_code)
                  .map((i, index) => (
                    <option value={i.emp_code}>
                      {i.emp_code} - {i.name}
                    </option>
                  ))}
                ;
              </select>
              <hr className="field-cont-hr" />
            </div>

          </div>

          <div className="btn-cont">
            <button className=" model-button-print" title="Download in Excel Format" onClick={handleDownloadAppraisalSheet}>
              <DownloadIcon />
            </button>
          </div>
        </div>

        <div className="table-css-white-background">
          <div className="table-heading-flex">
            <div className="repo-heading font-weight500 font-size-heading">Appraisal Ratings ({applraisalFormList.length}/{employeeList.length})</div>
            <div className="filter-showing">
              <div className="flex-row ">
                <label htmlFor="EmpCode" className="form-labels  font-weight500    font-size-subheading">
                  Time Stamps {timeStampView ? "Shown" : "Hidden"} : {" "}
                </label>
                <div className="checkbox-toggle">
                  <input
                    type="checkbox"
                    id="toggle1"
                    checked={timeStampView}
                    onChange={() => setTimeStampView(!timeStampView)}
                  />
                  <label htmlFor="toggle1"></label>
                </div>
              </div>
            </div>
          </div>
          <table ref={tableRef} className="table-css">
            <thead>
              <tr className="custom-table-head-tr">
                <th className="align-left">Sr.no</th>
                <th className="align-center">Empolyee</th>
                <th className="align-center">Employee Self Rating</th>
                {timeStampView ?
                  <th className="align-center">Employee TimeStamp</th>
                  : null
                }
                <th className="align-center">Reporting Head Rating</th>
                <th className="align-center">Reporting Head Submission</th>
                {timeStampView ?
                  <th className="align-center">Reporting Head TimeStamp</th>
                  : null
                }
                <th className="align-center">HOD Rating</th>
                <th className="align-center">HOD Submission</th>
                {timeStampView ?
                  <th className="align-center">HOD TimeStamp</th>
                  : null
                }
                <th className="align-center">Admin Rating</th>
                <th className="align-center">Admin Submission</th>

                {timeStampView ?
                  <th className="align-center">Admin TimeStamp</th>
                  : null
                }
                <th className="align-center">Final Rating</th>
                <th className="align-center">Actions</th>
              </tr>
            </thead>
            {buffer ? (
              <div className="spinner"></div>
            ) : (
              <tbody>
                {applraisalFormList.map((i, index) => (
                  <React.Fragment key={index}>
                    <tr className="tr-border-bottom">
                      <td colSpan="6"></td>
                    </tr>
                    <tr className="custom-table-head-td">
                      <td className="align-left">{index + 1}</td>
                      <td className="align-center">
                        <p>{i.emp_code}-{i.name}</p>
                      </td>

                      <td className="align-center">
                        {i.emp_update_datetime ?
                          <>
                            {i.total_emp}
                          </>
                          : "-"
                        }
                      </td>

                      {timeStampView ?
                        <td className="align-center">
                          {i.emp_update_datetime ?
                            <>
                              {i.emp_update_datetime ? formatDateTime(i.emp_update_datetime).full : null}
                            </>
                            : "-"
                          }
                        </td>
                        : null
                      }
                      <td className="align-center">
                        {i.rh_update_datetime ?
                          <>
                            {i.total_rh}
                          </>
                          : "-"
                        }
                      </td>

                      <td className="align-center">
                        {i.rh_update_by ?
                          <>
                            {i.rh_update_by}
                          </>
                          : "-"
                        }
                      </td>
                      {timeStampView ?
                        <td className="align-center">
                          {i.rh_update_datetime ?
                            <>
                              {i.rh_update_datetime ? formatDateTime(i.rh_update_datetime).full : null}
                            </>
                            : "-"
                          }
                        </td>
                        : null
                      }
                      <td className="align-center">
                        {i.hod_update_datetime ?
                          <>
                            {i.total_hod}
                          </>
                          : "-"
                        }
                      </td>

                      <td className="align-center">
                        {i.hod_update_by ?
                          <>
                            {i.hod_update_by}
                          </>
                          : "-"
                        }
                      </td>

                      {timeStampView ?
                        <td className="align-center">
                          {i.hod_update_datetime ?
                            <>
                              {i.hod_update_datetime ? formatDateTime(i.hod_update_datetime).full : null}
                            </>
                            : "-"
                          }
                        </td>
                        : null
                      }
                      <td className="align-center">
                        {i.admin_update_datetime ?
                          <>
                            {i.total_admin}
                          </>
                          : "-"
                        }
                      </td>

                      <td className="align-center">
                        {i.admin_update_by ?
                          <>
                            {i.admin_update_by}
                          </>
                          : "-"
                        }
                      </td>

                      {timeStampView ?
                        <td className="align-center">
                          {i.admin_update_datetime ?
                            <>
                              {i.admin_update_datetime ? formatDateTime(i.admin_update_datetime).full : null}
                            </>
                            : "-"
                          }
                        </td>
                        : null
                      }
                      <td className="align-center">
                        {i.final_rating || '-'}
                      </td>

                      <td className="align-center">
                        {/* {i.admin_update_datetime ?
                          <ViewAppraisalDetailsRHHODAdmin i={i} />
                          :
                          <UploadAppraisalDetailsByRHHODAdmin i={i} getDepartmentAppraisalData={getApplraisalFormListAdmin} />
                        } */}
                        <UploadAppraisalDetailsByRHHODAdmin i={i} getDepartmentAppraisalData={getApplraisalFormListAdmin} />
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            )}
          </table>
        </div>
      </div >
    </>
  );
};


export {
  AppraisalFormsTableRH,

  AppraisalFormsTableHOD,
  AppraisalFormsTableHODAdmin,

  AppraisalFormsTableRHHOD,
  AppraisalFormsTableRHHODAdmin,
};
