import React, { useEffect, useState } from "react";
import axios from "axios";
import { AUTH, AUTHH, BASE_URL } from "../../config/axios";
import Modal from "react-bootstrap/Modal";
import { ModalBody } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { formattedDateLong } from "../Date";
import { customSortByKey, handleAllError, handleErrorToast } from "../CustomFunctions";
import { Select } from "@mui/material";
import CreatableSelect from 'react-select/creatable';

const Requestapproved = ({ i, getoutdutyname }) => {
  const handleFormSubmitPut = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
    try {
      let res = await axios.put(`${BASE_URL}/wfm/leavedelete/${i.id}/`, {
        rh_leave_status: "approved",
        rh_update_datetime: new Date().toISOString(),
      });

      if (res.status === 200) {
        await getoutdutyname();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <button className="model-edit-button" onClick={handleFormSubmitPut}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15 4.5L6.75 12.75L3 9"
            stroke="#145650"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </>
  );
};

const RequestDelete = ({ i, getoutdutyname }) => {
  const handleFormSubmitPut = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.put(`${BASE_URL}/wfm/leavedelete/${i.id}/`, {
        rh_leave_status: "rejected",
        rh_update_datetime: new Date().toISOString(),
      });

      if (res.status === 200) {
        await getoutdutyname();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <button className="model-delete-button" onClick={handleFormSubmitPut}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M13.5 4.5L4.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M4.5 4.5L13.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </>
  );
};

const RequestOutdutyapproved = ({ i, getoutdutyname }) => {
  const [loading, setLoading] = useState(false); //loading logic

  const handleFormSubmitPut = async (e) => {
    e.preventDefault();
    setLoading(true); //loading logic
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.put(`${BASE_URL}/wfm/outdutydelete/${i.id}/`, {
        rh_leave_status: "approved",
        rh_update_datetime: new Date().toISOString(),
      });

      if (res.status === 200) {
        await getoutdutyname();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setLoading(false); //loading logic
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <button className="model-edit-button" onClick={handleFormSubmitPut} disabled={loading} >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15 4.5L6.75 12.75L3 9"
            stroke="#145650"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </>
  );
};

const RequestOutdutyDelete = ({ i, getoutdutyname }) => {
  const handleFormSubmitPut = async (e) => {
    e.preventDefault();

    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
    try {
      let res = await axios.put(`${BASE_URL}/wfm/outdutydelete/${i.id}/`, {
        rh_leave_status: "rejected",
        rh_update_datetime: new Date().toISOString(),
      });

      if (res.status === 200) {
        await getoutdutyname();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <button className="model-delete-button" onClick={handleFormSubmitPut}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M13.5 4.5L4.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M4.5 4.5L13.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </>
  );
};

const RequestImprestApproved = ({ i, getImprestname }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const handleFormSubmitPut = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      const approved_other_details = i.other_details.map((other) => ({
        other_name: other.other_name,
        amount: other.amount,
        approved_other: other.amount,
        remaining_other: other.remaining_other,
      }));
      let res = await axios.put(`${BASE_URL}/wfm/imprest/${i.id}/`, {
        rh_leave_status: "approved",
        admin_leave_status: "pending",
        approved_transportation: i.transportation,
        approved_accomodation: i.accomodation,
        approved_fooding: i.fooding,
        // approved_other: i.other,
        other_details: approved_other_details,
        amount_change_reason: "Confirmed",
        rh_update_datetime: new Date().toISOString(),
      });

      if (res.status === 200) {
        await getImprestname();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <button className="model-edit-button" title="TL Imprest Approved" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15 4.5L6.75 12.75L3 9"
            stroke="#145650"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Body>
          <div className="parent-div">

            <div className="bdy-div">
              <h6 className="task-Tab-heading align-center font-weight500 font-size-heading">Confirm Approval of Imprest "{i.project_name}" </h6>
              <div className="flex-row">
                <label htmlFor="date" className="form-labels font-weight500 announce-date font-weight400  font-size-heading">
                  Date :
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {formattedDateLong(i.date)}
                </div>
              </div>
              <div className="flex-row">
                <label
                  htmlFor="project_name"
                  className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                >
                  Project :
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {i.project_name}
                </div>
              </div>
              <div className="flex-row">
                <label htmlFor="total" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Total Expense -
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {
                    Number(i.transportation) +
                    Number(i.accomodation) +
                    Number(i.fooding) +
                    // Number(i.other)
                    (i.other_details?.length > 0
                      ? i.other_details.reduce((total, other) => total + Number(other.amount), 0)
                      : 0)
                  }
                </div>
              </div>

              <div className="flex-row">
                <label
                  htmlFor="transportation"
                  className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                >
                  Transportation -
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {i.transportation}
                </div>
              </div>

              <div className="flex-row">
                <label
                  htmlFor="accomodation"
                  className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                >
                  Accommodation -
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {i.accomodation}
                </div>

              </div>

              <div className="flex-row">
                <label htmlFor="fooding" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Food -
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {i.fooding}
                </div>
              </div>

              {/* <div className="flex-row">
                <label htmlFor="other" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  {i.other_name ? `${i.other_name} Expenses - ` : 'Other Expenses - '}

                </label>
                <div className="form-labels announce-date font-size-heading">
                  {i.other}
                </div>
              </div> */}

              <div className="flex-column">
                <label className="form-labels font-weight500 announce-date font-weight400 font-size-heading">
                  Other Expenses:
                </label>
                {i.other_details?.length > 0 ? (
                  <ul style={{ paddingLeft: '0', marginLeft: '0', listStylePosition: 'inside' }}>
                    {i.other_details.map((other, index) => (
                      <li key={index} className="form-labels announce-date font-size-heading">
                        <span className="form-text-trasformation-uppercase">
                          {other.other_name ? other.other_name : `Other-${index + 1}`} : {other.amount}
                        </span>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <span>No Other Expense <br /></span>
                )}
              </div>

              <div className="flex-row">
                <label
                  htmlFor="no_of_days"
                  className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                >
                  Number's of days :
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {i.no_of_days}
                </div>
              </div>


              <div className="flex-row">
                <label htmlFor="person" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Number's of Person :
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {i.multipleperson?.length}
                </div>

              </div>
              <div className="flex-column">
                <label htmlFor="person" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Name of Person :
                </label>
                {i.multiple_person_details.map((is) => {
                  return (
                    <div className="form-labels announce-date font-size-heading">
                      {is.emp_code} - {is.name}
                      <br />
                    </div>
                  );
                })}
              </div>

              <div className="flex-column">
                <label htmlFor="remarks" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Remarks :
                </label>
                {i.remarks}
                <div className="form-labels announce-date font-size-heading">
                </div>
              </div>

              <div className="button-models">
                <button className="model-button model-button-cancel  font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmitPut}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal >
    </>
  );
};

const RequestImprestPartialApproveBackup = ({ i, getImprestname }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [formData, setFormData] = useState({
    approved_transportation: i.transportation,
    approved_accomodation: i.accomodation,
    approved_fooding: i.fooding,
    // approved_other: i.other,
    other_details: i.other_details.map((other) => ({
      other_name: other.other_name,
      amount: other.amount,
      approved_other: other.amount,
      remaining_other: other.remaining_other,
    })),
    amount_change_reason: "",
  });

  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["amount_change_reason"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;

  //   setInputState({
  //     ...inputState,
  //     [name]: value.trim() ? "green" : "",
  //   });

  //   setFormData({
  //     ...formData,
  //     [name]: value,
  //   });
  // };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name.startsWith('approved_')) {

      const itemAmountKey = name.replace('approved_', '');
      const currentValue = i[itemAmountKey] !== undefined && i[itemAmountKey] !== null ? i[itemAmountKey] : 0;
      const maxLength = getNumberLength(currentValue);

      // Ensure the input value does not exceed the allowed length
      if (value.length <= maxLength) {
        const numericValue = value.trim() ? parseInt(value, 10) : 0;

        // Check if the numeric value is less than the current value
        if (numericValue <= currentValue) {
          setInputState({
            ...inputState,
            [name]: value.trim() ? "success" : "",
          });

          setFormData({
            ...formData,
            [name]: value,
          });

          // Clear any previous error
          setErrors({
            ...errors,
            [name]: "",
          });
        } else {
          // Handle case where the numeric value exceeds the current value
          setErrors({
            ...errors,
            [name]: `Value must be less than or equal to ${currentValue}`,
          });
        }
      } else {
        // Handle case where input length exceeds the allowed length
        setErrors({
          ...errors,
          [name]: `Maximum allowed length is ${maxLength} digits`,
        });
      }
    } else {
      // For `textarea` or other fields without length validation
      setInputState({
        ...inputState,
        [name]: value.trim() ? "success" : "",
      });

      setFormData({
        ...formData,
        [name]: value,
      });
      setErrors({
        ...errors,
        [name]: "",
      });
    }
  };

  const handleFormSubmitPut = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

      try {
        let res = await axios.put(`${BASE_URL}/wfm/imprest/${i.id}/`, {
          rh_leave_status: "approved",
          amount_change_reason: formData.amount_change_reason,
          approved_transportation: formData.approved_transportation,
          approved_accomodation: formData.approved_accomodation,
          approved_fooding: formData.approved_fooding,
          approved_other: formData.approved_other,
        });

        if (res.status === 200) {
          await getImprestname();
          // setShow(false);
        } else {
          alert(res);
        }
      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        toast.dismiss(loadingToastId);
      }
    }
  };

  function getNumberLength(value) {
    return value.toString().length;
  }

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <button className="model-partial-button" title="TL Imprest Partial Approved" onClick={handleShow}>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 48 48" id="exclamation">
          <path fill="#F7941E" fill-rule="evenodd" d="M24 10C25.1046 10 26 10.8954 26 12L26 30C26 31.1046 25.1046 32 24 32 22.8954 32 22 31.1046 22 30L22 12C22 10.8954 22.8954 10 24 10zM24 38C25.1046 38 26 37.1046 26 36 26 34.8954 25.1046 34 24 34 22.8954 34 22 34.8954 22 36 22 37.1046 22.8954 38 24 38z" clip-rule="evenodd"></path></svg>
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              <h1 className="task-Tab-heading align-center font-weight500 font-size-heading">Give Approvable Amount for Imprest : "{i.project_name}" </h1>
              <div className="flex-column">
                <label htmlFor="total" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Total Expense
                </label>
                <input
                  id="total"
                  type="number"
                  placeholder="0"
                  value={
                    Number(formData.approved_transportation) +
                    Number(formData.approved_accomodation) +
                    Number(formData.approved_fooding) +
                    Number(formData.approved_other)
                  }
                  readOnly
                  className="form-input   font-weight500   font-size-subheading"
                />
              </div>
              <div className="flex-column">
                <label
                  htmlFor="approved_transportation"
                  className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                >
                  Transportation
                </label>
                <input
                  id="approved_transportation"
                  type="number"
                  name="approved_transportation"
                  placeholder="0"
                  min={0}
                  maxLength={getNumberLength(i.transportation)}
                  max={i.transportation}
                  onChange={handleInputChange}
                  value={formData.approved_transportation}
                  className={`form-input ${errors.approved_transportation
                    ? "error"
                    : inputState.approved_transportation
                      ? "success"
                      : ""
                    }`}
                />
                {errors.approved_transportation && (
                  <span className="error-message font-size-text ">{errors.approved_transportation}</span>
                )}
              </div>

              <div className="flex-column">
                <label
                  htmlFor="approved_accomodation"
                  className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                >
                  Accommodation
                </label>
                <input
                  id="approved_accomodation"
                  type="number"
                  name="approved_accomodation"
                  placeholder="0"
                  min={0}
                  max={i.accomodation}
                  maxLength={getNumberLength(i.accomodation)}
                  onChange={handleInputChange}
                  value={formData.approved_accomodation}
                  className={`form-input ${errors.approved_accomodation
                    ? "error"
                    : inputState.approved_accomodation
                      ? "success"
                      : ""
                    }`}
                />
                {errors.approved_accomodation && (
                  <span className="error-message font-size-text ">{errors.approved_accomodation}</span>
                )}
              </div>

              <div className="flex-column">
                <label htmlFor="approved_fooding" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Food
                </label>
                <input
                  id="approved_fooding"
                  type="number"
                  name="approved_fooding"
                  placeholder="0"
                  min={0}
                  max={i.fooding}
                  maxLength={getNumberLength(i.fooding)}
                  onChange={handleInputChange}
                  value={formData.approved_fooding}
                  className={`form-input ${errors.approved_fooding
                    ? "error"
                    : inputState.approved_fooding
                      ? "success"
                      : ""
                    }`}
                />
                {errors.approved_fooding && (
                  <span className="error-message font-size-text ">{errors.approved_fooding}</span>
                )}
              </div>

              <div className="flex-column">
                <label htmlFor="approved_other" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  {i.other_name ? `${i.other_name} Expenses` : 'Other Expenses'}
                </label>
                <input
                  id="approved_other"
                  type="number"
                  name="approved_other"
                  placeholder="0"
                  min={0}
                  max={i.other}
                  maxLength={getNumberLength(i.other)}
                  onChange={handleInputChange}
                  value={formData.approved_other}
                  className={`form-input ${errors.approved_other ? "error" : inputState.approved_other ? "success" : ""
                    }`}
                />
                {errors.approved_other && (
                  <span className="error-message font-size-text ">{errors.approved_other}</span>
                )}
              </div>

              <div className="flex-column">
                <label htmlFor="amount_change_reason" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Amount Change Reason<span className="required">*</span>
                </label>
                <textarea
                  id="amount_change_reason"
                  type="text"
                  name="amount_change_reason"
                  placeholder="Your Reason"
                  onChange={handleInputChange}
                  maxLength={100}
                  value={formData.amount_change_reason}
                  className={`form-input-textarea   font-weight400  font-size-subheading ${errors.amount_change_reason
                    ? "error"
                    : inputState.amount_change_reason
                      ? "success"
                      : ""
                    }`}
                />
                {errors.amount_change_reason && (
                  <span className="error-message font-size-text ">{errors.amount_change_reason}</span>
                )}
              </div>

              <div className="button-models">
                <button className="model-button model-button-cancel   font-weight500" onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button   font-weight500    model-button-submit    font-size-heading"
                  onClick={handleFormSubmitPut}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

        </Modal.Body>
      </Modal>
    </>
  );
};

const RequestImprestPartialApprove = ({ i, getImprestname }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [formData, setFormData] = useState({
    approved_transportation: i.transportation,
    approved_accomodation: i.accomodation,
    approved_fooding: i.fooding,
    // approved_other: i.other,
    other_details: i.other_details.map((other) => ({
      other_name: other.other_name,
      amount: other.amount,
      approved_other: other.amount,
      remaining_other: other.remaining_other,
    })),
    rh_update_datetime: new Date().toISOString(),
    amount_change_reason: "",
  });

  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["amount_change_reason"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;

  //   setInputState({
  //     ...inputState,
  //     [name]: value.trim() ? "green" : "",
  //   });

  //   setFormData({
  //     ...formData,
  //     [name]: value,
  //   });
  // };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name.startsWith('approved_')) {

      // For individual expenses like transportation, accommodation, fooding, etc.
      if (!name.startsWith('approved_other_')) {
        const itemAmountKey = name.replace('approved_', '');
        const currentValue = i[itemAmountKey] !== undefined && i[itemAmountKey] !== null ? i[itemAmountKey] : 0;
        const maxLength = getNumberLength(currentValue);

        // Ensure the input value does not exceed the allowed length
        if (value.length <= maxLength) {
          const numericValue = value.trim() ? parseInt(value, 10) : 0;

          // Check if the numeric value is less than or equal to the current value
          if (numericValue <= currentValue) {
            setInputState({
              ...inputState,
              [name]: value.trim() ? "success" : "",
            });

            setFormData({
              ...formData,
              [name]: value,
            });

            // Clear any previous error
            setErrors({
              ...errors,
              [name]: "",
            });
          } else {
            // Handle case where the numeric value exceeds the current value
            setErrors({
              ...errors,
              [name]: `Value must be less than or equal to ${currentValue}`,
            });
          }
        } else {
          // Handle case where input length exceeds the allowed length
          setErrors({
            ...errors,
            [name]: `Maximum allowed length is ${maxLength} digits`,
          });
        }

      } else {
        // For entries in approved_other_details
        const index = parseInt(name.replace('approved_other_', ''), 10);
        const currentOther = i.other_details[index];
        const currentValue = currentOther.amount !== undefined && currentOther.amount !== null ? currentOther.amount : 0;
        const maxLength = getNumberLength(currentValue);

        // Ensure the input value does not exceed the allowed length
        if (value.length <= maxLength) {
          const numericValue = value.trim() ? parseInt(value, 10) : 0;

          // Check if the numeric value is less than or equal to the current value
          if (numericValue <= currentValue) {
            const newOtherDetails = [...formData.other_details];
            newOtherDetails[index].approved_other = value;

            setInputState({
              ...inputState,
              [name]: value.trim() ? "success" : "",
            });

            setFormData({
              ...formData,
              other_details: newOtherDetails,
            });

            // Clear any previous error
            setErrors({
              ...errors,
              [name]: "",
            });
          } else {
            // Handle case where the numeric value exceeds the current value
            setErrors({
              ...errors,
              [name]: `Value must be less than or equal to ${currentValue}`,
            });
          }
        } else {
          // Handle case where input length exceeds the allowed length
          setErrors({
            ...errors,
            [name]: `Maximum allowed length is ${maxLength} digits`,
          });
        }
      }

    } else {
      // For textarea or other fields without length validation
      setInputState({
        ...inputState,
        [name]: value.trim() ? "success" : "",
      });

      setFormData({
        ...formData,
        [name]: value,
      });
      setErrors({
        ...errors,
        [name]: "",
      });
    }
  };

  // Helper function to get the length of a number
  function getNumberLength(value) {
    return value.toString().length;
  }
  console.log("formData")
  console.log(formData)

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;

  //   if (name.startsWith('approved_')) {

  //     const itemAmountKey = name.replace('approved_', '');
  //     const currentValue = i[itemAmountKey] !== undefined && i[itemAmountKey] !== null ? i[itemAmountKey] : 0;
  //     const maxLength = getNumberLength(currentValue);

  //     // Ensure the input value does not exceed the allowed length
  //     if (value.length <= maxLength) {
  //       const numericValue = value.trim() ? parseInt(value, 10) : 0;

  //       // Check if the numeric value is less than the current value
  //       if (numericValue <= currentValue) {
  //         setInputState({
  //           ...inputState,
  //           [name]: value.trim() ? "success" : "",
  //         });

  //         setFormData({
  //           ...formData,
  //           [name]: value,
  //         });

  //         // Clear any previous error
  //         setErrors({
  //           ...errors,
  //           [name]: "",
  //         });
  //       } else {
  //         // Handle case where the numeric value exceeds the current value
  //         setErrors({
  //           ...errors,
  //           [name]: `Value must be less than or equal to ${currentValue}`,
  //         });
  //       }
  //     } else {
  //       // Handle case where input length exceeds the allowed length
  //       setErrors({
  //         ...errors,
  //         [name]: `Maximum allowed length is ${maxLength} digits`,
  //       });
  //     }
  //   } else {
  //     // For `textarea` or other fields without length validation
  //     setInputState({
  //       ...inputState,
  //       [name]: value.trim() ? "success" : "",
  //     });

  //     setFormData({
  //       ...formData,
  //       [name]: value,
  //     });
  //     setErrors({
  //       ...errors,
  //       [name]: "",
  //     });
  //   }
  // };



  const handleFormSubmitPut = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

      try {
        let res = await axios.put(`${BASE_URL}/wfm/imprest/${i.id}/`, {
          rh_leave_status: "approved",
          admin_leave_status: "pending",
          amount_change_reason: formData.amount_change_reason,
          approved_transportation: formData.approved_transportation,
          approved_accomodation: formData.approved_accomodation,
          approved_fooding: formData.approved_fooding,
          approved_other: formData.approved_other,
          other_details: formData.other_details,
          rh_update_datetime: new Date().toISOString(),
        });

        if (res.status === 200) {
          await getImprestname();
          // setShow(false);
        } else {
          alert(res);
        }
      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        toast.dismiss(loadingToastId);
      }
    }
  };

  function getNumberLength(value) {
    return value.toString().length;
  }

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <button className="model-partial-button" title="TL Imprest Partial Approved" onClick={handleShow}>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 48 48" id="exclamation">
          <path fill="#F7941E" fill-rule="evenodd" d="M24 10C25.1046 10 26 10.8954 26 12L26 30C26 31.1046 25.1046 32 24 32 22.8954 32 22 31.1046 22 30L22 12C22 10.8954 22.8954 10 24 10zM24 38C25.1046 38 26 37.1046 26 36 26 34.8954 25.1046 34 24 34 22.8954 34 22 34.8954 22 36 22 37.1046 22.8954 38 24 38z" clip-rule="evenodd"></path>
        </svg>
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              <h1 className="task-Tab-heading align-center font-weight500 font-size-heading">Give Approvable Amount for Imprest : "{i.project_name}" </h1>
              <div className="flex-column">
                <label htmlFor="total" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Total Expense
                </label>
                <input
                  id="total"
                  type="number"
                  placeholder="0"
                  value={
                    Number(formData.approved_transportation) +
                    Number(formData.approved_accomodation) +
                    Number(formData.approved_fooding) +
                    // Number(formData.approved_other)
                    (formData.other_details?.length > 0
                      ? formData.other_details.reduce((total, other) => total + Number(other.approved_other || 0), 0)
                      : 0)
                  }
                  readOnly
                  className="form-input   font-weight500   font-size-subheading"
                />
              </div>
              <div className="flex-column">
                <label
                  htmlFor="approved_transportation"
                  className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                >
                  Transportation
                </label>
                <input
                  id="approved_transportation"
                  type="number"
                  name="approved_transportation"
                  placeholder="0"
                  min={0}
                  maxLength={getNumberLength(i.transportation)}
                  max={i.transportation}
                  onWheel={(e) => e.target.blur()}
                  onChange={handleInputChange}
                  value={formData.approved_transportation}
                  className={`form-input ${errors.approved_transportation
                    ? "error"
                    : inputState.approved_transportation
                      ? "success"
                      : ""
                    }`}
                />
                {errors.approved_transportation && (
                  <span className="error-message font-size-text ">{errors.approved_transportation}</span>
                )}
              </div>

              <div className="flex-column">
                <label
                  htmlFor="approved_accomodation"
                  className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                >
                  Accommodation
                </label>
                <input
                  id="approved_accomodation"
                  type="number"
                  name="approved_accomodation"
                  placeholder="0"
                  min={0}
                  max={i.accomodation}
                  maxLength={getNumberLength(i.accomodation)}
                  onWheel={(e) => e.target.blur()}
                  onChange={handleInputChange}
                  value={formData.approved_accomodation}
                  className={`form-input ${errors.approved_accomodation
                    ? "error"
                    : inputState.approved_accomodation
                      ? "success"
                      : ""
                    }`}
                />
                {errors.approved_accomodation && (
                  <span className="error-message font-size-text ">{errors.approved_accomodation}</span>
                )}
              </div>

              <div className="flex-column">
                <label htmlFor="approved_fooding" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Food
                </label>
                <input
                  id="approved_fooding"
                  type="number"
                  name="approved_fooding"
                  placeholder="0"
                  min={0}
                  max={i.fooding}
                  maxLength={getNumberLength(i.fooding)}
                  onWheel={(e) => e.target.blur()}
                  onChange={handleInputChange}
                  value={formData.approved_fooding}
                  className={`form-input ${errors.approved_fooding
                    ? "error"
                    : inputState.approved_fooding
                      ? "success"
                      : ""
                    }`}
                />
                {errors.approved_fooding && (
                  <span className="error-message font-size-text ">{errors.approved_fooding}</span>
                )}
              </div>

              {/* <div className="flex-column">
                <label htmlFor="approved_other" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  {i.other_name ? `${i.other_name} Expenses` : 'Other Expenses'}
                </label>
                <input
                  id="approved_other"
                  type="number"
                  name="approved_other"
                  placeholder="0"
                  min={0}
                  max={i.other}
                  maxLength={getNumberLength(i.other)}
                  onChange={handleInputChange}
                  value={formData.approved_other}
                  className={`form-input ${errors.approved_other ? "error" : inputState.approved_other ? "success" : ""
                    }`}
                />
                {errors.approved_other && (
                  <span className="error-message font-size-text ">{errors.approved_other}</span>
                )}
              </div> */}

              <div className="flex-column">
                <label className="form-labels font-weight500 announce-date font-weight400 font-size-heading">
                  Other Expenses
                </label>
                {formData.other_details?.map((other, index) => (
                  <div key={index} className="flex-column">
                    <label htmlFor={`approved_other_${index}`} className="form-labels font-weight500 announce-date font-weight400 font-size-heading">
                      {other.other_name ? other.other_name : `Other-${index + 1}`} :
                    </label>
                    <input
                      id={`approved_other_${index}`}
                      type="number"
                      name={`approved_other_${index}`}
                      placeholder="0"
                      min={0}
                      max={other.amount}
                      onWheel={(e) => e.target.blur()}
                      onChange={handleInputChange}
                      value={other.approved_other}
                      className={`form-input ${errors[`approved_other_${index}`] ? "error" : inputState[`approved_other_${index}`] ? "success" : ""}`}
                    />
                    {errors[`approved_other_${index}`] && <span className="error-message font-size-text">{errors[`approved_other_${index}`]}</span>}
                  </div>
                ))}
              </div>


              <div className="flex-column">
                <label htmlFor="amount_change_reason" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Amount Change Reason<span className="required">*</span>
                </label>
                <textarea
                  id="amount_change_reason"
                  type="text"
                  name="amount_change_reason"
                  placeholder="Your Reason"
                  onChange={handleInputChange}
                  maxLength={100}
                  value={formData.amount_change_reason}
                  className={`form-input-textarea   font-weight400  font-size-subheading ${errors.amount_change_reason
                    ? "error"
                    : inputState.amount_change_reason
                      ? "success"
                      : ""
                    }`}
                />
                {errors.amount_change_reason && (
                  <span className="error-message font-size-text ">{errors.amount_change_reason}</span>
                )}
              </div>

              <div className="button-models">
                <button className="model-button model-button-cancel   font-weight500" onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button   font-weight500    model-button-submit    font-size-heading"
                  onClick={handleFormSubmitPut}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

        </Modal.Body>
      </Modal>
    </>
  );
};

const RequestImprestReject = ({ i, getImprestname }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [formData, setFormData] = useState({
    rejection_reason: "",
  });

  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["rejection_reason"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFormSubmitPut = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

      try {
        let res = await axios.put(`${BASE_URL}/wfm/imprest/${i.id}/`, {
          rh_leave_status: "rejected",
          rejection_reason: formData.rejection_reason,
          rh_update_datetime: new Date().toISOString(),
        });

        if (res.status === 200) {
          await getImprestname();
          // setShow(false);
        } else {
          alert(res);
        }
      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        toast.dismiss(loadingToastId);
      }
    }
  };

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <button className="model-delete-button" title="TL Imprest Reject" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M13.5 4.5L4.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M4.5 4.5L13.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        {/* <Modal.Header closeButton>
          <Modal.Title>Rejection Reason</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">

              <div className="flex-column">
                <label htmlFor="rejection_reason" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Reporting Head Rejection Reason
                </label>
                <textarea
                  id="rejection_reason"
                  type="text"
                  name="rejection_reason"
                  placeholder="Your Reason"
                  onChange={handleInputChange}
                  maxLength={100}
                  value={formData.rejection_reason}
                  className={`form-input-textarea   font-weight400  font-size-subheading ${errors.rejection_reason
                    ? "error"
                    : inputState.rejection_reason
                      ? "success"
                      : ""
                    }`}
                />
                {errors.rejection_reason && (
                  <span className="error-message font-size-text ">{errors.rejection_reason}</span>
                )}
              </div>

              <div className="button-models">
                <button className="model-button model-button-cancel   font-weight500" onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button   font-weight500    model-button-delete    font-size-heading"
                  onClick={handleFormSubmitPut}
                >
                  Reject
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

        </Modal.Body>
      </Modal>
    </>
  );
};

const RequestExpenseApproved = ({ i, getExpensename }) => {
  const handleFormSubmitPut = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.put(`${BASE_URL}/wfm/expense/${i.id}/`, {
        rh_status: "approved",
        rh_update_datetime: new Date().toISOString(),
      });

      if (res.status === 200) {
        await getExpensename();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <button className="model-edit-button" onClick={handleFormSubmitPut}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15 4.5L6.75 12.75L3 9"
            stroke="#145650"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </>
  );
};

const RequestExpenseReject = ({ i, getExpensename }) => {
  const handleFormSubmitPut = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.put(`${BASE_URL}/wfm/expense/${i.id}/`, {
        rh_leave_status: "rejected",
        rh_update_datetime: new Date().toISOString(),
      });

      if (res.status === 200) {
        await getExpensename();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <button className="model-delete-button" onClick={handleFormSubmitPut}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M13.5 4.5L4.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M4.5 4.5L13.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </>
  );
};

const HRleaveUpdate = ({ getleavelist }) => {
  const [show, setShow] = useState(false);
  const [leavebalance, setLeavebalance] = useState({});
  const [employeeList, setEmployeeList] = useState([]);
  const [insufficientBalanceError, setInsufficientBalanceError] = useState("");
  const [insufficientBalanceConfirmation, setInsufficientBalanceConfirmation] = useState(false);

  const handleClose = () => {
    setdataa({
      empcode: "",
      date: "",
      end_date: "",
      rh_leave_status: "pending",
      leave_type: "",
      remarks: "",
    });
    setRhApproval(false)
    setShow(false)
  };
  const handleShow = () => {
    getEmployeeList();
    setShow(true)
  };

  const [age, setAge] = useState("single");
  const [rhApproval, setRhApproval] = useState(false);
  const [maxEndDate, setMaxEndDate] = useState(null);

  const [dataa, setdataa] = useState({
    empcode: "",
    date: "",
    end_date: "",
    // rh_leave_status: "approved",
    rh_leave_status: rhApproval ? "approved" : "pending",
    leave_type: "",
    remarks: "",
  });

  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "empcode",
      "date",
      "leave_type",
      "remarks",
    ];
    requiredFields.forEach((field) => {
      if (!dataa[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    if (!dataa.remarks.trim()) {
      newErrors.remarks = "Remarks cannot be empty or contain only spaces!";
    }
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };


  const getEndTime = (intime) => {
    const start = new Date(`2023-03-15T${intime}`);
    const end = new Date(start.getTime() + 2 * 60 * 60 * 1000); // Add 2 hours in milliseconds
    const hh = String(end.getHours()).padStart(2, "0");
    const mm = String(end.getMinutes()).padStart(2, "0");
    const ss = String(end.getSeconds()).padStart(2, "0");

    setdataa({ ...dataa, in_time: intime, out_time: `${hh}:${mm}:${ss}` });
  };

  const date = new Date();

  const [remarksError, setRemarksError] = useState("");
  const [typeError, setTypeError] = useState("");

  let lastDateOfPreviousMonth;
  if (date.getDate() > 30) {
    lastDateOfPreviousMonth = new Date(date.getFullYear(), date.getMonth() - 1, 0);
  } else {
    lastDateOfPreviousMonth = new Date(
      date.getFullYear(),
      date.getMonth() - 2,
      +1
    );
  }

  const formattedLastDate = lastDateOfPreviousMonth.toISOString().substr(0, 10);

  const calculateDaysBetweenDates = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const differenceInTime = end.getTime() - start.getTime();
    return differenceInTime / (1000 * 3600 * 24) + 1; // +1 to include the start date
  };

  const checkLeaveBalance = () => {
    let numberOfDays = calculateDaysBetweenDates(dataa.date, dataa.end_date);

    if (age === "halfdayleave") {
      numberOfDays = 0.5;
    } else if (age === "shortleave") {
      numberOfDays = 0.25;
    }

    let balance = 0;

    switch (dataa.leave_type) {
      case "previous leave":
        balance = leavebalance.leavebalance || 0;
        break;
      case "casual leave":
        balance = leavebalance.casual_leave || 0;
        break;
      case "sick leave":
        balance = leavebalance.sick_leave || 0;
        break;
      case "earn leave":
        balance = leavebalance.earn_leave || 0;
        break;
      default:
        balance = 0;
        break;
    }

    if (numberOfDays > balance) {
      setInsufficientBalanceError(`Leave appling for ${numberOfDays} ${numberOfDays > 1 ? 'days' : 'day'}, but ${dataa.empcode} only has ${balance} ${dataa.leave_type} balance`);
    } else {
      setInsufficientBalanceError("");
    }
  };

  useEffect(() => {
    if (dataa.date && dataa.end_date && dataa.leave_type) {
      checkLeaveBalance();
    }
  }, [dataa.date, dataa.end_date, dataa.leave_type]);

  const [buffer, setBuffering] = useState(false); //buffering logic

  const getLeavebalance = async () => {
    setBuffering(true); //buffering logic // Start Buffering
    try {
      const res = await axios.get(`${BASE_URL}/wfm/leavebalance/${dataa.empcode}/`);
      setLeavebalance(res.data);
    } catch (err) {
      setLeavebalance({});
      // alert(err.message);
    } finally {
      setBuffering(false);// End Buffering
    }

  };

  const getEmployeeList = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/ourcompanyempdetailsactive/${sessionStorage.getItem("company_id")}/`);
      const sortedData = customSortByKey(res.data, "emp_code");
      setEmployeeList(sortedData);
    } catch (err) {
      // alert(err.message);
      handleErrorToast(err)
    }
  };

  useEffect(() => {
    getLeavebalance();
  }, [dataa.empcode]);



  const [loading, setLoading] = useState(false); //loading logic
  let handleSubmit = async (e) => {
    e.preventDefault();

    const enteredDate = dataa.date;
    const minDate = formattedLastDate;
    const applyTime = new Date().toISOString();

    const updatedFormData = {
      ...dataa,
      rh_leave_status: rhApproval ? "approved" : "pending",
      leave_Duration: age,
      apply_datetime: applyTime,
      rh_update_datetime: applyTime,
    };

    if (validateForm()) {
      if (enteredDate >= minDate) {

        setLoading(true); //loading logic
        const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

        try {
          let res = await axios.post(
            `${BASE_URL}/wfm/leaveapply/`,
            updatedFormData
          );

          if (res.status === 200) {
            await getleavelist();
            handleClose();
          } else {
            alert(res);
          }
        } catch (err) {
          handleErrorToast(err, loadingToastId)
        } finally {
          setLoading(false); //loading logic
          toast.dismiss(loadingToastId);
        }
        return;
      } else {
        alert("Please select a valid date ");
        return;
      }
    }
  };

  return (
    <>
      <button className="models-button model-add" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="#2576BC"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Apply Leave</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <h1 className="task-Tab-heading align-center  font-weight500    font-size-heading"><Modal.Title>Apply Leave</Modal.Title> </h1> */}
          <div>
            <form>
              <div className="flex-column model-age">
                <div className="flex-column">
                  <label htmlFor="EmpCode" className="form-labels  font-weight500    font-size-subheading">
                    Emp Code
                  </label>
                  {/* <input
                    id="EmpCode"
                    className="form-input   font-weight500   form-input-background"
                    onChange={(e) =>
                      setdataa({
                        ...dataa,
                        empcode: e.target.value,
                      })}
                    onInput={(e) => {
                      e.target.value = e.target.value.toUpperCase().trim();// Convert input to lowercase
                    }}
                  /> */}

                  <CreatableSelect
                    // className="attendance-input-field width-20vw date-field"
                    options={employeeList.map(emp => ({ value: emp.emp_code, label: `${emp.emp_code} - ${emp.name}` }))}
                    value={dataa.empcode ? { value: dataa.empcode, label: `${dataa.empcode} - ${employeeList.find(emp => emp.emp_code === dataa.empcode)?.name}` } : null}
                    onChange={(option) => setdataa({ ...dataa, empcode: option ? option.value : "" })}
                    placeholder="Select An Employee"
                    isClearable
                    // isSearchable
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        width: '33vw',
                        height: '50px',
                        borderRadius: '10px',
                        border: '1px solid #e3e3e3',
                        margin: '10px 0px 20px 0px',
                        color: '#4a4a4a',
                        backgroundColor: '#f7f8fa',
                        padding: '0px 10px',
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        color: '#4a4a4a',
                      }),
                      menu: (provided) => ({
                        ...provided,
                        width: '33vw',
                        borderRadius: '10px',
                        marginTop: '0',
                        zIndex: 3,
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: '#4a4a4a',
                      }),
                      input: (provided) => ({
                        ...provided,
                        color: '#4a4a4a',
                      }),
                    }}
                  />
                  {errors.empcode && (
                    <span className="error-message font-size-text ">{errors.empcode}</span>
                  )}
                </div>

                {buffer
                  ? <div className="spinner-small"></div> :
                  Object.keys(leavebalance).length > 0 ?
                    <div className="flex-column">
                      <h2 className="leave-balance-heading">All Available Leaves:</h2>
                      <div className="leave-balance-container">
                        <div className="leave-balance-cards">
                          <div className="leave-balance-card leave-balance-card-previous">
                            <div className="leave-balance-icon">&#128197;</div> {/* Calendar icon */}
                            <div className="leave-balance-info">
                              <span className="leave-balance-label">Previous : {" "}</span>
                              <span className="leave-balance-duration">
                                {leavebalance.leavebalance ? `${leavebalance.leavebalance} ${leavebalance.leavebalance > 1 ? 'days' : 'day'}` : "0 Available"}
                              </span>
                            </div>
                          </div>

                          <div className="leave-balance-card leave-balance-card-earned">
                            <div className="leave-balance-icon">&#127942;</div> {/* Trophy icon */}
                            <div className="leave-balance-info">
                              <span className="leave-balance-label">Earned : {" "}</span>
                              <span className="leave-balance-duration">
                                {leavebalance.earn_leave ? `${leavebalance.earn_leave} ${leavebalance.earn_leave > 1 ? 'days' : 'day'}` : "0 Available"}
                              </span>
                            </div>
                          </div>

                          <div className="leave-balance-card leave-balance-card-casual">
                            <div className="leave-balance-icon">&#128215;</div> {/* Notebook icon */}
                            <div className="leave-balance-info">
                              <span className="leave-balance-label">Casual : {" "}</span>
                              <span className="leave-balance-duration">
                                {leavebalance.casual_leave ? `${leavebalance.casual_leave} ${leavebalance.casual_leave > 1 ? 'days' : 'day'}` : "0 Available"}
                              </span>
                            </div>
                          </div>

                          <div className="leave-balance-card leave-balance-card-sick">
                            <div className="leave-balance-icon">&#128138;</div> {/* Sick emoji icon */}
                            <div className="leave-balance-info">
                              <span className="leave-balance-label">Sick : {" "}</span>
                              <span className="leave-balance-duration">
                                {leavebalance.sick_leave ? `${leavebalance.sick_leave} ${leavebalance.sick_leave > 1 ? 'days' : 'day'}` : "0 Available"}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    : null
                }


                {/* <div
                  onChange={(e) => setAge(e.target.value)}
                  className="leave-radio-button"
                >
                  <div className="leave-radio-button-inner-div">
                    <input
                      name="age"
                      type="radio"
                      value="single"
                      className="age-input"
                    />
                    <label className="age-label   font-weight500    font-size-heading">Single Day</label>
                  </div>
                  <div className="leave-radio-button-inner-div">
                    <input
                      name="age"
                      type="radio"
                      value="multiple"
                      className="age-input"
                    />
                    <label className="age-label   font-weight500    font-size-heading">Multi Day</label>
                  </div>
                  <div className="leave-radio-button-inner-div">
                    <input
                      name="age"
                      type="radio"
                      value="halfdayleave"
                      className="age-input"
                    />
                    <label className="age-label   font-weight500    font-size-heading">Half Day</label>
                  </div>
                  <div className="leave-radio-button-inner-div">
                    <input
                      name="age"
                      type="radio"
                      value="shortleave"
                      className="age-input"
                    />
                    <label className="age-label   font-weight500    font-size-heading">Short Day</label>
                  </div>
                </div> */}
                <div className="leave-duration-heading  font-weight500 font-size-heading">Leave Duration</div>
                <div onChange={(e) => setAge(e.target.value)} className="leave-duration-tabs">
                  <input type="radio" id="leave-single-day" name="leave-type" value="single" defaultChecked />
                  <label htmlFor="leave-single-day" className="leave-tab">Single Day</label>

                  <input type="radio" id="leave-multi-day" name="leave-type" value="multiple" />
                  <label htmlFor="leave-multi-day" className="leave-tab">Multi Day</label>

                  <input type="radio" id="leave-half-day" name="leave-type" value="halfdayleave" />
                  <label htmlFor="leave-half-day" className="leave-tab">Half Day</label>

                  <input type="radio" id="leave-short-day" name="leave-type" value="shortleave" />
                  <label htmlFor="leave-short-day" className="leave-tab">Short Day</label>

                  <span className="leave-tab-glider"></span>
                </div>

              </div>

              {age === "single" && (
                <div className="flex-column">
                  <label htmlFor="date" className="form-labels  font-weight500    font-size-subheading">
                    Date
                  </label>
                  <input
                    id="date"
                    type="date"
                    className="form-input   font-weight500   form-input-background"
                    onChange={(e) =>
                      setdataa({
                        ...dataa,
                        date: e.target.value,
                        end_date: e.target.value,
                      })
                    }
                    min={formattedLastDate}
                  />
                </div>
              )}

              {age === "multiple" && (
                <div className="flex-row multi-day-input">
                  <div className="flex-column multi-day">
                    <label className="form-labels  font-weight500    font-size-subheading">From Date</label>
                    <input
                      type="date"
                      className="multi-day-input1 form-input-background"
                      onChange={(e) => {
                        const startDate = e.target.value;
                        const lastDate = new Date(
                          new Date(startDate).getFullYear(),
                          new Date(startDate).getMonth() + 1,
                          1
                        )
                          .toISOString()
                          .split("T")[0];
                        setdataa({
                          ...dataa,
                          date: startDate,
                        });
                        setMaxEndDate(lastDate);
                      }}
                      min={formattedLastDate}
                    />
                  </div>
                  <div className="flex-column multi-day">
                    <label className="form-labels  font-weight500    font-size-subheading">To Date</label>
                    <input
                      type="date"
                      className="multi-day-input1 form-input-background"
                      onChange={(e) =>
                        setdataa({ ...dataa, end_date: e.target.value })
                      }
                      min={dataa.date}
                      max={maxEndDate}
                    />
                  </div>
                </div>
              )}

              {age === "halfdayleave" && (
                <div>
                  <div className="flex-column single-day-date">
                    <label className="form-labels  font-weight500    font-size-subheading">Date</label>
                    <input
                      type="date"
                      className="form-input   font-weight500   form-input-background"
                      onChange={(e) =>
                        setdataa({
                          ...dataa,
                          date: e.target.value,
                          end_date: e.target.value,
                        })
                      }
                      min={formattedLastDate}
                    />
                  </div>
                  <div required className="leave-radio-button">
                    <div className="leave-radio-button-inner-div">
                      <input
                        name="halfday"
                        type="radio"
                        value="firsthalft"
                        className="age-input"
                      />
                      <label className="age-label   font-weight500    font-size-heading">First Half</label>
                    </div>
                    <div className="leave-radio-button-inner-div">
                      <input
                        name="halfday"
                        type="radio"
                        value="secondhalft"
                        className="age-input"
                      />
                      <label className="age-label   font-weight500    font-size-heading">Second Half</label>
                    </div>
                  </div>
                </div>
              )}

              {age === "shortleave" && (
                <div>
                  <div className="flex-column single-day-date">
                    <label className="form-labels  font-weight500    font-size-subheading">Date</label>
                    <input
                      type="date"
                      className="form-input   font-weight500   form-input-background"
                      onChange={(e) =>
                        setdataa({
                          ...dataa,
                          date: e.target.value,
                          end_date: e.target.value,
                        })
                      }
                      min={formattedLastDate}
                    />
                  </div>
                  <div className="flex-row multi-day-input">
                    <div className="flex-column multi-day">
                      <label className="form-labels  font-weight500    font-size-subheading">Start Time</label>
                      <input
                        className="multi-day-input1 form-input-background"
                        type="time"
                        step="1"
                        min="09:30:00"
                        max="16:30:00"
                        onChange={(e) => getEndTime(e.target.value)}
                      />
                    </div>
                    <div className="flex-column multi-day">
                      <label className="form-labels  font-weight500    font-size-subheading">End Time</label>
                      <input
                        className="multi-day-input1 form-input-background"
                        type="time"
                        step="1"
                        min="09:30:00"
                        max="16:30:00"
                        value={dataa.out_time}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              )}
              {errors.date && (
                <span className="error-message font-size-text ">{errors.date}</span>
              )}

              {/* Leave Type */}

              <div className="flex-column">
                <label className="form-labels  font-weight500    font-size-subheading">Leave Type</label>

                <select
                  id="leave_type"
                  name="leave_type"
                  type="text"
                  value={dataa.leave_type}
                  className={`form-input `}
                  onChange={(e) => {
                    if (e.target.value.length >= 3) {
                      setdataa({ ...dataa, leave_type: e.target.value })
                      setTypeError(""); // Clear error message if within the limit
                    } else {
                      setTypeError(
                        "Ensure this field is selected."
                      );
                    }
                  }
                  }
                >
                  {/* <option value="">Select Leave Type</option>
            {leavebalance.sick_leave ?
              <option value="sick leave">Sick Leave</option>
              : null}
            {leavebalance.casual_leave ?
              <option value="casual leave">Casual Leave</option>
              : null}
            {leavebalance.earn_leave ?
              <option value="earn leave">Earned Leave</option>
              : null}
            {leavebalance.leavebalance ?
              <option value="previous leave">Previous Leave</option>
              : null} */}
                  <option value="">Select Leave Type</option>
                  <option value="casual leave">Casual Leave</option>
                  <option value="sick leave">Sick Leave</option>
                  <option value="earn leave">Earned Leave</option>
                  <option value="previous leave">Previous Leave</option>
                </select>

                {typeError && (
                  <div style={{ color: "red", marginTop: "5px" }}>{typeError}</div>
                )}
                {errors.leave_type && (
                  <span className="error-message font-size-text ">{errors.leave_type}</span>
                )}
              </div>

              <div className="flex-column">
                <label className="form-labels  font-weight500    font-size-subheading">Description</label>
                <textarea
                  className="form-input-textarea   font-weight400  form-input-background"
                  placeholder="Reason"
                  type="text"
                  value={dataa.text}
                  onChange={(e) => {
                    if (e.target.value.length <= 50) {
                      setdataa({ ...dataa, remarks: e.target.value });
                      setRemarksError(""); // Clear error message if within the limit
                    } else {
                      setRemarksError(
                        "Ensure this field has no more than 50 characters."
                      );
                    }
                  }}
                />

                {remarksError && (
                  <div style={{ color: "red", marginTop: "5px" }}>
                    {remarksError}
                  </div>
                )}
                {errors.remarks && (
                  <span className="error-message font-size-text ">{errors.remarks}</span>
                )}
              </div>
              <div className="flex-row ">
                <label htmlFor="EmpCode" className="form-labels  font-weight500    font-size-subheading">
                  RH {rhApproval ? "Approved" : "Pending"} : {" "}
                </label>
                <div className="checkbox-toggle">
                  <input
                    type="checkbox"
                    id="toggle1"
                    checked={rhApproval}
                    onChange={() => setRhApproval(!rhApproval)}
                  />
                  <label htmlFor="toggle1"></label>
                </div>
              </div>
              {insufficientBalanceError
                && (
                  <div className="flex-column" style={{ marginTop: "5px" }}>
                    <div style={{ color: "red" }}>{insufficientBalanceError}</div>
                    <label className="form-labels  font-weight500    font-size-subheading" style={{ marginTop: "5px" }}>
                      <input
                        type="checkbox"
                        checked={insufficientBalanceConfirmation}
                        onChange={(e) => setInsufficientBalanceConfirmation(e.target.checked)}
                      />
                      <> </>
                      <span>
                        Click to confirm and proceed with Insufficient leave balance
                      </span>
                      <span>
                        (Note: Leaves will be deducted from other leave balances)
                      </span>
                    </label>
                  </div>
                )}
              <div className="button-models">
                {/* <button onClick={handleClose} className="model-button model-button-cancel font-weight500    ">Cancel</button> */}
                <button
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleSubmit}
                  // disabled={loading}
                  disabled={loading || (insufficientBalanceError && !insufficientBalanceConfirmation)}
                >
                  {(insufficientBalanceError && !insufficientBalanceConfirmation) === true ? "Confirmation Needed" : "Apply"}

                </button>
              </div>
            </form>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};

const HROutdutyUpdate = ({ getoutdutyname }) => {
  const [show, setShow] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);

  const handleClose = () => {
    setdataa({
      empcode: "",
      date: "",
      end_date: "",
      rh_leave_status: "pending",
      remarks: "",
    });
    setRhApproval(false)
    setShow(false)
  };
  const handleShow = () => {
    getEmployeeList();
    setShow(true)
  };
  const [age, setAge] = useState("single");
  const [rhApproval, setRhApproval] = useState(false);
  const [maxEndDate, setMaxEndDate] = useState(null);

  const [dataa, setdataa] = useState({
    empcode: "",
    date: "",
    end_date: "",
    // rh_leave_status: "approved",
    rh_leave_status: rhApproval ? "approved" : "pending",
    remarks: "",
  });
  const getEmployeeList = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/ourcompanyempdetailsactive/${sessionStorage.getItem("company_id")}/`);
      const sortedData = customSortByKey(res.data, "emp_code");
      setEmployeeList(sortedData);
    } catch (err) {
      // alert(err.message);
      handleErrorToast(err)
    }
  };

  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "empcode",
      "date",
      "remarks",
    ];
    requiredFields.forEach((field) => {
      if (!dataa[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    if (!dataa.remarks.trim()) {
      newErrors.remarks = "Remarks cannot be empty or contain only spaces!";
    }
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const getEndTime = (intime) => {
    const start = new Date(`2023-03-15T${intime}`);
    const end = new Date(start.getTime() + 2 * 60 * 60 * 1000); // Add 2 hours in milliseconds
    const hh = String(end.getHours()).padStart(2, "0");
    const mm = String(end.getMinutes()).padStart(2, "0");
    const ss = String(end.getSeconds()).padStart(2, "0");

    setdataa({ ...dataa, in_time: intime, out_time: `${hh}:${mm}:${ss}` });
  };

  const date = new Date();

  const [remarksError, setRemarksError] = useState("");

  let lastDateOfPreviousMonth;
  if (date.getDate() > 30) {
    lastDateOfPreviousMonth = new Date(date.getFullYear(), date.getMonth() - 1, 0);
  } else {
    lastDateOfPreviousMonth = new Date(
      date.getFullYear(),
      date.getMonth() - 2,
      +1
    );
  }

  const formattedLastDate = lastDateOfPreviousMonth.toISOString().substr(0, 10);
  const [loading, setLoading] = useState(false); //loading logic

  let handleSubmit = async (e) => {
    e.preventDefault();

    const enteredDate = dataa.date;
    const minDate = formattedLastDate;
    const applyTime = new Date().toISOString();

    const updatedFormData = {
      ...dataa,
      rh_leave_status: rhApproval ? "approved" : "pending",
      outduty_Duration: age,
      apply_datetime: applyTime,
      rh_update_datetime: applyTime,
    };
    if (validateForm()) {
      if (enteredDate >= minDate) {

        setLoading(true); //loading logic
        const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

        try {
          let res = await axios.post(`${BASE_URL}/wfm/outduty/`, updatedFormData);

          if (res.status === 200) {
            await getoutdutyname();
            handleClose();
          } else {
            alert(res);
          }
        } catch (err) {
          handleErrorToast(err, loadingToastId)
        } finally {
          setLoading(false); //loading logic
          toast.dismiss(loadingToastId);
        }
        return;
      } else {
        alert("Please select a valid date ");
        return;
      }
    }
  };

  return (
    <>
      <button className="models-button model-add" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="#2576BC"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Apply OutDuty</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form>
              <div className="flex-column model-age">
                <div className="flex-column">
                  <label htmlFor="EmpCode" className="form-labels  font-weight500    font-size-subheading">
                    Emp Code
                  </label>
                  {/* <input
                    id="EmpCode"
                    className="form-input   font-weight500   form-input-background"
                    onChange={(e) =>
                      setdataa({
                        ...dataa,
                        empcode: e.target.value,
                      })}
                    onInput={(e) => {
                      e.target.value = e.target.value.toUpperCase().trim();// Convert input to lowercase
                    }}
                  /> */}

                  <CreatableSelect
                    options={employeeList.map(emp => ({ value: emp.emp_code, label: `${emp.emp_code} - ${emp.name}` }))}
                    value={dataa.empcode ? { value: dataa.empcode, label: `${dataa.empcode} - ${employeeList.find(emp => emp.emp_code === dataa.empcode)?.name}` } : null}
                    onChange={(option) => setdataa({ ...dataa, empcode: option ? option.value : "" })}
                    placeholder="Select an Employee"
                    isClearable
                    isSearchable
                    noOptionsMessage={() => "No other options"}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        width: '33vw',
                        height: '50px',
                        borderRadius: '10px',
                        border: '1px solid #e3e3e3',
                        margin: '10px 0px 20px 0px',
                        color: '#4a4a4a',
                        backgroundColor: '#f7f8fa',
                        padding: '0px 10px',
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        color: '#4a4a4a',
                      }),
                      menu: (provided) => ({
                        ...provided,
                        width: '33vw',
                        borderRadius: '10px',
                        marginTop: '0',
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: '#4a4a4a',
                      }),
                      input: (provided) => ({
                        ...provided,
                        color: '#4a4a4a',
                      }),
                    }}
                  />
                  {errors.empcode && (
                    <span className="error-message font-size-text ">{errors.empcode}</span>
                  )}
                </div>

                <div className="leave-duration  font-weight500   -heading  font-weight400  font-size-heading">OutDuty Duration </div>

                {/* <div
                  onChange={(e) => setAge(e.target.value)}
                  className="leave-radio-button"
                >
                  <div className="leave-radio-button-inner-div">
                    <input
                      name="age"
                      type="radio"
                      value="single"
                      className="age-input"
                    />
                    <label className="age-label   font-weight500    font-size-heading">Single Day</label>
                  </div>
                  <div className="leave-radio-button-inner-div">
                    <input
                      name="age"
                      type="radio"
                      value="multiple"
                      className="age-input"
                    />
                    <label className="age-label   font-weight500    font-size-heading">Multi Day</label>
                  </div>
                  <div className="leave-radio-button-inner-div">
                    <input
                      name="age"
                      type="radio"
                      value="halfdayleave"
                      className="age-input"
                    />
                    <label className="age-label   font-weight500    font-size-heading">Half Day</label>
                  </div>
                  <div className="leave-radio-button-inner-div">
                    <input
                      name="age"
                      type="radio"
                      value="shortleave"
                      className="age-input"
                    />
                    <label className="age-label   font-weight500    font-size-heading">Short Day</label>
                  </div>
                </div> */}
                <div onChange={(e) => setAge(e.target.value)} className="leave-duration-tabs">
                  <input type="radio" id="leave-single-day" name="leave-type" value="single" defaultChecked />
                  <label htmlFor="leave-single-day" className="leave-tab">Single Day</label>

                  <input type="radio" id="leave-multi-day" name="leave-type" value="multiple" />
                  <label htmlFor="leave-multi-day" className="leave-tab">Multi Day</label>

                  <input type="radio" id="leave-half-day" name="leave-type" value="halfdayleave" />
                  <label htmlFor="leave-half-day" className="leave-tab">Half Day</label>

                  <input type="radio" id="leave-short-day" name="leave-type" value="shortleave" />
                  <label htmlFor="leave-short-day" className="leave-tab">Short Day</label>

                  <span className="leave-tab-glider"></span>
                </div>
              </div>

              {age === "single" && (
                <div className="flex-column">
                  <label htmlFor="date" className="form-labels  font-weight500    font-size-subheading">
                    Date
                  </label>
                  <input
                    id="date"
                    type="date"
                    className="form-input   font-weight500   form-input-background"
                    onChange={(e) =>
                      setdataa({
                        ...dataa,
                        date: e.target.value,
                        end_date: e.target.value,
                      })
                    }
                    min={formattedLastDate}
                  />
                </div>
              )}

              {age === "multiple" && (
                <div className="flex-row multi-day-input">
                  <div className="flex-column multi-day">
                    <label className="form-labels  font-weight500    font-size-subheading">From Date</label>
                    <input
                      type="date"
                      className="multi-day-input1 form-input-background"
                      onChange={(e) => {
                        const startDate = e.target.value;
                        const lastDate = new Date(
                          new Date(startDate).getFullYear(),
                          new Date(startDate).getMonth() + 1,
                          1
                        )
                          .toISOString()
                          .split("T")[0];
                        setdataa({
                          ...dataa,
                          date: startDate,
                        });
                        setMaxEndDate(lastDate);
                      }}
                      min={formattedLastDate}
                    />
                  </div>
                  <div className="flex-column multi-day">
                    <label className="form-labels  font-weight500    font-size-subheading">To Date</label>
                    <input
                      type="date"
                      className="multi-day-input1 form-input-background"
                      onChange={(e) =>
                        setdataa({ ...dataa, end_date: e.target.value })
                      }
                      min={dataa.date}
                      max={maxEndDate}
                    />
                  </div>
                </div>
              )}

              {age === "halfdayleave" && (
                <div>
                  <div className="flex-column single-day-date">
                    <label className="form-labels  font-weight500    font-size-subheading">Date</label>
                    <input
                      type="date"
                      className="form-input   font-weight500   form-input-background"
                      onChange={(e) =>
                        setdataa({
                          ...dataa,
                          date: e.target.value,
                          end_date: e.target.value,
                        })
                      }
                      min={formattedLastDate}
                    />
                  </div>
                  <div required className="leave-radio-button">
                    <div className="leave-radio-button-inner-div">
                      <input
                        name="halfday"
                        type="radio"
                        value="firsthalft"
                        className="age-input"
                      />
                      <label className="age-label   font-weight500    font-size-heading">First Half</label>
                    </div>
                    <div className="leave-radio-button-inner-div">
                      <input
                        name="halfday"
                        type="radio"
                        value="secondhalft"
                        className="age-input"
                      />
                      <label className="age-label   font-weight500    font-size-heading">Second Half</label>
                    </div>
                  </div>
                </div>
              )}

              {age === "shortleave" && (
                <div>
                  <div className="flex-column single-day-date">
                    <label className="form-labels  font-weight500    font-size-subheading">Date</label>
                    <input
                      type="date"
                      className="form-input   font-weight500   form-input-background"
                      onChange={(e) =>
                        setdataa({
                          ...dataa,
                          date: e.target.value,
                          end_date: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="flex-row multi-day-input">
                    <div className="flex-column multi-day">
                      <label className="form-labels  font-weight500    font-size-subheading">Start Time</label>
                      <input
                        className="multi-day-input1 form-input-background"
                        type="time"
                        step="1"
                        min="09:30:00"
                        max="16:30:00"
                        onChange={(e) => getEndTime(e.target.value)}
                      />
                    </div>
                    <div className="flex-column multi-day">
                      <label className="form-labels  font-weight500    font-size-subheading">End Time</label>
                      <input
                        className="multi-day-input1 form-input-background"
                        type="time"
                        step="1"
                        min="09:30:00"
                        max="16:30:00"
                        value={dataa.out_time}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              )}
              {errors.date && (
                <span className="error-message font-size-text ">{errors.date}</span>
              )}
              <div className="flex-column">
                <label className="form-labels  font-weight500    font-size-subheading">Description</label>
                <textarea
                  className="form-input-textarea   font-weight400  form-input-background"
                  placeholder="Reason"
                  type="text"
                  value={dataa.text}
                  onChange={(e) => {
                    if (e.target.value.length <= 50) {
                      setdataa({ ...dataa, remarks: e.target.value });
                      setRemarksError(""); // Clear error message if within the limit
                    } else {
                      setRemarksError(
                        "Ensure this field has no more than 50 characters."
                      );
                    }
                  }}
                />

                {remarksError && (
                  <div style={{ color: "red", marginTop: "5px" }}>
                    {remarksError}
                  </div>
                )}
                {errors.remarks && (
                  <span className="error-message font-size-text ">{errors.remarks}</span>
                )}
              </div>
              <div className="flex-row ">
                <label htmlFor="EmpCode" className="form-labels  font-weight500    font-size-subheading">
                  RH {rhApproval ? "Approved" : "Pending"} : {" "}
                </label>
                <div className="checkbox-toggle">
                  <input
                    type="checkbox"
                    id="toggle1"
                    checked={rhApproval}
                    onChange={() => setRhApproval(!rhApproval)}
                  />
                  <label htmlFor="toggle1"></label>
                </div>
              </div>
              <div className="button-models">
                {/* <button className="model-button   font-weight500    ">Cancel</button> */}
                <button
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  Apply
                </button>
              </div>
            </form>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};

// const AdminRequestapproved = ({ i, getoutdutyname }) => {
//   const [loading, setLoading] = useState(false); //loading logic

//   const handleFormSubmitPut = async (e) => {
//     e.preventDefault();
//     setLoading(true); //loading logic
//     const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
//     let Description = ''

//     try {
//       let res = await axios.put(`${BASE_URL}/wfm/adminleaveapproval/${i.id}/`, {
//         admin_leave_status: "approved",
//       }, AUTH);

//       console.log("Data Before")
//       console.log(i)

//       if (res.status === 200) {
//         console.log("adminleaveapproval Data")
//         console.log(res.data.data)
//         let leaves = await axios.get(`${BASE_URL}/wfm/leavebalance/${i.empcode}/`);

//         if (leaves.status === 200) {
//           console.log("leavebalance Data")
//           console.log(leaves.data)


//           let deductions = [];

//           if (i.sick_leave !== leaves.data.sick_leave) {
//             deductions.push(`${i.sick_leave - leaves.data.sick_leave} leave from sick leave`);
//           }
//           if (i.casual_leave !== leaves.data.casual_leave) {
//             deductions.push(`${i.casual_leave - leaves.data.casual_leave} leave from casual leave`);
//           }
//           if (i.earn_leave !== leaves.data.earn_leave) {
//             deductions.push(`${i.earn_leave - leaves.data.earn_leave} leave from earn leave`);
//           }
//           if (i.leavebalance !== leaves.data.leavebalance) {
//             deductions.push(`${i.leavebalance - leaves.data.leavebalance} leave from previous leave`);
//           }

//           if (i.date === i.end_date) {
//             Description = `${i.leaveasked} leave deducted for leave request of ${formattedDateLong(i.date)}`;
//           } else {
//             Description = `${i.leaveasked} leave deducted for leave request of ${formattedDateLong(i.date)} to ${formattedDateLong(i.end_date)}`;
//           }

//           if (deductions.length > 0) {
//             Description += `. Leaves Deducted: ${deductions.join(', ')}.`;
//           }

//           let log = await axios.post(`${BASE_URL}/wfm/leaveLog/${i.empcode}/`, {
//             emp_code: i.empcode,
//             leave_type: i.leave_type,
//             action: "deduct",
//             sick_leave_before: i.sick_leave,
//             sick_leave_after: leaves.data.sick_leave,
//             casual_leave_before: i.casual_leave,
//             casual_leave_after: leaves.data.casual_leave,
//             earn_leave_before: i.earn_leave,
//             earn_leave_after: leaves.data.earn_leave,
//             previous_leave_before: i.leavebalance,
//             previous_leave_after: leaves.data.leavebalance,
//             affected: i.leaveasked || 0.0,
//             description: Description || null,
//           });
//           if (log.status === 200) {
//             await getoutdutyname();
//           }
//         }

//         // setShow(false);
//       } else {
//         alert(res);
//       }
//     } catch (err) {
//       console.log(err)
//       //toast Logic
//       if (err.response) {
//         const errorData = err.response.data;

//         if (typeof errorData.error === "string") {
//           toast.error(`Error: ${errorData.error}`);
//         } else if (typeof errorData === "object" && errorData !== null) {
//           if (errorData.detail && errorData.code && Array.isArray(errorData.messages)) {
//             toast.error(`Detail: ${errorData.detail}`);
//             toast.error(`Code: ${errorData.code}`);
//             errorData.messages.forEach((messageObj) => {
//               Object.entries(messageObj).forEach(([key, value]) => {
//                 toast.error(`${key}: ${value}`);
//               });
//             });
//           } else {
//             Object.entries(errorData).forEach(([field, messages]) => {
//               if (Array.isArray(messages)) {
//                 messages.forEach((message) =>
//                   toast.error(`"${field}": ${message}`)
//                 );
//               } else {
//                 toast.error(`"${field}": ${messages}`);
//               }
//             });
//           }
//         } else {
//           toast.error("Error:- Failed to Process!");
//         }
//       } else {
//         toast.error("Error processing your request.");
//       }

//     } finally {
//       setLoading(false); // Ensure loading state is stopped even in case of error //loading logic
//       toast.dismiss(loadingToastId);
//     }
//   };

//   return (
//     <>
//       <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
//       <button className="model-edit-button" title='Approve Leave' onClick={handleFormSubmitPut} disabled={loading} >{/* //loading logic */}
//         <svg
//           xmlns="http://www.w3.org/2000/svg"
//           width="18"
//           height="18"
//           viewBox="0 0 18 18"
//           fill="none"
//         >
//           <path
//             d="M15 4.5L6.75 12.75L3 9"
//             stroke="#145650"
//             strokeWidth="2"
//             strokeLinecap="round"
//             strokeLinejoin="round"
//           />
//         </svg>
//       </button>
//     </>
//   );
// };

// backup2
// const AdminRequestapproved = ({ i, getoutdutyname }) => {
//   const [loading, setLoading] = useState(false); //loading logic

//   const handleFormSubmitPut = async (e) => {
//     e.preventDefault();
//     setLoading(true); //loading logic
//     const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
//     let Description = '';
//     let affectedAmount = i.leaveasked;

//     try {
//       let res = await axios.put(`${BASE_URL}/wfm/adminleaveapproval/${i.id}/`, {
//         admin_leave_status: "approved",
//       }, AUTH);

//       console.log("Data Before");
//       console.log(i);

//       if (res.status === 200) {
//         console.log("adminleaveapproval Data");
//         console.log(res.data.data);
//         let leaves = await axios.get(`${BASE_URL}/wfm/leavebalance/${i.empcode}/`);

//         if (leaves.status === 200) {
//           console.log("leavebalance Data");
//           console.log(leaves.data);

//           let deductions = [];
//           const totalLeaveBalance =
//             parseFloat(leaves.data.sick_leave) +
//             parseFloat(leaves.data.casual_leave) +
//             parseFloat(leaves.data.earn_leave) +
//             parseFloat(leaves.data.leavebalance);

//           // Calculate deductions
//           if (i.sick_leave !== leaves.data.sick_leave && (i.sick_leave - leaves.data.sick_leave) > 0) {
//             deductions.push(`${i.sick_leave - leaves.data.sick_leave} leave from sick leave`);
//           }
//           if (i.casual_leave !== leaves.data.casual_leave && (i.casual_leave - leaves.data.casual_leave) > 0) {
//             deductions.push(`${i.casual_leave - leaves.data.casual_leave} leave from casual leave`);
//           }
//           if (i.earn_leave !== leaves.data.earn_leave && (i.earn_leave - leaves.data.earn_leave) > 0) {
//             deductions.push(`${i.earn_leave - leaves.data.earn_leave} leave from earn leave`);
//           }
//           if (i.leavebalance !== leaves.data.leavebalance && (i.leavebalance - leaves.data.leavebalance) > 0) {
//             deductions.push(`${i.leavebalance - leaves.data.leavebalance} leave from previous leave`);
//           }

//           // Check if leave requested exceeds total leave balance
//           if (parseFloat(i.leaveasked) > totalLeaveBalance) {
//             // Update affected to reflect actual deductions
//             const actualDeductions = {
//               sick_leave: i.sick_leave - leaves.data.sick_leave,
//               casual_leave: i.casual_leave - leaves.data.casual_leave,
//               earn_leave: i.earn_leave - leaves.data.earn_leave,
//               previous_leave: i.leavebalance - leaves.data.leavebalance,
//             };
//             affectedAmount = Object.values(actualDeductions).filter(amount => amount < 0).reduce((sum, amount) => sum + Math.abs(amount), 0);

//             // Description += `. Total leave deducted: ${affectedAmount}.`;
//           }

//           if (i.date === i.end_date) {
//             Description = `${affectedAmount} leave deducted for leave request of ${formattedDateLong(i.date)}`;
//           } else {
//             Description = `${affectedAmount} leave deducted for leave request of ${formattedDateLong(i.date)} to ${formattedDateLong(i.end_date)}`;
//           }

//           // Add deductions if any
//           if (deductions.length > 0) {
//             Description += `. Leaves Deducted: ${deductions.join(', ')}.`;
//           }

//           // Check if total leave balance is greater than zero before logging
//           if (totalLeaveBalance > 0) {
//             // let log = await axios.post(`${BASE_URL}/wfm/leaveLog/${i.empcode}/`, {
//             let log = await axios.post(`${BASE_URL}/wfm/leavelogbypass/`, {
//               emp_code: i.empcode,
//               date_time: new Date().toISOString(),
//               leave_type: i.leave_type,
//               action: "DEDUCTED",
//               sick_leave_before: i.sick_leave,
//               sick_leave_after: leaves.data.sick_leave,
//               casual_leave_before: i.casual_leave,
//               casual_leave_after: leaves.data.casual_leave,
//               earn_leave_before: i.earn_leave,
//               earn_leave_after: leaves.data.earn_leave,
//               previous_leave_before: i.leavebalance,
//               previous_leave_after: leaves.data.leavebalance,
//               affected: affectedAmount,
//               description: Description,
//             });

//             if (log.status === 200) {
//               await getoutdutyname();
//             }
//           }
//           else {
//             await getoutdutyname();
//           }
//         }

//         // setShow(false);
//       } else {
//         alert(res);
//       }
//     } catch (err) {
//       console.log("err : AdminRequestapproved");
//       console.log(err);
//       handleAllError(err, loadingToastId);
//     } finally {
//       setLoading(false); // Ensure loading state is stopped even in case of error //loading logic
//       toast.dismiss(loadingToastId);
//     }
//   };

//   return (
//     <>
//       <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
//       <button className="model-edit-button" title='Approve Leave' onClick={handleFormSubmitPut} disabled={loading}>
//         <svg
//           xmlns="http://www.w3.org/2000/svg"
//           width="18"
//           height="18"
//           viewBox="0 0 18 18"
//           fill="none"
//         >
//           <path
//             d="M15 4.5L6.75 12.75L3 9"
//             stroke="#145650"
//             strokeWidth="2"
//             strokeLinecap="round"
//             strokeLinejoin="round"
//           />
//         </svg>
//       </button>
//     </>
//   );
// };

const AdminRequestapproved = ({ i, getoutdutyname }) => {
  const [loading, setLoading] = useState(false); //loading logic

  const handleFormSubmitPut = async (e) => {
    e.preventDefault();
    setLoading(true); //loading logic
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
    let Description = '';
    let affectedAmount = 0;  // Initialize affectedAmount

    try {
      let res = await axios.put(`${BASE_URL}/wfm/adminleaveapproval/${i.id}/`, {
        admin_leave_status: "approved",
        rh_leave_status: "approved",
        hr_update_datetime: new Date().toISOString(),
      }, AUTH);

      console.log("Data Before");
      console.log(i);

      if (res.status === 200) {
        console.log("adminleaveapproval Data");
        console.log(res.data.data);
        let leaves = await axios.get(`${BASE_URL}/wfm/leavebalance/${i.empcode}/`);

        if (leaves.status === 200) {
          console.log("leavebalance Data");
          console.log(leaves.data);

          let deductions = [];
          const totalLeaveBalanceBefore =
            parseFloat(i.sick_leave) +
            parseFloat(i.casual_leave) +
            parseFloat(i.earn_leave) +
            parseFloat(i.leavebalance);
          const totalLeaveBalanceAfter =
            parseFloat(leaves.data.sick_leave) +
            parseFloat(leaves.data.casual_leave) +
            parseFloat(leaves.data.earn_leave) +
            parseFloat(leaves.data.leavebalance);


          // Calculate deductions and update affectedAmount
          if (i.sick_leave !== leaves.data.sick_leave) {
            const deduction = i.sick_leave - leaves.data.sick_leave;
            if (deduction > 0) {
              deductions.push(`${deduction} leave from sick leave`);
              affectedAmount += deduction;  // Add to affectedAmount
            }
          }
          if (i.casual_leave !== leaves.data.casual_leave) {
            const deduction = i.casual_leave - leaves.data.casual_leave;
            if (deduction > 0) {
              deductions.push(`${deduction} leave from casual leave`);
              affectedAmount += deduction;  // Add to affectedAmount
            }
          }
          if (i.earn_leave !== leaves.data.earn_leave) {
            const deduction = i.earn_leave - leaves.data.earn_leave;
            if (deduction > 0) {
              deductions.push(`${deduction} leave from earn leave`);
              affectedAmount += deduction;  // Add to affectedAmount
            }
          }
          if (i.leavebalance !== leaves.data.leavebalance) {
            const deduction = i.leavebalance - leaves.data.leavebalance;
            if (deduction > 0) {
              deductions.push(`${deduction} leave from previous leave`);
              affectedAmount += deduction;  // Add to affectedAmount
            }
          }

          // Build description based on affectedAmount and deductions
          if (i.date === i.end_date) {
            Description = `${affectedAmount} leave deducted for leave request of ${formattedDateLong(i.date)}`;
          } else {
            Description = `${affectedAmount} leave deducted for leave request of ${formattedDateLong(i.date)} to ${formattedDateLong(i.end_date)}`;
          }

          // Add deductions if any
          if (deductions.length > 0) {
            Description += `. Leaves Deducted: ${deductions.join(', ')}.`;
          }

          // Check if total leave balance is greater than zero before logging
          if (totalLeaveBalanceBefore > 0) {
            // let log = await axios.post(`${BASE_URL}/wfm/leavelogbypass/`, {
            //   emp_code: i.empcode,
            //   date_time: new Date().toISOString(),
            //   leave_date: i.date,
            //   leave_type: i.leave_type,
            //   action: "DEDUCTED",
            //   sick_leave_before: i.sick_leave,
            //   sick_leave_after: leaves.data.sick_leave,
            //   casual_leave_before: i.casual_leave,
            //   casual_leave_after: leaves.data.casual_leave,
            //   earn_leave_before: i.earn_leave,
            //   earn_leave_after: leaves.data.earn_leave,
            //   previous_leave_before: i.leavebalance,
            //   previous_leave_after: leaves.data.leavebalance,
            //   affected: affectedAmount,
            //   description: Description,
            // });

            // if (log.status === 200) {
            //   await getoutdutyname();
            // }

            await getoutdutyname();

          } else {
            await getoutdutyname();
          }
        }

        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      console.log("err : AdminRequestapproved");
      console.log(err);
      handleAllError(err, loadingToastId);
    } finally {
      setLoading(false); // Ensure loading state is stopped even in case of error //loading logic
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <button className="model-edit-button" title='Approve Leave' onClick={handleFormSubmitPut} disabled={loading}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15 4.5L6.75 12.75L3 9"
            stroke="#145650"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </>
  );
};


const AdminRequestDelete = ({ i, getoutdutyname }) => {
  const handleFormSubmitPut = async (e) => {
    e.preventDefault();

    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
    try {
      let res = await axios.put(`${BASE_URL}/wfm/leaverhput/${i.id}/`, {
        admin_leave_status: "rejected",
        hr_update_datetime: new Date().toISOString(),
      });

      if (res.status === 200) {
        await getoutdutyname();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <button className="model-delete-button" title='Reject Leave' onClick={handleFormSubmitPut}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M13.5 4.5L4.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M4.5 4.5L13.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </>
  );
};

const AdminOutdutyRequestapproved = ({ i, getoutdutyname }) => {
  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmitPut = async (e) => {
    e.preventDefault();
    setLoading(true); //loading logic

    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
    try {
      let res = await axios.put(`${BASE_URL}/wfm/adminoutdutyapproval/${i.id}/`, {
        admin_leave_status: "approved",
        rh_leave_status: "approved",
        hr_update_datetime: new Date().toISOString(),
      }, AUTH);

      if (res.status === 200) {
        await getoutdutyname();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      setLoading(false); //loading logic
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <button className="model-edit-button" onClick={handleFormSubmitPut} disabled={loading}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15 4.5L6.75 12.75L3 9"
            stroke="#145650"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </>
  );
};

const AdminOutdutyRequestDelete = ({ i, getoutdutyname }) => {
  const handleFormSubmitPut = async (e) => {
    e.preventDefault();

    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
    try {
      let res = await axios.put(`${BASE_URL}/wfm/odrhput/${i.id}/`, {
        admin_leave_status: "rejected",
        hr_update_datetime: new Date().toISOString(),
      });

      if (res.status === 200) {
        await getoutdutyname();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <button className="model-delete-button" onClick={handleFormSubmitPut}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M13.5 4.5L4.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M4.5 4.5L13.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </>
  );
};

const AdminapprovedHR = ({ i, getImprestname }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleFormSubmitPut = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.put(`${BASE_URL}/wfm/imprest/${i.id}/`, {
        admin_leave_status: "approved",
        admin_update_datetime: new Date().toISOString(),
        admin_update_by: sessionStorage.getItem('emp_code'),
      }, AUTH);

      if (res.status === 200) {
        await getImprestname();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      handleAllError(err, loadingToastId)
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <button className="model-edit-button" title="Admin Imprest Approved" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15 4.5L6.75 12.75L3 9"
            stroke="#145650"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Body>
          <div className="parent-div">

            <div className="bdy-div">
              <h6 className="task-Tab-heading align-center font-weight500 font-size-heading">Confirm Admin Approval of Imprest "{i.project_name}" </h6>
              <div className="flex-row">
                <label htmlFor="date" className="form-labels font-weight500 announce-date font-weight400  font-size-heading">
                  Date :
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {formattedDateLong(i.date)}
                </div>
              </div>

              <div className="flex-row">
                <label
                  htmlFor="project_name"
                  className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                >
                  Project :
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {i.project_name}
                </div>
              </div>

              <div className="flex-row">
                <label htmlFor="total" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Total Expense -
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {
                    Number(i.approved_transportation) +
                    Number(i.approved_accomodation) +
                    Number(i.approved_fooding) +
                    (i.other_details?.length > 0
                      ? i.other_details.reduce((total, other) => total + Number(other.approved_other), 0)
                      : 0)
                  }
                </div>
              </div>

              <div className="flex-row">
                <label
                  htmlFor="transportation"
                  className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                >
                  Transportation -
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {i.approved_transportation}
                </div>
              </div>

              <div className="flex-row">
                <label
                  htmlFor="accomodation"
                  className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                >
                  Accommodation -
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {i.approved_accomodation}
                </div>
              </div>

              <div className="flex-row">
                <label htmlFor="fooding" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Food -
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {i.approved_fooding}
                </div>
              </div>

              <div className="flex-column">
                <label className="form-labels font-weight500 announce-date font-weight400 font-size-heading">
                  Other Expenses:
                </label>
                {i.other_details?.length > 0 ? (
                  <ul style={{ paddingLeft: '0', marginLeft: '0', listStylePosition: 'inside' }}>
                    {i.other_details.map((other, index) => (
                      <li key={index} className="form-labels announce-date font-size-heading">
                        <span className="form-text-trasformation-uppercase">
                          {other.other_name ? other.other_name : `Other-${index + 1}`} : {other.approved_other}
                        </span>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <span>No Other Expense <br /></span>
                )}
              </div>

              <div className="flex-row">
                <label
                  htmlFor="no_of_days"
                  className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                >
                  Number's of days :
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {i.no_of_days}
                </div>
              </div>

              <div className="flex-row">
                <label htmlFor="person" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Number's of Person :
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {i.multipleperson?.length}
                </div>

              </div>
              <div className="flex-column">
                <label htmlFor="person" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Name of Person :
                </label>
                {i.multiple_person_details.map((is) => {
                  return (
                    <div className="form-labels announce-date font-size-heading">
                      {is.emp_code} - {is.name}
                      <br />
                    </div>
                  );
                })}
              </div>

              <div className="flex-column">
                <label htmlFor="remarks" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Remarks :
                </label>
                {i.remarks}
                <div className="form-labels announce-date font-size-heading">
                </div>
              </div>

              <div className="button-models">
                <button className="model-button model-button-cancel  font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmitPut}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>

        </Modal.Body>
      </Modal >
    </>
  );
};

const AdminDeleteHR = ({ i, getImprestname }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [formData, setFormData] = useState({
    admin_rejection_reason: "",
  });

  console.log(formData);
  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["admin_rejection_reason"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleFormSubmitPut = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

      try {
        let res = await axios.put(`${BASE_URL}/wfm/imprest/${i.id}/`, {
          admin_leave_status: "rejected",
          rh_leave_status: "pending",
          admin_rejection_reason: formData.admin_rejection_reason,
          admin_update_datetime: new Date().toISOString(),
          admin_update_by: sessionStorage.getItem('emp_code'),
        });

        if (res.status === 200) {
          await getImprestname();
          // setShow(false);
        } else {
          alert(res);
        }
      } catch (err) {
        handleErrorToast(err, loadingToastId)
      } finally {
        toast.dismiss(loadingToastId);
      }
    }
  };

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <button className="model-delete-button" title="Admin Imprest Reject" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M13.5 4.5L4.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M4.5 4.5L13.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              <div className="flex-column">
                <label htmlFor="admin_rejection_reason" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Admin Rejection Reason
                </label>
                <textarea
                  id="admin_rejection_reason"
                  type="text"
                  name="admin_rejection_reason"
                  placeholder="Your Reason"
                  onChange={handleInputChange}
                  maxLength={100}
                  value={formData.admin_rejection_reason}
                  className={`form-input-textarea   font-weight400  font-size-subheading ${errors.admin_rejection_reason
                    ? "error"
                    : inputState.admin_rejection_reason
                      ? "success"
                      : ""
                    }`}
                />
                {errors.admin_rejection_reason && (
                  <span className="error-message font-size-text ">{errors.admin_rejection_reason}</span>
                )}
              </div>

              <div className="button-models">
                <button className="model-button model-button-cancel   font-weight500" onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button   font-weight500    model-button-delete    font-size-heading"
                  onClick={handleFormSubmitPut}
                >
                  Reject
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const CompoffShortLeaveApproved = ({ i, getCompensatoryList }) => {
  const handleFormSubmitPut = async (e) => {
    e.preventDefault();

    try {
      let res = await axios.get(`${BASE_URL}/wfm/shortleaveandcompoffbyID/${i.id}/`);


      if (res.status === 200) {
        await getCompensatoryList();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <button className="model-edit-button" title="CompOff TL Approve" onClick={handleFormSubmitPut}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15 4.5L6.75 12.75L3 9"
            stroke="#145650"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </>
  );
};

const CompoffShortLeaveReject = ({ i, getCompensatoryList }) => {
  const handleFormSubmitPut = async (e) => {
    e.preventDefault();

    try {
      let res = await axios.get(`${BASE_URL}/wfm/shortleaveandcompoffbyIDrejected/${i.id}/`);

      if (res.status === 200) {
        await getCompensatoryList();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <button className="model-delete-button" title="CompOff TL Reject" onClick={handleFormSubmitPut}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M13.5 4.5L4.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M4.5 4.5L13.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </>
  );
};


const ExpenseapprovedHR = ({ i, getexpansename }) => {
  const handleFormSubmitPut = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.put(`${BASE_URL}/wfm/expense/${i.id}/`, {
        admin_leave_status: "approved",
      }, AUTH);

      if (res.status === 200) {
        await getexpansename();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <button className="model-edit-button" onClick={handleFormSubmitPut}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15 4.5L6.75 12.75L3 9"
            stroke="#145650"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </>
  );
};

const ExpenseDeleteHR = ({ i, getexpansename }) => {
  const handleFormSubmitPut = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.put(`${BASE_URL}/wfm/expense/${i.id}/`, {
        admin_leave_status: "rejected",
      });

      if (res.status === 200) {
        await getexpansename();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <button className="model-delete-button" onClick={handleFormSubmitPut}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M13.5 4.5L4.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M4.5 4.5L13.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </>
  );
};

const ImprestApprovedAccount = ({ i, getImprestname }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const [fileName, setFileName] = useState("");
  const [formData, setFormData] = useState({
    account_leave_status: "approved",
    account_approved_final_details: "",
    account_transaction_proof: null,
    account_update_datetime: new Date().toISOString(),
    account_update_by: sessionStorage.getItem('emp_code'),
  });

  const totalApproved =
    (Number(i.approved_transportation) +
      Number(i.approved_accomodation) +
      Number(i.approved_fooding) +
      // Number(i.approved_other)
      (i.other_details?.length > 0
        ? i.other_details.reduce((total, other) => total + Number(other.approved_other), 0)
        : 0));

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});
  const validateForm = () => {
    const newErrors = {};
    const requiredFields =
      totalApproved === 0 ? [] :
        [
          "account_approved_final_details",
          // "account_transaction_proof"
        ];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const { name } = e.target;
    const file = e.target.files[0];

    if (file) {
      setFormData({ ...formData, [name]: file, });
      setFileName(file.name);
    } else {
      setFileName("");
    }
  };

  const handleClear = () => {
    setFormData(prevState => ({
      ...prevState,
      account_transaction_proof: null
    }));
    setFileName("");

  }

  const handleFormSubmitPut = async (e) => {

    e.preventDefault();
    if (validateForm()) {
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

      const formDataa = new FormData();

      const symbols = '!@#$%^&*()_-+=';
      const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz';
      const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      const numbers = '0123456789';
      const now = new Date();
      const date = now.toLocaleDateString('en-GB').split('/').reverse().join('-'); // Format: YYYY-MM-DD
      const time = now.toLocaleTimeString('en-GB', { hour12: false }).replace(/:/g, '-'); // Format: HH-MM-SS
      const allChars = symbols + lowercaseLetters + uppercaseLetters + numbers;
      let randomCode = '';
      for (let i = 0; i < 8; i++) {
        const randomIndex = Math.floor(Math.random() * allChars.length);
        randomCode += allChars[randomIndex];
      }
      if (formData.account_transaction_proof && formData.account_approved_final_details) {
        const originalFile = formData?.account_transaction_proof;
        const customFileName = `Aimantra ${date} ${time}_${randomCode} ${originalFile?.name}`;
        const customFile = new File([originalFile], customFileName, { type: originalFile.type });

        formDataa.append("account_approved_final_details", formData.account_approved_final_details);
        formDataa.append("account_transaction_proof", customFile);
      }
      formDataa.append("account_update_datetime", formData.account_update_datetime);
      formDataa.append("account_update_by", formData.account_update_by);
      formDataa.append("account_leave_status", formData.account_leave_status);
      formDataa.append("other_details", [null]);
      try {
        let res = await axios.put(`${BASE_URL}/wfm/imprest/${i.id}/`, formDataa, AUTHH, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (res.status === 200) {
          await getImprestname();
          setShow(false);
        } else {
          alert(res);
        }
      } catch (err) {
        handleErrorToast(err, loadingToastId)
      } finally {
        toast.dismiss(loadingToastId);
      }
    }
  };

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <button className="model-edit-button" title="Account Imprest Approved" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15 4.5L6.75 12.75L3 9"
            stroke="#145650"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Body>
          <div className="parent-div">

            <div className="bdy-div">
              <h6 className="task-Tab-heading align-center font-weight500 font-size-heading">Confirm Account Approval of Imprest "{i.project_name}" </h6>
              <div className="flex-row">
                <label htmlFor="date" className="form-labels font-weight500 announce-date font-weight400  font-size-heading">
                  Date :
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {formattedDateLong(i.date)}
                </div>
              </div>

              <div className="flex-row">
                <label
                  htmlFor="project_name"
                  className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                >
                  Project :
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {i.project_name}
                </div>
              </div>


              <div className="flex-row">
                <label htmlFor="total" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Total Expense -
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {
                    Number(i.approved_transportation) +
                    Number(i.approved_accomodation) +
                    Number(i.approved_fooding) +
                    // Number(i.approved_other)
                    (i.other_details?.length > 0
                      ? i.other_details.reduce((total, other) => total + Number(other.approved_other), 0)
                      : 0)
                  }
                </div>
              </div>

              <div className="flex-row">
                <label
                  htmlFor="transportation"
                  className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                >
                  Transportation -
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {i.approved_transportation}
                </div>

              </div>

              <div className="flex-row">
                <label
                  htmlFor="accomodation"
                  className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                >
                  Accommodation -
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {i.approved_accomodation}
                </div>

              </div>

              <div className="flex-row">
                <label htmlFor="fooding" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Food -
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {i.approved_fooding}
                </div>
              </div>

              <div className="flex-column">
                <label className="form-labels font-weight500 announce-date font-weight400 font-size-heading">
                  Other Expenses:
                </label>
                {i.other_details?.length > 0 ? (
                  <ul style={{ paddingLeft: '0', marginLeft: '0', listStylePosition: 'inside' }}>
                    {i.other_details.map((other, index) => (
                      <li key={index} className="form-labels announce-date font-size-heading">
                        <span className="form-text-trasformation-uppercase">
                          {other.other_name ? other.other_name : `Other-${index + 1}`} : {other.approved_other}
                        </span>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <span>No Other Expense <br /></span>
                )}
              </div>

              <div className="flex-row">
                <label
                  htmlFor="no_of_days"
                  className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                >
                  Number's of days :
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {i.no_of_days}
                </div>
              </div>


              <div className="flex-row">
                <label htmlFor="person" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Number's of Person :
                </label>
                <div className="form-labels announce-date font-size-heading">
                  {i.multipleperson?.length}
                </div>

              </div>
              <div className="flex-column">
                <label htmlFor="person" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Name of Person :
                </label>
                {i.multiple_person_details.map((is) => {
                  return (
                    <div className="form-labels announce-date font-size-heading">
                      {is.emp_code} - {is.name}
                      <br />
                    </div>
                  );
                })}

              </div>


              <div className="flex-column">
                <label htmlFor="remarks" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Remarks :
                </label>
                {i.remarks}
                <div className="form-labels announce-date font-size-heading">
                </div>
              </div>


              {totalApproved === 0 ? null :
                <>
                  <div className="flex-column">
                    <label
                      htmlFor="account_approved_final_details"
                      className="form-labels  font-weight500    announce-date font-weight400  font-size-heading"
                    >
                      Final Approval Details (Transaction / Cheque no.)
                    </label>
                    <input
                      id="account_approved_final_details"
                      name="account_approved_final_details"
                      placeholder="Final Approval Details (Transaction / Cheque no.)"
                      onChange={handleInputChange}
                      value={formData.account_approved_final_details}
                      className={`form-input ${errors.account_approved_final_details
                        ? "error"
                        : inputState.account_approved_final_details
                          ? "success"
                          : ""
                        }`}
                    />
                    {errors.account_approved_final_details && (
                      <span className="error-message font-size-text ">{errors.account_approved_final_details}</span>
                    )}
                  </div>

                  <div className="flex-row">
                    <label htmlFor="" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                      Account Transaction Proof :
                    </label>
                    <div className="flex-column" >
                      <label
                        htmlFor="account_transaction_proof"
                        className={`align-center  form-inputss custom-file-upload 
                    ${errors.account_transaction_proof
                            ? "error"
                            : inputState.account_transaction_proof
                              ? "success"
                              : ""
                          }
                       `}
                      >
                        <svg
                          className="pdf-input"
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M17.8666 9.2081L10.2082 16.8664C9.27005 17.8046 7.99757 18.3317 6.67075 18.3317C5.34393 18.3317 4.07145 17.8046 3.13325 16.8664C2.19505 15.9282 1.66797 14.6558 1.66797 13.3289C1.66797 12.0021 2.19505 10.7296 3.13325 9.79144L10.7916 2.1331C11.4171 1.50763 12.2654 1.15625 13.1499 1.15625C14.0345 1.15625 14.8828 1.50763 15.5082 2.1331C16.1337 2.75857 16.4851 3.60689 16.4851 4.49144C16.4851 5.37598 16.1337 6.2243 15.5082 6.84977L7.84158 14.5081C7.52885 14.8208 7.10469 14.9965 6.66242 14.9965C6.22014 14.9965 5.79598 14.8208 5.48325 14.5081C5.17051 14.1954 4.99482 13.7712 4.99482 13.3289C4.99482 12.8867 5.17051 12.4625 5.48325 12.1498L12.5582 5.0831"
                            stroke="#707070"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </label>
                      <input
                        id="account_transaction_proof"
                        name="account_transaction_proof"
                        type="file"
                        onChange={handleFileChange}
                        onFocus={handleClear}
                        className="file-inputs align-center"
                        style={{ display: "none", position: "relative" }}
                      />
                      <br />
                      <div className="flex-row justify-evenly">
                        <div>
                          {fileName && <p style={{ margin: "0px" }}>{fileName}</p>}
                        </div>
                      </div>
                    </div>
                    {formData.account_transaction_proof ?
                      <div>
                        <button className="model-button" onClick={handleClear}>Clear</button>
                      </div>
                      : null
                    }
                    {errors.account_transaction_proof && (
                      <span className="error-message font-size-text ">{errors.account_transaction_proof}</span>
                    )}
                  </div>
                </>
              }
              <br />

              <div className="button-models">
                <button className="model-button model-button-cancel  font-weight500   " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button   font-weight500    model-button-leave  font-weight500    font-size-heading"
                  onClick={handleFormSubmitPut}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>

        </Modal.Body>
      </Modal >
    </>
  );
};

const ImprestDeleteAccount = ({ i, getImprestname }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [formData, setFormData] = useState({
    account_rejection_reason: "",
  });

  console.log(formData);
  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["account_rejection_reason"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleFormSubmitPut = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

      try {
        let res = await axios.put(`${BASE_URL}/wfm/imprest/${i.id}/`, {
          account_leave_status: "rejected",
          account_rejection_reason: formData.account_rejection_reason,
          account_update_datetime: new Date().toISOString(),
          account_update_by: sessionStorage.getItem('emp_code'),
        });

        if (res.status === 200) {
          await getImprestname();
          // setShow(false);
        } else {
          alert(res);
        }
      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }
      } finally {
        toast.dismiss(loadingToastId);
      }
    }
  };

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <button className="model-delete-button" title="Accounts Imprest Reject" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M13.5 4.5L4.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M4.5 4.5L13.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        {/* <Modal.Header closeButton>
          <Modal.Title>Rejection Reason</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">

              <div className="flex-column">
                <label htmlFor="account_rejection_reason" className="form-labels  font-weight500    announce-date font-weight400  font-size-heading">
                  Account Rejection Reason
                </label>
                <textarea
                  id="account_rejection_reason"
                  type="text"
                  name="account_rejection_reason"
                  placeholder="Your Reason"
                  onChange={handleInputChange}
                  maxLength={100}
                  value={formData.account_rejection_reason}
                  className={`form-input-textarea   font-weight400  font-size-subheading ${errors.account_rejection_reason
                    ? "error"
                    : inputState.account_rejection_reason
                      ? "success"
                      : ""
                    }`}
                />
                {errors.account_rejection_reason && (
                  <span className="error-message font-size-text ">{errors.account_rejection_reason}</span>
                )}
              </div>

              <div className="button-models">
                <button className="model-button model-button-cancel   font-weight500" onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button   font-weight500    model-button-delete    font-size-heading"
                  onClick={handleFormSubmitPut}
                >
                  Reject
                </button>
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

        </Modal.Body>
      </Modal>
    </>
  );
};

const ExpenseApprovedAccountStage1 = ({ i, getexpansename }) => {
  const handleFormSubmitPut = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.put(`${BASE_URL}/wfm/expense/${i.id}/`, {
        account_status_a: "approved",
      }, AUTH);

      if (res.status === 200) {
        await getexpansename();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <button className="model-edit-button" title="Accounts Stage 1 Approve" onClick={handleFormSubmitPut}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15 4.5L6.75 12.75L3 9"
            stroke="#145650"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </>
  );
};

const ExpenseDeleteAccountStage1 = ({ i, getexpansename }) => {
  const handleFormSubmitPut = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.put(`${BASE_URL}/wfm/expense/${i.id}/`, {
        account_status_a: "rejected",
      });

      if (res.status === 200) {
        await getexpansename();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <button className="model-delete-button" title="Accounts Stage 1 Reject" onClick={handleFormSubmitPut}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M13.5 4.5L4.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M4.5 4.5L13.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </>
  );
};

const ExpenseApprovedAccountStage2 = ({ i, getexpansename }) => {
  const handleFormSubmitPut = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.put(`${BASE_URL}/wfm/expense/${i.id}/`, {
        account_status_b: "approved",
      }, AUTH);

      if (res.status === 200) {
        await getexpansename();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      toast.dismiss(loadingToastId);
    }
  };
  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <button className="model-edit-button" title="Accounts Stage 2 Approve" onClick={handleFormSubmitPut}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15 4.5L6.75 12.75L3 9"
            stroke="#145650"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </>
  );
};

const ExpenseDeleteAccountStage2 = ({ i, getexpansename }) => {
  const handleFormSubmitPut = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.put(`${BASE_URL}/wfm/expense/${i.id}/`, {
        account_status_b: "rejected",
      });

      if (res.status === 200) {
        await getexpansename();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <button className="model-delete-button" title="Accounts Stage 2 Reject" onClick={handleFormSubmitPut}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M13.5 4.5L4.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M4.5 4.5L13.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </>
  );
};

const RequisitionApprovedRH = ({ i, getRequisitions }) => {
  const handleFormSubmitPut = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.put(`${BASE_URL}/jd/requisition/${i.id}/`, {
        rh_status: "approved",
      });

      if (res.status === 200) {
        await getRequisitions();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <button className="model-edit-button" title="Approve Request" onClick={handleFormSubmitPut}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15 4.5L6.75 12.75L3 9"
            stroke="#145650"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </>
  );
};

const RequisitionRejectRH = ({ i, getRequisitions }) => {
  const handleFormSubmitPut = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.put(`${BASE_URL}/jd/requisition/${i.id}/`, {
        rh_status: "rejected",
      });

      if (res.status === 200) {
        await getRequisitions();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <button className="model-delete-button" title="Reject Request" onClick={handleFormSubmitPut}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M13.5 4.5L4.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M4.5 4.5L13.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </>
  );
};

const RequisitionApprovedAdmin = ({ i, getRequisitions }) => {
  const handleFormSubmitPut = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.put(`${BASE_URL}/jd/requisition/${i.id}/`, {
        admin_status: "approved",
      });

      if (res.status === 200) {
        await getRequisitions();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <button className="model-edit-button" title="Approve Request" onClick={handleFormSubmitPut}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15 4.5L6.75 12.75L3 9"
            stroke="#145650"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </>
  );
};

const RequisitionRejectAdmin = ({ i, getRequisitions }) => {
  const handleFormSubmitPut = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.put(`${BASE_URL}/jd/requisition/${i.id}/`, {
        admin_status: "rejected",
      });

      if (res.status === 200) {
        await getRequisitions();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <button className="model-delete-button" title="Reject Request" onClick={handleFormSubmitPut}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M13.5 4.5L4.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M4.5 4.5L13.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </>
  );
};

const RequisitionApprovedTLAdmin = ({ i, getRequisitions }) => {
  const handleFormSubmitPut = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.put(`${BASE_URL}/jd/requisition/${i.id}/`, {
        rh_status: "approved",
        admin_status: "approved",
      });

      if (res.status === 200) {
        await getRequisitions();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <button className="model-edit-button" title="Approve Requests" onClick={handleFormSubmitPut}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15 4.5L6.75 12.75L3 9"
            stroke="#145650"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </>
  );
};

const RequisitionRejectTLAdmin = ({ i, getRequisitions }) => {
  const handleFormSubmitPut = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.put(`${BASE_URL}/jd/requisition/${i.id}/`, {
        rh_status: "rejected",
        admin_status: "rejected",
      });

      if (res.status === 200) {
        await getRequisitions();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <button className="model-delete-button" title="Reject Requests" onClick={handleFormSubmitPut}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M13.5 4.5L4.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M4.5 4.5L13.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </>
  );
};

const RequisitionApprovedHR = ({ i, getRequisitions }) => {
  const handleFormSubmitPut = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.put(`${BASE_URL}/jd/requisition/${i.id}/`, {
        hr_status: "approved",
      });

      if (res.status === 200) {
        await getRequisitions();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <button className="model-edit-button" title="Approve Request" onClick={handleFormSubmitPut}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15 4.5L6.75 12.75L3 9"
            stroke="#145650"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </>
  );
};

const RequisitionRejectHR = ({ i, getRequisitions }) => {
  const handleFormSubmitPut = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      let res = await axios.put(`${BASE_URL}/jd/requisition/${i.id}/`, {
        hr_status: "rejected",
      });

      if (res.status === 200) {
        await getRequisitions();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }

    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <button className="model-delete-button" title="Reject Request" onClick={handleFormSubmitPut}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M13.5 4.5L4.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M4.5 4.5L13.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </>
  );
};

const ClockInApprovedTL = ({ i, getClockInList }) => {
  const handleFormSubmitPut = async (e) => {
    e.preventDefault();

    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic
    try {
      // let res = await axios.get(`${BASE_URL}/wfm/clockIbyID/${i.id}/`);
      let res = await axios.put(`${BASE_URL}/wfm/clockinrequestapproved/${i.id}/`, {
        rh_leave_status: "approved",
        admin_leave_status: "approved",
        remark: 'Confirmed'
      });

      if (res.status === 200) {
        await getClockInList();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <button className="model-edit-button" title="Clockin TL Approve" onClick={handleFormSubmitPut}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          fill="none"
          viewBox="0 0 18 18"
        >
          <path
            d="M15 4.5L6.75 12.75L3 9"
            stroke="#145650"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </>
  );
};

const ClockInRejectTL = ({ i, getClockInList }) => {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setFormData(i);
    setShow(true);

  };

  const [formData, setFormData] = useState({
    remarks: "",

  });

  // useEffect(() => {
  //   setFormData(i);
  // }, [i]);
  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["remarks"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // ?  ************** Validation End **************  ! //
  const handleFormSubmitPut = async (e) => {
    e.preventDefault();
    const updatedformvalue = {
      ...formData,
      rh_leave_status: "rejected",
      admin_leave_status: "rejected",
    };
    if (validateForm()) {
      const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

      try {
        // let res = await axios.get(`${BASE_URL}/wfm/clockIbyIDrejected/${i.id}/`);
        let res = await axios.put(`${BASE_URL}/wfm/clockinrequestupdate/${i.id}/`, {
          updatedformvalue
        });
        if (res.status === 200) {
          await getClockInList();
        } else {
          alert(res);
        }
      } catch (err) {
        //toast Logic
        if (err.response) {
          toast.dismiss(loadingToastId);
          const errorData = err.response.data;

          if (typeof errorData.error === 'string') {
            // Single error message
            toast.error(`Error: ${errorData.error}`);
          } else if (typeof errorData === 'object' && errorData !== null) {
            // Multiple error messages
            Object.entries(errorData).forEach(([field, messages]) => {
              messages.forEach(message => toast.error(`"${field}": ${message}`));
            });
          } else {
            toast.error('Error:- Failed to Process!');
          }
        } else {
          toast.error('Error processing your request.');
        }

      } finally {
        setShow(false);
        toast.dismiss(loadingToastId);
      }
    }
  };

  return (
    <>
      <button className="model-delete-button" title="Clockin TL Reject" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M13.5 4.5L4.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M4.5 4.5L13.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave width-40vw">
        <Modal.Header closeButton>
          <Modal.Title>Reject for : {i.name}-{formattedDateLong(i.date)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="flex-column">
              <label htmlFor="remarks" className="form-labels  font-weight500    font-size-subheading">
                Reason for Rejection<span className="required"></span>
              </label>
              <textarea
                type="text"
                id="remarks"
                name="remarks"
                maxLength={150}
                placeholder="Reason..."
                onChange={handleInputChange}
                value={formData.remarks}
                className={`form-input-textarea   font-weight400  font-size-subheading ${errors.remarks ? "error" : inputState.remarks ? "success" : ""
                  }`}
              />
              {errors.remarks && (
                <span className="error-message font-size-text ">{errors.remarks}</span>
              )}
            </div>



            <div className="button-models">
              <button className="model-button   font-weight500   " onClick={handleClose}>
                Close
              </button>
              <button
                className="model-button   font-weight500    model-button-submit"
                onClick={handleFormSubmitPut}
              >
                Reject
              </button>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Modal.Body>
      </Modal>
    </>
  );
};

const ClockInApprovedHR = ({ i, getClockInList }) => {
  const handleFormSubmitPut = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      // let res = await axios.get(`${BASE_URL}/wfm/clockIbyID/${i.id}/`);

      let res = await axios.put(`${BASE_URL}/wfm/clockIbyID/${i.id}/`, {
        hr_leave_status: "approved",
      });
      if (res.status === 200) {
        await getClockInList();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <button className="model-edit-button" onClick={handleFormSubmitPut}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15 4.5L6.75 12.75L3 9"
            stroke="#145650"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </>
  );
};

const ClockInRejectHR = ({ i, getClockInList }) => {
  const handleFormSubmitPut = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

    try {
      // let res = await axios.get(`${BASE_URL}/wfm/clockIbyIDrejected/${i.id}/`);
      let res = await axios.put(`${BASE_URL}/wfm/clockIbyIDrejected/${i.id}/`, {
        hr_leave_status: "rejected",
      });
      if (res.status === 200) {
        await getClockInList();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      //toast Logic
      if (err.response) {
        toast.dismiss(loadingToastId);
        const errorData = err.response.data;

        if (typeof errorData.error === 'string') {
          // Single error message
          toast.error(`Error: ${errorData.error}`);
        } else if (typeof errorData === 'object' && errorData !== null) {
          // Multiple error messages
          Object.entries(errorData).forEach(([field, messages]) => {
            messages.forEach(message => toast.error(`"${field}": ${message}`));
          });
        } else {
          toast.error('Error:- Failed to Process!');
        }
      } else {
        toast.error('Error processing your request.');
      }
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <button className="model-delete-button" onClick={handleFormSubmitPut}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M13.5 4.5L4.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M4.5 4.5L13.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </>
  );
};

export {
  Requestapproved,
  RequestDelete,
  RequestOutdutyapproved,
  RequestOutdutyDelete,
  HRleaveUpdate,
  HROutdutyUpdate,
  RequestImprestApproved,
  RequestImprestPartialApprove,
  RequestImprestReject,
  RequestExpenseApproved,
  RequestExpenseReject,
  AdminRequestapproved,
  AdminRequestDelete,
  AdminOutdutyRequestapproved,
  AdminOutdutyRequestDelete,
  AdminapprovedHR,
  AdminDeleteHR,
  CompoffShortLeaveApproved,
  CompoffShortLeaveReject,
  ExpenseapprovedHR,
  ExpenseDeleteHR,
  ImprestApprovedAccount,
  ImprestDeleteAccount,
  ExpenseApprovedAccountStage1,
  ExpenseDeleteAccountStage1,
  ExpenseApprovedAccountStage2,
  ExpenseDeleteAccountStage2,
  RequisitionApprovedRH,
  RequisitionRejectRH,
  RequisitionApprovedAdmin,
  RequisitionRejectAdmin,
  RequisitionApprovedTLAdmin,
  RequisitionRejectTLAdmin,
  RequisitionApprovedHR,
  RequisitionRejectHR,
  ClockInApprovedTL,
  ClockInRejectTL,
  ClockInApprovedHR,
  ClockInRejectHR,
};
