import React, { useState } from "react";
import Dashboardnavbarcopy from "../../layout/Dashboardnavbarcopy";
import {
  BankFormat,
  BankSubmitFormat,
  EmployeeDetails,
  MonthlySalary,
  PayRun,
  PrintFormat,
} from "./PayrollTables";
import { PaymentDashboard } from './PayrollComponent';


const PayrollManagement = () => {
  const [toggleState, setToggleState] = useState(2);

  const getSelectedTitle = () => {
    switch (toggleState) {
      case 1:
        return "PayRoll Dashboard";
      case 2:
        return "Pay Run";
      case 3:
        return "Monthly Salary";
      case 4:
        return "Employee Details";
      case 5:
        return "Bank Format";

      default:
        return "Monthly Salary";
    }
  };

  return (
    <>
      <div>
        <div>
          <Dashboardnavbarcopy name={getSelectedTitle()} url="Pay Roll" />
          <div className="bloc-tabss  wfm-margin">
            {/* <button
              className={
                toggleState === 1
                  ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss dashboardcomponent-active-tabs"
                  : "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss"
              }
              onClick={() => setToggleState(1)}
            >
              PayRoll Dashboard
            </button> */}
            <button
              className={
                toggleState === 2
                  ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss dashboardcomponent-active-tabs"
                  : "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss"
              }
              onClick={() => setToggleState(2)}
            >
              Pay Run
            </button>
            {/* <button
              className={
                toggleState === 3
                  ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss dashboardcomponent-active-tabs"
                  : "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss"
              }
              onClick={() => setToggleState(3)}
            >
              Monthly Salary
            </button>
            <button
              className={
                toggleState === 4
                  ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss dashboardcomponent-active-tabs"
                  : "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss"
              }
              onClick={() => setToggleState(4)}
            >
              Employee Details
            </button>
            <button
              className={
                toggleState === 5
                  ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss dashboardcomponent-active-tabs"
                  : "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss"
              }
              onClick={() => setToggleState(5)}
            >
              Bank Format
            </button> */}
            {/* <button
             */}
            {/* <button
              className={
                toggleState === 7
                  ? "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss dashboardcomponent-active-tabs"
                  : "dashboardcomponent-tabs  font-weight400  font-size-subheading   dashboardcomponent-tabss"
              }
              onClick={() => setToggleState(7)}
            >
              Bank Submition Format
            </button> */}
          </div>
        </div>

        <div className="content-tabs">

          {toggleState === 1 ? <PaymentDashboard /> : null}
          {toggleState === 2 ? <PayRun /> : null}
          {toggleState === 3 ? <MonthlySalary /> : null}
          {toggleState === 4 ? <EmployeeDetails /> : null}
          {toggleState === 5 ? <BankFormat /> : null}
          {toggleState === 6 ? <PrintFormat /> : null}
          {toggleState === 7 ? <BankSubmitFormat /> : null}
        </div>
      </div>
    </>
  );
};

export default PayrollManagement;
