import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../config/axios"
import { useNavigate, useParams } from "react-router-dom";
import Careernavbar from "./update_details/Careernavbar";
import TimelineItem from "../Timeline";
import { ToastContainer, toast } from "react-toastify";
import { PersonaldetailsAdd, EducationdetailsAdd, WorkexperienceAdd, Documentdetails } from "./CareerComponent";


const Personaldetails = () => {


    let { id, email } = useParams()


    return (
        <>
            <Careernavbar id={id} />
            <div className="jd">


                {/*  ************** timeline Seaction Start  ************** */}

                <div className="jd-timeline">
                    <TimelineItem heading="Personal Information" color="#2576BC" />
                    <div className="jd-timeline-border timeline-color"></div>

                    <TimelineItem heading="Position Information" color="#707070" />
                    <div className="jd-timeline-border"></div>

                    <TimelineItem heading="Education Detail" color="#707070" />
                    <div className="jd-timeline-border"></div>

                    <TimelineItem heading="Work Experience" color="#707070" />
                    <div className="jd-timeline-border"></div>

                    <TimelineItem heading="Documents Upload" color="#707070" />
                </div>


                {/*  ************** timeline Seaction Close ************** */}

                <PersonaldetailsAdd email={email} id={id} forwardroutepath="positiondetails" />
            </div >
        </>
    );
};

const Positiondetails = () => {

    let { id, email } = useParams()

    const [data, setData] = useState(false)
    const [buffer, setBuffering] = useState(true); //buffering logic

    const [formData, setFormData] = useState({
        currentCTC: "",
        monthlyinhand: "",
        expectedCTC: "",
        applyingForPosition: "",
        areaOfInterest: "",
        noticePeriod: "",
        availableToJoin: "",
        workedBefore: false,
        workedBeforeInfo: "",
        yearofExp: "",
        bestAchieve: "",
        awardAchieve: "",
        accomplishments: "",


    });


    useEffect(() => {
        // const storedUserData = Cookies.get("userData");

        const getUSerDetails = async () => {
            setBuffering(true); //buffering logic // Start Buffering
            try {
                const res = await axios.get(`${BASE_URL}/userdata/positiondata/${email}/`)

                // Merge the data from both responses into one formData object

                setFormData(res.data);
                setData(true)


                // setDataFetched(true);
            } catch (err) {
                console.error('Error fetching user data:', err);
                // setDataFetched(false);
                // Handle error: You may want to display an error message to the user or do something else

                // toast Logic
                // if (err.response) {
                //     const errorData = err.response.data;

                //     if (typeof errorData.error === 'string') {
                //       // Single error message
                //       toast.error(`Error: ${errorData.error}`);
                //     } else if (typeof errorData === 'object' && errorData !== null) {
                //       // Multiple error messages
                //       Object.entries(errorData).forEach(([field, messages]) => {
                //         messages.forEach(message => toast.error(`"${field}": ${message}`));
                //       });
                //     } else {
                //       toast.error('Error:- Failed to Process!');
                //     }
                //   } else {
                //     toast.error('Error processing your request.');
                //   }
            } finally {
                setBuffering(false);// End Buffering
            }
        };



        getUSerDetails();

    }, [email]);



    console.log(formData)



    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = [
            "expectedCTC",
            "yearofExp",
            "applyingForPosition",
            "noticePeriod",
            "yearofExp",
            "availableToJoin",
            // formData.workedBefore === true ? "workedBeforeInfo" : null,

        ];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };


    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;

        setFormData((prevData) => ({
            ...prevData,
            [name]: checked,
        }));

    };



    // ?  ************** Validation End **************  ! //
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false); //loading logic

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            setLoading(true); //loading logic
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic

            const updatedFormData = {
                ...formData,
                user: email,
            };



            try {
                let res = await axios.post(`${BASE_URL}/userdata/positiondata/`, updatedFormData,)


                if (res.status === 200) {
                    navigate(`/educationdetail/${id}/${email}/`)
                } else {
                    alert(res);
                }
            } catch (err) {
                //toast Logic
                if (err.response) {
                    toast.dismiss(loadingToastId);
                    const errorData = err.response.data;

                    if (typeof errorData.error === 'string') {
                        // Single error message
                        toast.error(`Error: ${errorData.error}`);
                    } else if (typeof errorData === 'object' && errorData !== null) {
                        // Multiple error messages
                        Object.entries(errorData).forEach(([field, messages]) => {
                            messages.forEach(message => toast.error(`"${field}": ${message}`));
                        });
                    } else {
                        toast.error('Error:- Failed to Process!');
                    }
                } else {
                    toast.error('Error processing your request.');
                }
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);
            }
        }
    };


    const handleFormPut = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            setLoading(true); //loading logic
            const loadingToastId = toast.loading("Loading: Please wait..."); //toast Logic


            const updatedFormData = {
                ...formData,
                user: email,
            };




            try {
                let res = await axios.put(`${BASE_URL}/userdata/positiondata/${email}/`, updatedFormData,)




                if (res.status === 200) {
                    navigate(`/educationdetail/${id}/${email}/`)

                } else {
                    alert(res);
                }
            } catch (err) {
                //toast Logic
                if (err.response) {
                    toast.dismiss(loadingToastId);
                    const errorData = err.response.data;

                    if (typeof errorData.error === 'string') {
                        // Single error message
                        toast.error(`Error: ${errorData.error}`);
                    } else if (typeof errorData === 'object' && errorData !== null) {
                        // Multiple error messages
                        Object.entries(errorData).forEach(([field, messages]) => {
                            messages.forEach(message => toast.error(`"${field}": ${message}`));
                        });
                    } else {
                        toast.error('Error:- Failed to Process!');
                    }
                } else {
                    toast.error('Error processing your request.');
                }
            } finally {
                setLoading(false); //loading logic
                toast.dismiss(loadingToastId);

            }
        }
    };


    const [jobposition, setJobPosition] = useState([])


    const getJobpositionlist = async () => {
        setBuffering(true); //buffering logic // Start Buffering
        try {
            const res = await axios.get(`${BASE_URL}/jd/jobpostfilter/null/${sessionStorage.getItem("company_id")}/`);
            setJobPosition(res.data)
        } catch (err) {
            // alert(err.message);

            // toast Logic

            // if (err.response) {
            //     const errorData = err.response.data;

            //     if (typeof errorData.error === 'string') {
            //       // Single error message
            //       toast.error(`Error: ${errorData.error}`);
            //     } else if (typeof errorData === 'object' && errorData !== null) {
            //       // Multiple error messages
            //       Object.entries(errorData).forEach(([field, messages]) => {
            //         messages.forEach(message => toast.error(`"${field}": ${message}`));
            //       });
            //     } else {
            //       toast.error('Error:- Failed to Process!');
            //     }
            //   } else {
            //     toast.error('Error processing your request.');
            //   }
        } finally {
            setBuffering(false);// End Buffering
        }
    };

    useEffect(() => {
        getJobpositionlist();
    }, []);




    const navigatee = useNavigate()


    const backroute = () => {
        navigatee(`/personaldetails/${id}/${email}`)
    }



    return (
        <>
            {buffer ? <div className="spinner-z"></div> : null}{/* //buffering logic */}
            <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <Careernavbar id={id} />
            <div className="jd">
                {/*  ************** timeline Seaction  ************** */}

                <div className="jd-timeline">
                    <TimelineItem heading="Personal Information" color="#2576BC" />
                    <div className="jd-timeline-border timeline-color"></div>

                    <TimelineItem heading="Position Information" color="#2576BC" />

                    <div className="jd-timeline-border"></div>

                    <TimelineItem heading="Education Detail" color="#707070" />
                    <div className="jd-timeline-border"></div>
                    <TimelineItem heading="Work Experience" color="#707070" />
                    <div className="jd-timeline-border"></div>
                    <TimelineItem heading="Documents Upload" color="#707070" />
                </div>

                <div className="jd-heading-outer">
                    <h2 className="jd-heading-main font-weight600  font-size-heading">Position Information*</h2>
                    <div>
                        <div className="jd-heading-bottom-bold"></div>
                        <div className="jd-heading-bottom-light"></div>
                    </div>

                </div>

                <form className="register-form">
                    <div className="form-flex-wrap">


                        <div className="flex-column">
                            <label htmlFor="currentCTC" className="form-labels  font-weight500    font-size-subheading">
                                Monthly Current CTC
                            </label>
                            <input
                                type="number"
                                id="currentCTC"
                                name="currentCTC"
                                placeholder="Enter your current CTC"
                                onChange={handleInputChange}
                                min={0}
                                step="1"
                                value={formData.currentCTC}
                                onKeyPress={(e) => {
                                    // Prevent the entry of decimal points
                                    if (e.key === '.' || e.key === 'e' || e.key === '-') {
                                        e.preventDefault();
                                    }
                                }}
                                className={`form-input ${errors.currentCTC ? "error" : inputState.currentCTC ? "success" : ""}`}
                            />
                            {errors.currentCTC && (
                                <span className="error-message font-size-text ">{errors.currentCTC}</span>
                            )}
                        </div>

                        <div className="flex-column">
                            <label htmlFor="expectedCTC" className="form-labels  font-weight500    font-size-subheading">
                                Monthly Expected CTC<span className="required">*</span>
                            </label>
                            <input
                                type="number"
                                id="expectedCTC"
                                name="expectedCTC"
                                placeholder="Enter your expected CTC"
                                onChange={handleInputChange}
                                value={formData.expectedCTC}
                                step="1"
                                min={0}
                                onKeyPress={(e) => {
                                    // Prevent the entry of decimal points
                                    if (e.key === '.' || e.key === 'e' || e.key === '-') {
                                        e.preventDefault();
                                    }
                                }}
                                className={`form-input ${errors.expectedCTC ? "error" : inputState.expectedCTC ? "success" : ""}`}
                            />
                            {errors.expectedCTC && (
                                <span className="error-message font-size-text ">{errors.expectedCTC}</span>
                            )}
                        </div>


                        <div className="flex-column">
                            <label htmlFor="monthlyinhand" className="form-labels  font-weight500    font-size-subheading">
                                Monthly Current Inhand
                            </label>
                            <input
                                type="number"
                                id="monthlyinhand"
                                name="monthlyinhand"
                                placeholder="Enter your monthly Inhand"
                                onChange={handleInputChange}
                                value={formData.monthlyinhand}
                                step="1"
                                min={0}
                                onKeyPress={(e) => {
                                    // Prevent the entry of decimal points
                                    if (e.key === '.' || e.key === 'e' || e.key === '-') {
                                        e.preventDefault();
                                    }
                                }}
                                className={`form-input ${errors.monthlyinhand ? "error" : inputState.monthlyinhand ? "success" : ""}`}
                            />
                            {errors.monthlyinhand && (
                                <span className="error-message font-size-text ">{errors.monthlyinhand}</span>
                            )}
                        </div>

                        {/* 
                        <div className="flex-column">
                            <label htmlFor="applyingForPosition" className="form-labels  font-weight500    font-size-subheading">
                                Applying for Position
                            </label>
                            <input
                                type="text"
                                id="applyingForPosition"
                                name="applyingForPosition"
                                placeholder="Enter the position you are applying for"
                                onChange={handleInputChange}
                                maxLength={60}
                                value={formData.applyingForPosition}
                                className={`form-input ${errors.applyingForPosition ? "error" : inputState.applyingForPosition ? "success" : ""}`}
                            />
                            {errors.applyingForPosition && (
                                <span className="error-message font-size-text ">{errors.applyingForPosition}</span>
                            )}
                        </div> */}


                        <div className="flex-column form-group-selectt">
                            <label htmlFor="department" className="form-labels  font-weight500    font-size-subheading">
                                Applying for Position<span className="required">*</span>
                            </label>
                            <br />
                            <select
                                id="applyingForPosition"
                                name="applyingForPosition"
                                value={formData.applyingForPosition}
                                onChange={handleInputChange}
                                className={`form-input form-group-selection ${errors.applyingForPosition ? "error" : inputState.applyingForPosition ? "success" : ""
                                    }`}
                            >
                                <option value="">Available post</option>

                                {/* {jobposition?.sort((a, b) => a.department_name.localeCompare(b.department_name, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                                    <option value={`${i.department_name} - ${i.position_name}`}>{i.department_name} - {i.position_name}
                                    </option>
                                </>))
                                } */}
                                {jobposition
                                    .filter(i => i.status === 'active')
                                    .sort((a, b) => a.department_name?.localeCompare(b.department_name, 'en', { ignorePunctuation: true }))
                                    .map((i, index) => (
                                        // <option value={`${i.department_name} - ${i.position_name}`}>
                                        <option value={i.position_name}>
                                            {i.department_name} - {i.position_name}
                                        </option>
                                    ))}
                            </select>
                            <div className="form-group-selection-arrow">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="25"
                                    fill="currentColor"
                                    class="bi bi-caret-down-fill"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                </svg>
                            </div>
                            <div>
                                {errors.department && (
                                    <span className="error-message font-size-text ">{errors.department}</span>
                                )}
                            </div>
                        </div>
                        <div className="flex-column">
                            <label htmlFor="areaOfInterest" className="form-labels  font-weight500    font-size-subheading">
                                Area of Interest
                            </label>
                            <input
                                type="text"
                                id="areaOfInterest"
                                name="areaOfInterest"
                                placeholder="Enter your area of interest"
                                onChange={handleInputChange}
                                maxLength={60}
                                value={formData.areaOfInterest}
                                className={`form-input ${errors.areaOfInterest ? "error" : inputState.areaOfInterest ? "success" : ""}`}
                            />
                            {errors.areaOfInterest && (
                                <span className="error-message font-size-text ">{errors.areaOfInterest}</span>
                            )}
                        </div>

                        <div className="flex-column">
                            <label htmlFor="noticePeriod" className="form-labels  font-weight500    font-size-subheading">
                                Notice Period (days)<span className="required">*</span>
                            </label>
                            <input
                                type="number"
                                id="noticePeriod"
                                name="noticePeriod"
                                min={0}
                                placeholder="Enter your notice period in days"
                                onChange={handleInputChange}
                                maxLength={60}
                                value={formData.noticePeriod}
                                className={`form-input ${errors.noticePeriod ? "error" : inputState.noticePeriod ? "success" : ""}`}
                            />
                            {errors.noticePeriod && (
                                <span className="error-message font-size-text ">{errors.noticePeriod}</span>
                            )}
                        </div>
                        <div className="flex-column">
                            <label htmlFor="yearofExp" className="form-labels  font-weight500    font-size-subheading">
                                Year of Experience ?<span className="required">*</span>
                            </label>
                            <input
                                type="number"
                                min={0}
                                id="yearofExp"
                                name="yearofExp"
                                placeholder="Enter your Your Experience"
                                onChange={handleInputChange}
                                value={formData.yearofExp}
                                className={`form-input ${errors.yearofExp ? "error" : inputState.yearofExp ? "success" : ""}`}
                            />
                            {errors.yearofExp && (
                                <span className="error-message font-size-text ">{errors.yearofExp}</span>
                            )}
                        </div>

                        <div className="flex-column">
                            <label htmlFor="availableToJoin" className="form-labels  font-weight500    font-size-subheading">
                                When will you be available to join?<span className="required">*</span>
                            </label>
                            <input
                                type="text"
                                id="availableToJoin"
                                name="availableToJoin"
                                placeholder="Enter your availability information"
                                onChange={handleInputChange}
                                maxLength={40}
                                value={formData.availableToJoin}
                                className={`form-input ${errors.availableToJoin ? "error" : inputState.availableToJoin ? "success" : ""}`}
                            />
                            {errors.availableToJoin && (
                                <span className="error-message font-size-text ">{errors.availableToJoin}</span>
                            )}
                        </div>
                        {/* <div >

                        </div> */}

                        <div className="flex-column">

                            <div className="flex-row">
                                <label htmlFor="workedBefore" className="form-labels  font-weight500    font-size-subheading">
                                    Have you worked for the company before?
                                    {formData.workedBefore ? <span className="required">*</span> : ""}
                                </label>
                                <input
                                    type="checkbox"
                                    id="workedBefore"
                                    name="workedBefore"
                                    checked={formData.workedBefore}
                                    onChange={handleCheckboxChange}

                                    className="input-checkbox"
                                />
                            </div>
                        </div>
                        <div className="flex-column">
                        </div>
                        {formData.workedBefore === true ?

                            (<>
                                <br />
                                <br />
                                <div className="flex-column">

                                    <label htmlFor="workedBefore" className="form-labels-grey  font-weight500    font-size-subheading">
                                        Please provide your department, manager, the time period you have worked for Company andreason for leaving.
                                        <span className="required">*</span>
                                    </label>
                                    <textarea
                                        type="text"
                                        id="workedBeforeInfo"
                                        name="workedBeforeInfo"
                                        placeholder="Reason"
                                        onChange={handleInputChange}
                                        value={formData.workedBeforeInfo}
                                        maxLength={300}
                                        className={`form-input-textarea   font-weight400  font-size-subheading`}
                                    // className={`form-input-textarea   font-weight400  font-size-subheading ${errors.workedBefore ? "error"
                                    //     : inputState.workedBefore ? "success"
                                    //         : ""
                                    //     }`}
                                    />
                                </div>
                            </>
                            )
                            : <>
                            </>
                        }




                    </div>


                </form>


                <div className="jd-heading-outer">
                    <h2 className="jd-heading-main font-weight600  font-size-heading">Achievement
                        Details*</h2>
                    <div>
                        <div className="jd-heading-bottom-bold"></div>
                        <div className="jd-heading-bottom-light"></div>
                    </div>
                </div>

                <form className="register-form">
                    <div className="form-flex-wrap">


                        <div className="flex-column">
                            <label htmlFor="bestAchieve" className="form-labels  font-weight500    font-size-subheading">
                                What is your best achievement ?
                                {/* <span className="required">*</span> */}
                            </label>
                            <input
                                type="text"
                                id="bestAchieve"
                                name="bestAchieve"
                                placeholder="Enter your  best achievement"
                                onChange={handleInputChange}
                                maxLength={100}
                                value={formData.bestAchieve}
                                className={`form-input ${errors.bestAchieve ? "error" : inputState.bestAchieve ? "success" : ""}`}
                            />
                            {errors.bestAchieve && (
                                <span className="error-message font-size-text ">{errors.bestAchieve}</span>
                            )}
                        </div>

                        <div className="flex-column">
                            <label htmlFor="awardAchieve" className="form-labels  font-weight500    font-size-subheading">
                                Do you have any award achievement for your all career ?
                                {/* <span className="required">*</span> */}
                            </label>
                            <input
                                type="text"
                                id="awardAchieve"
                                name="awardAchieve"
                                maxLength={100}
                                placeholder="Enter name of award"
                                onChange={handleInputChange}
                                value={formData.awardAchieve}
                                className={`form-input ${errors.awardAchieve ? "error" : inputState.awardAchieve ? "success" : ""}`}
                            />
                            {errors.awardAchieve && (
                                <span className="error-message font-size-text ">{errors.awardAchieve}</span>
                            )}
                        </div>


                        <div className="flex-column">
                            <label htmlFor="accomplishments" className="form-labels  font-weight500    font-size-subheading">
                                Do you have accomplishments you are proud of ?
                                {/* <span className="required">*</span> */}
                            </label>
                            <input
                                type="text"
                                id="accomplishments"
                                name="accomplishments"
                                maxLength={100}
                                placeholder="Write Answer"
                                onChange={handleInputChange}
                                value={formData.accomplishments}
                                className={`form-input ${errors.accomplishments ? "error" : inputState.accomplishments ? "success" : ""}`}
                            />
                            {errors.accomplishments && (
                                <span className="error-message font-size-text ">{errors.accomplishments}</span>
                            )}
                        </div>



                    </div>
                    <div className="button-models">
                        <button className="model-button   font-weight500   " onClick={backroute}>Back</button>

                        {data ? (<button onClick={handleFormPut} className="model-button   font-weight500    model-button-submit" disabled={loading} >
                            Next
                        </button>) : (<button onClick={handleFormSubmit} className="model-button   font-weight500    model-button-submit" disabled={loading} >
                            Next
                        </button>)}
                        {/* //loading logic*/}
                    </div>
                </form>
            </div>
        </>
    );
};


const EducationDetail = () => {

    let { id, email } = useParams()




    return (

        <>
            <Careernavbar id={id} />
            <div className="jd">
                {/*  ************** timeline Seaction  ************** */}

                <div className="jd-timeline">
                    <TimelineItem heading="Personal Information" color="#2576BC" />
                    <div className="jd-timeline-border timeline-color"></div>

                    <TimelineItem heading="Position Information" color="#2576BC" />
                    <div className="jd-timeline-border timeline-color"></div>

                    <TimelineItem heading="Education Detail" color="#2576BC" />
                    <div className="jd-timeline-border"></div>
                    <TimelineItem heading="Work Experience" color="#707070" />
                    <div className="jd-timeline-border"></div>
                    <TimelineItem heading="Documents Upload" color="#707070" />
                </div>

                <EducationdetailsAdd id={id} email={email} forwardroutepath="workexprience" backroutepath="positiondetails" />

            </div>

        </>
    );
};

const Workexprience = () => {

    let { id, email } = useParams()


    return (

        <>
            <Careernavbar id={id} />


            <div className="jd">
                {/*  ************** timeline Seaction  ************** */}

                <div className="jd-timeline">
                    <TimelineItem heading="Personal Information" color="#2576BC" />
                    <div className="jd-timeline-border timeline-color"></div>

                    <TimelineItem heading="Position Information" color="#2576BC" />
                    <div className="jd-timeline-border timeline-color"></div>

                    <TimelineItem heading="Education Detail" color="#2576BC" />
                    <div className="jd-timeline-border timeline-color"></div>
                    <TimelineItem heading="Work Experience" color="#2576BC" />
                    <div className="jd-timeline-border "></div>
                    <TimelineItem heading="Documents Upload" color="#707070" />
                </div>


                <WorkexperienceAdd email={email} id={id} forwardroutepath="document" backroutepath="educationdetail" />
            </div>

        </>
    );
};

const DocumentdetailsPage = () => {


    let { id, email } = useParams()





    return (
        <>
            <Careernavbar id={id} />
            <div className="jd">
                {/*  ************** timeline Seaction  ************** */}

                <div className="jd-timeline">
                    <TimelineItem heading="Personal Information" color="#2576BC" />
                    <div className="jd-timeline-border timeline-color"></div>

                    <TimelineItem heading=
                        "Position Information" color="#2576BC" />
                    <div className="jd-timeline-border timeline-color"></div>

                    <TimelineItem heading="Education Detail" color="#2576BC" />

                    <div className="jd-timeline-border timeline-color"></div>

                    <TimelineItem heading="Work Experience" color="#2576BC" />
                    <div className="jd-timeline-border timeline-color"></div>
                    <TimelineItem heading="Documents Upload" color="#2576BC" />
                </div>


                <Documentdetails email={email} id={id} backroutepath="workexprience" />
            </div>

        </>
    );
};


export { Personaldetails, Positiondetails, EducationDetail, Workexprience, DocumentdetailsPage };
